export enum PatientManagementRequestTypeEnum {
  JOINT_ADMISSION = 'Admission',
  JOINT_ADMISSION_UI = 'Joint Admission',
  JOINT_ADMISSION_ROUTE_PARAM = 'joint-admission',
  CONSULTATION = 'Consultation',
  CONSULTATION_UI = 'Consultation',
  CONSULTATION_ROUTE_PARAM = 'consultation',
  CASE_TRANSFER = 'Patient Transfer',
  CASE_TRANSFER_UI = 'Case Transfer',
  CASE_TRANSFER_ROUTE_PARAM = 'case-transfer',
  ADD_BED_UI = 'Add Bed',
  ADD_BED_ROUTE_PARAM = 'add-bed',
  TRANSFER_BED_UI = 'Transfer Bed',
  TRANSFER_BED_ROUTE_PARAM = 'transfer-bed',
  RELEASE_BED_UI = 'Release Bed',
  RELEASE_BED_ROUTE_PARAM = 'release-bed',
  BED_TRANSFER = 'Bed Transfer',
  BED_TRANSFER_UI = 'Bed Transfer',
  BED_TRANSFER_ROUTE_PARAM = 'bed-transfer'
}

export enum PatientManagementListingTypeEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed'
}


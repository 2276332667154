import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-read-only-input',
  template: `
      <div class="read-only-input">
          <span class="{{labelClass}} font-small text-light info-label block mb-5px ">{{ label }} <ng-content select="[labelsuffix]"></ng-content></span>
          <div class="inline-flex info-value word-break {{valueClass}}">{{ value | isEmpty }}</div>
      </div>
  `,
  styles: [`

    .info-value {
      word-break: break-word;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.70);
    }
  `]
})
export class ReadOnlyInputComponent implements OnInit {
  @Input() label: string;
  @Input() value: string | number;
  @Input() valueClass: string;
  @Input() labelClass: string;

  constructor() {
  }

  ngOnInit() {
  }

}

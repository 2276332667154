import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-topbar',
  templateUrl: './topbar.component.html',
  styles: [`
    :host {
      display: block;
      width: 100%;
    }
  `]
})

export class PageTopbarComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}

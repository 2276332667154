export enum AppointmentTypeEnum {
  VISIT = 'OPD',
  ADMISSION = 'IPD'
}

export enum NextAppointmentTypeEnum {
  FOLLOW_UP = 'Follow-Up',
  FOLLOW_UP_VISIT = 'Follow-Up Visit',
  ADMIT_REQUEST = 'Admit Request'
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrimControl]'
})
export class TrimControlDirective {
  element: ElementRef;
  constructor(el: ElementRef) {
    this.element = el;
  }

  @HostListener('blur') onBlur() {
    this.element.nativeElement.value = this.element.nativeElement.value.trim();
    if (this.element.nativeElement.value == ' ') {
      this.element.nativeElement.value = null;
    }
  }

}

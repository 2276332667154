import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {CommonPipesModule} from '../../../../shared/pipes/common-pipes/common-pipes.module';
import {AgeDisplayComponent} from './age-display.component';

@NgModule({
  declarations: [AgeDisplayComponent],
  exports: [
    AgeDisplayComponent
  ],
  imports: [
    CommonModule,
    CommonPipesModule,
    MatTooltipModule,
    PipeModule
  ]
})
export class AgeDisplayModule {
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule, MatIconModule, MatRippleModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslocoModule} from '@ngneat/transloco';
import {StaleDataToastrComponent} from './stale-data-toastr.component';

@NgModule({
  declarations: [StaleDataToastrComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    TranslocoModule
  ],
  entryComponents: [StaleDataToastrComponent]
})
export class StaleDataToastrModule {
}

import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {UtilityService} from '@v2/core/services/utility.service';
import {finalize, take} from 'rxjs/operators';
// import {version} from '../../../../../package.json';
import {AuthService, LoginResponse} from '../../core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'authentication'}]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  errorMessage: string;
  showPassword = true;
  isSubmitting = false;
  appVersion = '14.2.0';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private utilityService: UtilityService,
    private authService: AuthService) {
  }

  get controls() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(formGroupDirective: FormGroupDirective) {
    this.errorMessage = null;
    if (this.loginForm.valid) {
      this.isSubmitting = true;
      this.authService.loginUser(this.loginForm.value)
        .pipe(take(1))
        .subscribe(
          (response) => this.onSuccess(response),
          (error: HttpErrorResponse) => {
            this.handleError(error, formGroupDirective);
          }
        );
    }
  }

  onSuccess(response: LoginResponse) {
    if (response.isOTPRequired) {
      this.router.navigateByUrl('/two-step-verification', {
        queryParams: {data: btoa(JSON.stringify(response))}
      }).then()
    } else {
      this.authService.setupTokenAndUser(response)
        .pipe(take(1), finalize(() => this.isSubmitting = false))
        .subscribe(() => {
          const currentUrl = localStorage.getItem('currentUrl');
          this.router.navigateByUrl(currentUrl || this.authService.defaultURL).then();
        }, () => this.errorMessage = 'Something went wrong, please try again')
    }
  }

  handleError(error: HttpErrorResponse, formGroupDirective: FormGroupDirective) {
    const firstError = this.utilityService.getFirstError(error.error.data);
    this.errorMessage = 'Something went wrong';
    if (firstError && firstError.reason) {
      this.errorMessage = firstError.reason === 'invalid_grant' ? 'Invalid username or password' : firstError.message;
    }
    this.isSubmitting = false;
    formGroupDirective.resetForm({username: this.controls.username.value});
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {AlertNoteLogViewModel} from '@v2/core/models/view/logs.view.model';
import {UtilityService} from '@v2/core/services/utility.service';
import {BaseSubscriptionHandlerClass} from '@v2/core/subscription-handler/subscription-handler.class';
import {finalize} from 'rxjs/operators';
import {NoteAlertService} from '../note-alert.service';

@Component({
  selector: 'app-note-alert-logs-modal',
  templateUrl: './note-alert-logs-modal.component.html',
  styleUrls: ['./note-alert-logs-modal.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class NoteAlertLogsModalComponent extends BaseSubscriptionHandlerClass implements OnInit {

  showLoader = false;
  isLogsLoading = false;
  noteAlertLogsList: AlertNoteLogViewModel[] = [];
  pageFilters: ICommonFilters = {
    filters: {
      page_size: 15
    },
    page: 1
  };
  totalPages = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { alertNoteId: string; },
    private noteAlertService: NoteAlertService,
    private utilityService: UtilityService
  ) {
    super();
  }

  ngOnInit() {
    this.getNoteAlertLogs();
  }

  getNoteAlertLogs(isScrolled = false) {
    this.showLoader = !isScrolled;
    this.isLogsLoading = true;
    this.noteAlertService.getNoteAlertLogs(this.data.alertNoteId, this.pageFilters)
      .pipe(
        this.pickUntil(),
        finalize(() => {
          this.showLoader = false;
          this.isLogsLoading = false;
        })
      )
      .subscribe((response) => {
        this.totalPages = response.totalPages;
        this.noteAlertLogsList = isScrolled ? [...this.noteAlertLogsList, ...response.items] : [...response.items];
      }, (error) => {
        this.utilityService.displayErrorSnackbar(error);
      })
  }

  onScroll() {
    if (!this.showLoader && !this.isLogsLoading) {
      this.pageFilters.page += 1;
      if (this.pageFilters.page <= this.totalPages) {
        this.getNoteAlertLogs(true);
      }
    }
  }

}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '../../../../shared/pipes/pipes.module';
import {DateDisplayComponent} from './date-display.component';

@NgModule({
  declarations: [
    DateDisplayComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [
    DateDisplayComponent
  ]
})
export class DateDisplayModule {
}

import {getName} from '@v2/core/functions/functions';
import {IDoctor} from '@v2/core/models/masterdata';

export class DoctorViewModel {
  id: string;
  name: string;
  xref: string

  fullName: string;
  firstName: string;
  lastName: string;

  static mapFromHttpModel(httpData: IDoctor): DoctorViewModel {
    const viewData = new DoctorViewModel();
    viewData.id = httpData.id;
    viewData.xref = httpData.xref;
    viewData.name = getName(httpData.firstName, httpData.lastName);
    viewData.firstName = httpData.firstName;
    viewData.lastName = httpData.lastName;
    return viewData;
  }
}

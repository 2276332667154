import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {AppointmentTypeEnum} from '@v2/core/enums/appointment-type.enum';
import {getEndOfDay, getStartOfDay} from '@v2/core/functions/functions';
import {IBillingAppointment} from '@v2/core/models/masterdata';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {Observable, Subject} from 'rxjs';
import {pluck, takeUntil, tap} from 'rxjs/operators';
import {AgeDisplayFormatEnum} from '../../../v2/shared-v2/components/age-display/age-display-format.enum';
import {NoteAlertService} from '../../../v2/shared-v2/module/note-alert/note-alert.service';
import {AppointmentHttpService} from '../../../v2/shared-v2/resources/http-service/appointment.http.service';
import {NurseNoteService} from '../../../visits/opd/shared/services/nurse-note.service';
import {PaymentStatusEnum} from '../../enums/payment-status.enum';
import {OpdSummaryPrintViewModalComponent} from './opd-summary-print-view-modal/opd-summary-print-view-modal.component';

@Component({
  selector: 'app-appointment-info',
  templateUrl: './appointment-info.component.html',
  styleUrls: ['./appointment-info.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}],
})
export class AppointmentInfoComponent implements OnInit, OnChanges, OnDestroy {
  // @Input() timelineArr;

  @Input() showContactDetails = false
  @Input() appointmentId = '';

  tlArr = [
    {
      className: 'bg-green',
      text: 'ds2'
    },
    {
      className: 'bg-dark-blue',
      text: 'ns3'
    },
    {
      className: 'bg-green',
      text: 'ds4'
    },
  ];

  @Input() showOPDSummary = false;
  @Input() showPaymentStatus = false;
  @Input() showPaymentStatusBlock = false;

  @Input() isBilling = false;
  @Input() isTimeline = false;

  @Input() includeNursingStations = false;
  @Input() includeBillingClaimInfo = false;
  @Input() includeBillDate = false;
  @Input() includePatientHeightWeightInfo = false;
  @Input() showNonVerifiedPaymentPlan = false
  @Input() appointmentInfoFlag = false;
  @Input() includeCashierNote = false
  @Input() includePaymentCoverage = false
  @Input() includePatientNationality = false;
  @Input() includeDaysAfterORInfo = false;
  @Input() includeLinkedOPDApptInfo = false;
  @Input() refetchDataInParentComponent = false;
  @Input() currentOAId: string;
  @Input() showNoteAlert = true;
  @Input() hasWriteAccess = true;

  @Output() isDischargeEventGenerated = new EventEmitter<boolean>();
  @Output() appointmentInfo = new EventEmitter<IBillingAppointment>();
  @Output() fetchData = new EventEmitter<void>();

  appointmentFilters: ICommonFilters = {
    filters: {
      includes: {
        patient: {
          nationality: true
        },
        patientPaymentPlanPriorities: true,
        patientBillingCode: true,
        doctor: true,
        appointmentEvents: true,
        department: true,
        patientPaymentPlanCoverages: undefined,
        cashierNotes: undefined,
        daysAfterOR: undefined
      },
      filters: {
        currentDate: getStartOfDay()
      }
    },
  };
  eventId: string;
  patientAppointmentInfo$: Observable<IBillingAppointment>;
  hasAppointmentId = false;
  doctorName = '';
  paymentStatusEnum = PaymentStatusEnum;
  ageDisplayFormatEnum = AgeDisplayFormatEnum;
  appointmentData: IBillingAppointment;
  patientNoteAlertCount: number;
  allowOPDSummaryPrint = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    private appointmentHttpService: AppointmentHttpService,
    private masterDataHttpService: MasterDataHttpService,
    private nurseNoteService: NurseNoteService,
    private noteAlertService: NoteAlertService,
    private matDialog: MatDialog
  ) {
  }

  ngOnInit() {
    /* If route is for billing then additional include these filter to get Coverage Amount and Cashier Notes*/
    this.appointmentFilters.filters.includes = {
      ...this.appointmentFilters.filters.includes,
      patientPaymentPlanCoverages: this.includePaymentCoverage ? true : undefined,
      cashierNotes: this.includeCashierNote ? true : undefined,
      apptNS: this.includeNursingStations ? true : undefined,

    }
    this.route.queryParams.subscribe(({appointmentId, eventId}) => {
      this.eventId = eventId;
      this.appointmentId = appointmentId || this.appointmentId;
      if (this.appointmentId) {
        this.hasAppointmentId = true;
        this.getPatientInfo();
      }
    })

    this.nurseNoteService.forwardedDoctor$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(doctor => {
        this.doctorName = doctor.doctorName || this.doctorName;
        this.appointmentData = {...this.appointmentData, doctorName: this.doctorName, doctorId: doctor.doctorId};
        this.appointmentHttpService.setAppointmentData(this.appointmentData)
        this.appointmentInfo.emit(this.appointmentData);
      });

    this.noteAlertService.noteAlertCountUpdated.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (this.appointmentData) {
        if ((this.appointmentData.patientId === data.patientId || (this.appointmentData.patient && this.appointmentData.patient.id === data.patientId)) && this.appointmentData.appointmentId === data.appointmentId && this.currentOAId === data.currentOA) {
          this.patientNoteAlertCount = data.notificationCount;
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appointmentInfoFlag && !changes.appointmentInfoFlag.firstChange) {
      this.getPatientInfo();
    }
  }

  modifyFilters() {
    if (this.includeBillingClaimInfo) {
      this.appointmentFilters.filters.includes.claim = {billFinalized: true};
      if (this.includeBillDate) {
        this.appointmentFilters.filters.includes.claim.billDate = true;
      }
    }
    if (this.includePatientHeightWeightInfo) {
      this.appointmentFilters.filters.includes.patient = {
        latestHeight: true,
        latestWeight: true
      };
    }
    if (this.includeDaysAfterORInfo) {
      this.appointmentFilters.filters.includes.daysAfterOR = true;
    }
    if (this.includePatientNationality) {
      if (typeof this.appointmentFilters.filters.includes.patient === 'object') {
        this.appointmentFilters.filters.includes.patient = {
          ...this.appointmentFilters.filters.includes.patient,
          nationality: true
        };
      } else {
        this.appointmentFilters.filters.includes.patient = {
          nationality: true
        };
      }
    }
    if (this.includeLinkedOPDApptInfo) {
      this.appointmentFilters.filters.includes.linkedOPDVisits = true;
    }
    if (this.eventId) {
      this.appointmentFilters.filters.filters.docEventId = this.eventId;
      const firstChildRouteSnapshot = this.route.snapshot.firstChild;
      if (firstChildRouteSnapshot) {
        const data = firstChildRouteSnapshot && firstChildRouteSnapshot.firstChild ? firstChildRouteSnapshot.firstChild.data : null;
        if (data && data.isPatientReview) {
          this.appointmentFilters.filters.filters.docEventId = undefined;
        }
      }
    }
  }

  getPatientInfo() {
    this.modifyFilters();
    this.patientAppointmentInfo$ = this.appointmentHttpService.getAppointmentInfoV2(this.appointmentId, this.appointmentFilters).pipe(
      pluck('data'),
      tap(response => {
        this.appointmentData = response;
        this.allowOPDSummaryPrint = this.showOPDSummary && response.appointmentType && response.appointmentType.label !== AppointmentTypeEnum.ADMISSION;
        this.getPatientNoteAlertCount();
        this.doctorName = response.doctorName;
        this.isDischargeEventGenerated.emit(!!response.isDischargeEventGenerated)
        this.appointmentHttpService.setAppointmentData(response);
        this.appointmentInfo.emit(response);
        if (this.refetchDataInParentComponent) {
          this.fetchData.emit();
        }
      })
    );
  }

  getPatientNoteAlertCount() {
    const patientIds = [this.appointmentData.patient ? this.appointmentData.patient.id : this.appointmentData.patientId];
    const filters: ICommonFilters = {
      filters: {
        filters: {
          patientIds,
          currentOAId: this.currentOAId,
          validityStartDT: getStartOfDay(),
          validityEndDT: getEndOfDay()
        }
      }
    };
    this.masterDataHttpService.getPatientNoteAlertCount(filters)
      .subscribe((countInfo) => {
        this.noteAlertService.isPatientInfoBanner.next(true)
        this.patientNoteAlertCount = countInfo && countInfo.length && countInfo[0].alertNoteCount;
      });
  }

  ngOnDestroy(): void {
    this.appointmentHttpService.setAppointmentData(null);
    this.noteAlertService.toggleNoteAlertSidebarModal.next({showAlertNoteSidebar: false});
    // setTimeout is required as top-68 transition for sidebar should not show while closing
    setTimeout(() => {
      this.noteAlertService.isPatientInfoBanner.next(false)
    }, 350);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openOPDSummaryModal() {
    if (!this.showOPDSummary && !this.appointmentData) {
      return;
    }
    const dialogRef = this.matDialog.open(OpdSummaryPrintViewModalComponent, {
      data: {
        appointmentId: this.appointmentId,
        visitXref: this.appointmentData.visitXref ? this.appointmentData.visitXref.xref : null,
        appointmentDate: this.appointmentData.aptScheduledDate,
        patient: this.appointmentData.patient,
        department: this.appointmentData.departmentName,
        doctor: this.appointmentData.doctorName
      },
      width: '94vw',
      maxWidth: 'none',
      height: '90vh'
    });
  }
}

import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {isArray, MAX_UPLOAD_SIZE} from '@v2/core/functions/functions';
import {Subscription} from 'rxjs';
import {JwtService} from 'src/app/core';
import {APP_SETTINGS} from 'src/app/core/services/setting';

@Component({
  selector: 'app-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UploadDocComponent),
    multi: true
  },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UploadDocComponent),
      multi: true,
    },
  ]

})
export class UploadDocComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() uploadEndPoint;
  @Input() files;
  @Input() imagePreview = false;
  @Input() isRequired = true;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() removeFileName = new EventEmitter();
  @Output() removeFileId = new EventEmitter();
  @Output() isLoaded = new EventEmitter();

  @ViewChild('profilePic', {static: true}) profilePic: any;
  singleFile: any = false;
  subscriptions: Subscription[] = [];

  file = [];
  displayfiles: Array<any> = [];
  baseUrl: string;
  apiPrefix: string;
  pondOptions: any;

  constructor(
    private jwtService: JwtService,
  ) {
    this.baseUrl = APP_SETTINGS.api_url;
    this.apiPrefix = APP_SETTINGS.api_prefix;
  }

  ngOnInit() {
    const token = this.jwtService.getToken();

    this.pondOptions = {
      class: 'payment-plan-edit-filepond mb-0',
      multiple: true,
      maxFileSize: MAX_UPLOAD_SIZE,
      server: {
        process: {
          url: `${this.baseUrl}${this.apiPrefix}${this.uploadEndPoint}?apiVersion=1.0`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          withCredentials: false,
          onload: (response) => {
            this.isLoaded.emit(false);
            const responseData = JSON.parse(response);
            const {data: {fileName, filePath, clientFileName}} = responseData;
            this.file.push({fileName, filePath, clientFileName});
            this.value = this.file;
          },
          onerror: (response) => {
            this.isLoaded.emit(false);
            // tslint:disable-next-line:no-unused-expression
            response.data
          },
          ondata: (formData) => {
            this.isLoaded.emit(true);
            return formData;
          }
        }
      },
      labelIdle: `<span>
                  Drag & Drop your files or <strong class='text-primary'>Browse</strong>
                </span>`,
      acceptedFileTypes: 'image/jpeg, image/png'
    };
  }

  get value(): any {
    return this.file;
  }

  set value(value: any) {
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
      this.file = value;
      if (isArray(value) && value.length === 0) {
        this.displayfiles = [];
      } else {
        Object.assign(this.displayfiles, value);
      }
    } else {
      // if (this.profilePic) {
      setTimeout(() => {
        this.profilePic.removeFiles();
      });
      // }
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate({value}: FormControl) {
    return ((value && value.length > 0) || !this.isRequired) ? null : {profilePic: {required: false, message: 'Profile pic is required'}};
  }

  pondHandleRemoveFile($event) {
    this.file = this.removeFile($event.file.source.name);
    this.value = this.file;
  }

  removeImagePreview(file) {
    const fileName = file.clientFileName === 'undefine' ? file.clientFileName : file.fileName;
    this.removeFileName.emit(fileName);
    this.removeFileId.emit(file.id);
    this.file = this.removeFile(fileName);
    this.value = this.file;
  }

  removeFile(fileName) {

    this.displayfiles = this.displayfiles.filter(item => {
      return item.clientFileName !== fileName && item.fileName !== fileName;
    });

    return this.file.filter(item => {
      return item.clientFileName !== fileName && item.fileName !== fileName;
    });


  }


}


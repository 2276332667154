import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {ISystemicReview, ISystemicReviewType} from '@v2/core/models/masterdata/ISystemicReview.http';

export class SystemicReviewTypeViewModel extends ConcurrencyRequest {
  status: number;
  statusLabel: string;
  note: string;
  title?: string;

  static mapFromHttpModel(httpData: ISystemicReviewType, title?: string): SystemicReviewTypeViewModel {
    const viewData = new SystemicReviewTypeViewModel();
    if (httpData.status) {
      viewData.status = httpData.status.key;
      viewData.statusLabel = httpData.status.label;
    }
    viewData.note = httpData.note;
    viewData.title = title;
    viewData.mapConcurrencyRequest(httpData);
    return viewData;
  }
}

export class SystemicReviewViewModel extends ConcurrencyRequest {
  id: string;
  constitutional: SystemicReviewTypeViewModel;
  eyes: SystemicReviewTypeViewModel;
  earsNoseMouthThroat: SystemicReviewTypeViewModel;
  neck: SystemicReviewTypeViewModel;
  respiratory: SystemicReviewTypeViewModel;
  cardiovascular: SystemicReviewTypeViewModel;
  chestBreasts: SystemicReviewTypeViewModel;
// public gastrointestinal: SystemicReviewTypeViewModel,
  gastrointestinalAbdomen: SystemicReviewTypeViewModel;
  maleGenitourinary: SystemicReviewTypeViewModel;
  femaleGenitourinary: SystemicReviewTypeViewModel;
  lymphatic: SystemicReviewTypeViewModel;
  musculoskeletal: SystemicReviewTypeViewModel;
  skin: SystemicReviewTypeViewModel;
  neurologic: SystemicReviewTypeViewModel;
  psychiatric: SystemicReviewTypeViewModel;
  otherNote: string;
  recordedTime: string;
  recorderBy: string;
  systemicReviewPrintData: Array<SystemicReviewTypeViewModel>;

  static mapFromHttpModel(httpData: ISystemicReview): SystemicReviewViewModel {
    if (httpData) {
      const viewData = new SystemicReviewViewModel();
      viewData.id = httpData.id;
      viewData.constitutional = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.constitutional, 'Constitutional');
      viewData.eyes = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.eyes, 'Eyes');
      viewData.earsNoseMouthThroat = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.earsNoseMouthThroat, 'Ears, Nose, Mouth and Throat');
      viewData.neck = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.neck, 'Neck');
      viewData.respiratory = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.respiratory, 'Respiratory');
      viewData.cardiovascular = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.cardiovascular, 'Cardiovascular');
      viewData.chestBreasts = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.chestBreasts, 'Chest(Breasts)');
      viewData.gastrointestinalAbdomen = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.gastrointestinalAbdomen, 'Gastrointestinal Abdomen');
      viewData.maleGenitourinary = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.maleGenitourinary, 'Male Genitourinary');
      viewData.femaleGenitourinary = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.femaleGenitourinary, 'Female Genitourinary');
      viewData.lymphatic = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.lymphatic, 'Lymphatic');
      viewData.musculoskeletal = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.musculoskeletal, 'Musculoskeletal');
      viewData.skin = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.skin, 'Skin');
      viewData.neurologic = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.neurologic, 'Neurologic');
      viewData.psychiatric = SystemicReviewTypeViewModel.mapFromHttpModel(httpData.psychiatric, 'Psychiatric');
      viewData.otherNote = httpData.otherNote;
      viewData.recordedTime = httpData.recordedTime;
      viewData.recorderBy = httpData.recordedBy ? httpData.recordedBy.fullName : '';
      viewData.systemicReviewPrintData = [viewData.constitutional, viewData.eyes, viewData.earsNoseMouthThroat, viewData.neck, viewData.respiratory, viewData.cardiovascular, viewData.chestBreasts, viewData.gastrointestinalAbdomen, viewData.maleGenitourinary, viewData.femaleGenitourinary, viewData.lymphatic, viewData.musculoskeletal, viewData.skin, viewData.neurologic, viewData.psychiatric].filter(data => {
        return !!data.status;
      });
      viewData.mapConcurrencyRequest(httpData);
      return viewData;
    }
  }
}

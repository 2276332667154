import {getUTCDateTime} from '@v2/core/functions/functions';

export interface IConcurrencyPayload {
  updatedAt?: string;
  mapConcurrencyPayload: (source: IConcurrencyRequest) => void;
}

export interface IConcurrencyRequest {
  updatedAt?: string;
}

export class ConcurrencyPayload implements IConcurrencyPayload {
  updatedAt?: string;
  mapConcurrencyPayload = (source: IConcurrencyRequest) => {
    this.updatedAt = source && source.updatedAt ? getUTCDateTime(source.updatedAt) : undefined;
  };
}

export class UniqueItemConcurrencyPayload extends ConcurrencyPayload {
  uniqueIdForConcurrency?: string;
}

export class ConcurrencyRequest implements IConcurrencyRequest {
  updatedAt?: string;
  mapConcurrencyRequest = (source: IConcurrencyRequest) => {
    this.updatedAt = source && source.updatedAt ? getUTCDateTime(source.updatedAt) : undefined;
  };
}


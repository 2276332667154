import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AllergySeverityPipe} from './allergy-severity.pipe';
import {IsEmptyPipe} from './is-empty.pipe';
import {OrderSeverityStatusClassPipe} from './order-severity.pipe';
import {SeverityStatusClassPipe} from './severity-status-class.pipe';

@NgModule({
  declarations: [IsEmptyPipe, AllergySeverityPipe, OrderSeverityStatusClassPipe, SeverityStatusClassPipe],
  imports: [
    CommonModule
  ],
  exports: [IsEmptyPipe, AllergySeverityPipe, OrderSeverityStatusClassPipe, SeverityStatusClassPipe]
})
export class PipesModule {
}

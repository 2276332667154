import {Injectable} from '@angular/core';
import {IDoctorNote} from '@v2/core/models/masterdata/IDoctorNote';
import {Observable} from 'rxjs';
import {BaseHttpService} from '../../../base-http.service';
import {BaseModel, DataModel} from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class NurseOpdDoctorNoteApiService {

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  getDoctorNotes(appointmentId: string): Observable<BaseModel<DataModel<IDoctorNote>>> {
    return this.baseHttpService.get(`appointment/${appointmentId}/patient-medical-data/doctor-note`)
  }

}

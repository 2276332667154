import {OnDestroy} from '@angular/core';
import {Observable, OperatorFunction, Subject} from 'rxjs';
import {take} from 'rxjs/internal/operators/take';
import {finalize, takeUntil} from 'rxjs/operators';

export class BaseSubscriptionHandlerClass implements OnDestroy {
  unsubscribe$ = new Subject<void>();
  isLoading = true;

  constructor() {
  }

  pickFirstOrUntil<T>(): OperatorFunction<T, T> {
    return (source: Observable<T>) => source.pipe(takeUntil(this.unsubscribe$), take(1));
  }

  pickUntil<T>(): OperatorFunction<T, T> {
    return (source: Observable<T>) => source.pipe(takeUntil(this.unsubscribe$));
  }

  stopLoading<T>(): OperatorFunction<T, T> {
    return (source: Observable<T>) => source.pipe(finalize(() => this.isLoading = false));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

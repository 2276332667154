import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export function FadeOutRight(timeout: number = 350) {
  return trigger('fadeOutRight', [
    transition(':leave', [
      animate(
        `${timeout}ms`,
        keyframes([style({opacity: 1, offset: 0}), style({transform: 'translate3d(100%, 0, 0)', opacity: 0, offset: 1})])
      )
    ])
  ]);
}

import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {setFilter} from '@v2/core/functions/functions';
import {IBase, IData, IPageableData, IStatusResponse} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IProcedureDetail, ITreatmentProcedure} from '@v2/core/models/masterdata/IProcedure.master-data';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {Observable} from 'rxjs';
import {ProcedureOrderPayload} from '../../components/patient-order/resources/models/payload/procedure-order.payload';
import {ComplyProcedureOrderPayload} from '../models/payload/comply-procedure-order.payload';

@Injectable()
export class ProcedureOrderHttpService {

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  complyProcedureOrder(appointmentId: string, payload: ComplyProcedureOrderPayload): Observable<IBase<ITreatmentProcedure>> {
    return this.baseHttpService.postWrapper<IBase<ITreatmentProcedure>>(`v2/appointment/${appointmentId}/procedure/order/comply`, payload, ConcurrencyScopeEnum.PROCEDURE_COMPLY);
  }

  getTreatmentProcedures(isOpd: boolean, isNS: boolean, doctorOrNSId: string, appointmentId: string, filters: ICustomFilters = {}): Observable<IBase<IData<ITreatmentProcedure>>> {
    const originType = isOpd ? 'opd' : 'ipd';
    const originLocation = isNS ? 'ns' : 'dr';
    return this.baseHttpService.get<IBase<IData<ITreatmentProcedure>>>(`${originType}/${originLocation}/${doctorOrNSId}/appointment/${appointmentId}/treatment/procedure`, setFilter(filters));
  }

  placeProcedureOrder(payload: ProcedureOrderPayload): Observable<IBase<IProcedureDetail>> {
    return this.baseHttpService.postWrapper<IBase<IProcedureDetail>>(`v2/procedure/order`, payload, ConcurrencyScopeEnum.PROCEDURE_PATIENT_ORDER, new HttpParams(), payload.uniqueIdForConcurrency);
  }

  updateProcedureOrder(orderId: string, payload: ProcedureOrderPayload): Observable<IBase<IProcedureDetail>> {
    return this.baseHttpService.putWrapper<IBase<IProcedureDetail>>(`v2/procedure/order/${orderId}`, payload, ConcurrencyScopeEnum.PROCEDURE_PATIENT_ORDER);
  }

  deleteProcedureOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<IBase<IStatusResponse>> {
    const params = new HttpParams().append('appointmentId', appointmentId);
    return this.baseHttpService.deleteWrapper<IBase<IStatusResponse>>(`v2/procedure/order/${orderId}/updated-at/${updatedAt}`, ConcurrencyScopeEnum.PROCEDURE_PATIENT_ORDER, params);
  }

  getProcedureOrders(appointmentId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<IProcedureDetail>>> {
    return this.baseHttpService.get<IBase<IPageableData<IProcedureDetail>>>(`v2/appointment/${appointmentId}/patient-orders/procedure`, setFilter(filters));
  }
}

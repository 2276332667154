import {FormControl, Validators} from '@angular/forms';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {PatientOrderStatusEnum} from '@v2/core/enums/patient-order-status.enum';
import {getName, mapDepartmentDataToDepartmentMinimal, mapFullNameInDoctor, objectValidator} from '@v2/core/functions/functions';
import {ICatalogGroup, IDepartmentMinimal, IDoctor} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IMedicalPackage} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IFavoriteOrderElement} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {IProcedureDetail} from '@v2/core/models/masterdata/IProcedure.master-data';
import {DoctorViewModel} from '@v2/core/models/view/doctor.view.model';
import {ProcedureViewModel} from '../view/procedure-view.model';
import {FavoriteFormDataModel} from './favorite-form.model';

interface IProcedureOrderForm {
  id: string | FormControl;
  catalogElementId: ProcedureViewModel | FormControl;
  qty: number | FormControl;
  days: number | FormControl;
  assignToId: DoctorViewModel | FormControl;
  instruction: string | FormControl;
  recordedTime: string | FormControl;
  actualRecordedTime: string | FormControl;
  orderType: string | FormControl;
  procedureComplyStatus: string | FormControl;
  catalogGroupId?: ICatalogGroup | FormControl;
  isGroup?: boolean | FormControl;
  isMedicalPackageOrder: boolean | FormControl;
  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
  orderOrigin: string | FormControl;
  patientOrderId: string | FormControl;
  patientOrderUpdatedAt: string | FormControl;

  isSubmitting?: boolean | FormControl;
  isSubmitted?: boolean | FormControl;
  errorMessage?: string | FormControl;
  isAdded?: boolean | FormControl;
  index?: number | FormControl;
  isFormValid?: boolean | FormControl;
}

export class ProcedureOrderForm implements IProcedureOrderForm {
  id = new FormControl()
  catalogElementId = new FormControl(null, [Validators.required, objectValidator])
  qty = new FormControl(null, [Validators.required, Validators.min(1)])
  days = new FormControl(1, [Validators.required, Validators.min(1)])
  assignToId = new FormControl(null, Validators.required)
  instruction = new FormControl()
  recordedTime = new FormControl()
  actualRecordedTime = new FormControl();
  orderType = new FormControl(OrderTypeEnum.Procedure);
  procedureComplyStatus = new FormControl();
  catalogGroupId = new FormControl();
  isGroup = new FormControl(false);
  isMedicalPackageOrder = new FormControl();
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  updatedAt = new FormControl();
  orderOrigin = new FormControl();
  patientOrderId = new FormControl();
  patientOrderUpdatedAt = new FormControl();

  isSubmitting = new FormControl();
  isSubmitted = new FormControl();
  errorMessage = new FormControl();
  isAdded = new FormControl(false);
  index = new FormControl();
  isFormValid = new FormControl(false);
}

export class ProcedureFormDataModel extends ConcurrencyRequest implements IProcedureOrderForm {
  id: string;
  catalogElementId: ProcedureViewModel;
  qty: number;
  days: number;
  assignToId: DoctorViewModel;
  instruction: string;
  recordedTime: string;
  actualRecordedTime: string;
  orderType: string;
  orderStatus: boolean;
  procedureComplyStatus: string;
  isMedicalPackageOrder: boolean;
  department: IDepartmentMinimal;
  doctor: IDoctor;
  orderOrigin: string;

  updatedAt: string;

  catalogGroupId?: ICatalogGroup;
  isGroup?: boolean;

  index?: number;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  errorMessage?: string;
  isAdded?: boolean;
  isFormValid?: boolean;
  medicalPackage: IMedicalPackage;
  packageSelectionItemId: string;
  patientOrderId: string;
  patientOrderUpdatedAt: string;

  static mapFromHttpModel(order: IProcedureDetail): ProcedureFormDataModel {
    if (!order.isDailyCharge) {
      const formData = new ProcedureFormDataModel();
      const assignedTo = new DoctorViewModel();
      if (order.assignTo) {
        const {staffId, staffName, id, firstName, lastName} = order.assignTo;
        assignedTo.id = staffId || id;
        assignedTo.name = staffName || getName(firstName, lastName);
        assignedTo.fullName = assignedTo.name;
      }
      formData.id = order.pdProcedureId;
      formData.catalogElementId = order.catalogElement && ProcedureViewModel.mapFromHttpModel(order.catalogElement);
      formData.qty = order.qty;
      formData.days = order.days;
      formData.assignToId = assignedTo;
      formData.instruction = order.instruction;
      formData.recordedTime = order.recordedTime;
      formData.actualRecordedTime = order.requestedDateTime;
      formData.orderType = order.orderType ? order.orderType.label : '';
      formData.orderStatus = order.orderStatus && order.orderStatus.label === PatientOrderStatusEnum.REQUESTED;
      formData.orderOrigin = order.orderOrigin ? order.orderOrigin.label : '';
      formData.procedureComplyStatus = order.procedureComplyStatus && order.procedureComplyStatus.label;
      formData.doctor = mapFullNameInDoctor(order.orderingDoctor);
      formData.department = mapDepartmentDataToDepartmentMinimal(order.orderingDepartment);
      formData.isSubmitted = null;
      formData.errorMessage = null;
      formData.isMedicalPackageOrder = order.isPkgOrder;
      formData.medicalPackage = order.medicalPackage;
      formData.packageSelectionItemId = order.packageSelectionItemId;
      formData.patientOrderId = order.patientOrderId;
      formData.patientOrderUpdatedAt = order.patientOrderUpdatedAt;
      formData.mapConcurrencyRequest(order);
      return formData;
    }
  }

  static mapFromMultiOrderFormModel(formData: ProcedureFormDataModel): ProcedureFormDataModel[] {
    const data: ProcedureFormDataModel[] = [];
    formData.catalogGroupId.catalogElements.map(element => {
      const formDataModel: ProcedureFormDataModel = {
        ...formData,
        isSubmitted: null,
        isFormValid: true,
        catalogElementId: ProcedureViewModel.mapFromHttpModel(element)
      };
      data.push(formDataModel);
    });
    return data;
  }

  static mapFromFavoriteOrder(favoriteData: FavoriteFormDataModel, element: IFavoriteOrderElement): ProcedureFormDataModel {
    const formData = new ProcedureFormDataModel();
    formData.id = null;
    formData.catalogElementId = ProcedureViewModel.mapFromHttpModel(element.catalogElement);
    formData.qty = 1;
    formData.assignToId = favoriteData.doctor;
    formData.instruction = element.instruction;
    formData.orderType = OrderTypeEnum.Procedure;
    formData.doctor = favoriteData.doctor;
    formData.department = favoriteData.department;
    formData.isGroup = false;
    formData.isSubmitted = null;
    formData.errorMessage = null;
    formData.orderStatus = null;
    formData.isFormValid = true;
    return formData;
  }
}

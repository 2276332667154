import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {

  getIpv4(): string {
    return window.localStorage.ip4Token;
  }

  saveIpv4(address: string) {
    window.localStorage.ip4Token = address;
  }

  getIpv6(): string {
    return window.localStorage.ip6Token;
  }

  saveIpv6(address: string) {
    window.localStorage.ip6Token = address;
  }

  destroyIp() {
    window.localStorage.removeItem('jwtToken');
  }
}

import {ChangeDetectorRef, Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {MaybeArray, TRANSLOCO_LANG, TRANSLOCO_SCOPE, TranslocoScope, TranslocoService} from '@ngneat/transloco';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/internal/operators/take';
import {IMasterData} from '@v2/core/models/masterdata';
import {TranslationLanguageEnum} from '@v2/core/enums/translation-language.enum';

@Pipe({
  name: 'masterDataTranslation',
  pure: false // required to update the value when language is changed
})
export class MasterDataTranslationPipe implements PipeTransform, OnDestroy {

  private subscription: Subscription | null = null;

  private lastValue = '';
  private lastKey: string | undefined;
  private listenToLangChange: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
    @Optional() @Inject(TRANSLOCO_LANG) private providerLang: string | null,
    @Optional() @Inject(TRANSLOCO_SCOPE) private providerScope: MaybeArray<TranslocoScope>) {
    this.listenToLangChange = this.shouldListenToLangChanges(this.translocoService, this.providerLang);
  }

  transform(item: IMasterData, defaultLang?: 'eng' | 'thai'): string {
    if (!item) {
      return '-';
    }

    if (defaultLang) {
      return defaultLang === 'thai' ? this.resolveValue(item, 'th') : this.resolveValue(item, 'en');
    }

    const key = item.label;

    if (key === this.lastKey) {
      return this.lastValue;
    }

    this.lastKey = key;
    this.unsubscribe();

    this.subscription = this.translocoService.langChanges$
      .pipe(this.listenOrNotOperator(this.listenToLangChange))
      .subscribe((activeLang: string) => this.updateValue(item, activeLang));

    return this.lastValue;
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateValue(item: IMasterData, activeLang: string) {
    this.lastValue = this.resolveValue(item, activeLang);
    this.cdr.markForCheck();
  }

  private resolveValue(item: IMasterData, activeLang: string): string {
    if (!activeLang || !item) {
      return null;
    }

    switch (activeLang) {
      case TranslationLanguageEnum.ENGLISH:
        return !!item.label ? item.label : item.thaiLabel;
      case TranslationLanguageEnum.THAI:
        return !!item.thaiLabel ? item.thaiLabel : item.label;
      default:
        return item.label;
    }
  }

  private listenOrNotOperator(listenToLangChange: boolean) {
    return listenToLangChange ? source => source : take(1);
  }

  private getPipeValue(str: string, value: string, char = '|'): [boolean, string] {
    if (typeof str === 'string') {
      const splitted = str.split(char);
      const lastItem = splitted.pop();
      return lastItem === value ? [true, splitted.toString()] : [false, lastItem];
    }

    return [false, ''];
  }

  private shouldListenToLangChanges(service: TranslocoService, lang: string) {
    const [hasStatic] = this.getPipeValue(lang, 'static');
    if (hasStatic === false) {
      // If we didn't get 'lang|static' check if it's set in the global level
      return service.config.reRenderOnLangChange;
    }

    // We have 'lang|static' so don't listen to lang changes
    return false;
  }
}

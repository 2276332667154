export enum TableBadgeEnum {
  FOLLOWUP = 'Follow-Up',
  PHARMACY = 'Pharmacy',
  REFERRAL = 'Referral',
  CONSULTATION = 'Consultation',
  TRANSFERRED = 'Transferred',
  JOINT_ADMISSION = 'Joint Admission',
  STOPPED = 'Stopped',
  PATIENT_TRANSFER = 'Patient Transfer',
  DISCHARGE = 'Discharge',
  CANCELED = 'Canceled',
  REORDERED = 'Reordered'
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PipesModule} from '../../pipes/pipes.module';
import {InfoItemComponent} from './info-item/info-item.component';



@NgModule({
  declarations: [InfoItemComponent],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [InfoItemComponent]
})
export class InfoItemModule { }

import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppointmentStatusEnum} from '@v2/core/enums/appointment-status.enum';
import {AppointmentTypeEnum} from '@v2/core/enums/appointment-type.enum';
import {ErrorHandlerEnum} from '@v2/core/enums/error-handler.enum';
import {FacilityResourceTypeEnum} from '@v2/core/enums/facility-resource-type.enum';
import {InventoryTypeEnum} from '@v2/core/enums/inventory-type.enum';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {PaymentModeEnum} from '@v2/core/enums/payment-mode.enum';
import {PurchaseRequestStatusEnum} from '@v2/core/enums/purchase-request-status.enum';
import {UserTypeEnum} from '@v2/core/enums/user-type.enum';
import {
  getAddBedType,
  getCurrentDateTime,
  getEndOfDay,
  getStartOfDay,
  getTransferBedType,
  getUserDisplayedOrderType,
  isArray,
  setFilter,
  todayDate
} from '@v2/core/functions/functions';
import {IExternalSystem} from '@v2/core/models/masterdata/IAIMSetupRequest';
import {IDischargeType, IPatientDischargeStatus} from '@v2/core/models/masterdata/IDischarge.master-data';
import {ICommonFilters, ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IFSOFacilityResource, IFSOFinancialServiceOrg} from '@v2/core/models/masterdata/IFSOFinancialServiceOrg';
import {IInsuranceForm} from '@v2/core/models/masterdata/IInsuranceForm.master-data';
import {
  IMedicalPackage,
  IMedicalPackagePlan,
  IUsedMedicalPackage,
  IUsedMedicalPackageDetailConfig
} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IDeletableEntity} from '@v2/core/models/masterdata/IPost.master-data';
import {IStockItem} from '@v2/core/models/masterdata/IStockItem.master-data';
import {IAttachEquipment, IManufacturer, IVendor} from '@v2/core/models/masterdata/IVendor.master-data';
import {IVitalUnit} from '@v2/core/models/masterdata/IVitals';
import {PrescriptionMedicineViewModel} from '@v2/core/models/view/prescription-medicine.view.model';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {BehaviorSubject, EMPTY, forkJoin, Observable, of} from 'rxjs';
import {catchError, map, pluck, shareReplay, tap} from 'rxjs/operators';

import {APP_SETTINGS} from 'src/app/core/services/setting';
import {MigrationTemplateTypeEnum} from '../../../aim-setup/migrate-data/enums/migration-template-type.enum';
import {CommonEnum} from '../../../shared/enums';
import {MedicalPackageListViewModel} from '../../../shared/module/medical-package/models/view/medical-package-list-view.model';
import {BLANK_SPACE_VALUE} from '../../shared-v2/components/filter/filter-form.model';
import {FavoriteFormDataModel} from '../../shared-v2/components/patient-order/resources/models/form/favorite-form.model';
import {PharmacyOutletPayload} from '../../shared-v2/components/patient-order/resources/models/payload/pharmacy-outlet.payload';
import {INoteAlertCount} from '../../shared-v2/module/note-alert/resources/model/http/INoteAlert';
import {ClaimPaymentTypeEnum} from '../../shared-v2/resources/enums/claim-payment-type.enum';
import {PharmacyStatusEnum} from '../../shared-v2/resources/enums/pharmacy-status.enum';
import {IOperationalArea} from '../../shared-v2/resources/models/http/IOperationalArea';
import {MedicineInteractionAllergyPayload} from '../../shared-v2/resources/models/payload/medicine-interaction-allergy.payload';
import {MedicalCatalogListViewModel} from '../../shared-v2/resources/models/view/medical-catalog-list-view.model';
import {PharmacyOutletViewModel} from '../../shared-v2/resources/models/view/pharmacy-outlet.view.model';
import {
  IAppointmentPaymentTypeAndBedDetailConfig,
  IBase,
  IBatchNumber,
  IBed,
  IBedMinimal,
  IBuilding,
  IBuildingMinimal,
  ICatalogCategory,
  ICatalogElement,
  ICatalogGroup,
  ICompany,
  ICompanyEmployee,
  IContract,
  IData,
  IDepartment,
  IDepartmentMinimal,
  IDiagnosis,
  IDiagnosisCode,
  IDiagnosisDetail,
  IDoctor,
  IDoctorStaff,
  IFloorMinimal,
  IHospital,
  IHospitalTeam,
  IIncomeType,
  IMasterData,
  IMedicalCatalog,
  IMedication,
  IMedicineInteractionAllergy,
  INurseStation,
  IPageableData,
  IPatient,
  IPatientBillingCode,
  IPatientExtended,
  IPatientVisitAdmissionXref,
  IPaymentPriority,
  IPaymentType,
  IPharmacyOutlet,
  IRoom,
  IRoomMinimal,
  IStatusResponse,
  ISubmitClaim,
  ISubStockMinimal,
  ISupportTicketTopic,
  IVatDetails,
  IWardMinimal
} from '../models/masterdata';
import {IFavoriteOrder, IPatientMedicineAllergyStatus} from '../models/masterdata/IPatientOrder.master-data';
import {IProcedure} from '../models/masterdata/IProcedure.master-data';

@Injectable({providedIn: 'root'})
export class MasterDataHttpService {
  baseUrl: string;
  apiPrefix: string;
  doctorCache$: Observable<Array<IDoctor>>;
  surgeonCache$: Observable<Array<IDoctor>>;
  anesthesiologistsCache$: Observable<Array<IDoctor>>;
  radiologistsCache$: Observable<Array<IDoctor>>;
  pathologistsCache$: Observable<Array<IDoctor>>;
  allDoctorCache$: Observable<Array<IDoctor>>;
  departmentCache$: Observable<Array<IDepartmentMinimal>>;
  medicalPackageCache$: Observable<Array<MedicalPackageListViewModel>>;
  transactionTypesCache$: Observable<Array<IMasterData>>;
  appointmentStatusCache$: Observable<Array<IMasterData>>;
  orderSeverityStatusCache$: Observable<Array<IMasterData>>;
  orSeverityStatusCache$: Observable<Array<IMasterData>>;
  patientOrderOriginCache$: Observable<Array<IMasterData>>;
  frequencyTypeCache$: Observable<Array<IMasterData>>;
  dosageUnitCache$: Observable<Array<IMasterData>>;
  systemicReviewStatusCache$: Observable<Array<IMasterData>>;
  fsoFacilityResourceTypeCache$: Observable<IPageableData<IMasterData>>;
  fsoTypesCache$: Observable<IPageableData<IMasterData>>;
  stockTypeCache$: Observable<IPageableData<IMasterData>>;
  banksListCache$: Observable<IPageableData<IFSOFinancialServiceOrg>>;
  machineNumbersListCache$: Observable<IPageableData<IFSOFacilityResource>>;
  stockOrderTypeCache$: Observable<IPageableData<IMasterData>>;
  noteAlertSeverityStatusCache$: Observable<IPageableData<IMasterData>>;
  batchListForCatalogElementV2$ = new BehaviorSubject<IPageableData<IBatchNumber>>(null);

  pageConfig = {
    filters: {
      page_size: 'all'
    }
  };
  private cacheSize = 1;

  constructor(
    private baseHttpService: BaseHttpService
  ) {
    this.baseUrl = APP_SETTINGS.api_url;
    this.apiPrefix = APP_SETTINGS.api_prefix;
  }

  getMasterData<T>(endPoint: string, addParams?: any): Observable<IMasterData[] | T> {
    const filter = {
      page_size: 'all',
      includes: {
        contact: true
      }
    };
    const params = addParams ? new HttpParams().set('filters', JSON.stringify(filter)) : null;
    return this.baseHttpService.get(`${endPoint}`, params).pipe(
      pluck('data', 'items')
    );
  }

  getPharmacyOutletById(pharmacyOutletId: string): Observable<IBase<IPharmacyOutlet>> {
    return this.baseHttpService.get<IBase<IPharmacyOutlet>>(`pharmacy/pharmacy-outlet/${pharmacyOutletId}`);
  }

  getMasterDataWithPagination(endpoint: string, filters: ICustomFilters = {}, addBlankSpaceInList = false): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(endpoint, setFilter(filters)).pipe(
      pluck('data'),
      map((response) => {
        if (addBlankSpaceInList && filters.page === 1) {
          response.items.unshift({
            key: BLANK_SPACE_VALUE,
            type: ' ',
            label: ' ',
            thaiLabel: ' '
          })
        }
        return response;
      })
    )
  }

  getMedicineIntake(): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(`master/medicine-intake`).pipe(
      pluck('data'),
      map((response: IPageableData<IMasterData>) => {
        const naParameterIndex = response.items.findIndex(model => model.label === 'N/A');
        if (naParameterIndex > -1) {
          const naParameterObjects = response.items.splice(naParameterIndex, 1);
          response.items.unshift(...naParameterObjects);
        }
        return response;
      })
    );
  }

  getAppointmentStatus(): Observable<Array<IMasterData>> {
    if (!this.appointmentStatusCache$) {
      this.appointmentStatusCache$ = this.baseHttpService.get<IBase<IData<IMasterData>>>(`appointment/status`).pipe(
        pluck('data', 'items'),
        shareReplay(this.cacheSize)
      );
    }
    return this.appointmentStatusCache$;
  }

  getAllDepartments(filter: ICustomFilters = {}): Observable<Array<IDepartmentMinimal>> {
    return this.baseHttpService.get<IBase<IData<IDepartmentMinimal>>>(`departments`, setFilter(filter))
      .pipe(pluck('data', 'items'));
  }

  getAllDepartmentsCache(filters: ICustomFilters = {}): Observable<Array<IDepartmentMinimal>> {
    if (!this.departmentCache$) {
      this.departmentCache$ = this.getDepartments(filters).pipe(pluck('items'), shareReplay(this.cacheSize));
    }
    return this.departmentCache$;
  }

  getDepartments(filter: ICustomFilters = {}): Observable<IPageableData<IDepartmentMinimal>> {
    return this.baseHttpService.get<IBase<IPageableData<IDepartmentMinimal>>>(`departments`, setFilter(filter))
      .pipe(pluck('data'));
  }

  getDepartmentsList(filter: ICustomFilters = {}): Observable<IPageableData<IDepartment>> {
    return this.baseHttpService.get<IBase<IPageableData<IDepartment>>>(`departments-list`, setFilter(filter))
      .pipe(pluck('data'));
  }

  getAllMsOrgDepartments(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/ms-org-specialities`)
      .pipe(pluck('data', 'items'));
  }

  getPathologyElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>('pathology/catalog-group-element', setFilter(filters)).pipe(
      pluck('data'));
  }

  getProceduresForOR(filters: ICustomFilters = {}) {
    return this.baseHttpService.get<IBase<IPageableData<IProcedure>>>(`procedure-list`, setFilter(filters)).pipe(
      pluck('data'));
  }

  getMedicalPartyDoctors(filters: ICustomFilters = {}): Observable<IPageableData<IDoctor>> {
    return this.baseHttpService.get<IBase<IPageableData<IDoctor>>>(`medical-party/doctor-staff`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllDoctors(departmentId: string = null): Observable<IDoctor[]> {
    let filter = {
      page_size: 'all',
      includes: {
        contact: true
      }
    };
    if (departmentId) {
      const depFilter = {
        filters: {
          doctorDepartment: departmentId
        }
      };
      filter = {...filter, ...depFilter};
    }

    const param = new HttpParams().set('filters', JSON.stringify(filter));
    return this.baseHttpService.get(`all-doctors`, param).pipe(
      pluck('data', 'items')
    );
  }

  getAllDoctorCache(filters: ICustomFilters = {}): Observable<Array<IDoctor>> {
    if (!this.doctorCache$) {
      this.doctorCache$ = this.getDoctorStaff(filters).pipe(pluck('items'), shareReplay(this.cacheSize));
    }
    return this.doctorCache$
  }

  getDoctors(filters: ICustomFilters = {}): Observable<IPageableData<IDoctor>> {
    return this.baseHttpService.get<IBase<IPageableData<IDoctor>>>(`all-doctors`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getDoctorsCache(filters: ICustomFilters = {}): Observable<Array<IDoctor>> {
    if (!this.allDoctorCache$) {
      this.allDoctorCache$ = this.getDoctors(filters).pipe(pluck('items'), shareReplay(this.cacheSize));
    }
    return this.allDoctorCache$
  }

  getTransactionTypes(filters: ICustomFilters = {}): Observable<Array<IMasterData>> {
    if (!this.transactionTypesCache$) {
      this.transactionTypesCache$ = this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/transaction-type`, setFilter(filters)).pipe(
        pluck('data', 'items'),
        shareReplay(this.cacheSize)
      );
    }
    return this.transactionTypesCache$;
  }

  getSystemicReviewStatus(filters: ICustomFilters = {}): Observable<Array<IMasterData>> {
    if (!this.systemicReviewStatusCache$) {
      this.systemicReviewStatusCache$ = this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/systemic-review-status`, setFilter(filters)).pipe(
        pluck('data', 'items'),
        shareReplay(this.cacheSize)
      );
    }
    return this.systemicReviewStatusCache$;
  }

  getBuildingList(filters: ICommonFilters): Observable<Array<IBuildingMinimal>> {
    return this.baseHttpService.get<IBase<IData<IBuildingMinimal>>>(`hospital/building/locations`, setFilter(filters)).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getBuildingsWithPagination(filters: ICommonFilters): Observable<IPageableData<IBuildingMinimal>> {
    return this.baseHttpService.get('hospital/building/locations', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getBuildingByWardId(wardId: string, filters: ICommonFilters): Observable<IPageableData<IBuildingMinimal>> {
    return this.baseHttpService.get<IBase<IPageableData<IBuildingMinimal>>>(`hospital/building/wards/${wardId}`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getFloorByBuildingId(buildingLocationId: string, filters: ICommonFilters): Observable<IPageableData<IFloorMinimal>> {
    return this.baseHttpService.get(`ward/building/${buildingLocationId}/available/floors`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getFloorByWardId(wardId: string, filters: ICommonFilters): Observable<IPageableData<IFloorMinimal>> {
    return this.baseHttpService.get<IBase<IPageableData<IFloorMinimal>>>(`hospital/floor/wards/${wardId}`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getFloorListForWard(wardId: string): Observable<Array<IFloorMinimal>> {
    return this.baseHttpService.get<IBase<IData<IFloorMinimal>>>(`hospital/floor/wards/${wardId}`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getRoomsOnFloor(floorId: string): Observable<Array<IRoomMinimal>> {
    return this.baseHttpService.get<IBase<IData<IRoomMinimal>>>(`rooms/location/${floorId}`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getRoomsByFloorId(floorId: string, filters: ICommonFilters): Observable<IPageableData<IRoomMinimal>> {
    return this.baseHttpService.get<IBase<IPageableData<IRoomMinimal>>>(`rooms/location/${floorId}`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getPharmacyOutletFacilities(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/pharmacy-outlet-facility`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getNursingStationList(filters: ICustomFilters = {}): Observable<Array<INurseStation>> {
    return this.baseHttpService.get<IBase<IPageableData<INurseStation>>>(
      `medical-resource/nursing-stations/${todayDate()}`,
      setFilter(filters)
    ).pipe(pluck('data', 'items'), catchError(_ => of([])));
  }

  getPharmacyMedicalCatalog(): Observable<IMedicalCatalog> {
    return this.baseHttpService.get<IBase<IMedicalCatalog>>(`medical-catalog/type/pharmacy`).pipe(
      pluck('data')
    );
  }

  getNutritionMealMedicalCatalog(): Observable<IMedicalCatalog> {
    return this.baseHttpService.get<IBase<IMedicalCatalog>>(`medical-catalog/type/nutrition-meal`).pipe(
      pluck('data')
    );
  }

  getMedicalCatalogList(filters: ICustomFilters = {}): Observable<IPageableData<MedicalCatalogListViewModel>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedicalCatalog>>>(`medical-catalogs`, setFilter(filters)).pipe(
      pluck('data'),
      map((response) => {
        const {items, ...pagination} = response
        const data = items.map(item => MedicalCatalogListViewModel.mapFromHttpModel(item));
        return {items: data, ...pagination};
      })
    );
  }

  getDispenseUnits(filters: ICustomFilters = {}): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/dispense-unit`, setFilter(filters)).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getFrequencyTypes(): Observable<Array<IMasterData>> {
    if (!this.frequencyTypeCache$) {
      this.frequencyTypeCache$ = this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/frequency-type`).pipe(
        pluck('data', 'items'),
        shareReplay(this.cacheSize)
      );
    }
    return this.frequencyTypeCache$;
  }

  getIncomeTypes(): Observable<Array<IIncomeType>> {
    const filterOption: ICommonFilters = {
      filters: {
        page_size: 'all'
      }
    };
    return this.baseHttpService.get<IBase<IData<IIncomeType>>>(`income-types`, setFilter(filterOption)).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getIncomeType(filters: ICustomFilters = {}): Observable<IPageableData<IIncomeType>> {
    return this.baseHttpService.get<IBase<IPageableData<IIncomeType>>>(`income-types`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getSeverityStatus(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>('master/severity').pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getADTSeverityStatus(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`adt/severity-status`).pipe(
      pluck('data', 'items')
    );
  }

  getOrderSeverityStatus(): Observable<Array<IMasterData>> {
    if (!this.orderSeverityStatusCache$) {
      this.orderSeverityStatusCache$ = this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/order/severity`).pipe(
        pluck('data', 'items'),
        shareReplay(this.cacheSize)
      );
    }
    return this.orderSeverityStatusCache$;
  }

  getNoteAlertSeverityStatus(): Observable<IPageableData<IMasterData>> {
    if (!this.noteAlertSeverityStatusCache$) {
      this.noteAlertSeverityStatusCache$ = this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(`master/alert-note-severity`).pipe(
        pluck('data'),
        map((response) => {
          const {items, ...pagination} = response
          const data = items.filter((item) => item.label !== MigrationTemplateTypeEnum.Other);
          return {items: data, ...pagination};
        })
      )
    }
    return this.noteAlertSeverityStatusCache$;
  }

  getFSOFacilityResourcesTypes(): Observable<IPageableData<IMasterData>> {
    if (!this.fsoFacilityResourceTypeCache$) {
      this.fsoFacilityResourceTypeCache$ = this.baseHttpService.get('master/fso-facility-resource-type')
        .pipe(pluck('data'));
    }
    return this.fsoFacilityResourceTypeCache$;
  }

  getFSOType(): Observable<IPageableData<IMasterData>> {
    if (!this.fsoTypesCache$) {
      this.fsoTypesCache$ = this.baseHttpService.get('master/fso-type')
        .pipe(pluck('data'));
    }
    return this.fsoTypesCache$;
  }

  getBanksList(filters: ICustomFilters = {}): Observable<IPageableData<IFSOFinancialServiceOrg>> {
    if (!this.banksListCache$) {
      this.banksListCache$ = this.baseHttpService.get<IBase<IPageableData<IFSOFinancialServiceOrg>>>(`v2/financial-service-org`, setFilter(filters)).pipe(
        pluck('data'),
        shareReplay(this.cacheSize)
      );
    }
    return this.banksListCache$;
  }

  getMachineNumbersList(filters: ICustomFilters = {}): Observable<IPageableData<IFSOFacilityResource>> {
    if (!this.machineNumbersListCache$) {
      this.machineNumbersListCache$ = this.baseHttpService.get<IBase<IPageableData<IFSOFacilityResource>>>(`v2/fso-facility-resources`, setFilter(filters)).pipe(
        pluck('data'),
        shareReplay(this.cacheSize)
      );
    }
    return this.machineNumbersListCache$;
  }

  getNotificationAreas(): Observable<IData<IOperationalArea>> {
    return this.baseHttpService.get<IBase<IData<IOperationalArea>>>(`v2/notification-areas`)
      .pipe(
        pluck('data')
      )
  }

  getORSeverityStatus(): Observable<Array<IMasterData>> {
    if (!this.orSeverityStatusCache$) {
      this.orSeverityStatusCache$ = this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/or/severity`).pipe(
        pluck('data', 'items'),
        shareReplay(this.cacheSize)
      );
    }
    return this.orSeverityStatusCache$;
  }

  getADTRequestTypes(): Observable<string[]> {
    return this.baseHttpService.get('master/adt-request-type').pipe(
      pluck('data', 'items')
    );
  }

  getADTRequestTypesAsMasterData(): Observable<IPageableData<IMasterData>> {
    return this.getADTRequestTypes().pipe(
      map(response => {
        const items: Array<IMasterData> = response.map(item => ({key: null, label: item} as IMasterData));
        return {items, totalPages: 1} as IPageableData<IMasterData>
      })
    );
  }

  getADTRequestStatus(): Observable<string[]> {
    return this.baseHttpService.get<IBase<IData<string>>>('master/adt-request-status').pipe(
      pluck('data', 'items'),
      map(response => {
        const index = response.findIndex(item => item === AppointmentStatusEnum.BOOKED);
        if (response[index]) {
          response[index] = AppointmentStatusEnum.ADMITTED;
        }
        return response;
      })
    );
  }

  getADTRequestStatusAsMasterData(): Observable<IPageableData<IMasterData>> {
    return this.getADTRequestStatus().pipe(
      map(response => {
        const items: Array<IMasterData> = response.map(item => ({key: null, label: item} as IMasterData));
        return {items, totalPages: 1} as IPageableData<IMasterData>
      })
    );
  }

  listAllMedicalParty(searchFilter: ICustomFilters = {}): Observable<IPageableData<IDoctor>> {
    return this.baseHttpService.get('medical-party/all', setFilter(searchFilter)).pipe(
      pluck('data'));
  }

  listPharmacyCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IPageableData<ICatalogElement>>(
      'pharmacy/catalog-elements', setFilter(filters)
    ).pipe(
      pluck('data')
    );
  }

  getPharmacyStatus(sourceTile: 'verify' | 'prepare' | 'dispense'): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IPageableData<IMasterData>>>('patient-orders/pharmacy/status').pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        if (sourceTile === 'prepare') {
          const filteredItems = items.filter(model => model.label !== PharmacyStatusEnum.Open && model.label !== PharmacyStatusEnum.Verified);
          return {items: filteredItems, ...pagination};
        } else if (sourceTile === 'dispense') {
          const filteredItems = items.filter(model => model.label === PharmacyStatusEnum.Bill_Prepared || model.label === PharmacyStatusEnum.Dispensed);
          return {items: filteredItems, ...pagination};
        }
        return response;
      })
    );
  }

  getPaymentTypes(filters: ICustomFilters = {}): Observable<Array<IMasterData>> {
    return this.baseHttpService.get('master/payment-type-category', setFilter(filters)).pipe(
      pluck('data', 'items')
    );
  }

  getPaymentTypeList(filters: ICustomFilters = {}): Observable<IPageableData<IPaymentType>> {
    return this.baseHttpService.get<IBase<IPageableData<IPaymentType>>>(`payment-types`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getPatientBillingCodes(filters: ICustomFilters = {}): Observable<IPageableData<IPatientBillingCode>> {
    return this.baseHttpService.get<IBase<IPageableData<IPatientBillingCode>>>(`patient-billing-code`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getDailySummaryReportStatus(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/daily-summary-report`).pipe(
      pluck('data', 'items')
    );
  }

  getRooms(filters: ICustomFilters = {}): Observable<IPageableData<IRoom>> {
    return this.baseHttpService.getWrapper<IBase<IPageableData<IRoom>>>(`rooms`, ErrorHandlerEnum.BED_MANAGEMENT_PATIENT_ROOM_LIST, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getRoomById(roomId: string): Observable<IRoom> {
    return this.baseHttpService.get<IBase<IRoom>>(`room/${roomId}`).pipe(
      pluck('data')
    );
  }

  getRoomFeatures(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`rooms/features`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getInsurancePlanById(planId: string): Observable<IMedicalPackagePlan> {
    return this.baseHttpService.get<IBase<IMedicalPackagePlan>>(`medical-service-package/insurance-plan/${planId}`).pipe(
      pluck('data')
    );
  }

  getCompanyContractPackageById(companyId: string, contractId: string, packageId: string): Observable<IMedicalPackagePlan> {
    return this.baseHttpService.get<IBase<IMedicalPackagePlan>>(
      `client-company/${companyId}/contract/${contractId}/med-service-pkg/${packageId}`
    ).pipe(pluck('data'));
  }

  getAllEmployees(filters: ICustomFilters = {}): Observable<IPageableData<ICompanyEmployee>> {
    return this.baseHttpService.get<IBase<IPageableData<ICompanyEmployee>>>(`employees`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getWards(filters: ICustomFilters = {}): Observable<IPageableData<IWardMinimal>> {
    return this.baseHttpService.get<IBase<IPageableData<IWardMinimal>>>('rooms/wards', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getRoomTypes(filters: ICustomFilters = {}): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`rooms/roomType`, setFilter(filters)).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getNewAdmissionPatientList(filters: ICustomFilters = {}): Observable<IPageableData<{ patient: IPatientExtended }>> {
    return this.baseHttpService.get(`patients`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getBedTransferPatientList(filters: ICustomFilters = {}): Observable<IPageableData<IPatient>> {
    return this.baseHttpService.get<IBase<IPageableData<IPatient>>>(`adt/patient-list`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getPatientList(filters: ICustomFilters = {}): Observable<IPageableData<{ patient: IPatientExtended }>> {
    return this.baseHttpService.get<IBase<IPageableData<{ patient: IPatientExtended }>>>(`patients`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllergyTypes(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/allergy-type`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getOrNameListWithPagination(filters: ICommonFilters): Observable<IPageableData<ICatalogCategory>> {
    return this.baseHttpService.get(`operation-rooms`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllSubStock(filters: ICommonFilters): Observable<IPageableData<ISubStockMinimal>> {
    return this.baseHttpService.get(`sub-stocks`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getBillingClaimForms(filters: ICustomFilters = {}): Observable<IPageableData<ISubmitClaim>> {
    return this.baseHttpService.get(`billing/claim-forms`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getIntegrationSystems(filters: ICustomFilters = {}, isFor: 'ris' | 'lis'): Observable<IPageableData<IExternalSystem>> {
    const endPoint = isFor === 'lis' ? `active/lis/integration/system/list` : `active/ris/integration/system/list`;
    return this.baseHttpService.get(endPoint, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getActiveIntegrationSystem(isFor: 'ris' | 'lis', filters: ICustomFilters = {}): Observable<IExternalSystem> {
    const endPoint = isFor === 'lis' ? `active/lis/integration/system` : `active/ris/integration/system`;
    return this.baseHttpService.get<IBase<IExternalSystem>>(endPoint, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllSurgeonsWithPagination(filters: ICommonFilters): Observable<IPageableData<IDoctor>> {
    return this.baseHttpService.get('doctors/surgeons', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllSurgeonsWithPaginationCache(filters: ICommonFilters) {
    if (!this.surgeonCache$) {
      this.surgeonCache$ = this.getAllSurgeonsWithPagination(filters).pipe(pluck('items'), shareReplay(this.cacheSize));
    }
    return this.surgeonCache$;
  }

  getAllAnesthesiologistsWithPagination(filters: ICommonFilters): Observable<IPageableData<IDoctor>> {
    return this.baseHttpService.get('doctors/anesthesiologists', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllAnesthesiologistsWithPaginationCache(filters: ICommonFilters) {
    if (!this.anesthesiologistsCache$) {
      this.anesthesiologistsCache$ = this.getAllAnesthesiologistsWithPagination(filters).pipe(pluck('items'), shareReplay(this.cacheSize));
    }
    return this.anesthesiologistsCache$;
  }

  getAllRadiologists(filters: ICommonFilters): Observable<IPageableData<IDoctor>> {
    return this.baseHttpService.get('doctors/radiologists', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllRadiologistsCache(filters: ICommonFilters) {
    if (!this.radiologistsCache$) {
      this.radiologistsCache$ = this.getAllRadiologists(filters).pipe(pluck('items'), shareReplay(this.cacheSize));
    }
    return this.radiologistsCache$;
  }

  getAllPathologists(filters: ICommonFilters): Observable<IPageableData<IDoctor>> {
    return this.baseHttpService.get('doctors/pathologists', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllPathologistsCache(filters: ICommonFilters) {
    if (!this.pathologistsCache$) {
      this.pathologistsCache$ = this.getAllPathologists(filters).pipe(pluck('items'), shareReplay(this.cacheSize));
    }
    return this.pathologistsCache$;
  }

  getBedRequestTypes(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/bed-request-type`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getTransferBedTypes(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/transfer-bed-type`).pipe(
      pluck('data', 'items'),
      map(types => types.map(type => ({...type, label: getTransferBedType(type.label)}))),
      catchError(_ => of([]))
    );
  }

  getAddBedTypes(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/add-bed-type`).pipe(
      pluck('data', 'items'),
      map(types => types.map(type => ({...type, label: getAddBedType(type.label)}))),
      catchError(_ => of([]))
    );
  }

  getPatientBeds(patientId: string): Observable<IData<IBedMinimal>> {
    return this.baseHttpService.get<IBase<IData<IBedMinimal>>>(`patient/${patientId}/occupied-hold-beds`).pipe(
      pluck('data')
    );
  }

  getRadiologyCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>(
      'radiology/catalog-elements',
      setFilter(filters)
    ).pipe(pluck('data'));
  }

  getRadiologyCatalogGroups(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogGroup>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogGroup>>>('radiology/catalog-groups', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getDoctorFeeElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>(
      'doctor-fee/catalog-elements',
      setFilter(filters)
    ).pipe(pluck('data'));
  }

  getDoctorFeeGroups(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogGroup>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogGroup>>>('doctor-fee/catalog-groups', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getProcedureCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>('procedure/catalog-elements', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getProcedureCatalogGroups(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogGroup>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogGroup>>>('procedure/catalog-groups', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getMedicineCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<PrescriptionMedicineViewModel>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>('pharmacy/catalog-elements', setFilter(filters)).pipe(
      pluck('data'),
      map(data => ({...data, items: data.items.map(res => PrescriptionMedicineViewModel.mapFromHttpModel(res))}))
    );
  }

  getSupplyCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>('supply/catalog-elements', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getSupplyCatalogGroups(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogGroup>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogGroup>>>('supply/catalog-groups', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getSupportTicketTopics(filters: ICommonFilters): Observable<IPageableData<ISupportTicketTopic>> {
    return this.baseHttpService.get<IBase<IPageableData<ISupportTicketTopic>>>(
      'support-center/support-ticket-topics', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getPathologyCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>(
      'pathology/catalog-elements',
      setFilter(filters)
    ).pipe(pluck('data'));
  }

  getNutritionMealCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>(`nutrition-meal/catalog-elements`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getNutritionMealCatalogElementById(catalogElementId: string): Observable<ICatalogElement> {
    return this.baseHttpService.get<IBase<ICatalogElement>>(`nutrition-meal/catalog-element/${catalogElementId}`).pipe(
      pluck('data')
    );
  }

  getNutritionMealCatalogGroups(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogGroup>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogGroup>>>('nutrition-meal-group/catalog-groups', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getNutritionMealCatalogGroupById(catalogGroupId: string): Observable<ICatalogGroup> {
    return this.baseHttpService.get<IBase<ICatalogGroup>>(`nutrition-meal-group/catalog-group/${catalogGroupId}`).pipe(
      pluck('data')
    );
  }

  getDiagnosisList(filters: ICustomFilters = {}, endpoint: string): Observable<IPageableData<IDiagnosisDetail>> {
    return this.baseHttpService.get<IBase<IPageableData<IDiagnosisDetail>>>(endpoint, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getDiagnosis(appointmentId: string, appointmentType: string, diagnosisForDate = getCurrentDateTime()): Observable<IBase<IData<IDiagnosis>>> {
    if (appointmentType === AppointmentTypeEnum.VISIT) {
      return this.baseHttpService.get(`appointment/${appointmentId}/diagnosis`)
    } else {
      return this.baseHttpService.get(`admission-appointment/${appointmentId}/diagnosis/date/${diagnosisForDate}`);
    }
  }

  getICD9Diagnosis(filters: ICustomFilters = {}): Observable<IPageableData<IDiagnosisDetail>> {
    return this.baseHttpService.get<IBase<IPageableData<IDiagnosisDetail>>>(`icd-9-pcs-items`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getICD10Diagnosis(filters: ICommonFilters): Observable<IPageableData<IDiagnosisDetail>> {
    return this.baseHttpService.get<IBase<IPageableData<IDiagnosisDetail>>>(`icd-10-diagnosis-items`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getPrincipalDiagnosis(filters: ICommonFilters): Observable<IPageableData<IDiagnosisDetail>> {
    return this.baseHttpService.get<IBase<IPageableData<IDiagnosisDetail>>>(`medical-code-list-items`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getPharmacyCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>(`pharmacy/catalog-elements`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getPharmacyCatalogGroups(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogGroup>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogGroup>>>(`pharmacy/catalog-groups`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getCommonPatientOrderType(): Observable<Array<IMasterData>> {
    const commonOrderTypes = [
      OrderTypeEnum.lab, OrderTypeEnum.radiology, OrderTypeEnum.nutritionMeal,
      OrderTypeEnum.TreatmentPlan, OrderTypeEnum.TreatmentProcedure, OrderTypeEnum.Medication,
    ];
    return this.getAllPatientOrderType().pipe(
      map(orderTypes => orderTypes.filter(type => commonOrderTypes.includes(type.label as OrderTypeEnum))),
      map(orderTypes => orderTypes.map(type => getUserDisplayedOrderType(type))),
      catchError(_ => of([]))
    );
  }

  getAllPatientOrderType(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get(`master/patient-order-type`).pipe(
      pluck('data', 'items')
    );
  }

  getRadiologyInvestigationType(): Observable<IMasterData[]> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/radiologyType`).pipe(
      pluck('data', 'items')
    );
  }

  getLabTypes(): Observable<IMasterData[]> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/pathologyType`).pipe(
      pluck('data', 'items')
    );
  }

  getReportInvestigationType(orderType: string): Observable<IMasterData[]> {
    if (orderType === OrderTypeEnum.radiology) {
      return this.getRadiologyInvestigationType();
    } else if (orderType === OrderTypeEnum.lab) {
      return this.getLabTypes();
    }
    return of([]);
  }

  getBedTransferProcessSteps(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/bed-transfer-process`).pipe(
      pluck('data', 'items')
    );
  }

  getAllVendor(filters: ICommonFilters): Observable<IPageableData<IVendor>> {
    return this.baseHttpService.get<IBase<IPageableData<IVendor>>>(`vendors`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getPriorityStatus(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`main-stock/procurement-request/priority`).pipe(
      pluck('data', 'items'),
      catchError(() => of([]))
    );
  }

  getManufacturers(filters: ICustomFilters = {}): Observable<IPageableData<IManufacturer>> {
    return this.baseHttpService.get<IBase<IPageableData<IManufacturer>>>(`manufacturers`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllWards(filters: ICustomFilters = {}): Observable<IPageableData<IWardMinimal>> {
    return this.baseHttpService.get<IBase<IPageableData<IWardMinimal>>>('wards', setFilter(filters)).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const data = items.map(item => ({...item, wardId: item.id, wardTitle: item.title}));
        return {items: data, ...pagination};
      })
    );
  }

  getClientCompanies(filters: ICustomFilters = {}): Observable<IPageableData<ICompany>> {
    return this.baseHttpService.get<IBase<IPageableData<ICompany>>>(`client-companies`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getClientCompanyContracts(filters: ICustomFilters = {}): Observable<IPageableData<IContract>> {
    return this.baseHttpService.get<IBase<IPageableData<IContract>>>(`contracts`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getContractPackages(filters: ICustomFilters = {}): Observable<IPageableData<IMedicalPackagePlan>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedicalPackagePlan>>>(`med-service-pkgs`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getInsuranceCompanies(filters: ICustomFilters = {}): Observable<IPageableData<ICompany>> {
    return this.baseHttpService.get<IBase<IPageableData<ICompany>>>(`insurance-companies`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getInsuranceCompanyContracts(filters: ICustomFilters = {}): Observable<IPageableData<IContract>> {
    return this.baseHttpService.get<IBase<IPageableData<IContract>>>(`insurance-contracts`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getInsuranceContractPlans(filters: ICustomFilters = {}): Observable<IPageableData<IMedicalPackagePlan>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedicalPackagePlan>>>(`medical-service-package/insurance-plans`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllBeds(filters: ICustomFilters = {}): Observable<IPageableData<IBed>> {
    return this.baseHttpService.getWrapper<IBase<IPageableData<IBed>>>(`beds`, ErrorHandlerEnum.BED_MANAGEMENT_HOSPITAL_BED_LIST, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getBedConditionTypes(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`beds/condition-types`).pipe(
      pluck('data', 'items'),
      map(response => response.filter(type => type.label !== 'Other')),
      catchError(_ => of([]))
    );
  }

  getAllBedChargeTypeHourly(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/charge-type-label-hourly`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getAllBedChargeTypeDaily(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/charge-type-label-daily`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getNursingStations(filters: ICustomFilters = {}): Observable<IPageableData<INurseStation>> {
    return this.baseHttpService.get<IBase<IPageableData<INurseStation>>>(
      `medical-resource/nursing-stations/${getStartOfDay()}`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getOPDNursingStations(filters: ICommonFilters): Observable<IPageableData<INurseStation>> {
    return this.baseHttpService.get<IBase<IPageableData<INurseStation>>>('nurse-stations/opd', setFilter(filters))
      .pipe(pluck('data'));
  }

  getIPDNursingStations(filters: ICommonFilters): Observable<IPageableData<INurseStation>> {
    return this.baseHttpService.get<IBase<IPageableData<INurseStation>>>('nurse-stations/ipd', setFilter(filters))
      .pipe(pluck('data'));
  }

  getNurseStationsByDepartmentId(departmentId: string, filters: ICommonFilters): Observable<IPageableData<INurseStation>> {
    return this.baseHttpService.get<IBase<IPageableData<INurseStation>>>(`medical-resource/dept-nursing-station/${departmentId}`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getOPDReceptions(filters: ICustomFilters = {}): Observable<IPageableData<INurseStation>> {
    return this.baseHttpService.get<IBase<IPageableData<INurseStation>>>(`receptions`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getRadiologyReceptions(): Observable<Array<INurseStation>> {
    return this.baseHttpService.get<IBase<IData<INurseStation>>>(`patient-orders/patient-investigation/radiology/receptions`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getLabReceptions(): Observable<Array<INurseStation>> {
    return this.baseHttpService.get<IBase<IData<INurseStation>>>(`patient-orders/patient-investigation/lab/receptions`).pipe(
      pluck('data', 'items'),
      catchError(_ => of([]))
    );
  }

  getStockCatalogElements(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogElement>>>(`stock/catalog-elements`, setFilter(filters)).pipe(
      pluck('data'),
    );
  }

  getAllHospitalTeam(filter: any = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get('hospital-teams', setFilter(filter)).pipe(
      pluck('data'));
  }

  getUsers(isMedicalParty: boolean, userType: string, filters: ICustomFilters = {}): Observable<IPageableData<IDoctorStaff>> {
    let endpoint = '';
    if (isMedicalParty) {
      if (userType === UserTypeEnum.Doctor) {
        endpoint = 'medical-party/doctor-staff';
      } else if (userType === UserTypeEnum.Nurse) {
        endpoint = 'medical-party/nurse-staff';
      } else if (userType === UserTypeEnum.Other) {
        endpoint = 'medical-party/other-staff';
      }
    } else {
      if (userType === UserTypeEnum.Executive) {
        endpoint = 'non-medical-party/executive-staff';
      } else if (userType === UserTypeEnum.Manager) {
        endpoint = 'non-medical-party/manager';
      } else if (userType === UserTypeEnum.Other) {
        endpoint = 'non-medical-party/other-staff';
      } else if (userType === UserTypeEnum.Cashier) {
        endpoint = 'staff/cashiers/list';
      }
    }
    return this.baseHttpService.get<IBase<IPageableData<IDoctorStaff>>>(endpoint, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getAllHospitals(filters: ICustomFilters = {}): Observable<IPageableData<IHospital>> {
    return this.baseHttpService.get(`corp-setup/hospitals`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getCorpSetupBuilding(filters: ICustomFilters = {}): Observable<IPageableData<IBuilding>> {
    return this.baseHttpService.get(`corp-setup/buildings`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getPharmacyOutlets(filters: ICustomFilters = {}): Observable<IPageableData<IPharmacyOutlet>> {
    return this.baseHttpService.get<IBase<IPageableData<IPharmacyOutlet>>>(`v2/pharmacy/pharmacy-outlets`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getHospitalTeams(filters: ICustomFilters = {}): Observable<IPageableData<IHospitalTeam>> {
    return this.baseHttpService.get<IBase<IPageableData<IHospitalTeam>>>('hospital-teams', setFilter(filters))
      .pipe(pluck('data'));
  }

  getAttachEquipment(filters: ICustomFilters = {}): Observable<IPageableData<IAttachEquipment>> {
    return this.baseHttpService.get<IBase<IPageableData<IAttachEquipment>>>(`medical-resource/equipment-managements/${getStartOfDay()}`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getManufacturer(filters: ICustomFilters = {}): Observable<IPageableData<IManufacturer>> {
    return this.baseHttpService.get<IBase<IPageableData<IManufacturer>>>(`equipment-manufacturers`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getInventoryCatalogs(filters: ICustomFilters = {}): Observable<IPageableData<IMedicalCatalog>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedicalCatalog>>>('procurement-catalogs', setFilter(filters))
      .pipe(pluck('data'));
  }

  getBatchListForCatalogElementV2(catalogElementId: string, filters: ICustomFilters = {}, refetchData = true): Observable<IPageableData<IBatchNumber>> {
    const currentValue = this.batchListForCatalogElementV2$.getValue();
    if (currentValue && !refetchData) {
      return of(currentValue);
    } else {
      return this.baseHttpService.get<IBase<IPageableData<IBatchNumber>>>(`v2/catalog-element/${catalogElementId}/batch-number/list`, setFilter(filters)).pipe(
        pluck('data')
      ).pipe(
        tap(response => this.batchListForCatalogElementV2$.next(response)),
      );
    }
  }

  getMedicalCodeList(filters: ICustomFilters = {}): Observable<IPageableData<IDiagnosisCode>> {
    return this.baseHttpService.get<IBase<IPageableData<IDiagnosisCode>>>(`medical-code-lists`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getPatientDischargeStatus(filters: ICustomFilters = {}): Observable<IPageableData<IPatientDischargeStatus>> {
    return this.baseHttpService.get<IBase<IPageableData<IPatientDischargeStatus>>>(`patient-discharge-status`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getDischargeType(filters: ICustomFilters = {}): Observable<IPageableData<IDischargeType>> {
    return this.baseHttpService.get<IBase<IPageableData<IDischargeType>>>(`discharge-type`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getDrOpdInsuranceForm(filters: ICustomFilters = {}): Observable<IPageableData<IInsuranceForm>> {
    return this.baseHttpService.get<IBase<IPageableData<IInsuranceForm>>>(`opd/dr/form-managements`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getDrIpdInsuranceForm(filters: ICustomFilters = {}): Observable<IPageableData<IInsuranceForm>> {
    return this.baseHttpService.get<IBase<IPageableData<IInsuranceForm>>>(`ipd/dr/form-managements`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getAllFavoriteOrders(filters: ICustomFilters = {}): Observable<IPageableData<FavoriteFormDataModel>> {
    return this.baseHttpService.get<IBase<IPageableData<IFavoriteOrder>>>('patient-order/favorite-orders', setFilter(filters)).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const data = items.map(order => FavoriteFormDataModel.mapFromHttpModel(order));
        return {...pagination, items: data};
      })
    );
  }

  getRadiologyInvestigationTypes(filters: ICustomFilters = {}): Observable<IPageableData<ICatalogElement>> {
    return this.baseHttpService.get(`investigation/radiology/types`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getPreferredPharmacyOutletByAppointmentId(appointmentId: string): Observable<PharmacyOutletViewModel> {
    return this.baseHttpService.get<IBase<IPharmacyOutlet>>(`appointment/${appointmentId}/preferred-pharmacy-outlet`).pipe(
      pluck('data'),
      map(outlet => PharmacyOutletViewModel.mapFromApi(outlet))
    );
  }

  getVatDetails(): Observable<IVatDetails> {
    return this.baseHttpService.get(`corp-setup/hospital/vat/by-login-user`).pipe(
      pluck('data')
    );
  }

  getPatientApptType(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/patient-appt-type`).pipe(
      pluck('data', 'items')
    );
  }

  getPatientOrderOrigins(): Observable<Array<IMasterData>> {
    if (!this.patientOrderOriginCache$) {
      this.patientOrderOriginCache$ = this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/patient-order-origin`).pipe(
        pluck('data', 'items'),
        shareReplay(this.cacheSize)
      );
    }
    return this.patientOrderOriginCache$;
  }

  getSupplyCategory(filters: ICustomFilters = {}): Observable<IPageableData<IMedicalCatalog>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedicalCatalog>>>('supply/catalog-categories', setFilter(filters))
      .pipe(pluck('data'));
  }

  getSupplySubCategory(filters: ICustomFilters = {}): Observable<IPageableData<IMedicalCatalog>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedicalCatalog>>>('supply/catalog-sub-categories', setFilter(filters))
      .pipe(pluck('data'));
  }

  getCatalogCategories(catalogId: string, filters: ICustomFilters = {}): Observable<IPageableData<ICatalogCategory>> {
    return this.baseHttpService.get<IBase<IPageableData<ICatalogCategory>>>(`medical-catalog/${catalogId}/catalog-categories`, setFilter(filters))
      .pipe(pluck('data'));
  }

  getAllMedicalPackageCache(filters: ICustomFilters = {}): Observable<Array<MedicalPackageListViewModel>> {
    if (!this.medicalPackageCache$) {
      this.medicalPackageCache$ = this.getMedicalPackageList(filters).pipe(
        pluck('items'),
        map(response => (isArray(response) && response.length) && response.map(item => MedicalPackageListViewModel.mapFromApiModel(item))),
        shareReplay(1)
      );
    }
    return this.medicalPackageCache$;
  }

  getMedicalPackageList(filters: ICustomFilters = {}): Observable<IPageableData<IMedicalPackage>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedicalPackage>>>('v2/packages', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getDoctorStaff(filters: ICustomFilters = {}): Observable<IPageableData<IDoctor>> {
    return this.baseHttpService.get<IBase<IPageableData<IDoctor>>>(`v2/medical-party/doctor-staff`, setFilter(filters)).pipe(
      pluck('data')
    )
  }

  getStockOrderTypes(filters: ICustomFilters = {}): Observable<IPageableData<IMasterData>> {
    if (!this.stockOrderTypeCache$) {
      this.stockOrderTypeCache$ = this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(`master/stock-order-type`, setFilter(filters)).pipe(
        pluck('data'),
        shareReplay(this.cacheSize)
      );
    }
    return this.stockOrderTypeCache$;
  }

  getStockType(filters: ICustomFilters = {}): Observable<IPageableData<IMasterData>> {
    if (!this.stockTypeCache$) {
      this.stockTypeCache$ = this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(`master/stock-type`, setFilter(filters)).pipe(
        pluck('data'),
        shareReplay(this.cacheSize)
      );
    }
    return this.stockTypeCache$;
  }

  getStockItems(filters: ICustomFilters = {}): Observable<IPageableData<IStockItem>> {
    return this.baseHttpService.get<IBase<IPageableData<IStockItem>>>(`stock/stock-items`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getStockItemOutstandingQuantity(filters: ICustomFilters = {}): Observable<Array<IStockItem>> {
    return this.baseHttpService.get<IBase<IData<IStockItem>>>(`procurement/outstanding-quantity`, setFilter(filters)).pipe(
      pluck('data', 'items')
    );
  }

  getStockItemsWithErrorWrapper(filters: ICustomFilters): Observable<IPageableData<IStockItem>> {
    return this.baseHttpService.getWrapper<IBase<IPageableData<IStockItem>>>(`stock/stock-items`, ErrorHandlerEnum.PROCUREMENT_MAIN_STOCK_STATIONERY_LIST, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getStockItemByStockItemId(stockItemId: string, filters: ICustomFilters = {}): Observable<IStockItem> {
    return this.baseHttpService.get<IBase<IStockItem>>(`stock-item/${stockItemId}`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  downloadFile(endPoint: string, filters: ICustomFilters = {}): Observable<Blob> {
    return this.baseHttpService.getXlsx(endPoint, setFilter(filters));
  }

  uploadFile(endPoint: string, data) {
    const params = new HttpParams().append(CommonEnum.uploadLogo, 'true');
    return this.baseHttpService.uploadFile(endPoint, data, params).pipe(
      pluck('data')
    );
  }

  getCompanies(): Observable<ICompany[]> {
    const filterObject: ICommonFilters = {
      filters: {
        includes: {
          address: true
        }
      }
    }
    return this.baseHttpService.get(`companies`, setFilter(filterObject)).pipe(
      pluck('data', 'items')
    )
  }

  getCatalogEndPoint(inventoryType: string, catalogElementId: string): string {
    switch (inventoryType) {
      case InventoryTypeEnum.Medicine:
        return `main-stock/medicine/stock-item/catalog-element/${catalogElementId}`
      case InventoryTypeEnum.Supplies:
        return `main-stock/supply/supply-item/catalog-element/${catalogElementId}`
      case InventoryTypeEnum.Housekeeping:
        return `main-stock/housekeeping/stock-item/catalog-element/${catalogElementId}`
      case InventoryTypeEnum.Stationary:
        return `main-stock/stationery/stock-item/catalog-element/${catalogElementId}`
      case InventoryTypeEnum.Equipment:
        return `main-stock/equipment/stock-item/catalog-element/${catalogElementId}`
    }

  }

  getCatalogElementById(inventoryType: string, catalogId: string): Observable<IStockItem> {
    const endPoint = this.getCatalogEndPoint(inventoryType, catalogId)
    return this.baseHttpService.get(endPoint).pipe(pluck('data'));
  }

  isItemExistsInPharmacyOutlet(postData: IDeletableEntity<PharmacyOutletPayload, string>): Observable<IPharmacyOutlet[]> {
    return this.baseHttpService.post<IBase<IData<IPharmacyOutlet>>>(`sub-stock/pharmacy/validate-item`, postData).pipe(
      pluck('data', 'items')
    );
  }

  checkPatientHasAllergy(patientId: string, filters: ICustomFilters = {}): Observable<IBase<IPatientMedicineAllergyStatus>> {
    return this.baseHttpService.get<IBase<IPatientMedicineAllergyStatus>>(`patient/${patientId}/check-patient-has-allergy`, setFilter(filters));
  }

  checkPatientHasAllergies(patientId: string, medicineIds: string[]): Observable<IBase<IData<IPatientMedicineAllergyStatus>>> {
    return this.baseHttpService.post<IBase<IData<IPatientMedicineAllergyStatus>>>(`patient/${patientId}/check-patient-has-group-ele-allergy`, {catalogElementIds: medicineIds});
  }

  checkMedicineInteractionAllergy(patientId: string, payload: MedicineInteractionAllergyPayload): Observable<IBase<IMedicineInteractionAllergy>> {
    return this.baseHttpService.post<IBase<IMedicineInteractionAllergy>>(`patient/${patientId}/check-med-interaction-allergy`, payload);
  }

  getCurrentMedication(patientId: string): Observable<IBase<IData<IMedication>>> {
    return this.baseHttpService.get<IBase<IData<IMedication>>>(`patient/${patientId}/patient-current-medication/${getStartOfDay()}`);
  }

  getAppointmentPaymentPriorityV2(filters: ICustomFilters = {}): Observable<IPaymentPriority[]> {
    return this.baseHttpService.get('v2/appointment/patient/payment/priorities', setFilter(filters)).pipe(
      pluck('data', 'items'),
      map((response: IPaymentPriority[]) => {
        return response;
      })
    );
  }

  getPatientBedWardV2(filters: ICustomFilters = {}): Observable<IBedMinimal[]> {
    return this.baseHttpService.get('v2/appointment/patient/occupied-bed', setFilter(filters)).pipe(
      pluck('data', 'items'),
      map((response: IBedMinimal[]) => {
        return response;
      })
    );
  }

  getPatientNoteAlertCount(filters: ICustomFilters = {}): Observable<INoteAlertCount[]> {
    return this.baseHttpService.get<IBase<IData<INoteAlertCount>>>('v2/alert-notes-count', setFilter(filters)).pipe(
      pluck('data', 'items'),
      map((response: INoteAlertCount[]) => {
        return response;
      })
    );
  }

  getPatientNoteAlertCountDetails(data: IPageableData<INoteAlertCount>, currentOAId: string): Observable<IPageableData<INoteAlertCount>> {
    if (!(data && data.items && data.items.length)) {
      return EMPTY;
    }
    const patientIds = data && isArray(data.items) ? data.items.map(item => item.patientId) : [];
    const filters: ICommonFilters = {
      filters: {
        filters: {
          patientIds,
          currentOAId,
          validityStartDT: getStartOfDay(),
          validityEndDT: getEndOfDay()
        }
      }
    };
    return this.getPatientNoteAlertCount(filters)
      .pipe(
        map((response) => {
          data.items.forEach((item) => {
            const countInfo = response.find((countItem) => countItem.patientId === item.patientId)
            if (countInfo) {
              item.alertNoteCount = countInfo.alertNoteCount;
            }
          })
          return data;
        }),
        catchError(() => of(data))
      )
  }

  getAppointmentPaymentStatus(params = new HttpParams()): Observable<Array<{ PatientApptId_uuid: string, billStatus: string }>> {
    return this.baseHttpService.get<IBase<IData<{ PatientApptId_uuid: string, billStatus: string }>>>(`appointment/bill/paid-status`, params).pipe(
      pluck('data', 'items')
    );
  }

  getPaymentTypeAndBedWardDetails<T>(data: IPageableData<T>, loadBedInfo: boolean, loadPaymentPriorityInfo: boolean, config: IAppointmentPaymentTypeAndBedDetailConfig<T>): Observable<IPageableData<T>> {
    if (!(data && data.items && data.items.length)) {
      return EMPTY;
    }

    if (!loadBedInfo && !loadPaymentPriorityInfo) {
      return of(data);
    }

    const requests: Array<Observable<IPaymentPriority[] | IBedMinimal[]>> = [];
    if (loadPaymentPriorityInfo) {
      const filters: ICommonFilters = {filters: {filters: {appointmentIds: config.appointmentIds}}};
      requests.push(this.getAppointmentPaymentPriorityV2(filters));
    }
    if (loadBedInfo && isArray(config.ipdAppointmentIds) && config.ipdAppointmentIds.length) {
      const filters: ICommonFilters = {filters: {filters: {appointmentIds: config.ipdAppointmentIds}}};
      requests.push(this.getPatientBedWardV2(filters))
    }

    return forkJoin(
      requests
    ).pipe(
      map(response => {
        const paymentPriority: IPaymentPriority[] = loadPaymentPriorityInfo ? response[0] as IPaymentPriority[] : [];
        const bedData = (!loadPaymentPriorityInfo ? response[0] as IBedMinimal[] : response[1] as IBedMinimal[]) || [];
        data.items = data.items.map(item => config.callbackFn(item, paymentPriority, bedData));
        return data;
      }),
      catchError(() => of(data))
    );
  }

  getUsedMedicalPackageInfo(filters: ICustomFilters = {}): Observable<Array<IUsedMedicalPackage>> {
    return this.baseHttpService.get<IBase<IData<IUsedMedicalPackage>>>(`v2/used-medical-package`, setFilter(filters)).pipe(
      pluck('data', 'items')
    );
  }

  getUsedMedicalPackageDetail<T>(data: Array<T>, config: IUsedMedicalPackageDetailConfig<T>): Observable<Array<T>> {
    if (!(data && data.length)) {
      return of([]);
    }
    config.selectionItemIds = config.selectionItemIds.filter(itemId => !!itemId);
    if (!config.selectionItemIds.length) {
      return of(data);
    }
    const filters: ICommonFilters = {filters: {filters: {selectionItemIds: config.selectionItemIds}}};
    return this.getUsedMedicalPackageInfo(filters).pipe(
      map(response => {
        data = data.map(item => config.callbackFn(item, response));
        return data;
      }),
      catchError(() => of(data))
    );
  }

  getPaymentModes(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>('master/payment-mode').pipe(
      pluck('data', 'items')
    );
  }

  getPaymentModeDetailEndpoint(paymentMode: string): string {
    switch (paymentMode) {
      case PaymentModeEnum.bankTransfer:
        return `master/payment-mode-detail-bank`
      case PaymentModeEnum.card:
        return `master/payment-mode-detail-card`
      case PaymentModeEnum.cheque:
        return `master/payment-mode-detail-cheque`
    }
  }

  getPaymentModeDetail(paymentMode: string): Observable<IMasterData[]> {
    const endpoint = this.getPaymentModeDetailEndpoint(paymentMode)
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(endpoint).pipe(
      pluck('data', 'items')
    );
  }

  getClaimPaymentTypes(): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/payment-type`).pipe(
      pluck('data', 'items'),
      map(response => {
        const finalPaymentType = response.find(type => type.label === ClaimPaymentTypeEnum.CLAIM_PAYMENT);
        if (finalPaymentType) {
          finalPaymentType.label = ClaimPaymentTypeEnum.FINAL_PAYMENT_UI;
        }
        return {items: response, totalItems: response.length, totalPages: 1} as IPageableData<IMasterData>
      })
    );
  }

  getPurchaseRequestStatus(): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IData<IMasterData>>>(`master/purchase-request-status`).pipe(
      pluck('data', 'items'),
      map(response => {
        const processedStatus = response.find(type => type.label === PurchaseRequestStatusEnum.PROCESSED);
        if (processedStatus) {
          processedStatus.label = PurchaseRequestStatusEnum.PROCESSED_UI;
        }
        return {items: response, totalItems: response.length, totalPages: 1} as IPageableData<IMasterData>
      })
    );
  }

  getLabLISReports(appointmentId: string = null): Observable<IStatusResponse> {
    return this.baseHttpService.post<IBase<IStatusResponse>>('aimconnect/pathology/fetch', {appointmentId}).pipe(pluck('data'));
  }

  getRadiologyRISReports(appointmentId: string = null): Observable<IStatusResponse> {
    return this.baseHttpService.post<IBase<IStatusResponse>>('aimconnect/radiology/fetch', {appointmentId}).pipe(pluck('data'));
  }

  getMedicalResultOutput(): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IPageableData<IMasterData>>>('master/medical-result-output').pipe(
      pluck('data')
    );
  }

  getPatientVisitAdmissionXref(patientId: string, filters: ICustomFilters = {}): Observable<IPageableData<IPatientVisitAdmissionXref>> {
    return this.baseHttpService.get<IBase<IPageableData<IPatientVisitAdmissionXref>>>(`v2/patient/${patientId}/in-progress-closed/visit-admissions-xrefs`, setFilter(filters)).pipe(
      pluck('data')
    );
  }

  getFacilityResourceTypes(filters: ICustomFilters = {}): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(`master/mso-facility-resource-type`, setFilter(filters)).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const updatedItems = items.map(item => {
          if (item.label === FacilityResourceTypeEnum.OPD_NS) {
            return {...item, label: FacilityResourceTypeEnum.OPD};
          } else if (item.label === FacilityResourceTypeEnum.IPD_NS) {
            return {...item, label: FacilityResourceTypeEnum.IPD};
          }
          return item;
        })
        return {...pagination, items: updatedItems} as IPageableData<IMasterData>;
      })
    );
  }

  getPurchaseOrderStatus(): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(`purchase-order/status`).pipe(
      pluck('data')
    );
  }

  getServiceAreaList(): Observable<IPageableData<IMasterData>> {
    return this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(`master/department-service-areas`).pipe(
      pluck('data')
    );
  }

  getAllPriceParameters(): Observable<Array<IMasterData>> {
    return this.baseHttpService.get<IBase<IPageableData<IMasterData>>>(`master/price-parameter`).pipe(
      pluck('data', 'items')
    );
  }

  getVitalUnitMappings(): Observable<IBase<{ items: IVitalUnit }>> {
    return this.baseHttpService.get<IBase<{ items: IVitalUnit }>>('get-unit-mappings');
  }
}

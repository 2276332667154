import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {setFilter} from '@v2/core/functions/functions';
import {IBase, IData, IPageableData, IPatient} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IAlertNoteLog} from '@v2/core/models/masterdata/ILog';
import {AlertNoteLogViewModel} from '@v2/core/models/view/logs.view.model';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {Observable, Subject} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {NoteAlertFormDataModel} from './resources/model/form/note-alert-form.model';
import {INoteAlert} from './resources/model/http/INoteAlert';
import {NoteAlertPayload} from './resources/model/payload/note-alert.payload';
import {NoteAlertListViewModel} from './resources/model/view/note-alert-list-view.model';

@Injectable({
  providedIn: 'root',
})
export class NoteAlertService {
  isPatientInfoBanner: Subject<boolean> = new Subject<boolean>();
  toggleNoteAlertSidebarModal: Subject<{
    showAlertNoteSidebar: boolean;
    patient?: IPatient;
    currentOAId?: string;
    appointmentId?: string;
    useV2TranslationPipe?: boolean;
    hasWriteAccess?: boolean;
    allowVisitRestriction?: boolean;
  }> = new Subject<{ showAlertNoteSidebar: boolean; patient: IPatient; currentOAId: string; appointmentId: string; useV2TranslationPipe: boolean, hasWriteAccess: boolean; }>();
  noteAlertCountUpdated: Subject<{ patientId: string, appointmentId: string, currentOA: string, notificationCount: number }> = new Subject<{ patientId: string; appointmentId: string; currentOA: string, notificationCount: number }>();

  constructor(private masterDataHttpService: MasterDataHttpService, private baseHttpService: BaseHttpService) {
  }

  addNoteAlert(payload: NoteAlertPayload): Observable<INoteAlert> {
    return this.baseHttpService.post<IBase<INoteAlert>>(`v2/alert-note/create`, payload)
      .pipe(pluck('data'))
  }

  updateNoteAlert(alertNoteId: string, payload: NoteAlertPayload): Observable<INoteAlert> {
    return this.baseHttpService.putWrapper<IBase<INoteAlert>>(`v2/alert-note/${alertNoteId}/update`, payload, ConcurrencyScopeEnum.PATIENT_NOTE_ALERT)
      .pipe(pluck('data'))
  }

  inActivateNoteAlert(alertNoteId: string, payload: { validityEndDT: string; updatedAt: string }): Observable<INoteAlert> {
    return this.baseHttpService.putWrapper<IBase<INoteAlert>>(`v2/alert-note/${alertNoteId}/inactive`, payload, ConcurrencyScopeEnum.PATIENT_NOTE_ALERT)
      .pipe(pluck('data'));
  }

  getNoteAlertById(alertNoteId: string): Observable<NoteAlertFormDataModel> {
    return this.baseHttpService.get<IBase<INoteAlert>>(`v2/alert-note/${alertNoteId}`).pipe(
      map((response) => {
        return NoteAlertFormDataModel.mapFromApiModel(response.data);
      })
    );
  }

  getNoteAlertList(filters: ICustomFilters = {}): Observable<NoteAlertListViewModel[]> {
    return this.baseHttpService.get<IBase<IData<INoteAlert>>>(`v2/alert-notes`, setFilter(filters)).pipe(
      pluck('data'),
      map((response) => {
        return response.items.map((item) => NoteAlertListViewModel.mapFromApiModel(item));
      })
    );
  }

  getNoteAlertLogs(noteAlertId: string, filters: ICustomFilters = {}): Observable<IPageableData<AlertNoteLogViewModel>> {
    return this.baseHttpService.get<IBase<IPageableData<IAlertNoteLog>>>(`v2/alert-note/${noteAlertId}/logs`, setFilter(filters)).pipe(
      pluck('data'),
      map((response) => {
        const {items, ...pagination} = response;
        const noteAlertLogsData = items.map((item) => AlertNoteLogViewModel.mapFromHttpModel(item));
        return {items: noteAlertLogsData, ...pagination};
      })
    );
  }
}

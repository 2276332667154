import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {setFilter} from '@v2/core/functions/functions';
import {IBase, IMedication, IPageableData, IStatusResponse} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IComplyInfo, IPharmacyStatus, IReturnedPatientOrderLog} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {PatientOrderHistoryViewModel} from '../../../../visits/ipd/shared/models/patient-data/patient-order-history-view.model';
import {MedicationOrderPayload} from '../../components/patient-order/resources/models/payload/medication-order.payload';

@Injectable()
export class MedicationOrderHttpService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  getMedicationOrder(appointmentId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<IMedication>>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedication>>>(`v2/appointment/${appointmentId}/patient-orders/medication`, setFilter(filters));
  }

  placeMedicationOrder(appointmentId: string, payload: MedicationOrderPayload): Observable<IBase<IMedication>> {
    return this.baseHttpService.postWrapper<IBase<IMedication>>(`v2/appointment/${appointmentId}/medication/order`, payload, ConcurrencyScopeEnum.MEDICATION_PATIENT_ORDER, new HttpParams(), payload.uniqueIdForConcurrency);
  }

  updateMedicationOrder(appointmentId: string, orderId: string, payload: MedicationOrderPayload): Observable<IBase<IMedication>> {
    return this.baseHttpService.putWrapper<IBase<IMedication>>(`v2/appointment/${appointmentId}/medication/order/${orderId}`, payload, ConcurrencyScopeEnum.MEDICATION_PATIENT_ORDER);
  }

  deleteMedicationOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<IBase<IStatusResponse>> {
    const params = new HttpParams().append('appointmentId', appointmentId);
    return this.baseHttpService.deleteWrapper<IBase<IStatusResponse>>(`v2/medication/order/${orderId}/updated-at/${updatedAt}`, ConcurrencyScopeEnum.MEDICATION_PATIENT_ORDER, params);
  }

  getPharmacyOrderStatus(ids: string[]): Observable<IBase<Array<IPharmacyStatus>>> {
    let httpParams = new HttpParams();
    ids.forEach(id => {
      httpParams = httpParams.append('ids[]', id)
    })
    return this.baseHttpService.get<IBase<Array<IPharmacyStatus>>>('pharmacy/order/latest-status', httpParams);
  }

  getMedicineItemReturnLog(pdMedicationId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<IReturnedPatientOrderLog>>> {
    return this.baseHttpService.get<IBase<IPageableData<IReturnedPatientOrderLog>>>(`v2/medication/order/${pdMedicationId}/return-order-logs`, setFilter(filters));
  }

  complyMedicationOrder(isNS: boolean, doctorOrNSId: string, appointmentId: string, payload: unknown): Observable<IComplyInfo> {
    return this.baseHttpService.postWrapper(`v2/appointment/${appointmentId}/medication/order/comply`, payload, ConcurrencyScopeEnum.OPD_TREATMENT);
  }

  getPatientOrderHistory(groupOrderId: string, filter): Observable<PatientOrderHistoryViewModel[]> {
    return this.baseHttpService.get<IBase<IPageableData<IMedication>>>(`v2/appointment/medication/order/${groupOrderId}/commercial-orders`, setFilter(filter)).pipe(
      pluck('data', 'items'),
      map((response) => {
        return response.map((item: IMedication, index: number) => {
          return PatientOrderHistoryViewModel.mapFromApiModel(item);
        })
      })
    )
  }

  getMedicationPlanSpecs(appointmentId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<IMedication>>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedication>>>(`v2/appointment/${appointmentId}/medication/plan/specs`, setFilter(filters));
  }
}

import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TableBadgeEnum} from '@v2/core/enums/table-badge.enum';
import {IPageableData, IPrimaryDoctorCheckerHttpModel} from '@v2/core/models/masterdata';
import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs';
import {catchError, map, take, tap} from 'rxjs/operators';
import {ValidationHttpService} from '../../../core/http/services/other/validation-http.service';
import {AppSecurityRoles, GetDoctorRole} from '../../../core/models/menu/sub-menu.model';
import {
  getFullNextPatientDataUrl,
  getFullPreviousPatientDataUrl,
  getNextPatientDataUrl,
  getPreviousPatientDataUrl
} from '../../../shared/sub-menu/routes/functions';
import {AppointmentHttpService} from '../../../v2/shared-v2/resources/http-service/appointment.http.service';
import {PatientManagementRequestStatusViewModel} from '../models/consultation-transfer/view';
import {PatientDataModel} from '../models/patient-data-ui.model';

@Injectable()
export class CurrentAppointmentInfoService {

  private isDischargeOrTransferInfoLoaded = false;
  private dischargeOrTransferInfo: IPrimaryDoctorCheckerHttpModel = {} as IPrimaryDoctorCheckerHttpModel;
  private _userRole: AppSecurityRoles = null;

  constructor(
    private validationHttpService: ValidationHttpService,
    private appointmentHttpService: AppointmentHttpService,
  ) {
  }

  private _isForwardedToDoctor = new BehaviorSubject<boolean>(false);

  isForwardedToDoctor$ = this._isForwardedToDoctor.asObservable();

  set isForwardedToDoctor(value: boolean) {
    this._isForwardedToDoctor.next(value);
  }

  getFullNextPatientDataUrl(currentUrl: string, isVisit: boolean, isNS: boolean): string {
    return getFullNextPatientDataUrl(currentUrl, isVisit, isNS, this._userRole);
  }

  getNextPatientDataUrl(currentUrl: string, isVisit: boolean, isNS: boolean): string {
    return getNextPatientDataUrl(currentUrl, isVisit, isNS, this._userRole);
  }

  getFullPreviousPatientDataUrl(currentUrl: string, isVisit: boolean, isNS: boolean): string {
    return getFullPreviousPatientDataUrl(currentUrl, isVisit, isNS, this._userRole);
  }

  getPreviousPatientDataUrl(currentUrl: string, isVisit: boolean, isNS: boolean): string {
    return getPreviousPatientDataUrl(currentUrl, isVisit, isNS, this._userRole);
  }

  resetData() {
    this.isDischargeOrTransferInfoLoaded = false;
    this.dischargeOrTransferInfo = {} as IPrimaryDoctorCheckerHttpModel;
    this._userRole = null;
  }

  checkConsultationAppointmentStatus(doctorId: string, appointmentId: string, eventId: string): Observable<PatientManagementRequestStatusViewModel> {
    return this.validationHttpService.checkConsultationAppointmentStatus(doctorId, appointmentId, eventId).pipe(
      map(info => PatientManagementRequestStatusViewModel.mapFromHttpModel(info)),
      catchError(_ => of({} as PatientManagementRequestStatusViewModel))
    );
  }

  checkForDoctorReview(data: IPageableData<PatientDataModel>): Observable<IPageableData<PatientDataModel>> {

    if (!(data && data.items && data.items.length)) {
      return EMPTY;
    }

    const params = this.constructIdsQueryParams(data);

    return this.appointmentHttpService.checkForDoctorReview(params).pipe(
      map(response => {
        data.items.forEach(item => {
          const appointmentInfo = response.find(appointment => appointment.id === item.appointmentId);
          if (appointmentInfo) {
            item.neededToReviewedByDoctor = appointmentInfo.neededToReview;
            item.tableBadgeType = appointmentInfo.neededToReview ? TableBadgeEnum.PHARMACY : item.tableBadgeType;
          }
        });
        return data;
      })
    );
  }

  getLabOrderCounts(data: IPageableData<PatientDataModel>) {

    if (!(data && data.items && data.items.length)) {
      return EMPTY;
    }

    const params = this.constructIdsQueryParams(data);
    return this.appointmentHttpService.getPathologyOrderCount(params).pipe(
      map(response => {
        data.items.forEach(item => {
          const appointmentInfo = response.find(appointment => appointment.id === item.appointmentId);
          if (appointmentInfo) {
            item.labInvestigations = {
              resultUploadedOrders: appointmentInfo.resultUploadedOrders,
              totalOrders: appointmentInfo.totalOrders,
              id: appointmentInfo.id
            };
          }
        });
        return data;
      })
    );
  }

  getRadiologyOrderCounts(data: IPageableData<PatientDataModel>) {

    if (!(data && data.items && data.items.length)) {
      return EMPTY;
    }
    const params = this.constructIdsQueryParams(data);
    return this.appointmentHttpService.getRadiologyOrderCount(params).pipe(
      map(response => {
        data.items.forEach(item => {
          const appointmentInfo = response.find(appointment => appointment.id === item.appointmentId);
          if (appointmentInfo) {
            item.radiologyInvestigations = {
              resultUploadedOrders: appointmentInfo.resultUploadedOrders,
              totalOrders: appointmentInfo.totalOrders,
              id: appointmentInfo.id
            };
          }
        });
        return data;
      })
    );

  }

  constructIdsQueryParams(data: IPageableData<PatientDataModel>): HttpParams {
    let params = new HttpParams();
    data.items.map(item => {
      params = params.append('ids[]', item.appointmentId);
    });

    return params;
  }

  canDischargeOrTransfer(appointmentId: string, doctorId: string, reloadData = false): Observable<{ role: AppSecurityRoles, info: IPrimaryDoctorCheckerHttpModel }> {
    if (this.isDischargeOrTransferInfoLoaded && !reloadData) {
      return of({role: this._userRole, info: this.dischargeOrTransferInfo});
    }
    return this.validationHttpService.canDischargeOrTransfer(appointmentId, doctorId).pipe(
      take(1),
      tap(dischargeOrTransferInfo => {
        this.dischargeOrTransferInfo = dischargeOrTransferInfo;
        this._userRole = GetDoctorRole(dischargeOrTransferInfo);
      }),
      map(() => ({role: this._userRole, info: this.dischargeOrTransferInfo}))
    );
  }

}

import {getName, isBeforeToday} from '@v2/core/functions/functions';
import {IMasterData} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IOperationalArea} from '../../../../../resources/models/http/IOperationalArea';
import {INoteAlert} from '../http/INoteAlert';

export class NoteAlertListViewModel extends ConcurrencyRequest {
  id: string;
  note: string
  onlyForCurrentAppointment: boolean
  validityStartDT: string
  validityEndDT: string
  severity: IMasterData;
  recordedBy: string;
  changedBy: string;
  changedAt: string;
  recordedAt: string;
  notificationArea: IOperationalArea[];
  isInactive: boolean;

  static mapFromApiModel(apiData: INoteAlert): NoteAlertListViewModel {
    const viewModel = new NoteAlertListViewModel();

    viewModel.id = apiData.id;
    viewModel.note = apiData.note
    viewModel.onlyForCurrentAppointment = apiData.onlyForCurrentAppointment
    viewModel.validityStartDT = apiData.validityStartDT
    viewModel.validityEndDT = apiData.validityEndDT
    viewModel.severity = apiData.severity;
    viewModel.recordedBy = apiData.recordedBy && getName(apiData.recordedBy.firstName, apiData.recordedBy.lastName, apiData.recordedBy.initial);
    viewModel.changedBy = apiData.changedBy && getName(apiData.changedBy.firstName, apiData.changedBy.lastName, apiData.recordedBy.initial);
    viewModel.recordedAt = apiData.recordedAt;
    viewModel.changedAt = apiData.changedAt;
    viewModel.notificationArea = apiData.notificationArea;
    viewModel.isInactive = apiData.validityEndDT ? isBeforeToday(apiData.validityEndDT) : false;
    viewModel.mapConcurrencyRequest(apiData);

    return viewModel;
  }
}

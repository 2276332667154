import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule, MatProgressSpinnerModule, MatSelectModule} from '@angular/material';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslocoModule} from '@ngneat/transloco';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ReadOnlyInputModule} from '../../../shared/components/read-only-input/read-only-input.module';
import {CommonDirectiveModule} from '../../../shared/directive/common-directive.module';
import {PaginatedDropdownControlComponent} from './paginated-dropdown-control.component';

@NgModule({
  declarations: [PaginatedDropdownControlComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatTooltipModule,
    MatIconModule,
    PipeModule,
    FlexModule,
    CommonDirectiveModule,
    ReadOnlyInputModule
  ],
  exports: [PaginatedDropdownControlComponent]
})
export class PaginatedDropdownControlModule {
}

import {ADMISSION_PD_PR_ROUTES} from '../../shared/sub-menu/routes/url-routs';
import {AppSecurityRoles as roles, SubMenuModel} from '../models/menu/sub-menu.model';

// PD: patient data
export const DR_IPD_PD: Array<SubMenuModel> = [
  {
    id: 1,
    title: 'Admission Note',
    url: ADMISSION_PD_PR_ROUTES.admissionNote,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  },
  {
    id: 2,
    title: 'Vitals',
    url: ADMISSION_PD_PR_ROUTES.vitals,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  },
  {
    id: 3,
    title: 'Nurse Note',
    url: ADMISSION_PD_PR_ROUTES.nurseNote,
    allowedRoles: [roles.D_PRIMARY]
  },
  {
    id: 4,
    title: 'Progress Note',
    url: ADMISSION_PD_PR_ROUTES.progressNote,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  },
  {
    id: 5,
    title: 'Diagnosis',
    url: ADMISSION_PD_PR_ROUTES.diagnosis,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  },
  {
    id: 6,
    title: 'Patient Order',
    url: ADMISSION_PD_PR_ROUTES.patientOrder,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  },
  {
    id: 7,
    title: 'Treatment',
    url: ADMISSION_PD_PR_ROUTES.treatment,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  },
  {
    id: 8,
    title: 'Reports',
    url: ADMISSION_PD_PR_ROUTES.reports,
    allowedRoles: [roles.D_PRIMARY]
  },
  {
    id: 9,
    title: 'Prescription On Discharge',
    url: ADMISSION_PD_PR_ROUTES.prescriptionOnDischarge,
    allowedRoles: [roles.D_PRIMARY]
  },
  {
    id: 10,
    title: 'Discharge',
    url: ADMISSION_PD_PR_ROUTES.discharge,
    allowedRoles: [roles.D_PRIMARY]
  },
  {
    id: 11,
    title: 'OR Request/\nSurgical Instruction',
    url: ADMISSION_PD_PR_ROUTES.orSurgicalIns,
    allowedRoles: [roles.D_PRIMARY]
  },
  {
    id: 12,
    title: 'Insurance Form',
    url: ADMISSION_PD_PR_ROUTES.insuranceForm,
    allowedRoles: [roles.D_PRIMARY]
  },
  {
    id: 13,
    title: 'Medical Certificate',
    url: ADMISSION_PD_PR_ROUTES.medicalCertificate,
    allowedRoles: [roles.D_PRIMARY]
  },
  {
    id: 14,
    title: 'Next Appointment',
    url: ADMISSION_PD_PR_ROUTES.nextAppointment,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  },
  {
    id: 15,
    title: 'Service Billing',
    url: ADMISSION_PD_PR_ROUTES.serviceBilling,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  },
  {
    id: 16,
    title: 'Patient Management',
    url: ADMISSION_PD_PR_ROUTES.patientManagement,
    allowedRoles: [roles.D_PRIMARY]
  },
  {
    id: 17,
    title: 'Medical Records',
    url: ADMISSION_PD_PR_ROUTES.medicalRecords,
    allowedRoles: [roles.D_PRIMARY, roles.D_SECONDARY, roles.D_CONSULTANT]
  }
]

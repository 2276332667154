import {IMedicalCatalog} from '@v2/core/models/masterdata';

export class MedicalCatalogListViewModel {
  id: string;
  xref: string;
  name: string;
  typeId: number;
  typeLabel: string;

  static mapFromHttpModel(apiData: IMedicalCatalog): MedicalCatalogListViewModel {
    const viewData = new MedicalCatalogListViewModel();
    viewData.id = apiData.id;
    viewData.xref = apiData.xref;
    viewData.name = apiData.name;
    viewData.typeId = apiData.type && apiData.type.key;
    viewData.typeLabel = apiData.type && apiData.type.label;
    return viewData;
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {TableBadgeEnum} from '@v2/core/enums/table-badge.enum';

@Component({
  selector: 'app-table-badge',
  templateUrl: './table-badge.component.html',
  styleUrls: ['./table-badge.component.scss']
})
export class TableBadgeComponent implements OnInit {

  constructor() {
  }

  @Input()
  tableBadgeType: TableBadgeEnum;

  private static enumToNgClass(tableBadgeType: TableBadgeEnum): string {
    switch (tableBadgeType) {
      case TableBadgeEnum.FOLLOWUP:
      case TableBadgeEnum.REORDERED:
        return 'bg-dark-cyan';
      case TableBadgeEnum.PHARMACY:
        return 'bg-burnt-sienna';
      case TableBadgeEnum.CONSULTATION:
        return 'bg-casablanca';
      case TableBadgeEnum.TRANSFERRED:
        return 'bg-green';
      case TableBadgeEnum.STOPPED:
      case TableBadgeEnum.CANCELED:
        return 'bg-burnt-sienna';
      case TableBadgeEnum.JOINT_ADMISSION:
        return 'bg-pastel-green font-x-small';
      case TableBadgeEnum.DISCHARGE:
        return 'bg-eucalyptus-15 text-eucalyptus border-eucalyptus-non-important';
    }
  }

  ngOnInit() {
  }

  getVisibilityClass(tableBadgeType: TableBadgeEnum): string {
    // typescript converts any value in enum on conversation
    // check the value is exist on this or not
    const enums = Object.values(TableBadgeEnum);
    const isVisible = enums.includes(tableBadgeType);

    if (isVisible) {
      return TableBadgeComponent.enumToNgClass(tableBadgeType)
    }
  }
}


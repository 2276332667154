import {Pipe, PipeTransform} from '@angular/core';
import {medicationTypeEnum} from '../../pharmacy/resources/enums/medication-type.enum';

@Pipe({
  name: 'pharmacyOrderTypeClass'
})
export class PharmacyOrderTypeClassPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let className = '';
    if (value === medicationTypeEnum.Prescription) {
      className = 'bg-hard-purple';
    } else if (value === medicationTypeEnum.Stat) {
      className = 'bg-red-secondary';
    } else if (value === medicationTypeEnum.Supply) {
      className = 'bg-cornflower-lilac';
    } else if (value === medicationTypeEnum.DailyMed) {
      className = 'bg-robin-egg-blue-dark';
    } else if (value === medicationTypeEnum.SOS) {
      className = 'bg-primary';
    }
    return className;
  }

}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule, MatDialogModule, MatIconModule} from '@angular/material';
import {ImagePreviewModalComponent} from './image-preview-modal.component';

@NgModule({
  declarations: [ImagePreviewModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    FlexModule
  ],
  entryComponents: [ImagePreviewModalComponent]
})
export class ImagePreviewModalModule {
}

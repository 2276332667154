import {getElementPrices} from '@v2/core/functions/functions';
import {ICatalogElement} from '@v2/core/models/masterdata';

export class SupplyViewModel {
  id: string;
  xref: string;
  name: string;
  size: string;
  instruction: string;
  englishTemplate: string;
  category: string;
  dispenseUnit: string;
  opdPrice: number;
  ipdPrice: number;
  baseSellingPrice: number;
  masterCatalogPrice: number;

  static mapFromHttpModel(httpData: ICatalogElement): SupplyViewModel {
    const prices = getElementPrices(httpData.elementPrices);
    const viewData = new SupplyViewModel();
    viewData.id = httpData.id;
    viewData.xref = httpData.xref;
    viewData.name = httpData.englishName || httpData.name;
    viewData.size = httpData.size;
    viewData.instruction = httpData.englishInstruction;
    viewData.englishTemplate = httpData.englishTemplate;
    viewData.category = httpData.catalogCategory ? httpData.catalogCategory.name : '';
    viewData.dispenseUnit = httpData.dispenseUnit ? httpData.dispenseUnit.label : '';
    if (prices) {
      viewData.opdPrice = prices.opdPrice;
      viewData.ipdPrice = prices.ipdPrice;
      viewData.baseSellingPrice = prices.baseSellingPrice;
      viewData.masterCatalogPrice = prices.masterCatalogCost;
    }
    return viewData;
  }
}

export enum ClaimPaymentTypeEnum {
  DEPOSIT = 'Deposit',
  CLAIM_PAYMENT = 'Claim Payment',
  REFUND = 'Refund',
  CLAIM_ITEM_PAYMENT = 'Claim Item Payment',
  FINAL_PAYMENT_UI = 'Final Payment',
  FINAL_SETTLEMENT = 'Final Settlement',
  RECONCILED = 'Reconciled',
  OTHER = 'Other'
}

import {CatalogTypeEnum} from '@v2/core/enums/catalog-type.enum';
import {getCurrentDateTime, getCurrentTime} from '@v2/core/functions/functions';
import {IPrescribedBy} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IMedicalPackageVisit} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import _ from 'lodash';
import {VisitPartStatusEnum} from '../../enums/visit-part-status.enum';
import {
  MedicalPackageNoteApiModel,
  MedicalPackageOrderApiModel,
  MedicalPackagePlanElementApiModel
} from '../api/medical-package-order-api.model';
import {CatalogElementViewModel, CatalogGroupViewModel} from '../view/catalog-detail-view.model';
import {MedicalPackageGroupViewModel} from '../view/medical-package-group-view.model';

export class MedicalPackageOrderFormModel extends ConcurrencyRequest {

  id: string;
  xref: string;
  medicalPackageId: string;
  medicalPackageXref: string;
  medicalPackageName: string;
  isStandardPackage: boolean;
  isOpdApplicable: boolean;
  isIpdApplicable: boolean;
  medicalPackageStartDate: string;
  medicalPackageEndDate: string;
  medicalPackageVisit: MedicalPackageVisitFormModel[];
  medicalPackageGroupData: MedicalPackageGroupViewModel[];
  medicalPackageNote: MedicalPackageNoteFormModel[];
  preferredDate: string;
  preferredTime: string;
  medicalPackageVisitId: string[];
  orderSource: string;
  selectedBy: string;
  status: string;
  canceledOn: string;
  cancellationNote: string;
  canceledBy: IPrescribedBy;
  medicalPackagePartsUtilise: IMedicalPackageVisit[];

  static mapFromApiModel(apiData: MedicalPackageOrderApiModel, appointmentId: string): MedicalPackageOrderFormModel {
    const planElementArray: MedicalPackagePlanElementFormModel[] = [];
    if (apiData.medicalPackagePlanElements) {
      const medicalPackageElement = this.groupPackageElements(apiData.medicalPackagePlanElements);
      medicalPackageElement.map(item => {
        planElementArray.push(MedicalPackagePlanElementFormModel.mapFromApiModel(item));
      });
    }

    let packageGroupData: MedicalPackageGroupViewModel[] = _.chain(planElementArray)
      .groupBy('medicalCatalogType')
      .map((value, key) => ({medicalCatalogType: key, packages: value}))
      .value();
    packageGroupData = packageGroupData.map(packageGroup => {
      if (packageGroup.packages.length) {
        packageGroup.medicalCatalogName = packageGroup.packages[0].name;
        packageGroup.orderAll = false;
        packageGroup.hasQtyError = false;
      }

      return packageGroup;
    });

    const formModel = new MedicalPackageOrderFormModel();

    formModel.id = apiData.id;
    formModel.xref = apiData.xref;
    if (apiData.medicalPackage) {
      formModel.medicalPackageId = apiData.medicalPackage.id;
      formModel.medicalPackageXref = apiData.medicalPackage.xref;
      formModel.medicalPackageName = apiData.medicalPackage.name;
      formModel.isStandardPackage = apiData.medicalPackage.isStandardPrice;
      formModel.isOpdApplicable = apiData.medicalPackage.opdApplicable;
      formModel.isIpdApplicable = apiData.medicalPackage.ipdApplicable;
      formModel.medicalPackageStartDate = apiData.medicalPackage.startDate;
      formModel.medicalPackageEndDate = apiData.medicalPackage.endDate;
      formModel.medicalPackageVisit = apiData.medicalPackage.packageParts && apiData.medicalPackage.packageParts.map(item => MedicalPackageVisitFormModel.mapFromApiModel(item, apiData.medicalPackagePartsUtilise, appointmentId));
    }
    formModel.medicalPackageGroupData = packageGroupData;
    formModel.medicalPackageNote = apiData.medicalPackageNotes && apiData.medicalPackageNotes.map(item => MedicalPackageNoteFormModel.mapFromApiModel(item));
    formModel.preferredDate = getCurrentDateTime();
    formModel.preferredTime = getCurrentTime('hh:mm a');
    formModel.medicalPackageVisitId = null;
    formModel.orderSource = apiData.selectionSource && apiData.selectionSource.label;
    formModel.selectedBy = apiData.selectedBy && this.getSelectedBy(apiData.selectedBy);
    formModel.status = apiData.status ? apiData.status.label : '';
    formModel.canceledOn = apiData.canceledOn;
    formModel.canceledBy = apiData.canceledBy;
    formModel.cancellationNote = apiData.cancellationNote;
    formModel.medicalPackagePartsUtilise = apiData.medicalPackagePartsUtilise;
    formModel.mapConcurrencyRequest(apiData);
    return formModel;
  }

  static getSelectedBy(data: IPrescribedBy): string {
    const role = (data.staffType && data.staffType.label) === 'Doctor' ? 'Dr.' : '';
    const staffName = data.staffName;
    return `${role} ${staffName}`;
  }

  static groupPackageElements(apiData: MedicalPackagePlanElementApiModel[]): MedicalPackagePlanElementApiModel[] {
    const packageElementArray: MedicalPackagePlanElementApiModel[] = [];
    if (apiData.length) {
      apiData.map(packageElement => {
        const [plannedQty, remainingQty, latestPackageUtilisationLogPlannedQty, latestPackageUtilisationLogRemainingQty] = MedicalPackagePlanElementFormModel.getPlannedAndRemainingQty(packageElement);
        if (!packageElement.catalogGroup) {
          packageElement.catalogElement.plannedQty = plannedQty;
          packageElement.catalogElement.remainingQty = remainingQty;
          packageElement.catalogElement.latestPackageUtilisationLogPlannedQty = latestPackageUtilisationLogPlannedQty;
          packageElement.catalogElement.latestPackageUtilisationLogRemainingQty = latestPackageUtilisationLogRemainingQty;
          packageElement.catalogElement.packageSelectionItemId = packageElement.id;
          packageElementArray.push(packageElement);
        } else {
          const medicalCatalogType = packageElement.catalogGroup && packageElement.catalogGroup.medicalCatalog && packageElement.catalogGroup.medicalCatalog.type && packageElement.catalogGroup.medicalCatalog.type.label;
          if (packageElement.catalogGroup && medicalCatalogType === CatalogTypeEnum.lab) {
            packageElement.catalogGroup.plannedQty = plannedQty;
            packageElement.catalogGroup.remainingQty = remainingQty;
            packageElement.catalogGroup.latestPackageUtilisationLogPlannedQty = latestPackageUtilisationLogPlannedQty;
            packageElement.catalogGroup.latestPackageUtilisationLogRemainingQty = latestPackageUtilisationLogRemainingQty;
            packageElement.catalogGroup.packageSelectionItemId = packageElement.id;
            packageElementArray.push(packageElement);
          } else {
            const matchedPackageGroupElement = packageElementArray.find(packageElementData => (packageElementData.catalogGroup && packageElementData.catalogGroup.id) === (packageElement.catalogGroup && packageElement.catalogGroup.id));
            if (!matchedPackageGroupElement) {
              if (packageElement.catalogGroup.catalogElements && packageElement.catalogGroup.catalogElements.length) {
                const matchedCatalogElement = packageElement.catalogGroup.catalogElements.find(element => element.id === (packageElement.catalogElement && packageElement.catalogElement.id));
                if (matchedCatalogElement) {
                  matchedCatalogElement.plannedQty = plannedQty;
                  matchedCatalogElement.remainingQty = remainingQty;
                  matchedCatalogElement.latestPackageUtilisationLogPlannedQty = latestPackageUtilisationLogPlannedQty;
                  matchedCatalogElement.latestPackageUtilisationLogRemainingQty = latestPackageUtilisationLogRemainingQty;
                  matchedCatalogElement.medicalCatalog = packageElement.catalogGroup.medicalCatalog;
                  matchedCatalogElement.packageSelectionItemId = packageElement.id;
                }
                packageElementArray.push(packageElement);
              }
            } else {
              if (matchedPackageGroupElement.catalogGroup.catalogElements && matchedPackageGroupElement.catalogGroup.catalogElements.length) {
                const matchedCatalogElement = matchedPackageGroupElement.catalogGroup.catalogElements.find(element => element.id === packageElement.catalogElement.id);
                if (matchedCatalogElement) {
                  matchedCatalogElement.plannedQty = plannedQty;
                  matchedCatalogElement.remainingQty = remainingQty;
                  matchedCatalogElement.latestPackageUtilisationLogPlannedQty = latestPackageUtilisationLogPlannedQty;
                  matchedCatalogElement.latestPackageUtilisationLogRemainingQty = latestPackageUtilisationLogRemainingQty;
                  matchedCatalogElement.medicalCatalog = packageElement.catalogGroup.medicalCatalog;
                  matchedCatalogElement.packageSelectionItemId = packageElement.id;
                }
              }
            }
          }
        }
      });
    }

    return packageElementArray;

  }
}

export class MedicalPackageNoteFormModel extends ConcurrencyRequest {

  id: number;
  note: string;
  recordedDT: string;
  recordedBy: string;

  static mapFromApiModel(apiData: MedicalPackageNoteApiModel): MedicalPackageNoteFormModel {
    const formModel = new MedicalPackageNoteFormModel();
    formModel.id = apiData.id;
    formModel.note = apiData.note;
    formModel.recordedDT = apiData.recordedDT;
    formModel.recordedBy = apiData.recordedBy && apiData.recordedBy.staffName;
    formModel.mapConcurrencyRequest(apiData);
    return formModel;
  }

}

export class MedicalPackageNotesFormModel extends ConcurrencyRequest {
  pkgSelectionId: string;
  pkgSelectionNotes: MedicalPackageNoteFormModel[];
}

export class MedicalPackagePlanElementFormModel extends ConcurrencyRequest {

  id: string;
  xref: string;
  name: string;
  plannedQty: number;
  remainingQty: number;
  latestPackageUtilisationLogPlannedQty: number;
  latestPackageUtilisationLogRemainingQty: number;
  usedQty: number;
  usedLogQty: number;
  orderQty: number;
  catalogElement: CatalogElementViewModel;
  catalogGroup: CatalogGroupViewModel;
  medicalCatalogType: string;
  medicalCatalogName: string;
  isOrdered: boolean;
  action: [];

  // public action: PathologyActionFormModel | RadiologyActionFormModel | NutritionAndMealActionFormModel | ServiceActionFormModel | SupplyActionFormModel | PharmacyActionFormModel

  static mapFromApiModel(apiData: MedicalPackagePlanElementApiModel): MedicalPackagePlanElementFormModel {
    const [plannedQty, remainingQty, latestPackageUtilisationLogPlannedQty, latestPackageUtilisationLogRemainingQty] = MedicalPackagePlanElementFormModel.getPlannedAndRemainingQty(apiData);
    const medicalCatalog = apiData.catalogElement ? (apiData.catalogElement.medicalCatalog) : (apiData.catalogGroup.medicalCatalog);
    const medicalCatalogType = medicalCatalog && medicalCatalog.type && medicalCatalog.type.label
    const medicalCatalogName = medicalCatalog && medicalCatalog.name
    const formModel = new MedicalPackagePlanElementFormModel();

    formModel.id = apiData.id;
    formModel.xref = apiData.xref;
    formModel.name = apiData.catalogElement ? (apiData.catalogElement.medicalCatalog && apiData.catalogElement.medicalCatalog.name) : (apiData.catalogGroup.medicalCatalog && apiData.catalogGroup.medicalCatalog.name);
    formModel.plannedQty = plannedQty;
    formModel.remainingQty = remainingQty;
    formModel.usedQty = plannedQty - remainingQty;
    formModel.latestPackageUtilisationLogPlannedQty = latestPackageUtilisationLogPlannedQty;
    formModel.latestPackageUtilisationLogRemainingQty = latestPackageUtilisationLogRemainingQty;
    formModel.usedLogQty = latestPackageUtilisationLogPlannedQty - latestPackageUtilisationLogRemainingQty;
    formModel.orderQty = null;
    formModel.catalogElement = apiData.catalogElement ? CatalogElementViewModel.mapFromApiModel(apiData.catalogElement, (apiData.catalogElement.medicalCatalog && apiData.catalogElement.medicalCatalog.type && apiData.catalogElement.medicalCatalog.type.label)) : {} as CatalogElementViewModel;
    formModel.catalogGroup = apiData.catalogGroup ? CatalogGroupViewModel.mapFromApiModel(apiData.catalogGroup, (apiData.catalogGroup.medicalCatalog && apiData.catalogGroup.medicalCatalog.type && apiData.catalogGroup.medicalCatalog.type.label)) : {} as CatalogGroupViewModel;
    formModel.medicalCatalogType = medicalCatalogType;
    formModel.medicalCatalogName = medicalCatalogName
    formModel.isOrdered = false;
    formModel.action = [];
    // this.getActionModel(medicalCatalogType)
    formModel.mapConcurrencyRequest(apiData);

    return formModel;
  }

  static getPlannedAndRemainingQty(apiData: MedicalPackagePlanElementApiModel): [number, number, number, number] {
    const plannedQty = apiData.plannedQty || 0;
    const remainingQty = apiData.remainingQty || 0;
    const latestPackageUtilisationLog = apiData.latestPackageUtilisationLog;
    const latestPackageUtilisationLogPlannedQty = latestPackageUtilisationLog ? latestPackageUtilisationLog.plannedQty : plannedQty;
    const latestPackageUtilisationLogRemainingQty = latestPackageUtilisationLog ? latestPackageUtilisationLog.remainingQty : remainingQty;
    return [plannedQty, remainingQty, latestPackageUtilisationLogPlannedQty, latestPackageUtilisationLogRemainingQty];
  }
}

export class MedicalPackageVisitFormModel extends ConcurrencyRequest {

  id: string;
  xref: string;
  name: string;
  type: string;
  price: number;
  isUsed: boolean;
  isSelected: boolean;
  appointmentId: string;
  usedDate: string;
  visitPartStatus: string;

  static mapFromApiModel(apiData: IMedicalPackageVisit, packagePartUtilise: IMedicalPackageVisit[], appointmentId: string): MedicalPackageVisitFormModel {
    const matchedPackagePart = packagePartUtilise.find(item => item.id === apiData.id);
    const formModel = new MedicalPackageVisitFormModel();
    formModel.id = apiData.id;
    formModel.xref = apiData.xref;
    formModel.name = apiData.name;
    formModel.type = apiData.type && apiData.type.label;
    formModel.price = apiData.price;
    formModel.isUsed = !!matchedPackagePart;
    formModel.isSelected = !!matchedPackagePart;
    formModel.appointmentId = matchedPackagePart && matchedPackagePart.appointmentId;
    formModel.usedDate = matchedPackagePart && matchedPackagePart.utilizeTime;
    formModel.visitPartStatus = this.getVisitPartStatus(appointmentId, matchedPackagePart && matchedPackagePart.appointmentId);
    formModel.mapConcurrencyRequest(apiData);
    return formModel;
  }

  static getVisitPartStatus(patientAppointmentId: string, visitPartAppointmentId: string): string {
    if (visitPartAppointmentId) {
      if (visitPartAppointmentId === patientAppointmentId) {
        return VisitPartStatusEnum.active;
      } else {
        return VisitPartStatusEnum.utilized;
      }
    } else {
      return VisitPartStatusEnum.planned;
    }
  }
}

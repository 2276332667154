import {AppSecurityRoles, GetAccessibleRoutes, SubMenuModel} from '../../../core/models/menu/sub-menu.model';
import {DR_ADMISSION_PD} from './admission/dr-admission-pd';
import {NS_ADMISSION_PD} from './admission/nurse-admission-pd';
import {PD_INITIALS} from './url-routs';
import {DR_VISIT_PD} from './visit/dr-visit-pd';
import {NS_VISIT_PD} from './visit/nurse-visit-pd';

export function getFullNextPatientDataUrl(currentUrl: string, isVisit: boolean, isNS: boolean, role?: AppSecurityRoles): string {
  let baseUrl: string
  if (isVisit) {
    baseUrl = isNS ? PD_INITIALS.nsVisit : PD_INITIALS.drVisit
  } else {
    baseUrl = isNS ? PD_INITIALS.nsAdmission : PD_INITIALS.drAdmission
  }
  return `${baseUrl}/${getNextPatientDataUrl(currentUrl, isVisit, isNS, role)}`
}

export function getNextPatientDataUrl(currentUrl: string, isVisit: boolean, isNS: boolean, role?: AppSecurityRoles): string {
  if (currentUrl === '') {
    return ''
  }
  let routes: Array<SubMenuModel>

  if (isVisit) {
    routes = isNS ? NS_VISIT_PD : DR_VISIT_PD
  } else {
    routes = isNS ? NS_ADMISSION_PD : DR_ADMISSION_PD
  }
  if (role) {
    routes = GetAccessibleRoutes(routes, role);
  }

  const index = routes.findIndex(value => value.url === currentUrl)
  if (index !== -1) {
    const length = routes.length
    if (index < length - 1) {
      const nextSubMenu = routes[index + 1];
      return nextSubMenu ? nextSubMenu.url : ''
    }
  }
  return ''
}

export function getFullPreviousPatientDataUrl(currentUrl: string, isVisit: boolean, isNS: boolean, role?: AppSecurityRoles): string {
  let baseUrl: string
  if (isVisit) {
    baseUrl = isNS ? PD_INITIALS.nsVisit : PD_INITIALS.drVisit
  } else {
    baseUrl = isNS ? PD_INITIALS.nsAdmission : PD_INITIALS.drAdmission
  }
  return `${baseUrl}/${getPreviousPatientDataUrl(currentUrl, isVisit, isNS, role)}`
}

export function getPreviousPatientDataUrl(currentUrl: string, isVisit: boolean, isNS: boolean, role?: AppSecurityRoles): string {
  if (currentUrl === '') {
    return ''
  }
  let routes: Array<SubMenuModel>

  if (isVisit) {
    routes = isNS ? NS_VISIT_PD : DR_VISIT_PD
  } else {
    routes = isNS ? NS_ADMISSION_PD : DR_ADMISSION_PD
  }
  if (role) {
    routes = GetAccessibleRoutes(routes, role);
  }

  const index = routes.findIndex(value => value.url === currentUrl)
  if (index !== -1) {
    if (index > 0) {
      const previousSubMenu = routes[index - 1];
      return previousSubMenu ? previousSubMenu.url : ''
    }
  }
  return ''
}

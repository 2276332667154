import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-status',
  templateUrl: './timeline-status.component.html',
  styleUrls: ['./timeline-status.component.scss']
})
export class TimelineStatusComponent implements OnInit {

  @Input() timelineArr;

  constructor() { }

  ngOnInit() {
  }

}

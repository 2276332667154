import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {PaymentPlanViewModel} from '@v2/core/models/view/payment-plan.view.model';
import {Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {PaymentTypeEnum} from '../../../../../v2/shared-v2/resources/enums/payment-type.enum';
import {PatientHttpService} from '../../../../../v2/shared-v2/resources/http-service/patient-http.service';

@Component({
  selector: 'app-payment-plan-detail-modal',
  templateUrl: './payment-plan-detail-modal.component.html',
  styleUrls: ['./payment-plan-detail-modal.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class PaymentPlanDetailModalComponent implements OnInit {

  paymentTypeName: string;
  paymentTypeEnum = PaymentTypeEnum;
  paymentPlanInfo$: Observable<PaymentPlanViewModel>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { paymentPlanId: string; appointmentId: string },
    private dialogRef: MatDialogRef<PaymentPlanDetailModalComponent>,
    private patientHttpService: PatientHttpService
  ) {
  }

  ngOnInit() {
    this.paymentPlanInfo$ = this.patientHttpService.getPatientPaymentTypeDetail(this.data.paymentPlanId, this.data.appointmentId).pipe(
      tap(response => this.paymentTypeName = response.paymentPlanTypeName),
      share()
    )
  }

}

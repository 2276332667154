import {IDoctor} from '@v2/core/models/masterdata';

export class DoctorViewModel {
  constructor(
    public id: string,
    public name: string
  ) {
  }

  static mapFormApiModel(apiData: IDoctor): DoctorViewModel {
    return new DoctorViewModel(
      apiData.id,
      `${apiData.firstName} ${apiData.lastName ? apiData.lastName : ''}`
    )
  }
}

import {PatientOrderStatusEnum} from '@v2/core/enums/patient-order-status.enum';
import {TableBadgeEnum} from '@v2/core/enums/table-badge.enum';
import {ICatalogElement, IMasterData, IMedication, IPrescribedBy, IUsageTemplateMethodLog} from '@v2/core/models/masterdata';

export class PatientOrderHistoryViewModel {
  pdMedicationId: string;
  medicineId: string
  medicine: ICatalogElement;
  dosage: number;
  dosageUnit: IMasterData;
  dispensingUnit: IMasterData;
  usageInstruction: string;
  usageTemplate: string;
  qty: number;
  frequencyType: IMasterData;
  frequency: number;
  isCustomFrequency: boolean;
  recordedTime: string;
  latestLog: IUsageTemplateMethodLog
  orderXref: number;
  orderedQty: number;
  dispenseQty: number;
  orderByDoctor: IPrescribedBy;
  verifiedBy: IPrescribedBy;
  dispenseDateTime: string;
  isCancelled: boolean;
  tableBadgeType: TableBadgeEnum;

  static mapFromApiModel(apiData: IMedication) {
    const viewModel = new PatientOrderHistoryViewModel();

    viewModel.pdMedicationId = apiData.pdMedicationId
    viewModel.medicineId = apiData.medicineId
    viewModel.medicine = apiData.medicine
    viewModel.dosage = apiData.dosage as number;
    viewModel.dosageUnit = apiData.dosageUnit
    viewModel.dispensingUnit = apiData.dispenseUnit;
    viewModel.usageInstruction = apiData.usageInstruction
    viewModel.usageTemplate = apiData.usageTemplate
    viewModel.qty = apiData.qty
    viewModel.frequencyType = apiData.frequencyType
    viewModel.frequency = apiData.frequency
    viewModel.isCustomFrequency = apiData.isCustomFrequency
    viewModel.recordedTime = apiData.recordedTime
    viewModel.latestLog = apiData.latestLog
    viewModel.orderXref = apiData.orderXref
    viewModel.orderedQty = apiData.orderedQty
    viewModel.dispenseQty = apiData.dispenseQty
    viewModel.orderByDoctor = apiData.orderByDoctor
    viewModel.verifiedBy = apiData.verifiedBy
    viewModel.dispenseDateTime = apiData.dispenseDateTime
    viewModel.isCancelled = apiData.orderStatus && apiData.orderStatus.label === PatientOrderStatusEnum.CANCELED;
    if (viewModel.isCancelled) {
      viewModel.tableBadgeType = TableBadgeEnum.CANCELED;
    }
    return viewModel;
  }
}

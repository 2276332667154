export enum MasterDataEnum {
  DISPENSING_UNIT = 'master/dispense-unit',
  GENDER = 'master/gender',
  NATIONALITY = 'master/nationality',
  BLOOD_GROUP = 'master/bloodgroup',
  PATIENT_INITIAL_NAME = 'master/patient-initial-name',
  INITIAL_NAME = 'master/initial-name',
  KIN_RELATIONS = 'master/kin-relations',
  EVIDENCE_TYPE = 'master/evidence-type',
  RELIGION = 'master/religion',
  MIGRATION_DATA_TYPE = 'master/migration-data-type',
  MIGRATION_DATA_METHOD = 'master/migration-data-method',
  MIGRATION_STATUS = 'master/migration-data-status',
  INTEGRATION_SYSTEM_TYPE = 'master/integration-system-type',
  BED_OCCUPANCY_STATUS = 'master/msof-status-occupancy',
  BED_CONDITION = 'master/msof-status-condition',
  OR_SEVERITY_STATUS = 'master/or/severity',
  ADT_SEVERITY_STATUS = 'adt/severity-status',
  SEVERITY_STATUS = 'master/severity',
  CHRONIC_DISEASE_TYPE = 'master/chronic-disease-type',

  PATIENT_APPT_STATUS = 'master/patient-appt-status',
  APPT_TYPE = 'master/patient-appt-type',
  VISIT_TYPE = 'patient-order/appointment/types',
  MEAL_ORDER_CATEGORY = 'master/meal-order-category',
  MEAL_ORDER_TYPE = 'master/meal-order-type',

  MEDICAL_PARTY_OTHER_STAFF_DESIGNATION = 'medical-party/other-staff/designation',
  MEDICAL_PARTY_OTHER_STAFF_SPECIALIZATION = 'medical-party/other-staff/specialization',
  MEDICAL_PARTY_OTHER_STAFF_LEVEL = 'medical-party/other-staff/level',
  MEDICAL_PARTY_OTHER_STAFF_MANAGEMENT_CATEGORY = 'medical-party/other-staff/management-category',
  MEDICAL_PARTY_OTHER_STAFF_FUNCTIONAL_AREA = 'medical-party/other-staff/functional-area',

  MEDICAL_PARTY_DOCTOR_STAFF_DESIGNATION = 'medical-party/doctor-staff/designation',
  MEDICAL_PARTY_DOCTOR_STAFF_SPECIALIZATION = 'medical-party/doctor-staff/specialization',
  MEDICAL_PARTY_DOCTOR_STAFF_LEVEL = 'medical-party/doctor-staff/level',
  MEDICAL_PARTY_DOCTOR_STAFF_MANAGEMENT_CATEGORY = 'medical-party/doctor-staff/management-category',
  MEDICAL_PARTY_DOCTOR_STAFF_FUNCTIONAL_AREA = 'medical-party/doctor-staff/functional-area',

  MEDICAL_PARTY_NURSE_STAFF_DESIGNATION = 'medical-party/nurse-staff/designation',
  MEDICAL_PARTY_NURSE_STAFF_SPECIALIZATION = 'medical-party/nurse-staff/specialization',
  MEDICAL_PARTY_NURSE_STAFF_LEVEL = 'medical-party/nurse-staff/level',
  MEDICAL_PARTY_NURSE_STAFF_MANAGEMENT_CATEGORY = 'medical-party/nurse-staff/management-category',
  MEDICAL_PARTY_NURSE_STAFF_FUNCTIONAL_AREA = 'medical-party/nurse-staff/functional-area',

  NON_MEDICAL_PARTY_OTHER_STAFF_DESIGNATION = 'non-medical-party/other-staff/designation',
  NON_MEDICAL_PARTY_OTHER_STAFF_SPECIALIZATION = 'non-medical-party/other-staff/specialization',
  NON_MEDICAL_PARTY_OTHER_STAFF_LEVEL = 'non-medical-party/other-staff/level',
  NON_MEDICAL_PARTY_OTHER_STAFF_MANAGEMENT_CATEGORY = 'non-medical-party/other-staff/management-category',
  NON_MEDICAL_PARTY_OTHER_STAFF_FUNCTIONAL_AREA = 'non-medical-party/other-staff/functional-area',

  NON_MEDICAL_PARTY_EXECUTIVE_STAFF_DESIGNATION = 'non-medical-party/executive-staff/designation',
  NON_MEDICAL_PARTY_EXECUTIVE_STAFF_SPECIALIZATION = 'non-medical-party/executive-staff/specialization',
  NON_MEDICAL_PARTY_EXECUTIVE_STAFF_LEVEL = 'non-medical-party/executive-staff/level',
  NON_MEDICAL_PARTY_EXECUTIVE_STAFF_MANAGEMENT_CATEGORY = 'non-medical-party/executive-staff/management-category',
  NON_MEDICAL_PARTY_EXECUTIVE_STAFF_FUNCTIONAL_AREA = 'non-medical-party/executive-staff/functional-area',

  NON_MEDICAL_PARTY_MANAGER_STAFF_DESIGNATION = 'non-medical-party/manager/designation',
  NON_MEDICAL_PARTY_MANAGER_STAFF_SPECIALIZATION = 'non-medical-party/manager/specialization',
  NON_MEDICAL_PARTY_MANAGER_STAFF_LEVEL = 'non-medical-party/manager/level',
  NON_MEDICAL_PARTY_MANAGER_STAFF_MANAGEMENT_CATEGORY = 'non-medical-party/manager/management-category',
  NON_MEDICAL_PARTY_MANAGER_STAFF_FUNCTIONAL_AREA = 'non-medical-party/manager/functional-area',

  STAFF_ROLE = 'ward/staff/roles',
  PURCHASE_ORDER_STATUS = 'purchase-order/status',
  INVENTORY_TYPES = 'inventory-types',
  INVENTORY_SOURCE_TYPES = 'inventory-source-types',
  PURCHASE_ORDER_FULFILLMENT_STATUS = 'master/purchase-order-fulfillment-status',
  PURCHASE_ORDER_PROGRESS_STATUS = 'master/purchase-order-progress-status',
  PURCHASE_ORDER_PRIORITY = 'master/purchase-order-priority',
  PURCHASE_REQUEST_STATUS = 'master/purchase-request-status',
  PURCHASE_ORDER_PROGRESS_FULFILLMENT_STATUS = 'master/purchase-order-status',
  MS_ORG_SPECIALITIES_DEPARTMENT = 'master/ms-org-specialities',
  PROCUREMENT_PURCHASE_REQUEST_PRIORITY = 'main-stock/procurement-request/priority',

  DEPT_ROOM_TYPE = 'medical-resource/hospital-room-type',

  FREQUENCY = 'master/frequency-type',
  MEDICINE_INTAKE = 'master/medicine-intake',
  MEDICINE_ROUTE = 'master/medicine-route',
  DRUG_TYPE_FAMILY = 'master/drug-type-family',

  MEDICAL_CODE_SYSTEM = 'medical-code-system',

  ROOM_TYPE = 'rooms/roomType',
  APPOINTMENT_STATUS = 'appointment/status',

  PATHOLOGY_TEST_UNITS = 'pathology/test-units',
  SPECIMEN_TYPE = 'master/specimen-type',

  COMPANY_TYPE = 'master/corporation-type',

  HOSPITAL_TYPE = 'corp-setup/hospital-speciality',

  DEPT_SERVICE_AREA = 'master/department-service-areas',

  MSO_FORM_TYPE = 'master/mso-form-type',
  FORM_COMPONENT = 'master/procedure-component',
  FORM_SUB_COMPONENT = 'master/procedure-sub-component',
  ANES_TYPE = 'master/anes-type',

  DOCTOR_ROLE = 'doctor-roles',

  ALLERGY_SEVERITY = 'master/allergy-severity',
  ALLERGY_TYPE = 'master/allergy-type',

  RADIOLOGY_TYPE = 'master/radiologyType',
  ORDER_SEVERITY_STATUS = 'master/order/severity',

  MANUFACTURER_TYPE = 'manufacturer-type',
  DIAGNOSIS_TYPE = 'diagnosis-type',

  SUPPORT_TICKET_TYPES = 'support-center/support-ticket-types',
  SUPPORT_TICKET_RESPONSE_TYPES = 'support-center/support-ticket-response-type',
  SUPPORT_TICKET_LEVELS = 'support-center/support-ticket-levels',
  SUPPORT_TICKET_STATUS = 'support-center/support-ticket-status',
  SUPPORT_TICKET_VERSIONS = 'support-center/support-ticket-versions',

  MEDICAL_CATALOG_TYPE = 'medical-catalog-types',
  PAYMENT_TYPE_CATEGORY = 'master/payment-type-category',
  PAYMENT_MODE = 'master/payment-mode',

  TRANSACTION_TYPE = 'master/transaction-type',
  STOCK_ORDER_ITEM_UNIT = 'master/stock-order-item-unit',

  SEED_TYPE = 'master/seed-types',

  CLAIM_PAYMENT_TYPES = 'master/payment-type',
  PATIENT_ORDER_ORIGIN = 'master/patient-order-origin',
  PHARMACY_ORDER_STATUS = 'patient-orders/pharmacy/status',
  PATIENT_ORDER_TYPE = 'master/patient-order-type',
  ADT_REQUEST_TYPE = 'master/adt-request-type',
  ADT_REQUEST_STATUS = 'master/adt-request-status',
  ALERT_NOTE_SEVERITY_STATUS = 'master/alert-note-severity',
  FACILITY_RESOURCE_TYPE = 'master/mso-facility-resource-type',
  DOCTOR_FEE_TYPE = 'master/doctor-fee-type',
  PATHOLOGY_REPORT_RESULT_TYPE = 'pathology/report-result-type',
  MEDICAL_RESULT_OUTPUT = 'master/medical-result-output'
}

import {Component, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {isNullOrUndefined} from '@v2/core/functions/functions';
import {IPatient} from '@v2/core/models/masterdata';
import {BaseSubscriptionHandlerClass} from '@v2/core/subscription-handler/subscription-handler.class';
import {NoteAlertService} from '../../../v2/shared-v2/module/note-alert/note-alert.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent extends BaseSubscriptionHandlerClass implements OnInit {
  loadingRouteConfig = false;
  showNoteAlertSidebar = false;
  patient: IPatient;
  currentOAId: string;
  appointmentId: string;
  isPatientInfoBanner: boolean;
  useV2TranslationPipe: boolean;
  hasWriteAccess: boolean;
  allowVisitRestriction = true;

  constructor(private router: Router, private noteAlertService: NoteAlertService) {
    super();
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.loadingRouteConfig = false;
      }
      if (event instanceof NavigationEnd) {
        // do not record login / forbidden url
        if (!event.url.includes('login') && !event.url.includes('forbidden')) {
          localStorage.setItem('currentUrl', event.url);
        }
      }
    }, () => this.loadingRouteConfig = false);
  }

  ngOnInit() {
    this.noteAlertService.isPatientInfoBanner.pipe(this.pickUntil()).subscribe((data) => {
      this.isPatientInfoBanner = data;
    })
    this.handleToggleNoteAlertSidebar();
  }

  handleToggleNoteAlertSidebar() {
    this.noteAlertService.toggleNoteAlertSidebarModal.pipe(this.pickUntil()).subscribe((data) => {
      if (data) {
        this.showNoteAlertSidebar = data.showAlertNoteSidebar;
        if (this.showNoteAlertSidebar) {
          this.patient = data.patient;
          this.currentOAId = data.currentOAId;
          this.appointmentId = data.appointmentId;
          this.useV2TranslationPipe = data.useV2TranslationPipe;
        }
        this.hasWriteAccess = data.hasWriteAccess;
        this.allowVisitRestriction = isNullOrUndefined(data.allowVisitRestriction) ? true : data.allowVisitRestriction;
      }
    })
  }
}

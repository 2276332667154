import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'absoluteNumber'
})
export class AbsoluteNumberPipe implements PipeTransform {

  transform(value: any): any {
    if (Number(value)) {
      return Math.abs(Number(value));
    }
    return null;
  }

}

import {IAttachment} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IPhysicalExamination} from '@v2/core/models/masterdata/IPhysicalExamination.http';

export class PhysicalExaminationViewModel extends ConcurrencyRequest {
  id: string;
  description: string;
  isDiagramFileChanged: boolean;
  note: string;
  diagramFile: string;
  physicalExaminationDocs: Array<IAttachment>;
  recordedTime: string;
  recordedBy: string;
  deletedDocs: number[];

  static mapFromHttpModel(httpData: IPhysicalExamination): PhysicalExaminationViewModel {
    const viewData = new PhysicalExaminationViewModel();
    if (httpData) {
      viewData.id = httpData.id;
      viewData.description = httpData.description;
      viewData.isDiagramFileChanged = httpData.isDiagramFileChanged;
      viewData.note = httpData.note;
      viewData.diagramFile = httpData.diagramFileUrl;
      viewData.physicalExaminationDocs = Array.isArray(httpData.attachments) ? httpData.attachments.map(attachment => ({
        ...attachment,
        filePath: attachment.fileUrl
      }) as IAttachment) : [];
      viewData.recordedTime = httpData.recodedTime;
      viewData.deletedDocs = [];
      viewData.recordedBy = httpData.recordedBy ? httpData.recordedBy.fullName : '';
      if (httpData.updatedAt) {
        viewData.mapConcurrencyRequest(httpData);
      }
    }
    return viewData;
  }
}

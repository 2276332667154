import {Component, Input, OnInit} from '@angular/core';
import {HOSPITAL_ADDRESS, HOSPITAL_CONTACT, HOSPITAL_LOGO, HOSPITAL_NAME} from '../../../../../../shared/helpers/app-const';
import {PatientInfoModalViewModel} from '../../resources/models/view/patient-info-modal.view.model';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';

@Component({
  selector: 'app-patient-info-print-modal',
  templateUrl: './patient-info-print-modal.component.html',
  styleUrls: ['./patient-info-print-modal.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'common'
    }
  ]
})
export class PatientInfoPrintModalComponent implements OnInit {

  @Input() patientInfo: PatientInfoModalViewModel;

  hospitalName = HOSPITAL_NAME;
  hospitalContactNo = HOSPITAL_CONTACT;
  hospitalLogo = HOSPITAL_LOGO;
  hospitalAddress = HOSPITAL_ADDRESS;

  constructor() {
  }

  ngOnInit() {
  }

}

import {ChangeDetectorRef, Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {MaybeArray, TRANSLOCO_LANG, TRANSLOCO_SCOPE, TranslocoScope, TranslocoService} from '@ngneat/transloco';
import {
  patientNameDisplay,
  PatientNameTranslation,
  PatientNameTranslationV2,
  translatePatientNameV2
} from '@v2/core/functions/patient-name-translation';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/internal/operators/take';

@Pipe({
  name: 'patientNameTranslationV2',
  pure: false
})
export class PatientNameTranslationV2Pipe implements PipeTransform, OnDestroy {

  private subscription: Subscription | null = null;
  private lastValue = '';
  private lastKey: string | undefined;
  private listenToLangChange: boolean;

  constructor(
    private translocoService: TranslocoService,
    @Optional() @Inject(TRANSLOCO_SCOPE) private providerScope: MaybeArray<TranslocoScope>,
    @Optional() @Inject(TRANSLOCO_LANG) private providerLang: string | null,
    private cdr: ChangeDetectorRef
  ) {
    this.listenToLangChange = this.shouldListenToLangChanges(this.translocoService, this.providerLang);
  }

  transform(key: PatientNameTranslationV2, display?: patientNameDisplay): string {
    if (!key) {
      return '-';
    }

    const keyName = key.patientId || key.id;

    if (keyName === this.lastKey) {
      return this.lastValue;
    }

    this.lastKey = keyName;
    this.unsubscribe();

    this.subscription = this.translocoService.langChanges$.pipe(
      this.listenOrNotOperator(this.listenToLangChange)
    ).subscribe((activeLang: string) => {
      this.updateValue(key, activeLang, display);
    });

    return this.lastValue;
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateValue(key: PatientNameTranslationV2, activeLang: string, display: patientNameDisplay) {
    this.lastValue = this.resolveValue(key, activeLang, display);
    this.cdr.markForCheck();
  }

  private resolveValue(value: PatientNameTranslationV2, activeLanguage: string, display: patientNameDisplay): string {
    if (!activeLanguage || !value) {
      return null;
    }
    return this.getName(value, activeLanguage, display);
  }

  private getName(value: PatientNameTranslationV2, activeLanguage: string, display: patientNameDisplay) {
    switch (display) {
      case 'imageInitials':
        const name = translatePatientNameV2(value, activeLanguage, false);
        const splittedValue = name.split(' ');
        const firstName = splittedValue[0] || '';
        const lastName = splittedValue.length > 1 ? splittedValue[1] : ''
        return firstName.substr(0, 1) + lastName.substr(0, 1);
      default:
        return translatePatientNameV2(value, activeLanguage);
    }
  }

  private listenOrNotOperator(listenToLangChange: boolean) {
    return listenToLangChange ? source => source : take(1);
  }

  private getPipeValue(str: string, value: string, char = '|'): [boolean, string] {
    if (typeof str === 'string') {
      const splitted = str.split(char);
      const lastItem = splitted.pop();
      return lastItem === value ? [true, splitted.toString()] : [false, lastItem];
    }

    return [false, ''];
  }

  private shouldListenToLangChanges(service: TranslocoService, lang: string) {
    const [hasStatic] = this.getPipeValue(lang, 'static');
    if (hasStatic === false) {
      // If we didn't get 'lang|static' check if it's set in the global level
      return service.config.reRenderOnLangChange;
    }

    // We have 'lang|static' so don't listen to lang changes
    return false;
  }

}

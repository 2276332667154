export interface MasterData {
  key: number;
  type: string;
  label: string;
  thaiLabel: string
}

export interface DischargeType {
  id: number;
  dischargeType: string;
}

export interface PatientDischargeStatus {
  id: number;
  patientDischargeStatus: string;
}

export interface RoomWard {
  wardId: string
  wardTitle: string
}

export class TItemHttpModel<T1, T2> {
  items: Array<T1> = []
  deletedItems: Array<T2> = []
}

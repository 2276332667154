import {OrRequestFilterRoomApiModel} from '../../api/operation-room/or-request-filter-room-api.model';

export class OrRequestRoomFilterViewModel {
  constructor(
    public id: string,
    public name: string
  ) {
  }

  static mapFromApiModel(element: OrRequestFilterRoomApiModel): OrRequestRoomFilterViewModel {
    return new OrRequestRoomFilterViewModel(
      element.id,
      element.name
    )
  }
}

import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorCodeMessageEnum} from '@v2/core/enums/error-code-message.enum';
import {currentTimeZone} from '@v2/core/functions/functions';
import {ConcurrencyService} from '@v2/core/services/concurrency.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {GlobalErrorHandlerService} from '../../v2/shared-v2/components/error-handler/global-error-handler.service';
import {APP_SETTINGS} from '../services/setting';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {
  baseUrl = APP_SETTINGS.api_url;
  urlSeparator = APP_SETTINGS.api_prefix;

  constructor(
    private httpClient: HttpClient,
    private concurrencyService: ConcurrencyService,
    private globalErrorHandlerService: GlobalErrorHandlerService) {
  }

  private static handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }

  errorHandler(error: HttpErrorResponse, key, uniqueKeyForConcurrency?: string) {
    if (key && ((error.status === 409 && error.error.error.message === ErrorCodeMessageEnum.ERROR_409_STALE_DATA) || error.status === 423)) {
      this.concurrencyService.eventHandler.next({key, uniqueKeyForConcurrency});
    }
  }

  globalErrorHandler(error: HttpErrorResponse, key: string) {
    this.globalErrorHandlerService.setLoadingError(error, key);
  }

  get<T>(path: string, params = new HttpParams()): Observable<T> {
    return this.httpClient.get<T>(`${this.baseUrl}${this.urlSeparator}${path}`, {params})
      .pipe(catchError(BaseHttpService.handleError));
  }

  getWrapper<T>(path: string, key: string, params = new HttpParams()): Observable<T> {
    return this.httpClient.get<T>(`${this.baseUrl}${this.urlSeparator}${path}`, {params})
      .pipe(catchError((error) => {
        this.globalErrorHandler(error, key);
        return throwError(error);
      }));
  }

  getXlsx(path: string, params = new HttpParams()): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}${this.urlSeparator}${path}`, {responseType: 'blob', params})
      .pipe(map(data => new Blob([data], {type: 'application/vnd.ms-excel'})));
  }

  getPDF(path: string, params = new HttpParams()): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}${this.urlSeparator}${path}`, {responseType: 'blob', params})
      .pipe(map(data => new Blob([data], {type: 'application/pdf'})));
  }

  post<T>(path: string, data: any, params = new HttpParams()): Observable<T> {
    const formData = {data, timezone: currentTimeZone()};
    return this.httpClient.post<T>(`${this.baseUrl}${this.urlSeparator}${path}`, formData, {params})
      .pipe(catchError(BaseHttpService.handleError));
  }

  postWrapper<T>(path: string, data: any, key: string, params = new HttpParams(), uniqueKeyForConcurrency?: string): Observable<T> {
    const formData = {data, timezone: currentTimeZone()};
    return this.httpClient.post<T>(`${this.baseUrl}${this.urlSeparator}${path}`, formData, {params})
      .pipe(catchError((error) => {
        this.errorHandler(error, key, uniqueKeyForConcurrency);
        return throwError(error.error);
      }));
  }

  put<T>(path: string, data: any, params = new HttpParams()): Observable<T> {
    const formData = {data};
    return this.httpClient.put<T>(`${this.baseUrl}${this.urlSeparator}${path}`, formData, {params})
      .pipe(catchError(BaseHttpService.handleError));
  }

  putWrapper<T>(path: string, data: any, key: string, params = new HttpParams(), uniqueKeyForConcurrency?: string): Observable<T> {
    const formData = {data};
    return this.httpClient.put<T>(`${this.baseUrl}${this.urlSeparator}${path}`, formData, {params})
      .pipe(catchError((error) => {
        this.errorHandler(error, key, uniqueKeyForConcurrency);
        return throwError(error.error);
      }));
  }

  delete<T>(path: string): Observable<T> {
    return this.httpClient.delete<T>(`${this.baseUrl}${this.urlSeparator}${path}`)
      .pipe(catchError(BaseHttpService.handleError));
  }

  deleteWrapper<T>(path: string, key: string, uniqueKeyForConcurrency?: string): Observable<T> {
    return this.httpClient.delete<T>(`${this.baseUrl}${this.urlSeparator}${path}`)
      .pipe(catchError(error => {
        this.errorHandler(error, key, uniqueKeyForConcurrency);
        return throwError(error.error);
      }));
  }

  patch<T>(path: string, data: any): Observable<T> {
    const formData = {data};
    return this.httpClient.patch<T>(`${this.baseUrl}${this.urlSeparator}${path}`, formData)
      .pipe(catchError(BaseHttpService.handleError));
  }

  patchWrapper<T>(path: string, data: any, key: string, uniqueKeyForConcurrency?: string): Observable<T> {
    const formData = {data};
    return this.httpClient.patch<T>(`${this.baseUrl}${this.urlSeparator}${path}`, formData)
      .pipe(catchError(error => {
        this.errorHandler(error, key, uniqueKeyForConcurrency);
        return throwError(error.error);
      }));
  }
}

import {getName, isArray, NOT_APPLICABLE} from '@v2/core/functions/functions';
import {ICompany, ICompanyEmployee, IContract, IItemDiff, IPageableData} from '@v2/core/models/masterdata';
import {IAlertNoteLog, ICatalogItemLog, ILogColumnData, ILogConfig, ILogVersion, IStockItemLog} from '@v2/core/models/masterdata/ILog';
import {IMedicalPackagePlan, IPackageDiscountFactor} from '@v2/core/models/masterdata/IMedicalPackage.master-data';

const dateFields: string[] = ['ValidityEndDT', 'ValidityStartDT'];

export class AlertNoteLogViewModel {
  id: string;
  name: string;
  title: string;
  oldValue: string;
  newValue: string;
  changedAt: string;
  changedBy: string;
  isDateField: boolean;
  isOldValueNotApplicable: boolean;
  isNewValueNotApplicable: boolean;

  static mapFromHttpModel(log: IAlertNoteLog): AlertNoteLogViewModel {
    const viewModel = new AlertNoteLogViewModel();
    viewModel.id = log.id;
    viewModel.name = log.name;
    viewModel.title = log.title;
    viewModel.oldValue = log.oldValue || NOT_APPLICABLE;
    viewModel.newValue = log.newValue || NOT_APPLICABLE;
    viewModel.changedAt = log.changedAt;
    viewModel.isDateField = dateFields.includes(log.name);
    viewModel.isOldValueNotApplicable = viewModel.oldValue === NOT_APPLICABLE;
    viewModel.isNewValueNotApplicable = viewModel.newValue === NOT_APPLICABLE;
    if (log.changedBy) {
      viewModel.changedBy = getName(log.changedBy.firstName, log.changedBy.lastName, log.changedBy.initial);
    }
    return viewModel;
  }
}

export class LogVersionViewModel {
  id: string | number;
  createdOrUpdatedBy: string;
  createdOrUpdatedAt: string;
  isCreated: boolean;
  isActive: boolean;
  version: number;
  isSelected: boolean;

  static mapFromHttpModel(httpData: ILogVersion, viewData?: LogVersionViewModel, setId?: boolean): LogVersionViewModel {
    if (!viewData) {
      viewData = new LogVersionViewModel();
    }
    if (setId) {
      viewData.id = httpData.id;
    }
    viewData.createdOrUpdatedAt = httpData.recordedAt;
    viewData.createdOrUpdatedBy = httpData.recordedBy ? getName(httpData.recordedBy.firstName, httpData.recordedBy.lastName) : '';
    viewData.isCreated = httpData.version === 1;
    viewData.isActive = httpData.isActiveVersion;
    viewData.version = httpData.version;
    viewData.isSelected = false;
    return viewData;
  }

  static mapFromData(createdOrUpdatedAt: string, createdOrUpdatedBy: string, version: number, isActiveVersion: boolean): LogVersionViewModel {
    const viewData = new LogVersionViewModel();
    viewData.createdOrUpdatedAt = createdOrUpdatedAt;
    viewData.createdOrUpdatedBy = createdOrUpdatedBy;
    viewData.isCreated = version === 1;
    viewData.isActive = isActiveVersion;
    viewData.version = version;
    viewData.isSelected = false;
    return viewData;
  }
}

export class StockItemLogViewModel extends LogVersionViewModel {
  id: number;
  vendorXref: string;
  vendorName: string;
  duPrice: number;
  skuPrice: number;
  stockKeepingUnit: string;
  secondaryUnit: string;
  duPerSU: number;
  suPerSKU: number;
  vatType: string;
  effectiveDate: string;
  validToDate: string;

  static mapFromHttpModel(httpData: IStockItemLog): StockItemLogViewModel {
    let viewData = new StockItemLogViewModel();
    viewData.id = httpData.id;
    if (httpData.priorityVendor) {
      viewData.vendorName = httpData.priorityVendor.name;
      viewData.vendorXref = httpData.priorityVendor.xref;
    }
    viewData.duPrice = +httpData.dispensingUnitPrice || null;
    viewData.skuPrice = +httpData.skuPrice || null;
    viewData.stockKeepingUnit = httpData.stockKeepingUnit;
    viewData.secondaryUnit = httpData.secondaryUnit;
    viewData.duPerSU = +httpData.DUPerSU || null;
    viewData.suPerSKU = +httpData.SUPerSKU || null;
    viewData.vatType = !!httpData.isVatApplicable ? 'Yes' : 'No';
    viewData.effectiveDate = httpData.effectiveFromDate;
    viewData.validToDate = httpData.validToDate;
    const {id, ...rest} = LogVersionViewModel.mapFromHttpModel(httpData, viewData);
    viewData = {...viewData, ...rest};
    return viewData;
  }

  static getLogConfig(): ILogConfig<StockItemLogViewModel> {
    return {
      columns: [
        {label: 'Dispensing Unit Price', type: 'number', key: 'duPrice'},
        {label: 'SKU Price', type: 'number', key: 'skuPrice'},
        {label: 'Stock Keeping Unit (SKU)', type: 'string', key: 'stockKeepingUnit'},
        {label: 'Secondary Unit (SU)', type: 'string', key: 'secondaryUnit'},
        {label: 'DU / Secondary Unit (SU)', type: 'number', key: 'duPerSU'},
        {label: 'Secondary Unit (SU)/SKU', type: 'number', key: 'suPerSKU'},
        {label: 'Priority Vendor Name', type: 'string', key: 'vendorName'},
        {label: 'Priority Vendor ID', type: 'string', key: 'vendorXref'},
        {label: 'VAT Type', type: 'string', key: 'vatType'},
        {label: 'Effective Date', type: 'dateTime', key: 'effectiveDate'},
        {label: 'Valid To Date', type: 'dateTime', key: 'validToDate'},
      ],
      editedByColumn: {key: 'createdOrUpdatedBy'},
      editedDateTimeColumn: {key: 'createdOrUpdatedAt'},
      isActiveColumn: {key: 'isActive'},
      isCreatedOrUpdatedColumn: {key: 'isCreated'},
      versionIdColumn: {key: 'version'}
    } as ILogConfig<StockItemLogViewModel>;
  }
}

export class CatalogItemLogViewModel extends LogVersionViewModel {
  id: number;
  isVatApplicable: string;
  doctorFeeType: string;
  masterCatalogPrice: number;
  baseSellingPrice: number;
  ipdPrice: number;
  opdPrice: number;
  effectiveDate: string;
  validToDate: string;

  static mapFromHttpModel(httpData: ICatalogItemLog): CatalogItemLogViewModel {
    let viewData = new CatalogItemLogViewModel();
    viewData.id = httpData.id;
    viewData.isVatApplicable = httpData.isVatApplicable ? 'Yes' : 'No';
    viewData.doctorFeeType = httpData.doctorFeeType ? httpData.doctorFeeType.label : '';
    viewData.masterCatalogPrice = +httpData.masterCatalogPrice || null;
    viewData.baseSellingPrice = +httpData.baseSellingPrice || null;
    viewData.ipdPrice = +httpData.ipdPrice || null;
    viewData.opdPrice = +httpData.opdPrice || null;
    viewData.effectiveDate = httpData.effectiveFromDate;
    viewData.validToDate = httpData.validToDate;
    const {id, ...rest} = LogVersionViewModel.mapFromHttpModel(httpData, viewData);
    viewData = {...viewData, ...rest};
    return viewData;
  }

  static getLogConfig(): ILogConfig<CatalogItemLogViewModel> {
    return {
      columns: [
        {label: 'Master Catalog Price', type: 'number', key: 'masterCatalogPrice'},
        {label: 'Base Selling Price', type: 'number', key: 'baseSellingPrice'},
        {label: 'IPD Price', type: 'number', key: 'ipdPrice'},
        {label: 'OPD Price', type: 'number', key: 'opdPrice'},
        {label: 'VAT Applicable', type: 'string', key: 'isVatApplicable'},
        {label: 'Applicable Doctor Fee Type', type: 'string', key: 'doctorFeeType'},
        {label: 'Effective Date', type: 'dateTime', key: 'effectiveDate'},
        {label: 'Valid To Date', type: 'dateTime', key: 'validToDate'},
      ],
      editedByColumn: {key: 'createdOrUpdatedBy'},
      editedDateTimeColumn: {key: 'createdOrUpdatedAt'},
      isActiveColumn: {key: 'isActive'},
      isCreatedOrUpdatedColumn: {key: 'isCreated'},
      versionIdColumn: {key: 'version'}
    } as ILogConfig<CatalogItemLogViewModel>;
  }
}

export class CompanyLogViewModel extends LogVersionViewModel {
  id: string;
  companyNameThai: string;
  companyNameEnglish: string;
  addressLine1: string;
  telephone: string;
  emailId: string;
  taxId: string;
  effectiveDate: string;
  validToDate: string;

  static mapFromHttpModel(httpData: ICompany): CompanyLogViewModel {
    let viewData = new CompanyLogViewModel();
    viewData.id = httpData.id;
    viewData.companyNameThai = httpData.thaiName;
    viewData.companyNameEnglish = httpData.name;
    if (httpData.addressHeadQuarters) {
      viewData.addressLine1 = httpData.addressHeadQuarters.line1;
      viewData.emailId = httpData.addressHeadQuarters.email;
      viewData.telephone = httpData.addressHeadQuarters.phoneNumber;
    }
    viewData.taxId = httpData.taxId;
    viewData.effectiveDate = httpData.effectiveFromDate;
    viewData.validToDate = httpData.validToDate;
    const {id, ...rest} = LogVersionViewModel.mapFromHttpModel(httpData, viewData);
    viewData = {...viewData, ...rest};
    return viewData;
  }

  static getLogConfig(): ILogConfig<CompanyLogViewModel> {
    return {
      columns: [
        {label: 'Company Name Thai', type: 'string', key: 'companyNameThai'},
        {label: 'Company Name English', type: 'string', key: 'companyNameEnglish'},
        {label: 'Address Line 1', type: 'string', key: 'addressLine1'},
        {label: 'Telephone', type: 'string', key: 'telephone'},
        {label: 'Email ID', type: 'string', key: 'emailId'},
        {label: 'Tax ID', type: 'string', key: 'taxId'},
        {label: 'Effective Date', type: 'dateTime', key: 'effectiveDate'},
        {label: 'Valid To Date', type: 'dateTime', key: 'validToDate'},
      ],
      editedByColumn: {key: 'createdOrUpdatedBy'},
      editedDateTimeColumn: {key: 'createdOrUpdatedAt'},
      isActiveColumn: {key: 'isActive'},
      isCreatedOrUpdatedColumn: {key: 'isCreated'},
      versionIdColumn: {key: 'version'}
    } as ILogConfig<CompanyLogViewModel>;
  }
}

export class ContractLogViewModel extends LogVersionViewModel {
  id: string;
  contractNameThai: string;
  contractNameEnglish: string;
  contractStartDate: string;
  contractEndDate: string;
  effectiveDate: string;
  validToDate: string;

  static mapFromHttpModel(httpData: IContract): ContractLogViewModel {
    let viewData = new ContractLogViewModel();
    viewData.id = httpData.id;
    viewData.contractNameThai = httpData.thaiName;
    viewData.contractNameEnglish = httpData.name;
    viewData.contractStartDate = httpData.startDate;
    viewData.contractEndDate = httpData.endDate;
    viewData.effectiveDate = httpData.effectiveFromDate;
    viewData.validToDate = httpData.validToDate;
    const {id, ...rest} = LogVersionViewModel.mapFromHttpModel(httpData, viewData);
    viewData = {...viewData, ...rest};
    return viewData;
  }

  static getLogConfig(): ILogConfig<ContractLogViewModel> {
    return {
      columns: [
        {label: 'Company Contract Name in Thai', type: 'string', key: 'contractNameThai'},
        {label: 'Company Contract Name in English', type: 'string', key: 'contractNameEnglish'},
        {label: 'Contract Start Date', type: 'date', key: 'contractStartDate'},
        {label: 'Contract End Date', type: 'date', key: 'contractEndDate'},
        {label: 'Effective Date', type: 'dateTime', key: 'effectiveDate'},
        {label: 'Valid To Date', type: 'dateTime', key: 'validToDate'},
      ],
      editedByColumn: {key: 'createdOrUpdatedBy'},
      editedDateTimeColumn: {key: 'createdOrUpdatedAt'},
      isActiveColumn: {key: 'isActive'},
      isCreatedOrUpdatedColumn: {key: 'isCreated'},
      versionIdColumn: {key: 'version'}
    } as ILogConfig<ContractLogViewModel>;
  }
}

export class InsurancePlanLogViewModel extends LogVersionViewModel {
  id: string;
  planNameThai: string;
  planNameEnglish: string;
  priceParameter: string;
  planStartDate: string;
  planEndDate: string;
  effectiveDate: string;
  validToDate: string;

  static mapFromHttpModel(httpData: IMedicalPackagePlan): InsurancePlanLogViewModel {
    let viewData = new InsurancePlanLogViewModel();
    viewData.id = httpData.id;
    viewData.planNameThai = httpData.thaiName;
    viewData.planNameEnglish = httpData.name;
    viewData.priceParameter = httpData.priceParameter ? httpData.priceParameter.label : '';
    viewData.planStartDate = httpData.startDate;
    viewData.planEndDate = httpData.endDate;
    viewData.effectiveDate = httpData.effectiveFromDate;
    viewData.validToDate = httpData.validToDate;
    const {id, ...rest} = LogVersionViewModel.mapFromHttpModel(httpData, viewData);
    viewData = {...viewData, ...rest};
    return viewData;
  }

  static getLogConfig(): ILogConfig<InsurancePlanLogViewModel> {
    return {
      columns: [
        {label: 'Plan Name in Thai', type: 'string', key: 'planNameThai'},
        {label: 'Plan Name in English', type: 'string', key: 'planNameEnglish'},
        {label: 'Price Parameter', type: 'string', key: 'priceParameter'},
        {label: 'Plan Start Date', type: 'date', key: 'planStartDate'},
        {label: 'Plan End Date', type: 'date', key: 'planEndDate'},
        {label: 'Effective Date', type: 'dateTime', key: 'effectiveDate'},
        {label: 'Valid To Date', type: 'dateTime', key: 'validToDate'},
      ],
      editedByColumn: {key: 'createdOrUpdatedBy'},
      editedDateTimeColumn: {key: 'createdOrUpdatedAt'},
      isActiveColumn: {key: 'isActive'},
      isCreatedOrUpdatedColumn: {key: 'isCreated'},
      versionIdColumn: {key: 'version'}
    } as ILogConfig<InsurancePlanLogViewModel>;
  }
}

export class CompanyContractPlanLogViewModel extends LogVersionViewModel {
  id: string;
  planNameThai: string;
  planNameEnglish: string;
  paymentBy: string;
  addEmployee: string;
  priceParameter: string;
  planStartDate: string;
  planEndDate: string;
  effectiveDate: string;
  validToDate: string;

  static mapFromHttpModel(httpData: IMedicalPackagePlan): CompanyContractPlanLogViewModel {
    let viewData = new CompanyContractPlanLogViewModel();
    viewData.id = httpData.id;
    viewData.planNameThai = httpData.thaiName;
    viewData.planNameEnglish = httpData.name;
    viewData.paymentBy = httpData.paymentBy ? httpData.paymentBy.label : '';
    viewData.addEmployee = !!httpData.addEmployee ? 'Yes' : 'No';
    viewData.priceParameter = httpData.priceParameter ? httpData.priceParameter.label : '';
    viewData.planStartDate = httpData.startDate;
    viewData.planEndDate = httpData.endDate;
    viewData.effectiveDate = httpData.effectiveFromDate;
    viewData.validToDate = httpData.validToDate;
    const {id, ...rest} = LogVersionViewModel.mapFromHttpModel(httpData, viewData);
    viewData = {...viewData, ...rest};
    return viewData;
  }

  static getLogConfig(): ILogConfig<CompanyContractPlanLogViewModel> {
    return {
      columns: [
        {label: 'Plan Name in Thai', type: 'string', key: 'planNameThai'},
        {label: 'Plan Name in English', type: 'string', key: 'planNameEnglish'},
        {label: 'Payment By', type: 'string', key: 'paymentBy'},
        {label: 'Add Employee', type: 'string', key: 'addEmployee'},
        {label: 'Price Parameter', type: 'string', key: 'priceParameter'},
        {label: 'Plan Start Date', type: 'date', key: 'planStartDate'},
        {label: 'Plan End Date', type: 'date', key: 'planEndDate'},
        {label: 'Effective Date', type: 'dateTime', key: 'effectiveDate'},
        {label: 'Valid To Date', type: 'dateTime', key: 'validToDate'},
      ],
      editedByColumn: {key: 'createdOrUpdatedBy'},
      editedDateTimeColumn: {key: 'createdOrUpdatedAt'},
      isActiveColumn: {key: 'isActive'},
      isCreatedOrUpdatedColumn: {key: 'isCreated'},
      versionIdColumn: {key: 'version'}
    } as ILogConfig<CompanyContractPlanLogViewModel>;
  }
}

export class CompanyContractEmployeeLogViewModel extends LogVersionViewModel {
  id: string;
  employeeNameThai: string;
  employeeNameEnglish: string;
  employeeId: string;
  serviceStartDate: string;
  serviceEndDate: string;
  effectiveDate: string;
  validToDate: string;

  static mapFromHttpModel(httpData: ICompanyEmployee): CompanyContractEmployeeLogViewModel {
    let viewData = new CompanyContractEmployeeLogViewModel();
    viewData.id = httpData.id;
    viewData.employeeNameThai = httpData.thaiEmployeeName;
    viewData.employeeNameEnglish = httpData.fullFirstLastName;
    viewData.employeeId = httpData.employeeNumber;
    viewData.serviceStartDate = httpData.serviceStartDate;
    viewData.serviceEndDate = httpData.serviceEndDate;
    viewData.effectiveDate = httpData.effectiveFromDate;
    viewData.validToDate = httpData.validToDate;
    const {id, ...rest} = LogVersionViewModel.mapFromHttpModel(httpData, viewData);
    viewData = {...viewData, ...rest};
    return viewData;
  }

  static getLogConfig(): ILogConfig<CompanyContractEmployeeLogViewModel> {
    return {
      columns: [
        {label: 'Employee Name Thai', type: 'string', key: 'employeeNameThai'},
        {label: 'Employee Name English', type: 'string', key: 'employeeNameEnglish'},
        {label: 'Employee ID', type: 'string', key: 'employeeId'},
        {label: 'Service Start Date', type: 'date', key: 'serviceStartDate'},
        {label: 'Service End Date', type: 'date', key: 'serviceEndDate'},
        {label: 'Effective Date', type: 'dateTime', key: 'effectiveDate'},
        {label: 'Valid To Date', type: 'dateTime', key: 'validToDate'},
      ],
      editedByColumn: {key: 'createdOrUpdatedBy'},
      editedDateTimeColumn: {key: 'createdOrUpdatedAt'},
      isActiveColumn: {key: 'isActive'},
      isCreatedOrUpdatedColumn: {key: 'isCreated'},
      versionIdColumn: {key: 'version'}
    } as ILogConfig<CompanyContractEmployeeLogViewModel>;
  }
}

export class DiscountFactorLogDiffViewModel {
  incomeTypeCode: IItemDiff;
  incomeType: IItemDiff;
  opdDiscount: IItemDiff;
  ipdDiscount: IItemDiff;

  static mapFromData(discountFactor: IPackageDiscountFactor): DiscountFactorLogDiffViewModel {
    const viewData = new DiscountFactorLogDiffViewModel();
    viewData.incomeTypeCode = {
      oldValue: discountFactor.incomeType_old && discountFactor.incomeType_old.xref,
      newValue: discountFactor.incomeType && discountFactor.incomeType.xref
    };
    viewData.incomeType = {
      oldValue: discountFactor.incomeType_old && discountFactor.incomeType_old.computed,
      newValue: discountFactor.incomeType && discountFactor.incomeType.computed
    };
    viewData.opdDiscount = {oldValue: discountFactor.opdDiscount_old, newValue: discountFactor.opdDiscount};
    viewData.ipdDiscount = {oldValue: discountFactor.ipdDiscount_old, newValue: discountFactor.ipdDiscount};
    return viewData;
  }
}

export class DiscountFactorsLogViewModel {
  totalPages: number;
  page: number;
  items: Array<DiscountFactorLogDiffViewModel>;
  isLoading: boolean;
  isNextPageLoading: boolean;
  isDataLoaded: boolean;

  static mapFromHttpModel(data: IPageableData<IPackageDiscountFactor>, loadedPage: number): DiscountFactorsLogViewModel {
    const viewData = new DiscountFactorsLogViewModel();
    viewData.totalPages = data.totalPages;
    viewData.page = loadedPage;
    viewData.isLoading = false;
    viewData.isNextPageLoading = false;
    viewData.isDataLoaded = viewData.totalPages === viewData.page;
    viewData.items = data.items.map(item => DiscountFactorLogDiffViewModel.mapFromData(item));
    return viewData;
  }

  static getDefault(): DiscountFactorsLogViewModel {
    const viewData = new DiscountFactorsLogViewModel();
    viewData.totalPages = 1;
    viewData.page = 1;
    viewData.isLoading = false;
    viewData.isNextPageLoading = false;
    viewData.items = [];
    return viewData;
  }
}

export class LogsViewModel {
  logEntries: Array<LogVersionViewModel>;
  data: Array<ILogColumnData>;
  totalPages: number;

  static mapFromConfig<T>(data: Array<T>, config: ILogConfig<T>, totalPages: number): LogsViewModel {
    const viewData = new LogsViewModel();
    viewData.logEntries = [];
    viewData.data = [];
    const activeStatusInfo: Array<boolean> = [];
    viewData.totalPages = totalPages;
    if (isArray(data) && config) {
      config.columns.forEach((column, columnIndex) => {
        const columnLabel = column.label;
        const type = column.type;
        const items: Array<string | number> = [];
        data.forEach((log) => {
          if (columnIndex === 0) {
            const logEntry = LogVersionViewModel.mapFromData(
              log[config.editedDateTimeColumn.key] as unknown as string,
              log[config.editedByColumn.key] as unknown as string,
              log[config.versionIdColumn.key] as unknown as number,
              log[config.isActiveColumn.key] as unknown as boolean
            );
            viewData.logEntries.push(logEntry);
            activeStatusInfo.push(log[config.isActiveColumn.key] as unknown as boolean);
          }
          items.push(log[column.key] as unknown as string | number);
        });
        viewData.data.push({columnLabel, type, items, activeStatusInfo});
      });
    }
    return viewData;
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {DobDisplayComponent} from './dob-display.component';

@NgModule({
  declarations: [DobDisplayComponent],
  exports: [
    DobDisplayComponent
  ],
  imports: [
    CommonModule,
    PipeModule
  ]
})
export class DobDisplayModule {
}

export default class Utils {
  static objToArray(obj: object) {
    if (Object.keys(obj).length) {
      return Object.keys(obj).map(key => {
        const newObj = {};
        newObj[key] = obj[key];
        return newObj;
      });
    }
  }
}

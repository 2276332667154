import {Directive, HostListener, Input} from '@angular/core';
import {MatTooltip} from '@angular/material/tooltip';
import {TooltipPosition} from '@angular/material/tooltip/typings/tooltip';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';

@Directive({
  selector: '[appCommonTooltip]',
  providers: [MatTooltip, {provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class CommonTooltipDirective {
  tooltip: MatTooltip;
  @Input() tooltipMessage = 'You do not have access to ';
  @Input() tooltipPosition: TooltipPosition = 'left';
  @Input() tooltipClass = `tooltip-with-tip`;
  @Input() showTooltip = true;
  @Input() action = 'perform this action';

  constructor(tooltip: MatTooltip, private translocoService: TranslocoService) {
    this.tooltip = tooltip;
  }

  @HostListener('mouseover') mouseover() {
    if (this.showTooltip) {
      this.tooltip.message = this.translocoService.translate(this.tooltipMessage + this.action, {}, 'common');
      this.tooltip.position = this.tooltipPosition;
      this.tooltip.tooltipClass = `${this.tooltipClass}
      ${this.tooltipPosition === 'left' ? 'tip-right' : ''}
      ${this.tooltipPosition === 'right' ? 'tip-left' : ''}
      ${this.tooltipPosition === 'above' ? 'tip-bottom' : ''}
      ${this.tooltipPosition === 'below' ? 'tip-top' : ''}`;
      this.tooltip.show();
    }
  }
}

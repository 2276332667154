import {Component, Input, OnInit} from '@angular/core';
import {IPatient} from '@v2/core/models/masterdata';

@Component({
  selector: 'app-dob-display',
  templateUrl: './dob-display.component.html',
  styleUrls: ['./dob-display.component.scss']
})
export class DobDisplayComponent implements OnInit {

  @Input() dobInfo: Partial<IPatient>;
  @Input() format = `DD-MM-YYYY`;

  constructor() {
  }

  ngOnInit() {
  }

}

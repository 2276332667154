import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {

  transform(minutes: number, hourLabel: string = 'hours', minuteLabel: string = 'minutes'): any {
    let output = '';
    const duration = moment.duration(minutes, 'minutes');
    const hours = Math.trunc(duration.asHours());
    const min = Math.trunc(duration.asMinutes() % 60);
    if (hours) {
      output = hours + ' ' + hourLabel;
    }
    if (min) {
      if (output) {
        output += ' ';
      }
      output += min + ' ' + minuteLabel;
    }
    return output;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {FilterButtonComponent} from './filter-button.component';



@NgModule({
  declarations: [FilterButtonComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [FilterButtonComponent]
})
export class FilterButtonModule { }

import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {setFilter} from '@v2/core/functions/functions';
import {
  IAppointment,
  IAppointmentClaim,
  IBase,
  IData,
  IPatient,
  IPatientDrugAllergy,
  IPatientPaymentPlan,
  IPaymentType,
  IStatusResponse
} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {PaymentPlanViewModel} from '@v2/core/models/view/payment-plan.view.model';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {ClaimOrdersDiscountPayload} from '../models/payload/claim-orders-discount.payload';
import {PatientPaymentPriorityPayload, PatientValidateCoveragePayload, PatientVerificationPayload} from '../models/payload/patient.payload';
import {DeletePaymentPlanPayload, PaymentTypePayload, TransferOrdersToSelfPayPayload} from '../models/payload/payment-plan.payload';

@Injectable()
export class PatientHttpService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  verifyPatient(appointmentId: string, requestPayload: PatientVerificationPayload): Observable<IBase<IAppointment>> {
    return this.baseHttpService.putWrapper(`appointment/${appointmentId}/verification`, requestPayload, ConcurrencyScopeEnum.PATIENT_APPOINTMENT_ACTIONS);
  }

  getPatientPaymentTypes(patientId: string): Observable<IBase<IPatientPaymentPlan>> {
    return this.baseHttpService.get<IBase<IPatientPaymentPlan>>(`patient/${patientId}/rapid-payment-plans`);
  }

  getPatientPaymentTypesForAppointment(patientId: string, appointmentId: string): Observable<IBase<IPatientPaymentPlan>> {
    return this.baseHttpService.get(`patient/${patientId}/appointment/${appointmentId}/rapid-payment-plans`);
  }

  updatePaymentCoverage(patientId: string, appointmentId: string, payload: PatientValidateCoveragePayload): Observable<IBase<IPatientPaymentPlan>> {
    return this.baseHttpService.putWrapper(`patient/${patientId}/appointment/${appointmentId}/coverage-payment`, payload, ConcurrencyScopeEnum.PATIENT_APPOINTMENT_ACTIONS);
  }

  updatePaymentPriority(patientId: string, appointmentId: string, payload: PatientPaymentPriorityPayload): Observable<IBase<IPatientPaymentPlan>> {
    return this.baseHttpService.putWrapper(`patient/${patientId}/appointment/${appointmentId}/set-priority`, payload, ConcurrencyScopeEnum.PATIENT_APPOINTMENT_ACTIONS);
  }

  getPatientInfoById(patientId: string): Observable<IBase<IPatient>> {
    return this.baseHttpService.get<IBase<IPatient>>(`patient-details/${patientId}`);
  }

  addPaymentType(patientId: string, payload: PaymentTypePayload): Observable<IBase<IPatientPaymentPlan>> {
    return this.baseHttpService.postWrapper<IBase<IPatientPaymentPlan>>(`patient/${patientId}/rapid-payment-plan`, payload, ConcurrencyScopeEnum.PATIENT_ADD_PAYMENT);
  }

  deletePaymentType(patientId: string, payload: DeletePaymentPlanPayload): Observable<IBase<IStatusResponse>> {
    return this.baseHttpService.patchWrapper(`patient/${patientId}/rapid-remove-payment-plan`, payload, ConcurrencyScopeEnum.PATIENT_REMOVE_PAYMENT);
  }

  getOpenAppointmentsForPatient(patientId: string, paymentPlanId: string): Observable<IBase<IData<IAppointment>>> {
    return this.baseHttpService.get<IBase<IData<IAppointment>>>(`patient/${patientId}/open-appointments-by-payment-plan/${paymentPlanId}`);
  }

  moveOrdersOfOpenAppointmentToSelfPay(patientId: string, appointmentId: string, payload: TransferOrdersToSelfPayPayload): Observable<IBase<IAppointmentClaim>> {
    return this.baseHttpService.postWrapper<IBase<IAppointmentClaim>>(`patient/${patientId}/appointment/${appointmentId}/move-order-items-to-self-pay`, payload, ConcurrencyScopeEnum.PATIENT_TRANSFER_OPEN_APPOINTMENT_ORDERS_TO_SELF_PAY);
  }

  onBillingCodeChange(payload: ClaimOrdersDiscountPayload): Observable<IBase<IAppointment>> {
    return this.baseHttpService.putWrapper<IBase<IAppointment>>(`appointment/${payload.appointmentId}/claim-orders-discount`, payload, ConcurrencyScopeEnum.PATIENT_ADD_PAYMENT);
  }

  getPatientAllergies(patientId: string, filters: ICustomFilters = {}): Observable<IBase<IData<IPatientDrugAllergy>>> {
    return this.baseHttpService.get<IBase<IData<IPatientDrugAllergy>>>(`patient/${patientId}/patient-allergy`, setFilter(filters));
  }

  getPatientPaymentTypeDetail(paymentPlanId: string, appointmentId: string): Observable<PaymentPlanViewModel> {
    return this.baseHttpService.get<IBase<IPaymentType>>(`patient-payment-detail/${paymentPlanId}`).pipe(
      pluck('data'),
      map(response => PaymentPlanViewModel.mapFromHttpModel(response))
    )
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CommonTooltipDirective} from './common-tooltip.directive';
import {ExpandMenuDirective} from './expand-menu.directive';
import {InfiniteScrollDirective} from './infinite-scroll.directive';
import {NgxDatatableDefaultDirective} from './ngx-datatable-default.directive';
import {StopPropagationDirective} from './stop-propagation.directive';
import {TrimInputLeadingSpaceDirective} from './trim-input-leading-space.directive';

@NgModule({
  declarations: [StopPropagationDirective, NgxDatatableDefaultDirective, TrimInputLeadingSpaceDirective, ExpandMenuDirective, CommonTooltipDirective, InfiniteScrollDirective],
  imports: [
    CommonModule
  ],
  exports: [StopPropagationDirective, NgxDatatableDefaultDirective, TrimInputLeadingSpaceDirective, ExpandMenuDirective, CommonTooltipDirective, InfiniteScrollDirective]
})
export class CommonDirectiveModule { }

export enum BedTransferRequestTypeEnum {
  TRANSFER_BED = 'Transfer Bed',
  ADD_BED = 'Add Bed',
  RELEASE_BED = 'Release Bed',
  BOOK_BED = 'Book Bed',
  CANCELED = 'Canceled'
}

export enum AddBedTypeEnum {
  ADD_BED_AND_TRANSFER = 'Add Bed And Transfer', // received from backend
  ADD_BED_AND_TRANSFER_UI = 'Physical Transfer of Patient to added bed', // displayed on front-end
  ADD_HOLD_BED = 'Add Hold Bed', // received from backend,
  ADD_HOLD_BED_UI = 'Patient remains in current room' // displayed on front-end
}

export enum TransferBedTypeEnum {
  TRANSFER_TO_HOLD_BED = 'Transfer Hold Bed', // received from backend
  TRANSFER_TO_HOLD_BED_UI = 'Transfer patient to on hold bed', // displayed on front-end
  TRANSFER_TO_NEW_BED = 'Transfer New Bed', // received from backend
  TRANSFER_TO_NEW_BED_UI = 'New Preference' // displayed on front-end
}

export enum BedTransferProcessStepEnum {
  BED_READY = 'Bed Ready',
  BED_READY_UI = 'Bed ready for the new admission',
  CLEANING_COMPLETED = 'Cleaning Completed',
  CLEANING_COMPLETED_UI = 'Cleaning Completed',
  PHYSICAL_TRANSFER = 'Physical Transfer',
  PHYSICAL_TRANSFER_UI_TRANSFER = 'Physical Transfer of Patient',
  PHYSICAL_TRANSFER_UI_ADD_BED = 'Patient is Physically Transferred to the newly added Bed',
}

import {IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';

export class FuturePatientOrderViewModel {
  testDateTime: string
  testName: string[]
  orderType: string
  orderBy: string
  department: string

  constructor() {
  }

  static mapFromApiModel(apiData: IPatientOrder): FuturePatientOrderViewModel {
    let requestedDepartment = '-';
    if (apiData.investigations.length > 0) {
      if (apiData.investigations[0].requestedDepartment) {
        requestedDepartment = apiData.investigations[0].requestedDepartment.name;
      }
    }
    const testNames = apiData.investigations.map(investigation => {
      if (investigation.catalogGroupElement) {
        return investigation.catalogGroupElement.name;
      } else {
        return investigation.catalogElement.englishName;
      }
    });

    const viewModel = new FuturePatientOrderViewModel();

    viewModel.testDateTime = apiData.preferredDateTime ? apiData.preferredDateTime : ''
    viewModel.testName = testNames
    viewModel.orderType = apiData.orderType && apiData.orderType.label ? apiData.orderType.label : '-'
    viewModel.orderBy = apiData.requestedBy ? `Dr. ${apiData.requestedBy.fullName}` : '-'
    viewModel.department = requestedDepartment

    return viewModel;
  }

}

export interface IAdmissionAppointmentExistInfo {
  isAdmissionReqExists: boolean;
  selectedAdmissionDate?: string;
}

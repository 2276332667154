import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '../../pipes/pipes.module';
import {ReadOnlyInputComponent} from './read-only-input.component';

@NgModule({
  declarations: [ReadOnlyInputComponent],
  exports: [
    ReadOnlyInputComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class ReadOnlyInputModule {
}

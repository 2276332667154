import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule, MatProgressSpinnerModule} from '@angular/material';

import {LoadingButtonComponent} from './loading-button/loading-button.component';
import {LoadingIconButtonComponent} from './loading-icon-button/loading-icon-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [LoadingButtonComponent, LoadingIconButtonComponent],
  declarations: [LoadingButtonComponent, LoadingIconButtonComponent],
  providers: [],
})
export class LoadingButtonModule {
}

import {DOCTOR_PREFIX, getMasterDataKey, getMasterDataLabel, getName} from '@v2/core/functions/functions';
import {IDiagnosis, IDiagnosisDetail, IMasterData} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import * as moment from 'moment';

export class DiagnosisViewModel extends ConcurrencyRequest {
  diagnosisName: string;
  diagnosis: DiagnosisDetailViewModel;
  diagnosisCode: string;
  icdCode: string;
  diagnosisType: IMasterData;
  diagnosisTypeKey: number;
  note: string;
  recordedBy: string;
  role: string;
  pdDiagnosisId: string;
  icd: string;
  provisionalDefinite: boolean;
  diagnosisNote: string;
  recordedTime: string;
  doctorName: string;
  diagnosisNoteInfo: string;
  daysAfterOR?: number;
  id: string;
  diagnosisId: string;
  diagnosisObj: IDiagnosisDetail;
  name: string
  diagnosisTypeLabel: string
  icdNum: string

  static mapFromHttpModel(item: IDiagnosis): DiagnosisViewModel {
    const viewModel = new DiagnosisViewModel();
    viewModel.id = item.pdDiagnosisId;
    viewModel.pdDiagnosisId = item.pdDiagnosisId;
    if (item.diagnosis) {
      viewModel.diagnosis = DiagnosisDetailViewModel.mapFromApiModel(item.diagnosis);
      viewModel.diagnosisCode = item.diagnosis.code;
      viewModel.diagnosisName = item.diagnosis.desc;
      viewModel.diagnosisNoteInfo = this.getDiagnosisNoteInfo(item);
      viewModel.name = item.diagnosis.desc;
      viewModel.diagnosisTypeLabel = getMasterDataLabel(item.diagnosisType);
      viewModel.icdNum = item.diagnosis.code;
      viewModel.diagnosisObj = {
        id: item.diagnosis.id,
        diagnosis: item.diagnosis.desc,
        icdNum: item.diagnosis.codeList && item.diagnosis.codeList.name
      } as IDiagnosisDetail;
      viewModel.icdCode = item.diagnosis.codeList ? item.diagnosis.codeList.name : item.diagnosis.code ? item.diagnosis.code : '-';
      viewModel.icd = item.diagnosis.code;
    }
    viewModel.diagnosisType = item.diagnosisType;
    viewModel.recordedTime = item.recordedTime;
    viewModel.diagnosisTypeKey = item.diagnosisType && getMasterDataKey(item.diagnosisType)
    viewModel.note = item.value;
    viewModel.provisionalDefinite = !!item.isDefinite;
    viewModel.doctorName = item.recordedByName;
    if (item.recordedBy) {
      viewModel.recordedBy = item.recordedBy.fullName;
      viewModel.role = item.recordedBy.role;
    }
    viewModel.diagnosisNote = item.value;
    viewModel.mapConcurrencyRequest(item);
    return viewModel;
  }

  static getDiagnosisNoteInfo(element: IDiagnosis): string {
    let diagnosisNoteInfo = '';
    if (!element.isEdited) {
      diagnosisNoteInfo = element.recordedByName + ' (Doctor) \n' + moment(element.recordedTime).format('HH:mm');
    } else {
      diagnosisNoteInfo = getName(element.editedBy.firstName, element.editedBy.lastName, DOCTOR_PREFIX);
      diagnosisNoteInfo += element.editedBy.role ? ` (${element.editedBy.role})` : ` (Doctor)`;
      diagnosisNoteInfo += '\n' + moment(element.editedTime).format('HH:mm') + ' (Edited)';
    }
    return diagnosisNoteInfo;
  }
}

export class DiagnosisDetailViewModel {
  id: string;
  codeListId: string;
  name: string;
  desc: string;
  xref: string;
  icdNum: string;
  diagnosis: string

  static mapFromApiModel(apiData: IDiagnosisDetail): DiagnosisDetailViewModel {

    const viewModel = new DiagnosisDetailViewModel();

    viewModel.id = apiData.id;
    viewModel.diagnosis = apiData.desc;
    viewModel.codeListId = apiData.codeList && apiData.codeList.id
    viewModel.name = apiData.desc
    viewModel.desc = apiData.desc
    viewModel.xref = apiData.codeList && apiData.codeList.xref
    viewModel.icdNum = apiData.code

    return viewModel;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {isArray} from '@v2/core/functions/functions';

@Pipe({
  name: 'secondsToMinute'
})
export class SecondsToMinutePipe implements PipeTransform {

  transform(value: number, returnFormat: 'short' | 'long' = 'short'): string {
    if (!value) {
      return returnFormat === 'short' ? '00:00' : '0 sec';
    }
    const remainingTime = new Date(value * 1000).toISOString().substr(11, 8);
    if (returnFormat === 'short') {
      return remainingTime;
    }
    const values = remainingTime.split(':');
    if (isArray(values) && values.length > 2) {
      const hours = +values[0] === 0 ? '' : `${values[0]} hour `;
      const min = +values[1] === 0 ? '' : `${values[1]} min `;
      const seconds = `${values[2]} sec`;
      return hours + min + seconds;
    } else {
      return remainingTime;
    }
  }

}

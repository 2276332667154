import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {setFilter} from '@v2/core/functions/functions';
import {IBase, IPageableData, IStatusResponse} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IPatientOrder, IReturnedPatientOrderLog} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {Observable} from 'rxjs';
import {SupplyOrderPayload} from '../../components/patient-order/resources/models/payload/supply-order.payload';

@Injectable()
export class SupplyOrderHttpService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  getSupplyOrders(appointmentId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<IPatientOrder>>> {
    return this.baseHttpService.get<IBase<IPageableData<IPatientOrder>>>(`v2/appointment/${appointmentId}/patient-orders/supply`, setFilter(filters));
  }

  placeSupplyOrder(appointmentId: string, payload: SupplyOrderPayload): Observable<IBase<IPatientOrder>> {
    return this.baseHttpService.postWrapper<IBase<IPatientOrder>>(`v2/appointment/${appointmentId}/supply/order`, payload, ConcurrencyScopeEnum.SUPPLY_PATIENT_ORDER, new HttpParams(), payload.uniqueIdForConcurrency);
  }

  updateSupplyOrder(appointmentId: string, orderId: number, payload: SupplyOrderPayload): Observable<IBase<IPatientOrder>> {
    return this.baseHttpService.putWrapper<IBase<IPatientOrder>>(`v2/appointment/${appointmentId}/supply/order/${orderId}`, payload, ConcurrencyScopeEnum.SUPPLY_PATIENT_ORDER);
  }

  deleteSupplyOrder(appointmentId: string, orderId: string, updatedAt: string, groupCommercialOrderId: string, groupCommercialOrderUpdatedAt: string): Observable<IBase<IStatusResponse>> {
    const params = new HttpParams().append('appointmentId', appointmentId);
    return this.baseHttpService.deleteWrapper<IBase<IStatusResponse>>(`v2/supply/order/${orderId}/updated-at/${updatedAt}/group-commercial-order/${groupCommercialOrderId}/updated-at/${groupCommercialOrderUpdatedAt}`, ConcurrencyScopeEnum.SUPPLY_PATIENT_ORDER, params);
  }

  getSupplyItemReturnLog(planPatientOrderId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<IReturnedPatientOrderLog>>> {
    return this.baseHttpService.get<IBase<IPageableData<IReturnedPatientOrderLog>>>(`v2/supply/order/${planPatientOrderId}/return-order-logs`, setFilter(filters));
  }
}

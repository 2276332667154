/*
1. Intellectual Property Ownership
ArcNovo Tech Inc owns or is the authorized licensee of all intellectual property rights related to the Software Codebase to the extent the Software Codebase have not been modified, customized, or designed for Buyer. If and to the extent Software Codebase have been or will be modified, customized, or designed by Buyer or any other party, ArcNovo Tech Inc hereby does not agrees to irrevocably assign and transfer to Buyer and does hereby assign and transfer to Buyer all of ArcNovo Tech Inc’s worldwide right, title, and interest in and to the Works for Hire (defined below) including all associated intellectual property rights. For purposes of existing Contract between ArcNovo Tech Inc and Buyer or any other pary, "Works for Hire" include all rights in ideas, inventions, works of authorship, strategies, plans and data created in or resulting from ArcNovo Tech Inc’s performance
under this Contract, including all patent rights, copyrights, rights in proprietary information, database rights, trademark rights and other intellectual property rights. All Works for Hire shall at all times be and remain the sole and exclusive property of ArcNovo Tech Inc. Buyer will have the sole right to determine the treatment of any Works for Hire, including the right to keep it as trade secret, execute and file patent applications on it, to use and disclose it without prior patent application, to file registrations for copyright or trademark in its own name, or to follow any other procedure that Buyer deems appropriate once all contractual terms are met including any outstanding payments. Buyer hereby grants a exclusive,
    assignable license, which is revocable with or without cause at any time, to ArcNovo Tech Inc to use any
information, drawings, specifications, computer software, know-how and other data furnished or paid for by Buyer hereunder for the sole purpose of performing this Contract for Buyer. No licence shall be valid unless provided
in writing with specificity and signed by ArcNovo Tech Inc

2. Indemnification
Buyer shall, at its sole expense, indemnify, defend, and hold ArcNovo Tech Inc and its affiliates, employees, representatives and agents and its direct or indirect customers harmless (“Indemnified Parties”) from and
against any and all damages, legal actions, settlements, judgments, liabilities, claims, fines, penalties, costs, expenses or losses of any nature whatsoever (including all attorney’s fees), arising from or relating
to: (1) ArcNovo Tech Inc’s breach of its representations, warranties, covenants or obligations under this agreement; (2) product liability attributable to an actual or alleged defect in the design of, manufacture of, or warnings and instructions included with Software Codebase claimed to have been suffered by any person or entity; and (3) the
provision of Software Codebase by ArcNovo Tech Inc under this agreement

3. Intellectual Property Indemnification
Buyer shall, at its sole expense, defend, indemnify and hold harmless ArcNovo Tech Inc and any Indemnified Parties against any and all losses arising out of or in connection with any claim that Buyer's or Indemnitee's use or possession of the Software Codebase infringes or misappropriates the patent, copyright, trade secret or other intellectual property right of any third party. In no event shall ArcNovo Tech Inc enter into any settlement of a claim made against ArcNovo Tech Inc by Buyer or other parties.
*/
import {DOCUMENT} from '@angular/common';
import {ApplicationRef, Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {SwUpdate} from '@angular/service-worker';
import {CHECK_NEW_VERSION_INTERVAL} from '@v2/core/functions/functions';
import {BaseSubscriptionHandlerClass} from '@v2/core/subscription-handler/subscription-handler.class';
import {fromEvent, interval} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';
import {ICON_REGISTRIES} from './shared/layout/icon-registries';
import {GlobalErrorHandlerService} from './v2/shared-v2/components/error-handler/global-error-handler.service';
import {ClearBrowserCacheModalComponent} from './v2/shared-v2/modals/clear-browser-cache-modal/clear-browser-cache-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseSubscriptionHandlerClass implements OnInit {
  private IconRegistries = ICON_REGISTRIES;

  private modalParameters: { height: number; width: number } = {
    height: window.innerHeight - 80 > 500 ? window.innerHeight - 80 : 500,
    width: window.innerWidth,
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private swUpdate: SwUpdate,
    private applicationRef: ApplicationRef,
    private matDialog: MatDialog,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.registerIcons();
    // this.checkForUpdate();
    // this.updateIfNewVersionAvailable();
  }

  ngOnInit() {
    this.handleChunkLoadingError();
  }

  updateIfNewVersionAvailable() {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.swUpdate.available.pipe(this.pickUntil()).subscribe((event) => {
      // tslint:disable-next-line:no-console
      console.debug(`version - current : ${event.current}, available : ${event.available}`);
      this.openBrowserCacheDialog();
    });
  }

  checkForUpdate() {
    this.applicationRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(CHECK_NEW_VERSION_INTERVAL); // check if new version available at every 1 hour

        timeInterval.pipe(this.pickUntil()).subscribe(() => {
          this.swUpdate.checkForUpdate().then(() => {
            // tslint:disable-next-line:no-console
            console.debug('checking for new version');
          });
        });
      }
    });
  }

  handleChunkLoadingError() {
    this.globalErrorHandlerService.chunkLoadingError$.pipe(
      this.pickUntil()
    ).subscribe(() => {
      this.openBrowserCacheDialog();
    });
  }

  openBrowserCacheDialog() {
    const dialogRefId = this.matDialog.getDialogById('clear-cache');
    if (!dialogRefId) {
      const modalHandler = this.matDialog.open(ClearBrowserCacheModalComponent, {
        id: 'clear-cache',
        panelClass: 'cache-clear-modal',
        disableClose: true,
        width: `${this.modalParameters.width}px`,
        height: `${this.modalParameters.height}px`,
        data: this.modalParameters,
      });
      // add hide-scrollbar class to body when modal is opened
      modalHandler
        .afterOpened()
        .pipe(this.pickFirstOrUntil())
        .subscribe(() => this.toggleBodyScroll(true));
      // subscription when modal is closed
      modalHandler
        .afterClosed()
        .pipe(this.pickFirstOrUntil())
        .subscribe(() => {
          this.toggleBodyScroll(false);
          location.reload();
        });
      this.registerResizeEventForModal();
    }
  }

  /**
   * Registering window resize event for modal,
   * when window is resized we will update modal height and width
   */
  private registerResizeEventForModal() {
    fromEvent(window, 'resize').pipe(
      debounceTime(150),
      this.pickUntil(),
      tap((event) => this.handlerWindowResize(event))
    ).subscribe();
  }

  /**
   * Will add or remove class "hide-scrollbar" in body
   * @param toAdd
   */
  private toggleBodyScroll(toAdd: boolean) {
    if (toAdd) {
      this.document.body.classList.add('hide-scrollbar')
    } else {
      this.document.body.classList.remove('hide-scrollbar');
    }
  }

  /**
   * Will handle modal height when window is resized
   * @param event
   */
  private handlerWindowResize(event) {
    const dialogRefId = this.matDialog.getDialogById('clear-cache');
    if (dialogRefId) {
      this.modalParameters.height = event.target.innerHeight - 80 > 500 ? event.target.innerHeight - 80 : 500;
      this.modalParameters.width = event.target.innerWidth;
      dialogRefId.updateSize(`${this.modalParameters.width}px`, `${this.modalParameters.height}px`);
    }
  }

  private registerIcons() {
    this.IconRegistries.forEach((value) => {
      this.matIconRegistry.addSvgIcon(value.icon, this.domSanitizer.bypassSecurityTrustResourceUrl(value.path));
    });
  }
}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {OpdSummarySectionEnum} from '@v2/core/enums/opd-summary-section.enum';
import {currentTimeZone} from '@v2/core/functions/functions';
import {IPatient} from '@v2/core/models/masterdata';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {OpdSummaryViewModel} from '@v2/core/models/view/appointment.view.model';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {UtilityService} from '@v2/core/services/utility.service';
import {BaseSubscriptionHandlerClass} from '@v2/core/subscription-handler/subscription-handler.class';
import {saveAs} from 'file-saver';
import {finalize} from 'rxjs/operators';
import {AppointmentHttpService} from '../../../../v2/shared-v2/resources/http-service/appointment.http.service';
import {OPDSummaryInfo, OpdSummaryInfoService} from '../opd-summary-info.service';

@Component({
  selector: 'app-opd-summary-print-view-modal',
  templateUrl: './opd-summary-print-view-modal.component.html',
  styleUrls: ['./opd-summary-print-view-modal.component.scss']
})
export class OpdSummaryPrintViewModalComponent extends BaseSubscriptionHandlerClass implements OnInit, OnDestroy {

  opdSummarySectionEnum = OpdSummarySectionEnum;
  opdSummarySectionEnumValues = Object.values(OpdSummarySectionEnum) as Array<OpdSummarySectionEnum>;
  isDownloadingPDF = false;
  patientId: string;
  opdSummaryInfo: OPDSummaryInfo = {opdSummaryData: {}, opdSummarySectionConfig: {}} as OPDSummaryInfo;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      appointmentId: string,
      visitXref: string,
      appointmentDate: string,
      patient: IPatient,
      department: string,
      doctor: string
    },
    private appointmentHttpService: AppointmentHttpService,
    private masterDataHttpService: MasterDataHttpService,
    private utilityService: UtilityService,
    private opdSummaryInfoService: OpdSummaryInfoService
  ) {
    super();
  }

  ngOnInit() {
    this.patientId = this.data.patient && this.data.patient.id;
    const {visitXref, appointmentDate, patient, department, doctor} = this.data;
    this.opdSummaryInfo.opdSummaryData = OpdSummaryViewModel.getInitialModel(visitXref, appointmentDate, patient, department, doctor);
    this.opdSummaryInfo.opdSummarySectionConfig = this.opdSummaryInfoService.getDefaultOpdSummarySectionConfig();
    this.opdSummaryInfoService.loadOPDSummary(this.opdSummaryInfo, this.data.appointmentId, this.patientId, this.data.appointmentDate);
  }

  downloadOPDSummaryPDF() {
    if (this.isDownloadingPDF) {
      return;
    }
    this.isDownloadingPDF = true;
    const downloadFilters: ICommonFilters = {
      filters: {
        timezone: currentTimeZone(),
        downloadPDF: true
      }
    };
    this.opdSummaryInfoService.downloadOPDSummary(this.data.appointmentId, this.opdSummaryInfo.opdSummarySectionConfig, downloadFilters).pipe(
      finalize(() => this.isDownloadingPDF = false)
    ).subscribe(response => {
      saveAs(response, 'opd-summary.pdf')
    }, error => {
      this.utilityService.displayErrorSnackbar(error);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.opdSummaryInfoService.snackBarRef) {
      this.opdSummaryInfoService.snackBarRef.dismiss();
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {IAttachment} from '@v2/core/models/masterdata';
import {ImagePreviewModalComponent} from '../../../../v2/shared-v2/components/image-preview-modal/image-preview-modal.component';

@Component({
  selector: 'app-doc-list',
  templateUrl: './doc-list.component.html',
  styleUrls: ['./doc-list.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class DocListComponent implements OnInit {
  @Input() attachments: IAttachment[] = [];
  @Input() showPreviewOnClick = false;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  onImageClicked(filePath: string) {
    if (this.showPreviewOnClick) {
      this.dialog.open(ImagePreviewModalComponent, {
        width: '80%',
        data: filePath
      });
    }
  }
}

import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {setFilter} from '@v2/core/functions/functions';
import {IBase, IPageableData, IStatusResponse} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {Observable} from 'rxjs';
import {RadiologyOrderPayload} from '../../components/patient-order/resources/models/payload/radiology-order.payload';

@Injectable()
export class RadiologyOrderHttpService {

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  getRadiologyOrders(appointmentId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<IPatientOrder>>> {
    return this.baseHttpService.get<IBase<IPageableData<IPatientOrder>>>(`v2/appointment/${appointmentId}/patient-orders/radiology`, setFilter(filters));
  }

  placeRadiologyOrder(payload: RadiologyOrderPayload): Observable<IBase<IPatientOrder>> {
    return this.baseHttpService.postWrapper<IBase<IPatientOrder>>(`v2/radiology/order`, payload, ConcurrencyScopeEnum.RADIOLOGY_PATIENT_ORDER, new HttpParams(), payload.uniqueIdForConcurrency);
  }

  updateRadiologyOrder(orderId: string, payload: RadiologyOrderPayload): Observable<IBase<IPatientOrder>> {
    return this.baseHttpService.putWrapper<IBase<IPatientOrder>>(`v2/radiology/order/${orderId}`, payload, ConcurrencyScopeEnum.RADIOLOGY_PATIENT_ORDER);
  }

  deleteRadiologyOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<IBase<IStatusResponse>> {
    const params = new HttpParams().append('appointmentId', appointmentId);
    return this.baseHttpService.deleteWrapper<IBase<IStatusResponse>>(`v2/radiology/order/${orderId}/updated-at/${updatedAt}`, ConcurrencyScopeEnum.RADIOLOGY_PATIENT_ORDER, params);
  }
}

import {formatDateTime, getFormattedDate} from '@v2/core/functions/functions';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IReturnedPatientOrderLog} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {BatchNumberViewModel} from '../../../shared-v2/resources/models/view/batch-number.view.model';

export class ReturnedPatientOrderViewModel extends ConcurrencyRequest {

  id: string;
  xref: string;
  itemXref: string;
  itemName: string;
  itemCategory: string;
  itemType: string;
  returnId: string;
  returnedDate: string;
  returnedTime: string;
  batchNumbers: BatchNumberViewModel[];
  unitItemPrice: number;
  returnedQty: number;
  dispensingUnit: string;
  pharmacyOutlet: string;
  returnedBy: string;
  note: string;
  orderXref: string;

  static mapFromReturnMedicineHttp(response: IReturnedPatientOrderLog): ReturnedPatientOrderViewModel {
    const medicine = new ReturnedPatientOrderViewModel();
    medicine.id = response.id;
    medicine.orderXref = response.planXref;
    if (response.catalogElement) {
      medicine.itemXref = response.catalogElement.xref;
      medicine.itemName = response.catalogElement.brandName || response.catalogElement.englishName;
      medicine.itemType = response.catalogElement.catalogCategory && response.catalogElement.catalogCategory.name;
      medicine.dispensingUnit = response.catalogElement.dispensingUnit ? response.catalogElement.dispensingUnit.label : '';
    }
    medicine.itemCategory = response.patientOrderType && response.patientOrderType.label;
    medicine.returnId = response.xref;
    medicine.returnedDate = response.returnDate && getFormattedDate(response.returnDate);
    medicine.returnedTime = response.returnDate && formatDateTime(response.returnDate, 'hh:mm a');
    medicine.batchNumbers = response.invReceivedItemForPatientOrder && response.invReceivedItemForPatientOrder.length ? response.invReceivedItemForPatientOrder.map((item) => BatchNumberViewModel.mapFromInventoryReceivedItem(item)) : [];
    medicine.unitItemPrice = response.unitItemPrice;
    medicine.returnedQty = response.returnQty;
    medicine.pharmacyOutlet = response.pharmacyOutlet && response.pharmacyOutlet.title;
    medicine.returnedBy = response.returnBy && response.returnBy.staffName;
    medicine.note = response.note;
    medicine.mapConcurrencyRequest(response);
    return medicine;
  }
}

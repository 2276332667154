import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UtilityService} from '@v2/core/services/utility.service';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {StaleActionEnum} from '../../shared-v2/resources/enums/stale-action.enum';

@Injectable({
  providedIn: 'root'
})
export class ConcurrencyService {

  eventHandler: Subject<{ key: string, uniqueKeyForConcurrency?: string }> = new Subject<{ key: string, uniqueKeyForConcurrency?: string }>();
  public refreshDataHandler: () => void;

  constructor(
    private utilityService: UtilityService,
    private snackBar: MatSnackBar,
    private toastr: ToastrService) {
  }

  showSnackBar(handler: () => void) {
    const activeToastr = this.toastr.error('error', 'error', {
      disableTimeOut: true,
      tapToDismiss: false
    });
    activeToastr.onAction.pipe(take(1)).subscribe((data) => {
      if (data === StaleActionEnum.Refresh) {
        handler();
      }
      this.toastr.remove(activeToastr.toastId);
    })
    return activeToastr;
  }

  handler(data: Array<{ scope: string, refreshDataHandler: () => void, uniqueId?: string }>, unsubscribe$: Subject<void>) {
    const keyEvent = this.eventHandler.pipe(takeUntil(unsubscribe$)).subscribe(({key, uniqueKeyForConcurrency}) => {
      if (key) {
        let t: { scope: string, refreshDataHandler: () => void, uniqueId?: string };
        if (uniqueKeyForConcurrency) {
          t = data.find(value => value.scope === key && value.uniqueId === uniqueKeyForConcurrency);
        } else {
          t = data.find(value => value.scope === key);
        }
        if (t) {
          const toast = this.showSnackBar(t.refreshDataHandler);
          keyEvent.add(() => {
            this.toastr.remove(toast.toastId);
          });
        }
      }
    });

  }

  handlerForSingle(data: { scope: string, refreshDataHandler: () => void, uniqueId?: string }, unsubscribe$: Subject<void>) {
    const subscription = this.eventHandler.pipe(takeUntil(unsubscribe$)).subscribe(({key, uniqueKeyForConcurrency}) => {
      if (key && ((key === data.scope && !uniqueKeyForConcurrency) || (uniqueKeyForConcurrency && key === data.scope && uniqueKeyForConcurrency === data.uniqueId))) {
        const toast = this.showSnackBar(data.refreshDataHandler);
        subscription.add(() => {
          this.toastr.remove(toast.toastId);
        });
      }
    });
  }

}

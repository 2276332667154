import {CommonModule, DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';

import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {RouterModule} from '@angular/router';
import {MdePopoverModule} from '@material-extended/mde';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {SignaturePadModule} from 'angular2-signaturepad';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import {Ng2FittextModule} from 'ng2-fittext';

import {FilePondModule, registerPlugin} from 'ngx-filepond';
import {NgxMaskModule} from 'ngx-mask';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {DateDisplayModule} from '../v2/shared-v2/components/date-display/date-display.module';
import {PageLoaderModule} from '../v2/shared-v2/components/page-loader/page-loader.module';
import {ProfilePicModule} from '../v2/shared-v2/components/profile-pic/profile-pic.module';

import {BaseListingComponent, UploadDocComponent, WarningModalComponent} from './common';
import {AppointmentInfoModule} from './common/appointment-info/appointment-info.module';
import {ArchivedDataModule} from './common/archived-data/archived-data.module';
import {DocListModule} from './common/doc-list/doc-list.module';
import {FilterButtonModule} from './common/filter-button/filter-button.module';
import {FormAddButtonModule} from './common/form-add-button/form-add-button.module';
import {DateMonthYearComponent} from './common/form-control/date-month-year/date-month-year.component';
import {SignatureControlModule} from './common/form-control/signature-control/signature-control.module';
import {InfoItemModule} from './common/info-item/info-item.module';
import {NoDataModule} from './common/no-data/no-data.module';
import {PaymentPriorityChipsModule} from './common/payment-priority-chips/payment-priority-chips.module';
import {SecondaryMenuModule} from './common/secondary-menu/secondary-menu.module';
import {SeverityStatusModule} from './common/severity-status/severity-status.module';
import {SignatureDisplayModule} from './common/signature-display/signature-display.module';
import {TableBadgeModule} from './common/table-badge/table-badge.module';
import {TimelineStatusModule} from './common/timeline-status/timeline-status.module';
import {ReadOnlyInputModule} from './components/read-only-input/read-only-input.module';
import {CommonDirectiveModule} from './directive/common-directive.module';
import {TrimControlDirective} from './directive/trim-control.directive';
import {LayoutModule} from './layout/layout.module';
import {MaterialModule} from './material.module';
import {CombineWithPipe} from './pipes';
import {CommonPipesModule} from './pipes/common-pipes/common-pipes.module';
import {PipesModule} from './pipes/pipes.module';
import {TimeDurationPipe} from './pipes/time-duration.pipe';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

@NgModule({
  declarations: [
    WarningModalComponent,
    BaseListingComponent,
    CombineWithPipe,
    DateMonthYearComponent,
    UploadDocComponent,
    TrimControlDirective,
    TimeDurationPipe,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PageLoaderModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    FlexLayoutModule,
    NgxDatatableModule,
    FilePondModule,
    MdePopoverModule,
    NgxMaterialTimepickerModule,
    NgxSkeletonLoaderModule,
    Ng2FittextModule,
    SignaturePadModule,
    NgxPaginationModule,
    SatDatepickerModule,
    SatNativeDateModule,
    FormAddButtonModule,
    InfoItemModule,
    PipesModule,
    DocListModule,
    LayoutModule,
    CommonPipesModule,
    FilterButtonModule,
    CommonDirectiveModule,
    ProfilePicModule,
    AppointmentInfoModule,
    TranslocoModule,
    ReadOnlyInputModule,
    ArchivedDataModule,
    TimelineStatusModule,
    NoDataModule,
    SignatureDisplayModule,
    SignatureControlModule,
    SecondaryMenuModule,
    // InvestigationElementModule,
    PipeModule,
    SeverityStatusModule,
    SwiperModule,
    TableBadgeModule,
    DateDisplayModule,
    PaymentPriorityChipsModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MaterialModule,
    PageLoaderModule,
    FlexLayoutModule,
    NgxDatatableModule,
    FilePondModule,
    MdePopoverModule,
    // ExpandMenuDirective,
    TrimControlDirective,
    BaseListingComponent,
    CombineWithPipe,
    NgxMaskModule,
    NgxMaterialTimepickerModule,
    NgxSkeletonLoaderModule,
    DateMonthYearComponent,
    UploadDocComponent,
    // SecondaryMenuComponent,
    SecondaryMenuModule,
    Ng2FittextModule,
    SignaturePadModule,
    NgxPaginationModule,
    SatDatepickerModule,
    SatNativeDateModule,
    TimeDurationPipe,
    FormAddButtonModule,
    InfoItemModule,
    PipesModule,
    DocListModule,
    LayoutModule,
    CommonPipesModule,
    FilterButtonModule,
    CommonDirectiveModule,
    ProfilePicModule,
    AppointmentInfoModule,
    TimelineStatusModule,
    NoDataModule,
    SignatureDisplayModule,
    SignatureControlModule,
    // InvestigationElementModule,
    SeverityStatusModule,
    TableBadgeModule,
    DateDisplayModule
  ],
  entryComponents: [
    WarningModalComponent
  ],
  providers: [
    DatePipe,
    {provide: TRANSLOCO_SCOPE, useValue: 'common'}
  ]
})
export class SharedModule {
}

import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {setFilter} from '@v2/core/functions/functions';
import {IBase, IPageableData, IStatusResponse} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {Observable} from 'rxjs';
import {PathologyOrderPayload} from '../../components/patient-order/resources/models/payload/pathology-order.payload';

@Injectable()
export class LabOrderHttpService {

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  getLabOrders(appointmentId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<IPatientOrder>>> {
    return this.baseHttpService.get<IBase<IPageableData<IPatientOrder>>>(`v2/appointment/${appointmentId}/patient-orders/pathology`, setFilter(filters));
  }

  placeLabOrder(payload: PathologyOrderPayload): Observable<IBase<IPatientOrder>> {
    return this.baseHttpService.postWrapper<IBase<IPatientOrder>>(`v2/pathology/order`, payload, ConcurrencyScopeEnum.PATHOLOGY_PATIENT_ORDER, new HttpParams(), payload.uniqueIdForConcurrency);
  }

  updateLabOrder(orderId: string, payload: PathologyOrderPayload): Observable<IBase<IPatientOrder>> {
    return this.baseHttpService.putWrapper<IBase<IPatientOrder>>(`v2/pathology/order/${orderId}`, payload, ConcurrencyScopeEnum.PATHOLOGY_PATIENT_ORDER);
  }

  deleteLabOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<IBase<IStatusResponse>> {
    const params = new HttpParams().append('appointmentId', appointmentId);
    return this.baseHttpService.deleteWrapper<IBase<IStatusResponse>>(`v2/pathology/order/${orderId}/updated-at/${updatedAt}`, ConcurrencyScopeEnum.PATHOLOGY_PATIENT_ORDER, params);
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'arraySlice'
})
export class ArraySlicePipe implements PipeTransform {

  transform(value: any, start: number, end?: number): Array<any> | null {
    if (value == null) {
      return null;
    }

    if (!Array.isArray(value)) {
      throw Error(`InvalidPipeArgument: '${value}' for pipe ArraySlicePipe`);
    }

    return value.slice(start, end);
  }
}

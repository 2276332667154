import {Injectable} from '@angular/core';
import {MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {TranslocoService} from '@ngneat/transloco';
import {NextAppointmentTypeEnum} from '@v2/core/enums/appointment-type.enum';
import {OpdSummarySectionEnum} from '@v2/core/enums/opd-summary-section.enum';
import {getStartOfDay, isArray, partitionArray, setFilter} from '@v2/core/functions/functions';
import {
  IBase,
  IBillingAppointment,
  IOPDSummary,
  IOPDSummarySectionConfig,
  IOPDSummarySectionItemConfig,
  IPatientDrugAllergy
} from '@v2/core/models/masterdata';
import {ICommonFilters, ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {OpdSummaryViewModel} from '@v2/core/models/view/appointment.view.model';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {UtilityService} from '@v2/core/services/utility.service';
import {forkJoin, Observable, of} from 'rxjs';
import {map, pluck, switchMap, take} from 'rxjs/operators';
import {AllergyTypesEnum} from '../../../v2/pharmacy/shared-pharamcy/resources/enum/allergy-type.enum';
import {PatientOrderService} from '../../../v2/shared-v2/components/patient-order/patient-order.service';
import {MedicationFormDataModel} from '../../../v2/shared-v2/components/patient-order/resources/models/form/medication-form.model';
import {NextAppointmentService} from '../../../v2/shared-v2/module/next-appointment/next-appointment.service';
import {AppointmentHttpService} from '../../../v2/shared-v2/resources/http-service/appointment.http.service';
import {PatientHttpService} from '../../../v2/shared-v2/resources/http-service/patient-http.service';

export interface OPDSummaryInfo {
  isLoading: boolean;
  isOrdersLoading: boolean;
  isNextAppointmentDataLoading: boolean;
  isPatientAllergiesLoading: boolean;
  opdSummaryData: OpdSummaryViewModel;
  opdSummarySectionConfig: IOPDSummarySectionConfig
}

@Injectable()
export class OpdSummaryInfoService {

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  opdSummaryInfo: OPDSummaryInfo = {} as OPDSummaryInfo;

  constructor(
    private masterDataHttpService: MasterDataHttpService,
    private patientOrderService: PatientOrderService,
    private patientHttpService: PatientHttpService,
    private utilityService: UtilityService,
    private translocoService: TranslocoService,
    private baseHttpService: BaseHttpService,
    private nextAppointmentService: NextAppointmentService,
    private appointmentHttpService: AppointmentHttpService
  ) {
  }

  getPatientAppointmentDetails(appointmentInfo: IBillingAppointment) {
    const appointmentId = appointmentInfo.appointmentId;
    const patient = appointmentInfo.patient
    const patientId = appointmentInfo.patient && appointmentInfo.patient.id
    const appointmentDate = appointmentInfo.aptScheduledDate;
    const department = appointmentInfo.departmentName;
    const doctor = appointmentInfo.doctorName;
    const visitXref = appointmentInfo.visitXref ? appointmentInfo.visitXref.xref : '';
    return {appointmentId, patientId, appointmentDate, patient, department, doctor, visitXref};
  }

  downloadOPDSummary(appointmentId: string, config: IOPDSummarySectionConfig, filters: ICustomFilters = {}): Observable<Blob> {
    return this.baseHttpService.getPDF(`v2/appointment/${appointmentId}/opd-summary`, setFilter(filters));
  }

  getOPDSummaryData(appointmentId: string): Observable<OpdSummaryViewModel> {
    return this.baseHttpService.get<IBase<IOPDSummary>>(`appointment/${appointmentId}/patient-medical-data`).pipe(
      pluck('data'),
      map(data => OpdSummaryViewModel.mapFromHttpModel(data))
    );
  }

  loadOPDSummary(opdSummaryInfo: OPDSummaryInfo, appointmentId: string, patientId: string, aptDate: string, fetchAppointmentInfo = false) {
    if (!fetchAppointmentInfo) {
      this.getOPDSummaryInfo(opdSummaryInfo, appointmentId, patientId, aptDate);
    } else {
      const filters: ICommonFilters = {filters: {includes: {patient: true, doctor: true, appointmentEvents: true, department: true}}};
      this.appointmentHttpService.getAppointmentInfoV2(appointmentId, filters, true, false).pipe(
        pluck('data')
      ).subscribe(response => {
        this.opdSummaryInfo = opdSummaryInfo;
        const {visitXref, appointmentDate, patient, department, doctor} = this.getPatientAppointmentDetails(response);
        this.opdSummaryInfo.opdSummaryData = OpdSummaryViewModel.getInitialModel(visitXref, appointmentDate, patient, department, doctor);
        this.getOPDSummaryInfo(this.opdSummaryInfo, appointmentId, patientId, appointmentDate);
      })
    }
  }

  getOPDSummaryInfo(opdSummaryInfo: OPDSummaryInfo, appointmentId: string, patientId: string, appointmentDate: string) {
    this.opdSummaryInfo = opdSummaryInfo;
    this.loadOPDSummaryData(appointmentId);
    this.loadPatientAllergies(patientId);
    this.loadOrdersData(appointmentId);
    this.loadNextAppointments(patientId, appointmentDate);
  }

  loadOPDSummaryData(appointmentId: string) {
    this.opdSummaryInfo.isLoading = true;
    this.getOPDSummaryData(appointmentId).pipe(
      take(1)
    ).subscribe(({labOrders, radiologyOrders, treatmentOrders, supplyOrders, ...rest}: OpdSummaryViewModel) => {
      this.opdSummaryInfo.opdSummaryData = {...this.opdSummaryInfo.opdSummaryData, ...rest};
      this.setConfigForMedicalDataItems();
      this.opdSummaryInfo.isLoading = false;
    }, () => {
      this.snackBarRef = this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Error occurred while loading data.', {}, 'common'), {duration: 0});
    });
  }

  loadPatientAllergies(patientId: string) {
    this.opdSummaryInfo.isPatientAllergiesLoading = true;
    this.masterDataHttpService.getAllergyTypes().pipe(
      take(1),
      switchMap(response => {
        const drugModel = response.find(allergyType => allergyType.label === AllergyTypesEnum.Drug);
        if (drugModel) {
          const filters: ICommonFilters = {filters: {filters: {allergyType: drugModel.key}}};
          return this.patientHttpService.getPatientAllergies(patientId, filters).pipe(pluck('data', 'items'));
        }
        return of(null);
      })
    ).subscribe((drugAllergy: IPatientDrugAllergy[]) => {
      if (isArray(drugAllergy)) {
        const confirmedDrugAllergies = drugAllergy.map(allergy => allergy.catalogElement ? allergy.catalogElement.brandName : '').filter(allergyName => !!allergyName);
        this.opdSummaryInfo.opdSummaryData = {...this.opdSummaryInfo.opdSummaryData, drugAllergy: confirmedDrugAllergies.join(', ')};
      }
      this.opdSummaryInfo.isPatientAllergiesLoading = false;
    }, () => {
      this.snackBarRef = this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Error occurred while loading data.', {}, 'common'), {duration: 0});
    });
  }

  loadOrdersData(appointmentId: string) {
    this.opdSummaryInfo.isOrdersLoading = true;
    const orderFilters: ICommonFilters = {filters: {page_size: 'all'}, page: 1};
    forkJoin(
      this.patientOrderService.getRadiologyOrders(appointmentId, orderFilters).pipe(pluck('items')),
      this.patientOrderService.getLabOrders(appointmentId, orderFilters).pipe(pluck('items')),
      this.patientOrderService.getMedicationOrders(appointmentId, null, orderFilters).pipe(pluck('items')),
      this.patientOrderService.getSupplyOrders(appointmentId, orderFilters).pipe(pluck('items'))
    ).pipe(
      take(1)
    ).subscribe(([radiologyOrders, labOrders, medicationOrders, supplyOrders]) => {
      const [prescriptionOrders, treatmentOrders] = partitionArray<MedicationFormDataModel>(medicationOrders, (order) => order.isHomeMedicine);
      this.opdSummaryInfo.opdSummaryData = {
        ...this.opdSummaryInfo.opdSummaryData,
        radiologyOrders,
        labOrders,
        supplyOrders,
        prescriptionOrders,
        treatmentOrders
      };
      this.setConfigForOrders();
      this.opdSummaryInfo.isOrdersLoading = false;
    }, () => {
      this.snackBarRef = this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Error occurred while loading data.', {}, 'common'), {duration: 0});
    });
  }

  loadNextAppointments(patientId: string, appointmentDate: string) {
    this.opdSummaryInfo.isNextAppointmentDataLoading = true;
    const filters: ICommonFilters = {
      filters: {
        filters: {
          isScheduledAndDraftAppts: true,
          apptsDateAfter: getStartOfDay(appointmentDate)
        }
      }
    };
    this.nextAppointmentService.getBookedAppointment(patientId, filters).pipe(
      take(1)
    ).subscribe(response => {
      const followUpAppointments = [];
      const admitRequests = [];
      const nextAppointments = [];
      if (isArray(response)) {
        response.forEach(appointment => {
          nextAppointments.push(appointment);
          if (appointment.visitType === NextAppointmentTypeEnum.FOLLOW_UP_VISIT) {
            followUpAppointments.push(appointment);
          } else {
            admitRequests.push(appointment);
          }
        })
      }
      this.opdSummaryInfo.opdSummaryData = {...this.opdSummaryInfo.opdSummaryData, nextAppointments, followUpAppointments, admitRequests};
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.NEXT_APPOINTMENT] = isArray(response) && response.length ? this.getConfig(false, true) : this.getConfig(true, false);
      this.opdSummaryInfo.isNextAppointmentDataLoading = false;
    }, () => {
      this.snackBarRef = this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Error occurred while loading data.', {}, 'common'), {duration: 0});
    });
  }

  getDefaultOpdSummarySectionConfig(): IOPDSummarySectionConfig {
    return {
      [OpdSummarySectionEnum.VITALS]: this.getConfig(true, false),
      [OpdSummarySectionEnum.CHIEF_COMPLAINT]: this.getConfig(true, false),
      [OpdSummarySectionEnum.PRESENT_ILLNESS]: this.getConfig(true, false),
      [OpdSummarySectionEnum.PHYSICAL_EXAMINATION_SYSTEMIC_REVIEW]: this.getConfig(true, false),
      [OpdSummarySectionEnum.NURSE_NOTE]: this.getConfig(true, false),
      [OpdSummarySectionEnum.INVESTIGATION]: this.getConfig(true, false),
      [OpdSummarySectionEnum.DIAGNOSIS]: this.getConfig(true, false),
      [OpdSummarySectionEnum.ADVICE_TREATMENT_PLAN]: this.getConfig(true, false),
      [OpdSummarySectionEnum.PRESCRIPTION]: this.getConfig(true, false),
      [OpdSummarySectionEnum.TREATMENT]: this.getConfig(true, false),
      [OpdSummarySectionEnum.SUPPLY]: this.getConfig(true, false),
      [OpdSummarySectionEnum.NEXT_APPOINTMENT]: this.getConfig(true, false),
    }
  }

  private getConfig(isDisabled: boolean, isSelected: boolean): IOPDSummarySectionItemConfig {
    return {isDisabled, isSelected};
  }

  private getConfigForItem<T>(data: Array<T>): IOPDSummarySectionItemConfig {
    return isArray(data) && data.length ? this.getConfig(false, true) : this.getConfig(true, false);
  }

  private setConfigForMedicalDataItems() {
    if (this.opdSummaryInfo.opdSummaryData) {
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.VITALS] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.vitals);
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.CHIEF_COMPLAINT] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.chiefComplaints);
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.PRESENT_ILLNESS] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.presentIllness);
      if (this.opdSummaryInfo.opdSummaryData.physicalExamination || this.opdSummaryInfo.opdSummaryData.systemReview) {
        this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.PHYSICAL_EXAMINATION_SYSTEMIC_REVIEW] = this.getConfig(false, true);
      } else {
        this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.PHYSICAL_EXAMINATION_SYSTEMIC_REVIEW] = this.getConfig(true, false);
      }
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.NURSE_NOTE] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.nurseNotes);
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.DIAGNOSIS] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.diagnosis);
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.ADVICE_TREATMENT_PLAN] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.doctorNotes);
    }
  }

  private setConfigForOrders() {
    if (this.opdSummaryInfo.opdSummaryData) {
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.SUPPLY] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.supplyOrders);
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.PRESCRIPTION] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.prescriptionOrders);
      this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.TREATMENT] = this.getConfigForItem(this.opdSummaryInfo.opdSummaryData.treatmentOrders);
      if ((isArray(this.opdSummaryInfo.opdSummaryData.labOrders) && this.opdSummaryInfo.opdSummaryData.labOrders.length) || (isArray(this.opdSummaryInfo.opdSummaryData.radiologyOrders) && this.opdSummaryInfo.opdSummaryData.radiologyOrders.length)) {
        this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.INVESTIGATION] = this.getConfig(false, true);
      } else {
        this.opdSummaryInfo.opdSummarySectionConfig[OpdSummarySectionEnum.INVESTIGATION] = this.getConfig(true, false);
      }
    }
  }
}

export const APP_SETTINGS = {
  app_version: '',
  api_url: '',
  api_prefix: '',
  client_id: '',
  client_secret: '',
  grant_type: '',
  pacs_registry_url: '',
  use_secure_websocket_bulk_upload: false,
  bulk_upload_websocket_url: '',
  bulk_upload_websocket_prefix: '',
  go_socket_prefix: ''
};

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {IData} from '@v2/core/models/masterdata';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IOperationalArea} from '../../../resources/models/http/IOperationalArea';

@Component({
  selector: 'app-notification-areas-dropdown',
  templateUrl: './notification-areas-dropdown.component.html',
  styleUrls: ['./notification-areas-dropdown.component.scss']
})
export class NotificationAreasDropdownComponent implements OnInit {

  @Input() control: FormControl;
  @Input() controlLabel: string;
  @Input() isFilter = false;
  @Input() isRequired = false;
  @Input() requiredMessage: string;
  @Input() reloadData = false;
  @Input() isEditMode = false;
  @Input() disabled = false;
  @Input() multiSelect = false;

  @Output() optionSelected = new EventEmitter<IOperationalArea>();
  @Output() responseReceived = new EventEmitter<IOperationalArea[]>();

  filter: ICommonFilters = {
    filters: {
      filters: {}
    }
  };

  constructor(
    private masterDataHttpService: MasterDataHttpService
  ) {
  }

  ngOnInit() {
  }

  apiCall = (filters: ICommonFilters): Observable<IData<IOperationalArea>> => {
    return this.masterDataHttpService.getNotificationAreas().pipe(
      map((response) => {
        this.responseReceived.emit(response.items && response.items.slice());
        const filteredItems = response.items.filter((item) => item.title !== 'All OA')
        return {...response, items: filteredItems};
      })
    )
  }

  getNotificationAreaId = (notificationArea: IOperationalArea) => notificationArea.id;

  getNotificationAreaName = (notificationArea: IOperationalArea) => notificationArea.title;

  onOptionSelected(selectedNotificationArea: unknown) {
    this.optionSelected.emit(selectedNotificationArea as IOperationalArea);
  }

}

export enum PatientOrderStatusEnum {
  REQUESTED = 'Requested',
  CHECKED_IN = 'CheckedIn',
  CANCELED = 'Canceled',
  COMPLETED = 'Completed',
  ORDERED = 'Ordered',
  OPEN = 'Open',
  VERIFIED = 'Verified',
  DECLINED = 'Declined',
  CONVERTED_TO_NORMAL = 'Converted To Normal',
  DETACHED = 'Detached'
}

export interface Items<T> {
  items: T[];
  deletedItems: string[]
  recordedDate?: string
}

export class PostModel<T, R> {
  constructor(
    public items: T[],
    public deletedItems: R[]
  ) {
  }
}

export class ListingQueryParam {
  constructor(
    public value: string | boolean,
    public type: string,
  ) {
  }
}

export interface IDateRange {
  startDate: string;
  endDate: string;
}

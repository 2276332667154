export enum PurchaseRequestStatusEnum {
  OPEN = 'Open',
  RFA = 'RFA',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
  PROCESSED = 'Processed (PR turned to PO)',
  PROCESSED_UI = 'Processed',
  COMPLETED = 'Complete'
}

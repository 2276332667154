import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatRadioChange} from '@angular/material';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {MasterData} from 'src/app/core';

@Component({
  selector: 'app-order-severity-status',
  templateUrl: './severity-status.component.html',
  styleUrls: ['./severity-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SeverityStatusComponent),
      multi: true
    }
  ]
})
export class SeverityStatusComponent implements OnInit, OnDestroy, ControlValueAccessor {
  subscriptions: Subscription[] = [];
  severity = new FormControl('');
  severityStatus: MasterData[] = [];
  @Output() change = new EventEmitter<any>();

  constructor(
    private masterDataHttpService: MasterDataHttpService
  ) {

    this.subscriptions.push(
      this.severity.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

  }

  get value(): any {
    return this.severity.value;
  }

  set value(value: any) {
    this.severity.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnInit() {
    this.masterDataHttpService.getOrderSeverityStatus().pipe(take(1)).subscribe(severityStatusList =>
      this.severityStatus = severityStatusList
    );
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  writeValue(value) {
    if (value === null) {
      this.severity.reset();
    }
    if (value) {
      this.value = value;
    }
  }

  onSeveritySelectionChange($event: MatRadioChange) {
    this.change.emit(this.severityStatus.find(status => status.key === $event.value))
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {

  transform(text: string, searchString: string): string {

    if (!searchString) {
      return text;
    }

    const pattern = searchString
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter(value => value.length > 0)
      .join('|');

    return text.replace(new RegExp(pattern, 'gi'), highlighted => `<mark>${highlighted}</mark>`);
  }
}

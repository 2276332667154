import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule, GridModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';

import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {TwoStepVerificationComponent} from './two-step-verification/two-step-verification.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, TwoStepVerificationComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FlexModule,
    GridModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterModule
  ]
})
export class AuthModule { }

import {ElementPriceEnum} from '@v2/core/enums/element-price.enum';
import {ICatalogElement, IMasterData, IMedication} from '@v2/core/models/masterdata';

export class PrescriptionMedicineViewModel {

  id: string;
  xref: string;
  brandName: string;
  genericName: string;
  category: string;
  dispensingUnit: string | IMasterData;
  strength: string;
  opdPrice?: number;
  ipdPrice?: number;
  baseSellingPrice?: number;
  masterCatalogPrice?: number;
  name?: string;
  route?: string;
  routeObj?: IMasterData;
  dosage?: number | string;
  dosageUnit?: IMasterData;
  frequencyType?: IMasterData;
  days?: number;
  intake?: IMasterData;
  usageTemplate?: string;
  usageMethod?: string;
  thaiUsageTemplate?: string;
  thaiUsageMethod?: string;

  static mapFromHttpModel(element: ICatalogElement | IMedication) {
    const opdPrice = element.elementPrices && element.elementPrices.find(price => price.priceType && price.priceType.label === ElementPriceEnum.opd)
    const ipdPrice = element.elementPrices && element.elementPrices.find(price => price.priceType && price.priceType.label === ElementPriceEnum.ipd)
    const baseSellingPrice = element.elementPrices && element.elementPrices.find(price => price.priceType && price.priceType.label === ElementPriceEnum.baseSelling)
    const masterCatalogPrice = element.elementPrices && element.elementPrices.find(price => price.priceType && price.priceType.label === ElementPriceEnum.masterCatalog)
    const viewData = new PrescriptionMedicineViewModel();
    viewData.id = element.id;
    viewData.xref = element.xref;
    viewData.brandName = element.brandName;
    viewData.genericName = element.genericName;
    viewData.category = element.catalogCategory && element.catalogCategory.name;
    viewData.dispensingUnit = element.dispenseUnit || element.dispensingUnit;
    viewData.strength = element.strengthUnit;
    viewData.opdPrice = opdPrice && opdPrice.price;
    viewData.ipdPrice = ipdPrice && ipdPrice.price;
    viewData.baseSellingPrice = baseSellingPrice && baseSellingPrice.price;
    viewData.masterCatalogPrice = masterCatalogPrice && masterCatalogPrice.price;
    viewData.name = element.brandName;
    viewData.route = element.route ? element.route.label : '';
    viewData.routeObj = element.route;
    viewData.dosage = element.dose;
    viewData.dosageUnit = element.dosageUnit;
    viewData.frequencyType = element.frequency;
    viewData.days = element.days;
    viewData.intake = element.intake;
    viewData.usageTemplate = element.englishTemplate;
    viewData.usageMethod = element.englishInstruction;
    viewData.thaiUsageTemplate = element.thaiTemplate;
    viewData.thaiUsageMethod = element.thaiInstruction;
    return viewData;
  }
}

import {CatalogTypeEnum} from '@v2/core/enums/catalog-type.enum';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IMedicalPackage, IMedicalPackageElement, IMedicalPackageVisit} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {CatalogElementViewModel, CatalogGroupViewModel} from './catalog-detail-view.model';

export class MedicalPackageListViewModel extends ConcurrencyRequest {
  id: string;
  xref: string;
  name: string;
  desc: string;
  note: string;
  startDate: string;
  endDate: string;
  opdApplicable: boolean;
  ipdApplicable: boolean;
  isActive: boolean;
  packageElements: PackageElementViewModel[];
  isStandardPrice: boolean;
  opdPrice: number;
  ipdPrice: number;
  customPrice: number;
  isPackageHasParts: boolean;
  countOfPackageParts: number;
  packageParts: PackagePartViewModel[];

  static mapFromApiModel(apiData: IMedicalPackage): MedicalPackageListViewModel {
    const packageElementData = this.groupPackageElements(apiData.packageElements);
    const viewModel = new MedicalPackageListViewModel();
    viewModel.id = apiData.id;
    viewModel.xref = apiData.xref;
    viewModel.name = apiData.name;
    viewModel.desc = apiData.desc;
    viewModel.note = apiData.note;
    viewModel.startDate = apiData.startDate;
    viewModel.endDate = apiData.endDate;
    viewModel.opdApplicable = apiData.opdApplicable;
    viewModel.ipdApplicable = apiData.ipdApplicable;
    viewModel.isActive = apiData.isActive;
    viewModel.packageElements = packageElementData && packageElementData.length && packageElementData.map(item => PackageElementViewModel.mapFromApiModel(item));
    viewModel.isStandardPrice = apiData.isStandardPrice;
    viewModel.opdPrice = apiData.opdPrice;
    viewModel.ipdPrice = apiData.ipdPrice;
    viewModel.customPrice = apiData.customPrice;
    viewModel.isPackageHasParts = apiData.isPackageHasParts;
    viewModel.countOfPackageParts = apiData.countOfPackageParts;
    viewModel.packageParts = apiData.packageParts && apiData.packageParts.map(item => PackagePartViewModel.mapFromApiModel(item));
    viewModel.mapConcurrencyRequest(apiData);
    return viewModel;
  }

  static groupPackageElements(apiData: IMedicalPackageElement[]): IMedicalPackageElement[] {
    const packageElementArray: IMedicalPackageElement[] = [];
    if (apiData && apiData.length) {
      apiData.map(packageElement => {
        if (!packageElement.catalogGroup) {
          packageElement.catalogElement.allowedOrderQty = packageElement.allowedOrderQty;
          packageElement.catalogElement.packageSelectionItemId = packageElement.id;
          packageElementArray.push(packageElement);
        } else {
          const medicalCatalogType = packageElement.catalogGroup && packageElement.catalogGroup.medicalCatalog && packageElement.catalogGroup.medicalCatalog.type && packageElement.catalogGroup.medicalCatalog.type.label;
          if (packageElement.catalogGroup && medicalCatalogType === CatalogTypeEnum.lab) {
            packageElement.catalogGroup.allowedOrderQty = packageElement.allowedOrderQty;
            packageElement.catalogGroup.packageSelectionItemId = packageElement.id;
            packageElementArray.push(packageElement);
          } else {
            const matchedPackageGroupElement = packageElementArray.find(packageElementData => (packageElementData.catalogGroup && packageElementData.catalogGroup.id) === (packageElement.catalogGroup && packageElement.catalogGroup.id));
            if (!matchedPackageGroupElement) {
              if (packageElement.catalogGroup.catalogElements && packageElement.catalogGroup.catalogElements.length) {
                const matchedCatalogElement = packageElement.catalogGroup.catalogElements.find(element => element.id === (packageElement.catalogElement && packageElement.catalogElement.id));
                if (matchedCatalogElement) {
                  matchedCatalogElement.allowedOrderQty = packageElement.allowedOrderQty;
                  matchedCatalogElement.medicalCatalog = packageElement.catalogGroup.medicalCatalog;
                  matchedCatalogElement.packageSelectionItemId = packageElement.id;
                }
                packageElementArray.push(packageElement);
              }
            } else {
              if (matchedPackageGroupElement.catalogGroup.catalogElements && matchedPackageGroupElement.catalogGroup.catalogElements.length) {
                const matchedCatalogElement = matchedPackageGroupElement.catalogGroup.catalogElements.find(element => element.id === packageElement.catalogElement.id);
                if (matchedCatalogElement) {
                  matchedCatalogElement.allowedOrderQty = packageElement.allowedOrderQty;
                  matchedCatalogElement.medicalCatalog = packageElement.catalogGroup.medicalCatalog;
                  matchedCatalogElement.packageSelectionItemId = packageElement.id;
                }
              }
            }
          }
        }
      });
    }

    return packageElementArray;
  }
}

export class PackageElementViewModel extends ConcurrencyRequest {

  id: string;
  xref: string;
  name: string;
  desc: string;
  allowedOrderQty: number;
  catalogGroup: CatalogGroupViewModel;
  catalogElement: CatalogElementViewModel;
  medicalCatalogType: string;
  medicalCatalogName: string

  static mapFromApiModel(apiData: IMedicalPackageElement): PackageElementViewModel {
    const viewModel = new PackageElementViewModel();
    const medicalCatalog = apiData.catalogElement ? (apiData.catalogElement.medicalCatalog) : (apiData.catalogGroup.medicalCatalog);
    viewModel.id = apiData.id;
    viewModel.xref = apiData.xref;
    viewModel.name = apiData.name;
    viewModel.desc = apiData.desc;
    viewModel.allowedOrderQty = apiData.allowedOrderQty ? apiData.allowedOrderQty : 0;
    viewModel.catalogGroup = apiData.catalogGroup && CatalogGroupViewModel.mapFromApiModel(apiData.catalogGroup, apiData.catalogGroup.medicalCatalog.name);
    viewModel.catalogElement = apiData.catalogElement && CatalogElementViewModel.mapFromApiModel(apiData.catalogElement, apiData.catalogElement.medicalCatalog.name);
    viewModel.medicalCatalogType = medicalCatalog && medicalCatalog.type && medicalCatalog.type.label
    viewModel.medicalCatalogName = medicalCatalog && medicalCatalog.name
    viewModel.mapConcurrencyRequest(apiData);
    return viewModel;
  }
}

export class PackagePartViewModel extends ConcurrencyRequest {
  id: string;
  xref: string;
  name: string;
  type: string;
  price: number;

  static mapFromApiModel(apiData: IMedicalPackageVisit): PackagePartViewModel {
    const viewModel = new PackagePartViewModel();
    viewModel.id = apiData.id;
    viewModel.xref = apiData.xref;
    viewModel.name = apiData.name;
    viewModel.type = apiData.type && apiData.type.label;
    viewModel.price = apiData.price;
    viewModel.mapConcurrencyRequest(apiData);
    return viewModel;
  }
}

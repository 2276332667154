import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from '@angular/material';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html'
})
export class LoadingButtonComponent implements OnInit {
  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() disabled = false;
  @Input() color: ThemePalette = 'primary';
  @Input() trigger = false;
  @Input() varient: 'flat' | 'stroked' | 'raised' = 'flat';
  @Input() cssClass = '';
  @Input() spinnerDiameter = 20;
  @Input() type: 'submit' | 'button' = 'button';

  constructor() {
  }

  ngOnInit() {
  }

  pressed(event: MouseEvent) {
    if (!this.disabled) {
      this.clicked.emit(event);
    }
  }
}

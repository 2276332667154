import {AppointmentStatusEnum} from '@v2/core/enums/appointment-status.enum';
import {BedTransferRequestStatusEnum} from '@v2/core/enums/bed-transfer-request-status.enum';
import {BedTransferRequestTypeEnum} from '@v2/core/enums/bed-transfer-request-type.enum';
import {getAddress, getCurrentDateTime, getName, getUTCDateTime, isAfterDate, isArray} from '@v2/core/functions/functions';
import {
  PatientNameTranslationV2,
  translatePatientFullNameExcludeMiddle,
  translatePatientName
} from '@v2/core/functions/patient-name-translation';
import {
  IAppointment,
  IMasterData,
  IPatient,
  IPatientDOBInfo,
  IPatientIdentification,
  IPaymentType,
  IWardMinimal
} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {PatientInfoModalViewModel} from '../../../../../shared-v2/components/patient-name-display/resources/models/view/patient-info-modal.view.model';
import {INoteAlertCount} from '../../../../../shared-v2/module/note-alert/resources/model/http/INoteAlert';
import {IAdmitTransferRequestHttp} from '../../http/admit-transfer-request/IAdmitTransferRequest.http';

export class AdmitTransferRequestViewModel extends ConcurrencyRequest implements INoteAlertCount {
  appointmentId: string;
  appointmentId_xref: string;
  admissionXref: string;
  aptScheduledDate: string;
  patientId: string;
  patientName: string;
  patientNameWithInitial: string;
  patientXref: string;
  patientProfilePic: string;
  patientContactNo: string;
  patientAge: number;
  patientEmail: string;
  patientGender: IMasterData;
  isBuddhistDOB: boolean
  patientBuddhistDOB: string
  patientAddress: string
  patientNationalIDIdentification: IPatientIdentification;
  patientOtherIDIdentification: IPatientIdentification;
  patientDOB: string;
  patientBloodGroup: string;
  patientReligion: IMasterData;
  requestId_xref: string;
  requestId: string;
  requestedDateTime: string;
  fromDepartmentName: string;
  fromDoctorName: string;
  toDepartmentId: string;
  toDepartmentName: string;
  toDoctorId: string;
  toDoctorName: string;
  severityStatusId: number;
  severityStatus: string;
  paymentType: Array<IPaymentType>;
  requestType: string;
  requestStatus: string;
  isPatientVerified: boolean;
  isPaymentVerified: boolean;

  buildingLocation: string;
  floorLocation: string;
  currentRoomType: string;
  requestedRoomType: string;
  note: string;
  preferredWard: string;
  wardNo: string;
  roomNo: string;
  bedNo: string;

  preferredWards: Array<IWardMinimal>;
  preferredRoomTypes: Array<IMasterData>;
  patient: IPatient;
  appointmentUpdatedAt: string;

  patientObj: PatientNameTranslationV2;
  patientDOBInfo: IPatientDOBInfo;
  schedulingAppointment: boolean;
  patientNationalId: string;
  otherEvidenceId: string;
  isFutureAppointment: boolean;
  isBillFinalized: boolean;
  alertNoteCount: number;

  static mapFromHttpModel(httpData: IAdmitTransferRequestHttp) {
    const viewData = new AdmitTransferRequestViewModel();
    viewData.appointmentId = httpData.appointmentId;
    viewData.appointmentId_xref = httpData.appointmentId_xref;
    if (httpData.patient) {
      if (httpData.patient.patientInfo) {
        const patientInfo = httpData.patient.patientInfo
        viewData.patientObj = patientInfo;
        viewData.patientDOBInfo = patientInfo;
        viewData.patient = patientInfo;
        viewData.patientId = patientInfo.patientId;
        viewData.patientName = translatePatientName(patientInfo);
        viewData.patientNameWithInitial = translatePatientFullNameExcludeMiddle(patientInfo);
        viewData.patientXref = patientInfo.patientId_xref;
        viewData.patientProfilePic = patientInfo.profilePicPath;
        viewData.patientContactNo = patientInfo.phoneNumber;
        viewData.patientEmail = patientInfo.email;
        viewData.patientAge = patientInfo.age;
        viewData.patientGender = patientInfo.gender;
        viewData.patientDOB = patientInfo.dob;
        viewData.patientBloodGroup = patientInfo.bloodGroup;
        viewData.patientReligion = patientInfo.religion;
        viewData.isBuddhistDOB = patientInfo.isBuddhistDOB
        viewData.patientBuddhistDOB = patientInfo.buddhistDOB && patientInfo.buddhistDOB.toString()
        viewData.patientAddress = patientInfo.address && patientInfo.address.length ? getAddress(patientInfo.address[0]) : null
      }
      viewData.paymentType = Array.isArray(httpData.paymentPriority) && httpData.paymentPriority || []
      if (viewData.paymentType.length === 0 && httpData.patient && Array.isArray(httpData.patient.paymentPlan)) {
        viewData.paymentType = httpData.patient.paymentPlan;
      }
      if (httpData.patient.identification && isArray(httpData.patient.identification)) {
        viewData.patientNationalIDIdentification = httpData.patient.identification.find(identification => identification.evidenceType.label === 'National ID');
        viewData.patientOtherIDIdentification = httpData.patient.identification.find(identification => identification.evidenceType.label !== 'National ID');
      }
    }
    viewData.requestId_xref = httpData.requestId_xref;
    viewData.requestId = httpData.requestId;
    viewData.requestedDateTime = httpData.requestDate;
    viewData.isFutureAppointment = isAfterDate(viewData.requestedDateTime, getCurrentDateTime());
    if (httpData.requestedByDoctor) {
      viewData.fromDoctorName = getName(httpData.requestedByDoctor.firstName, httpData.requestedByDoctor.lastName);
    }
    if (httpData.requestedByDepartment) {
      viewData.fromDepartmentName = httpData.requestedByDepartment.name;
    }
    viewData.toDepartmentId = httpData.departmentId;
    viewData.toDepartmentName = httpData.departmentName;
    viewData.toDoctorId = httpData.doctorId;
    viewData.toDoctorName = httpData.doctorName;
    if (httpData.severityStatus) {
      viewData.severityStatus = httpData.severityStatus.label;
      viewData.severityStatusId = httpData.severityStatus.key;
    }
    viewData.requestStatus = httpData.apptStatus ? httpData.apptStatus.label : '';
    viewData.requestType = httpData.requestType ? httpData.requestType.label : '';
    if (viewData.requestStatus === AppointmentStatusEnum.CHECKED_IN) {
      viewData.requestStatus = AppointmentStatusEnum.ADMITTED;
    }
    if (httpData.bedTransferData) {
      viewData.requestStatus = httpData.bedTransferData.bedTransferReqStatus ? httpData.bedTransferData.bedTransferReqStatus.label : '';
      viewData.requestType = httpData.bedTransferData.bedRequestType ? httpData.bedTransferData.bedRequestType.label : '';
      if (viewData.requestStatus === BedTransferRequestStatusEnum.COMPLETED || viewData.requestStatus === BedTransferRequestStatusEnum.IN_PROGRESS) {
        if (viewData.requestType === BedTransferRequestTypeEnum.TRANSFER_BED) {
          viewData.requestStatus = BedTransferRequestStatusEnum.TRANSFERRED;
        } else if (viewData.requestType === BedTransferRequestTypeEnum.RELEASE_BED) {
          viewData.requestStatus = BedTransferRequestStatusEnum.RELEASED;
        } else if (viewData.requestType === BedTransferRequestTypeEnum.ADD_BED) {
          viewData.requestStatus = BedTransferRequestStatusEnum.ADDED;
        }
      }
    }
    viewData.isPatientVerified = !!httpData.isPatientVerified;
    viewData.isPaymentVerified = !!httpData.isPaymentPlanVerified;
    if (httpData.currentRoom) {
      viewData.buildingLocation = httpData.currentRoom.wardLocationBuildingNo;
      viewData.floorLocation = httpData.currentRoom.wardLocationFloor;
      viewData.currentRoomType = httpData.currentRoom.roomType ? httpData.currentRoom.roomType.label : '';
    }
    if (httpData.currentBed) {
      viewData.wardNo = httpData.currentBed.bedWard;
      viewData.roomNo = httpData.currentBed.roomNo;
      viewData.bedNo = httpData.currentBed.bedNo;
    }
    viewData.requestedRoomType = httpData.requestedRoomType && httpData.requestedRoomType.label;
    viewData.note = httpData.note;
    viewData.preferredWard = httpData.currentWard && httpData.currentWard.title;
    viewData.preferredWards = httpData.preferredWards && httpData.preferredWards.length ? httpData.preferredWards.filter(ward => !!ward) : [];
    viewData.preferredRoomTypes = httpData.preferredRoomTypes && httpData.preferredRoomTypes.length ? httpData.preferredRoomTypes.filter(type => !!type) : [];
    viewData.admissionXref = httpData.admissionXref ? httpData.admissionXref.xref : '';
    viewData.aptScheduledDate = httpData.aptScheduledDate;
    viewData.mapConcurrencyRequest(httpData);
    viewData.schedulingAppointment = false;
    viewData.appointmentUpdatedAt = httpData.appointment && !!httpData.appointment.updatedAt ? getUTCDateTime(httpData.appointment.updatedAt) : undefined;
    viewData.isBillFinalized = httpData.isBillFinalized;
    return viewData;
  }

  static mapFromPatientInfoViewModel(data: PatientInfoModalViewModel): AdmitTransferRequestViewModel {
    const viewData = new AdmitTransferRequestViewModel();
    viewData.patientObj = data.patientObj;
    viewData.patientGender = data.gender
    viewData.patientBuddhistDOB = data.buddhistDOB;
    viewData.patientDOB = data.dob;
    viewData.patientReligion = data.religion;
    viewData.patientAge = data.age;
    viewData.patientXref = data.xref;
    viewData.patientNationalIDIdentification = data.nationalId;
    viewData.patientOtherIDIdentification = data.otherEvidenceId;
    viewData.patientContactNo = data.contactNo;
    viewData.patientAddress = data.address && data.address.length ? getAddress(data.address[0]) : null
    return viewData;
  }
}

export class RescheduledAdmitRequestViewModel {
  rescheduledDateTime: string;
  priorityStatus: string;

  static mapFromHttpModel(httpData: IAppointment): RescheduledAdmitRequestViewModel {
    const viewData = new RescheduledAdmitRequestViewModel();
    viewData.rescheduledDateTime = httpData.scheduleDate;
    viewData.priorityStatus = httpData.admissionXref && httpData.admissionXref.priority ? httpData.admissionXref.priority.label : '';
    return viewData;
  }
}

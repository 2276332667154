import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'combineWith'
})
export class CombineWithPipe implements PipeTransform {

  transform(address: any[], joinStr: string = ', '): any {
    let fullAddress = '';
    address = address.filter((obj) => obj);
    if (address.length) {
      fullAddress = address.join(joinStr);
    }
    return fullAddress;
  }

}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PharmacyStatusEnum} from '../../../../v2/shared-v2/resources/enums/pharmacy-status.enum';

@Component({
  selector: 'app-pharmacy-order-status-timeline',
  templateUrl: './pharmacy-order-status-timeline.component.html',
  styleUrls: ['./pharmacy-order-status-timeline.component.scss']
})
export class PharmacyOrderStatusTimelineComponent implements OnInit, OnChanges {

  @Input() groupOrderStatus: string;
  @Input() isPaymentCompleted = false;

  activeClassName = 'bg-green';
  inactiveClassName = 'bg-white border border-black-1 text-black-1';

  timelineData = [];

  constructor() { }

  ngOnInit() {
    this.generateOrderStatusTimeline();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.isPaymentCompleted && !changes.isPaymentCompleted.firstChange) ||
      (changes.groupOrderStatus && !changes.groupOrderStatus.firstChange && !!changes.groupOrderStatus.currentValue)
    ) {
      this.generateOrderStatusTimeline();
    }
  }

  generateOrderStatusTimeline() {
    const data = {
      [PharmacyStatusEnum.Open]: this.activeClassName,
      [PharmacyStatusEnum.Verified]: this.inactiveClassName,
      [PharmacyStatusEnum.Order_Prepared]: this.inactiveClassName,
      [PharmacyStatusEnum.Bill_Paid]: this.inactiveClassName,
      [PharmacyStatusEnum.Dispensed]: this.inactiveClassName,
    };

    if (this.groupOrderStatus) {
      if (this.groupOrderStatus !== PharmacyStatusEnum.Open) {
        data[PharmacyStatusEnum.Verified] = this.activeClassName;

        if (this.groupOrderStatus !== PharmacyStatusEnum.Verified) {
          data[PharmacyStatusEnum.Order_Prepared] = this.activeClassName;

          if (this.groupOrderStatus === PharmacyStatusEnum.Dispensed) {
            data[PharmacyStatusEnum.Dispensed] = this.activeClassName;
          }
          if (this.isPaymentCompleted) {
            data[PharmacyStatusEnum.Bill_Paid] = this.activeClassName;
          }
        }
      }
    }
    this.timelineData = Object.entries(data).map(([key, value]) => ({className: value, text: key}));
  }

}

import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {setFilter} from '@v2/core/functions/functions';
import {
  IAppointment,
  IAppointmentClaim,
  IAppointmentCompletionStatusAtStation,
  IAppointmentDoctorReviewStatus,
  IBase,
  IBillingAppointment,
  IData,
  IPageableData
} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, pluck, tap} from 'rxjs/operators';
import {PatientReorderViewModel} from '../../components/patient-order/resources/models/view/patient-order-operations.view.model';
import {IPatientInvestigationCount} from '../models/http/IPatientInvestigationCount';
import {BillingDailyChargesPayload, DailyChargesOrdersPayload} from '../models/payload/billing-daily-charges.payload';

@Injectable({
  providedIn: 'root'
})
export class AppointmentHttpService {

  appointmentInfo: IBase<IBillingAppointment>;
  appointmentData$ = new BehaviorSubject<IBillingAppointment>(null)
  private _visitCompletedStatusChange = new BehaviorSubject<IAppointmentCompletionStatusAtStation>({
    isCompletedAtNS: true,
    isCompletedAtDoctor: true
  });
  visitCompletedStatus$ = this._visitCompletedStatusChange.asObservable();

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  set visitCompletedAtNS(value: boolean) {
    const visitStatus = this._visitCompletedStatusChange.value;
    visitStatus.isCompletedAtNS = value;
    this._visitCompletedStatusChange.next(visitStatus);
  }

  set visitCompletedAtDR(value: boolean) {
    const visitStatus = this._visitCompletedStatusChange.value;
    visitStatus.isCompletedAtDoctor = value;
    this._visitCompletedStatusChange.next(visitStatus);
  }

  getAppointmentInfo(appointmentId: string, filters: ICustomFilters = {}): Observable<IBase<IAppointment>> {
    return this.baseHttpService.get<IBase<IAppointment>>(`appointment/${appointmentId}`, setFilter(filters));
  }

  getAppointmentInfoV2(appointmentId: string, filters: ICustomFilters = {}, fetchFromApi = true, storeAppointmentData = true): Observable<IBase<IBillingAppointment>> {
    if (!fetchFromApi && this.appointmentInfo) {
      return of(this.appointmentInfo);
    }
    return this.baseHttpService.get<IBase<IBillingAppointment>>(`v2/appointment/${appointmentId}`, setFilter(filters))
      .pipe(
        tap((response: IBase<IBillingAppointment>) => {
          if (storeAppointmentData) {
            this.setAppointmentInfoV2(response);
            this.setAppointmentData(response.data);
          }
        })
      );
  }

  setAppointmentData(data: IBillingAppointment) {
    this.appointmentData$.next(data);
  }

  getAppointmentData(): Observable<IBillingAppointment> {
    return this.appointmentData$.asObservable();
  }

  updateBillingDailyChargesForAppt(appointmentId: string, payload: BillingDailyChargesPayload): Observable<IBase<IAppointmentClaim>> {
    return this.baseHttpService.putWrapper<IBase<IAppointmentClaim>>(`billing-pipeline/${appointmentId}/update-billing-daily-bed-charges`, payload, ConcurrencyScopeEnum.BILLING_UPDATE_DAILY_BED_CHARGES);
  }

  setAppointmentInfoV2(appointmentInfo: IBase<IBillingAppointment>) {
    this.appointmentInfo = appointmentInfo;
  }

  checkForDoctorReview(params = new HttpParams()): Observable<Array<IAppointmentDoctorReviewStatus>> {
    return this.baseHttpService.get<IBase<IData<IAppointmentDoctorReviewStatus>>>(`v2/appointment/need-to-review/medicine`, params).pipe(
      pluck('data', 'items')
    );
  }

  refreshDailyChargesOrderForAppt(appointmentId: string, payload: DailyChargesOrdersPayload): Observable<IBase<IAppointmentClaim>> {
    return this.baseHttpService.put<IBase<IAppointmentClaim>>(`v2/ipd/appointment/${appointmentId}/refresh/daily-charge/orders`, payload);
  }

  getPathologyOrderCount(params = new HttpParams()): Observable<Array<IPatientInvestigationCount>> {
    return this.baseHttpService.get<IBase<IData<IPatientInvestigationCount>>>('v2/appointment/orders-count/pathology', params).pipe(pluck('data', 'items'))
  }

  getRadiologyOrderCount(params = new HttpParams()): Observable<Array<IPatientInvestigationCount>> {
    return this.baseHttpService.get<IBase<IData<IPatientInvestigationCount>>>('v2/appointment/orders-count/radiology', params).pipe(pluck('data', 'items'))
  }

  getAppointmentsForPatient(patientId: string, filters: ICustomFilters = {}): Observable<IPageableData<PatientReorderViewModel>> {
    return this.baseHttpService.get<IBase<IPageableData<IBillingAppointment>>>(`v2/patient/${patientId}/in-progress-closed/appointment`, setFilter(filters)).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const data = items.map(item => PatientReorderViewModel.mapFromAppointmentModel(item))
        return {items: data, ...pagination}
      }),
      catchError(() => of({items: []} as IPageableData<PatientReorderViewModel>))
    );
  }
}

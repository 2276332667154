import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {getStartOfDay, setFilter} from '@v2/core/functions/functions';
import {IBase, IDiagnosis, IDoctor, IMedication, IPageableData, IPharmacyOutlet} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IMedicalPackage, IMedicalPackagePartOrder} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IOrderStatus} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {DiagnosisViewModel} from '@v2/core/models/view/diagnosis.view.model';
import * as moment from 'moment';
import {Observable, Subject} from 'rxjs';
import {map, pluck, tap} from 'rxjs/operators';
import {BaseHttpService} from '../../../../core/http/base-http.service';
import {BaseModel, DataModel} from '../../../../core/http/models';
import {PostModel} from '../../../../core/models';
import {APP_SETTINGS} from '../../../../core/services/setting';
import {OrScheduleFilterModel} from '../../patient-order/models/re-schedule-model/filter/or-schedule.filter.model';
import {DrugAllergyApiModel, DrugInteractionApiModel} from '../models/api/drug-allergy-api.model';
import {MedicalPackageNoteApiModel, MedicalPackageOrderApiModel} from '../models/api/medical-package-order-api.model';
import {OrRequestFilterRoomApiModel} from '../models/api/operation-room/or-request-filter-room-api.model';
import {OrRequestScheduleApiModel} from '../models/api/operation-room/or-request-schedule-api.model';
import {MedicalPackageOrderFormModel} from '../models/form/medical-package-order-form.model';
import {SubStockValidationFormModel} from '../models/form/sub-stock-validation-form.model';
import {
  CancelMedicalPackagePartPayload,
  CancelMedicalPackagePayload,
  CancelOrDetachOrderPayload
} from '../models/post/cancel-or-detach-order.payload';
import {DrugInteractionPostModel} from '../models/post/drug-interaction-post.model';
import {MedicalPackageNotesPostModel} from '../models/post/medical-package-order-post.model';
import {SelectPackagePostModel} from '../models/post/select-package-post.model';
import {UtilizePackagePayload} from '../models/post/utilize-package.payload';
import {CurrentMedicationViewModel} from '../models/view/current-medical-view.model';
import {DoctorViewModel} from '../models/view/doctor-view.model';
import {DrugAllergyViewModel, DrugInteractionViewModel} from '../models/view/drug-allergy-view.model';
import {MedicalPackageListViewModel} from '../models/view/medical-package-list-view.model';
import {MedicalPackagePartOrderViewModel} from '../models/view/medical-package-part-order-view.model';
import {OrRequestRoomFilterViewModel} from '../models/view/operation-room/or-request-room-filter-view.model';
import {OrScheduleEventsViewModel} from '../models/view/operation-room/or-schedule-events-view.model';
import {PharmacyOutletViewModel} from '../models/view/pharmacy-outlet-view.model';

@Injectable({
  providedIn: 'root'
})
export class MedicalPackageHttpService {

  baseUrl = APP_SETTINGS.api_url;
  urlSeparator = APP_SETTINGS.api_prefix;
  private _medicalPackageUpdated = new Subject<{ pkgSelectionId: string, updatedAt: string }>();

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  get medicalPackageUpdated$(): Observable<{ pkgSelectionId: string, updatedAt: string }> {
    return this._medicalPackageUpdated.asObservable();
  }

  getCurrentMedication(patientId: string): Observable<CurrentMedicationViewModel[]> {
    const todayDT = getStartOfDay();
    return this.baseHttpService.get(`patient/${patientId}/patient-current-medication/${todayDT}`).pipe(
      pluck('data', 'items'),
      map((response: IMedication[]) => response.map(item => CurrentMedicationViewModel.mapFromApiModel(item)))
    )
  }

  getDrugAllergy(patientId: string, catalogElementId: string): Observable<DrugAllergyViewModel[]> {
    const filter = {
      filters: {
        filters: {
          catalogEleId: catalogElementId
        }
      }
    }
    return this.baseHttpService.get(`patient/${patientId}/patient-allergy`).pipe(
      pluck('data', 'items'),
      map((response: DrugAllergyApiModel[]) => response.map(item => DrugAllergyViewModel.mapFromApiModel(item)))
    )
  }

  getDrugInteraction(patientId: string, postData: DrugInteractionPostModel): Observable<DrugInteractionViewModel[]> {
    return this.baseHttpService.post(`patient/${patientId}/check-med-interaction-allergy`, postData).pipe(
      pluck('data'),
      map((response: DrugInteractionApiModel) => DrugInteractionViewModel.mapFromApiModel(response, postData.medicineId))
    )
  }

  getPharmacyOutletList(filter = {}): Observable<PharmacyOutletViewModel[]> {
    return this.baseHttpService.get(`v2/pharmacy/pharmacy-outlets`, setFilter(filter)).pipe(
      pluck('data', 'items'),
      map((response: IPharmacyOutlet[]) => response.map(item => PharmacyOutletViewModel.mapFromApiModel(item)))
    )
  }

  getOrRoom(): Observable<OrRequestRoomFilterViewModel[]> {
    const filterOptions = {
      filters: {
        filters: {
          requestedDT: moment().format()
        },
        page: 'all'
      }
    };
    return this.baseHttpService.get('operation-rooms', setFilter(filterOptions)).pipe(
      pluck('data', 'items'),
      map((response: OrRequestFilterRoomApiModel[]) => {
        return response.map(element => OrRequestRoomFilterViewModel.mapFromApiModel(element))
      })
    )
  }

  getOrSchedule(filters: OrScheduleFilterModel = {} as OrScheduleFilterModel) {
    const filterOptions = {
      filters: {
        data: filters
      }
    };
    return this.baseHttpService.get('operation-room/scheduled/list', setFilter(filterOptions)).pipe(
      pluck('data', 'items'),
      map((response: OrRequestScheduleApiModel[]) => {
        return response.map(element => OrScheduleEventsViewModel.mapFromApiModel(element, filters.surgeonId, filters.anesthesiologistId))
      })
    )
  }

  getSurgeonList(params = new HttpParams()): Observable<DoctorViewModel[]> {
    return this.baseHttpService.get(`doctors/surgeons`, params).pipe(
      pluck('data', 'items'),
      map((response: IDoctor[]) => {
        return response.map(surgeon => DoctorViewModel.mapFormApiModel(surgeon))
      })
    )
  }

  getAnesthesiologistsList(params = new HttpParams()): Observable<DoctorViewModel[]> {
    return this.baseHttpService.get(`doctors/anesthesiologists`, params).pipe(
      pluck('data', 'items'),
      map((response: IDoctor[]) => {
        return response.map(anesthesiologists => DoctorViewModel.mapFormApiModel(anesthesiologists))
      })
    )
  }

  getMedicalPackageList(params = new HttpParams()): Observable<MedicalPackageListViewModel[]> {
    return this.baseHttpService.get('packages', params).pipe(
      pluck('data', 'items'),
      map((response: IMedicalPackage[]) => {
        return response.map(item => MedicalPackageListViewModel.mapFromApiModel(item))
      })
    )
  }

  getMedicalPackageById(medicalPackageId: string): Observable<MedicalPackageListViewModel> {
    return this.baseHttpService.get(`package/${medicalPackageId}`).pipe(
      pluck('data'),
      map((response: IMedicalPackage) => MedicalPackageListViewModel.mapFromApiModel(response))
    )
  }

  getSelectedMedicalPackageById(appointmentId: string, pkgSelectionId: string): Observable<MedicalPackageOrderFormModel> {
    return this.baseHttpService.get(`medical-package-selection/${pkgSelectionId}`).pipe(
      pluck('data'),
      map((response: MedicalPackageOrderApiModel) => MedicalPackageOrderFormModel.mapFromApiModel(response, appointmentId))
    )
  }

  // region Get Selected Package API
  getNsOpdSelectedPackage(nsId: string, appointmentId: string): Observable<MedicalPackageOrderFormModel[]> {
    return this.baseHttpService.get(`opd/ns/${nsId}/appointment/${appointmentId}/assign/medical-packages`).pipe(
      pluck('data', 'items'),
      map((response: MedicalPackageOrderApiModel[]) => {
        return response.map(item => MedicalPackageOrderFormModel.mapFromApiModel(item, appointmentId))
      })
    )
  }

  getNsIpdSelectedPackage(nsId: string, appointmentId: string): Observable<MedicalPackageOrderFormModel[]> {
    return this.baseHttpService.get(`ipd/ns/${nsId}/admission-appointment/${appointmentId}/assign/medical-packages`).pipe(
      pluck('data', 'items'),
      map((response: MedicalPackageOrderApiModel[]) => {
        return response.map(item => MedicalPackageOrderFormModel.mapFromApiModel(item, appointmentId))
      })
    )
  }

  getDoctorOpdSelectedPackage(doctorId: string, appointmentId: string): Observable<MedicalPackageOrderFormModel[]> {
    return this.baseHttpService.get(`opd/doctor/${doctorId}/appointment/${appointmentId}/assign/medical-packages`).pipe(
      pluck('data', 'items'),
      map((response: MedicalPackageOrderApiModel[]) => {
        return response.map(item => MedicalPackageOrderFormModel.mapFromApiModel(item, appointmentId))
      })
    )
  }

  getDoctorIpdSelectedPackage(doctorId: string, appointmentId: string): Observable<MedicalPackageOrderFormModel[]> {
    return this.baseHttpService.get(`ipd/doctor/${doctorId}/admission-appointment/${appointmentId}/assign/medical-packages`).pipe(
      pluck('data', 'items'),
      map((response: MedicalPackageOrderApiModel[]) => {
        return response.map(item => MedicalPackageOrderFormModel.mapFromApiModel(item, appointmentId))
      })
    )
  }

  getOrSelectedPackage(orId: string, appointmentId: string): Observable<MedicalPackageOrderFormModel[]> {
    return this.baseHttpService.get(`operation-room/${orId}/appointment/${appointmentId}/assign/medical-packages`).pipe(
      pluck('data', 'items'),
      map((response: MedicalPackageOrderApiModel[]) => {
        return response.map(item => MedicalPackageOrderFormModel.mapFromApiModel(item, appointmentId))
      })
    )
  }

  // endregion

  // region Save Selected Package API

  saveNsOpdSelectedPackage(nsId: string, appointmentId: string, postData: SelectPackagePostModel) {
    return this.baseHttpService.postWrapper(`opd/ns/${nsId}/appointment/${appointmentId}/assign/medical-package`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  saveNsIpdSelectedPackage(nsId: string, appointmentId: string, postData: SelectPackagePostModel) {
    return this.baseHttpService.postWrapper(`ipd/ns/${nsId}/admission-appointment/${appointmentId}/assign/medical-package`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  saveDoctorOpdSelectedPackage(doctorId: string, appointmentId: string, postData: SelectPackagePostModel) {
    return this.baseHttpService.postWrapper(`opd/doctor/${doctorId}/appointment/${appointmentId}/assign/medical-package`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  saveDoctorIpdSelectedPackage(doctorId: string, appointmentId: string, postData: SelectPackagePostModel) {
    return this.baseHttpService.postWrapper(`ipd/doctor/${doctorId}/admission-appointment/${appointmentId}/assign/medical-package`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  saveOrSelectedPackage(orId: string, appointmentId: string, postData: SelectPackagePostModel) {
    return this.baseHttpService.postWrapper(`operation-room/${orId}/appointment/${appointmentId}/assign/medical-package`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  // endregion

  // region Save Note
  saveNsOpdNote(nsId: string, appointmentId: string, postData: MedicalPackageNotesPostModel) {
    return this.baseHttpService.postWrapper<BaseModel<DataModel<MedicalPackageNoteApiModel>>>(`opd/ns/${nsId}/appointment/${appointmentId}/assign/medical-package/notes`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  saveNsIpdNote(nsId: string, appointmentId: string, postData: MedicalPackageNotesPostModel) {
    return this.baseHttpService.postWrapper<BaseModel<DataModel<MedicalPackageNoteApiModel>>>(`ipd/ns/${nsId}/admission-appointment/${appointmentId}/assign/medical-package/notes`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  saveDoctorOpdNote(doctorId: string, appointmentId: string, postData: MedicalPackageNotesPostModel) {
    return this.baseHttpService.postWrapper<BaseModel<DataModel<MedicalPackageNoteApiModel>>>(`opd/doctor/${doctorId}/appointment/${appointmentId}/assign/medical-package/notes`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  saveDoctorIpdNote(doctorId: string, appointmentId: string, postData: MedicalPackageNotesPostModel) {
    return this.baseHttpService.postWrapper<BaseModel<DataModel<MedicalPackageNoteApiModel>>>(`ipd/doctor/${doctorId}/admission-appointment/${appointmentId}/assign/medical-package/notes`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  saveOrNote(orId: string, appointmentId: string, postData: MedicalPackageNotesPostModel) {
    return this.baseHttpService.postWrapper<BaseModel<DataModel<MedicalPackageNoteApiModel>>>(`operation-room/${orId}/appointment/${appointmentId}/assign/medical-package/notes`, postData, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT)
  }

  // endregion

  // region Get Diagnosis List
  getNsOpdDiagnosisList(nsId: string, appointmentId: string, filter = {}): Observable<DiagnosisViewModel[]> {
    return this.baseHttpService.get(`opd/ns/${nsId}/appointment/${appointmentId}/treatment/diagnosis`, setFilter(filter)).pipe(
      pluck('data', 'items'),
      map((response: IDiagnosis[]) => {
        return response.map(item => DiagnosisViewModel.mapFromHttpModel(item))
      })
    )
  }

  getNsIpdDiagnosisList(nsId: string, appointmentId: string, filter = {}): Observable<DiagnosisViewModel[]> {
    return this.baseHttpService.get(`ipd/ns/${nsId}/appointment/${appointmentId}/treatment/diagnosis`, setFilter(filter)).pipe(
      pluck('data', 'items'),
      map((response: IDiagnosis[]) => {
        return response.map(item => DiagnosisViewModel.mapFromHttpModel(item))
      })
    )
  }

  getDrOpdDiagnosisList(doctorId: string, appointmentId: string, filter = {}): Observable<DiagnosisViewModel[]> {
    return this.baseHttpService.get(`opd/dr/${doctorId}/appointment/${appointmentId}/treatment/diagnosis`, setFilter(filter)).pipe(
      pluck('data', 'items'),
      map((response: IDiagnosis[]) => {
        return response.map(item => DiagnosisViewModel.mapFromHttpModel(item))
      })
    )
  }

  getDrIpdDiagnosisList(doctorId: string, appointmentId: string, filter = {}): Observable<DiagnosisViewModel[]> {
    return this.baseHttpService.get(`ipd/dr/${doctorId}/appointment/${appointmentId}/treatment/diagnosis`, setFilter(filter)).pipe(
      pluck('data', 'items'),
      map((response: IDiagnosis[]) => {
        return response.map(item => DiagnosisViewModel.mapFromHttpModel(item))
      })
    )
  }

  getOrDiagnosisList(orId: string, appointmentId: string, filter = {}): Observable<DiagnosisViewModel[]> {
    return this.baseHttpService.get(`operation-room/${orId}/appointment/${appointmentId}/treatment/diagnosis`, setFilter(filter)).pipe(
      pluck('data', 'items'),
      map((response: IDiagnosis[]) => {
        return response.map(item => DiagnosisViewModel.mapFromHttpModel(item))
      })
    )
  }

  // endregion

  checkOrderStock(nsId: string, orId: string, postData: PostModel<SubStockValidationFormModel, any>): Observable<IOrderStatus[]> {
    const endpoint = orId ? `sub-stock/operation-room/${orId}/validate-item` : `sub-stock/nursing-station/${nsId}/validate-item`;
    return this.baseHttpService.post(endpoint, postData).pipe(
      pluck('data', 'items'),
      map((response: IOrderStatus[]) => response)
    )
  }

  validatePharmaStock(postData: PostModel<SubStockValidationFormModel, any>): Observable<IOrderStatus[]> {
    return this.baseHttpService.post(`sub-stock/pharmacy/validate-item`, postData).pipe(
      pluck('data', 'items'),
      map((response: IOrderStatus[]) => response)
    )
  }

  processCustomPackageOrder(appointmentId: string, pkgSelectionId: string, payload: UtilizePackagePayload): Observable<MedicalPackageOrderFormModel> {
    return this.baseHttpService.postWrapper<MedicalPackageOrderApiModel>(`v2/appointment/${appointmentId}/assign/medical-package/process-order`, payload, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT_ORDER).pipe(
      pluck('data'),
      map((response: MedicalPackageOrderApiModel) => {
        return MedicalPackageOrderFormModel.mapFromApiModel(response, appointmentId);
      }),
      tap(data => this._medicalPackageUpdated.next({pkgSelectionId, updatedAt: data && typeof data === 'object' && data.updatedAt}))
    );
  }

  utilizeCustomPackage(appointmentId: string, payload: UtilizePackagePayload): Observable<MedicalPackageOrderFormModel> {
    return this.baseHttpService.post<MedicalPackageOrderApiModel>(`v2/appointment/${appointmentId}/assign/medical-package/utilise`, payload).pipe(
      pluck('data'),
      map((response: MedicalPackageOrderApiModel) => {
        return MedicalPackageOrderFormModel.mapFromApiModel(response, appointmentId);
      })
    );
  }

  utilizeStandardPackage(appointmentId: string, pkgSelectionId: string, payload: UtilizePackagePayload): Observable<MedicalPackageOrderFormModel> {
    return this.baseHttpService.putWrapper<MedicalPackageOrderApiModel>(`v2/appointment/${appointmentId}/medical-package/selection/in-use`, payload, ConcurrencyScopeEnum.MEDICAL_PACKAGE_SUBMIT_ORDER).pipe(
      pluck('data'),
      map((response: MedicalPackageOrderApiModel) => {
        return MedicalPackageOrderFormModel.mapFromApiModel(response, appointmentId);
      }),
      tap(data => this._medicalPackageUpdated.next({pkgSelectionId, updatedAt: data && typeof data === 'object' && data.updatedAt}))
    );
  }

  getMedicalPackageOrders(filter: ICustomFilters = {}, includeDailyCharges = false, includeMedicalPackageTypeOrders = false): Observable<IPageableData<MedicalPackagePartOrderViewModel>> {
    return this.baseHttpService.get<IBase<IPageableData<IMedicalPackagePartOrder>>>(`v2/medical-package/orders`, setFilter(filter))
      .pipe(
        pluck('data'),
        map((response: IPageableData<IMedicalPackagePartOrder>) => {
          let {items, ...pagination} = response;
          if (!includeDailyCharges) {
            items = items.filter((item) => !item.isDailyCharge);
          }
          if (!includeMedicalPackageTypeOrders) {
            items = items.filter((item) => item.orderType.label !== OrderTypeEnum.MEDICAL_PACKAGE);
          }
          const data = MedicalPackagePartOrderViewModel.mapFromApiModel(
            items.map((item: IMedicalPackagePartOrder) => {
              item.orderTypeLabel = item.orderType.label;
              return item;
            }).filter((item) => item)
          )
          return {items: data, ...pagination};
        })
      );
  }

  cancelOrder(payload: CancelOrDetachOrderPayload): Observable<boolean> {
    return this.baseHttpService.postWrapper<IBase<boolean>>(`v2/medical-package/cancel-order`, payload, ConcurrencyScopeEnum.MEDICAL_PACKAGE_CANCEL_ORDER)
      .pipe(pluck('data'))
  }

  detachOrders(payload: CancelOrDetachOrderPayload): Observable<boolean> {
    return this.baseHttpService.postWrapper<IBase<boolean>>(`v2/medical-package/convert-to-normal-order`, payload, ConcurrencyScopeEnum.MEDICAL_PACKAGE_DETACH_ORDER)
      .pipe(pluck('data'))
  }

  cancelMedicalPackage(payload: CancelMedicalPackagePayload): Observable<boolean> {
    return this.baseHttpService.postWrapper<IBase<boolean>>('v2/medical-package/cancel', payload, ConcurrencyScopeEnum.MEDICAL_PACKAGE_CANCEL)
      .pipe(pluck('data'))
  }

  cancelMedicalPackagePart(payload: CancelMedicalPackagePartPayload): Observable<boolean> {
    return this.baseHttpService.postWrapper<IBase<boolean>>('v2/medical-package/cancel-package-part', payload, ConcurrencyScopeEnum.MEDICAL_PACKAGE_PART_CANCEL)
      .pipe(pluck('data'))
  }

  cancelMedicalPackageIfNotUsed(payload: CancelMedicalPackagePayload): Observable<MedicalPackageOrderApiModel> {
    return this.baseHttpService.postWrapper<IBase<MedicalPackageOrderApiModel>>('v2/medical-package/cancel-if-not-used', payload, ConcurrencyScopeEnum.MEDICAL_PACKAGE_CANCEL_IF_NOT_USED)
      .pipe(pluck('data'))
  }
}

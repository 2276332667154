import {FormControl} from '@angular/forms';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {getUserDisplayedOrderType} from '@v2/core/functions/functions';
import {ICatalogElement, ICatalogGroup, IDepartmentMinimal, IDoctor, IMasterData} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IFavoriteOrder, IFavoriteOrderElement} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {MedicationFormDataModel} from './medication-form.model';
import {NutritionMealFormDataModel} from './nutrition-meal-form.model';
import {PathologyFormDataModel} from './pathology-form.model';
import {ProcedureFormDataModel} from './procedure-form.model';
import {RadiologyFormDataModel} from './radiology-form.model';
import {SupplyFormDataModel} from './supply-form.model';

export class FavoriteFormDataModel extends ConcurrencyRequest {
  id: string;
  slipName: string;
  slipDesc: string;
  slipElements: IFavoriteOrderElement[];
  department: IDepartmentMinimal;
  doctor: IDoctor;

  static mapFromHttpModel(httpData: IFavoriteOrder): FavoriteFormDataModel {

    function getCatalogCategoryName(data: IFavoriteOrderElement) {
      let type = '';
      if (data.isCatalogGroup && data.catalogGroup) {
        type = data.catalogGroup.catalogCategory ? data.catalogGroup.catalogCategory.name : '';
      } else if (!data.isCatalogGroup && data.catalogElement) {
        type = data.catalogElement.catalogCategory ? data.catalogElement.catalogCategory.name : '';
      }
      return type;
    }

    function getCatalogCategoryId(data: IFavoriteOrderElement) {
      let type = '';
      if (data.isCatalogGroup && data.catalogGroup) {
        type = data.catalogGroup.catalogCategory ? data.catalogGroup.catalogCategory.id : '';
      } else if (!data.isCatalogGroup && data.catalogElement) {
        type = data.catalogElement.catalogCategory ? data.catalogElement.catalogCategory.id : '';
      }
      return type;
    }

    function getRoute(data: IFavoriteOrderElement) {
      if (data.orderType.label === OrderTypeEnum.Medication && data.catalogElement.route) {
        return data.catalogElement.route;
      }
      return null;
    }

    function getElementName(data: IFavoriteOrderElement) {
      return data.orderType.label === OrderTypeEnum.Medication ? data.catalogElement.brandName : data.catalogElement.name;
    }

    const formData = new FavoriteFormDataModel();
    formData.id = httpData.id;
    formData.slipName = httpData.slipName;
    formData.slipDesc = httpData.slipDesc;
    formData.slipElements = httpData.slipElements.map(element => {
      let catalogElement: ICatalogElement = null;
      let catalogGroup: ICatalogGroup = null;
      if (!element.isCatalogGroup) {
        catalogElement = {
          ...element.catalogElement,
          catalogCategoryName: getCatalogCategoryName(element),
          catalogCategoryId: getCatalogCategoryId(element),
          route: getRoute(element),
          name: getElementName(element)
        };
      } else {
        catalogGroup = {
          ...element.catalogGroup,
          catalogCategoryName: getCatalogCategoryName(element),
          catalogCategoryId: getCatalogCategoryId(element),
          route: getRoute(element)
        }
      }
      return {
        id: element.id,
        orderType: getUserDisplayedOrderType(element.orderType),
        isCatalogGroup: element.isCatalogGroup,
        catalogElement,
        catalogGroup,
        instruction: element.instruction,
        size: element.size,
        englishInstruction: element.englishInstruction
      }
    });
    formData.mapConcurrencyRequest(httpData);
    return formData;
  }
}

interface IFavoriteOrderForm {
  slipName: string | FormControl;
  procedureList: ProcedureFormDataModel[] | FormControl;
  radiologyList: RadiologyFormDataModel[] | FormControl;
  pathologyList: PathologyFormDataModel[] | FormControl;
  nutritionMealList: NutritionMealFormDataModel[] | FormControl;
  supplyList: SupplyFormDataModel[] | FormControl;
  medicationList: MedicationFormDataModel[] | FormControl;
}

export class FavoriteOrderForm implements IFavoriteOrderForm {
  slipName = new FormControl();
  procedureList = new FormControl([]);
  radiologyList = new FormControl([]);
  pathologyList = new FormControl([]);
  nutritionMealList = new FormControl([]);
  supplyList = new FormControl([]);
  medicationList = new FormControl([]);
}

export class FavoriteOrderFormDataModel implements IFavoriteOrderForm {
  slipName: string;
  procedureList: ProcedureFormDataModel[] = [];
  radiologyList: RadiologyFormDataModel[] = [];
  pathologyList: PathologyFormDataModel[] = [];
  nutritionMealList: NutritionMealFormDataModel[] = [];
  supplyList: SupplyFormDataModel[] = [];
  medicationList: MedicationFormDataModel[] = [];

  static mapFromFormModel(formData: FavoriteFormDataModel, normalSeverityStatusObject: IMasterData, currentUserId?: string): FavoriteOrderFormDataModel {
    const data = new FavoriteOrderFormDataModel();
    data.slipName = formData.slipName;
    formData.slipElements.forEach((element, index) => {
      switch (element.orderType.label) {
        case OrderTypeEnum.radiology:
          data.radiologyList.push(RadiologyFormDataModel.mapFromFavoriteOrder(formData, element, normalSeverityStatusObject));
          break;
        case OrderTypeEnum.lab:
          data.pathologyList.push(PathologyFormDataModel.mapFromFavoriteOrder(formData, element, normalSeverityStatusObject));
          break;
        case OrderTypeEnum.nutritionMeal:
          data.nutritionMealList.push(NutritionMealFormDataModel.mapFromFavoriteOrder(formData, element));
          break;
        case OrderTypeEnum.TreatmentPlanProcedure:
        case OrderTypeEnum.Procedure:
        case OrderTypeEnum.TreatmentProcedure:
          data.procedureList.push(ProcedureFormDataModel.mapFromFavoriteOrder(formData, element));
          break;
        case OrderTypeEnum.Supply:
          data.supplyList.push(SupplyFormDataModel.mapFromFavoriteOrder(formData, element, currentUserId));
          break;
        case OrderTypeEnum.Medication:
        case OrderTypeEnum.TreatmentPlan:
          data.medicationList.push(MedicationFormDataModel.mapFromFavoriteOrder(formData, element));
          break;
      }
    });
    return data;
  }
}

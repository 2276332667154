import {IPrimaryDoctorCheckerHttpModel} from '@v2/core/models/masterdata';

export class PatientCanDischargeOrTransferViewModel {
  constructor(
    public isPrimaryDoctor: boolean,
    public isConsultationAppt: boolean,
  ) {
  }

  static mapFromApiModel(element: IPrimaryDoctorCheckerHttpModel) {
    return new PatientCanDischargeOrTransferViewModel(
      element.isPrimaryDoctor,
      element.isConsultationAppt
    )
  }
}

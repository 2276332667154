import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {setFilter} from '@v2/core/functions/functions';
import {IAppUser} from '@v2/core/models/masterdata/IAppUser';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {Observable, Subject} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {BaseModel} from '../http/models';
import {AppUserViewModel} from '../models/app-user.view.model';
import {APP_SETTINGS} from './setting';

@Injectable()
export class InitService {
  private subject = new Subject<AppUserViewModel>();

  constructor(private httpClient: HttpClient, private handler: HttpBackend) {
  }

  getCurrentUser(): Observable<AppUserViewModel> {
    return this.subject.asObservable();
  }

  loadConfigs(): Promise<any> {
    return Promise.resolve()
      .then(() => this.loadEnvironmentConfigurations())
      .then(() => {
        const jwtToken = window.localStorage.jwtToken;
        if (!!jwtToken) {
          return this.getMyInfo()
            .pipe(
              map((value) => {
                this.subject.next(AppUserViewModel.mapFromApiModel(value));
                return value;
              })
            )
            .toPromise();
        } else {
          return new Promise<IAppUser>((resolve) => resolve());
        }
      });
  }

  private loadEnvironmentConfigurations(): Promise<any> {
    const http = new HttpClient(this.handler);

    return http
      .get<any>('/api/init')
      .toPromise()
      .then((setting) => {
        const data = setting.data;
        APP_SETTINGS.app_version = data.APP_VERSION;

        APP_SETTINGS.api_url = data.API_URL;
        APP_SETTINGS.api_prefix = data.API_PREFIX;

        APP_SETTINGS.pacs_registry_url = data.PACS_REGISTRY_KEY || 'pacshttp';
        APP_SETTINGS.use_secure_websocket_bulk_upload = data.USE_SECURE_WEBSOCKET_BULK_UPLOAD === 'true';
        APP_SETTINGS.bulk_upload_websocket_url = data.BULK_UPLOAD_WEBSOCKET_URL;
        APP_SETTINGS.bulk_upload_websocket_prefix = data.BULK_UPLOAD_WEBSOCKET_PREFIX;
        APP_SETTINGS.go_socket_prefix = data.GO_SOCKET_PREFIX;

        window.localStorage.ip4Token = data.ipV4Address;
        window.localStorage.ip6Token = data.ipV6Address;
      });
  }

  private getMyInfo(): Observable<IAppUser> {

    const filters: ICommonFilters = {filters: {includes: {hospital: {company: true}, roles: true}}};
    const params = setFilter(filters);

    const url = `${APP_SETTINGS.api_url}${APP_SETTINGS.api_prefix}login-user/roles-with-operational-area`;
    return this.httpClient.get<BaseModel<IAppUser>>(url, {params}).pipe(
      pluck('data')
    );
  }
}

export function checkNested(obj /*, level1, level2, ... levelN*/) {

  const args = Array.prototype.slice.call(arguments, 1);

  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;

}

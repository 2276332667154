import {Component} from '@angular/core';

@Component({
  selector: 'app-page-loader',
  template: `
    <div class="component-loader">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  `,
  styles: [`
    :host {
      min-height: 100px;
    }
  `]
})
export class PageLoaderComponent {

}

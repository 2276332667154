import {HttpParams} from '@angular/common/http';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MdePopoverTrigger} from '@material-extended/mde';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import * as moment from 'moment';
import {Observable, Subject} from 'rxjs';
import {pluck, share, tap} from 'rxjs/operators';
import {ApiResponse, ApiService} from 'src/app/core';

interface PageConfig {
  page?: number;
  filter_fields?: any;

  [name: string]: any;
}

@Component({
  selector: 'app-base-listing',
  template: '',
  styleUrls: ['./base-listing.component.scss']
})

export class BaseListingComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly headerHeight = 50;
  readonly footerHeight = 50;
  readonly rowHeight = 80;
  filterForm: FormGroup;
  response$: Observable<ApiResponse<any>>;
  items$: Observable<any>;
  itemsPerPage$: Observable<number>;
  totalItems$: Observable<number>;
  pageIndex$: Observable<number>;
  filterQueryParams: any = {};
  pageConfig: PageConfig = {};
  sortingOrder: any = {};
  getPaymentPlans = false;
  getPatientHistory = false;
  getTodayDataOnly = false;
  dischargeListing = false;
  isLoading = false;
  public fb = new FormBuilder();
  @ViewChild(MdePopoverTrigger, {static: true}) trigger: MdePopoverTrigger;
  protected service: ApiService;
  protected unsubscribe$ = new Subject<void>();
  protected model: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  initFilterForm() {
    const filterFormData: any = {};
    const filterFormkeys = (this.filterForm && this.filterForm.value) ? Object.keys(this.filterForm.value) : [];

    filterFormkeys.forEach((item, index) => {
      filterFormData[item] = '';
    });

    return filterFormData;
  }

  getFilterParams(queryParams) {
    let filterObject = {};

    if (!isEmpty(queryParams)) {
      // const filterFormData = this.initFilterForm();
      // const searchParam = [];
      // decode filter params
      const filterParamObj = (queryParams.filters) ? JSON.parse(atob(queryParams.filters)) : null;
      // filterObject = queryParamsToFilter(filterParamObj);
      // const filterFormObj = queryParamsToForm(filterParamObj);

      // if (filterParam) {
      //   const filterParamkeys = Object.keys(filterParam);
      //
      //   filterParamkeys.forEach((item, index) => {
      //     if (filterParam[item].value && !isEmpty(filterParam[item].value)) {
      //       let searchObj = {};
      //       filterFormData[item] = filterParam[item].value;
      //       searchObj = {key: item, value: filterParam[item].value};
      //       if (filterParam[item].type === 'search') {
      //         searchParam.push(searchObj);
      //       } else {
      //         filterParamArray.push(searchObj);
      //       }
      //     }
      //   });
      //   filterParamArray.push(filterParam);
      // }

      // if (Object.keys(filterParamObj).length > 0) {
      //   filterObject.filters = filterParamObj;
      // }
      //
      // if (searchParam.length > 0) {
      //   filterObject.search = searchParam;
      // }
      if (this.filterForm && !isEmpty(filterObject)) {
        this.filterForm.patchValue(filterObject, {emitEvent: false, onlySelf: true});
      } else if (this.filterForm) {
        this.filterForm.reset({}, {emitEvent: false, onlySelf: true});
      }

    } else if (this.filterForm && !this.getTodayDataOnly) {
      // Note - Commented to avoid form reset on default value setting
      this.filterForm.reset({}, {emitEvent: false, onlySelf: true});
    }

    return filterObject;

  }

  getParamsFromQueryParams(queryParams) {
    let param = {};
    const filterParam: any = this.getFilterParams(queryParams);

    if (!isEmpty(queryParams)) {
      // destructure query params
      const {page, sort_field, sort_dir, searchValue} = queryParams;

      const sort = [];
      if (searchValue) {
        filterParam.globalSearch = searchValue;
      }

      if (sort_field && sort_dir) {
        sort.push({key: sort_field, value: sort_dir});
      }

      param = pickBy({
        sort: sort.length ? sort : undefined,
        // search: filterParam.search,
        filters: Object.keys(filterParam).length ? filterParam : undefined
      }, identity);

      this.pageConfig = pickBy({page}, identity);

    }

    return param;
  }

  setPageConfig<T>(queryParams, endpoint) {
    this.pageConfig = {};
    let param = {};
    param = this.getParamsFromQueryParams(queryParams);
    this.getResponse<T>(endpoint, param);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  search(searchValue) {
    const searchValueParam = searchValue ? {searchValue} : {searchValue: null};
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: searchValueParam,
        queryParamsHandling: 'merge'
      });
  }

  getResponse<T>(endpoint, filterparam) {
    this.isLoading = true;

    const params: any = {};

    if (filterparam && Object.keys(filterparam).length > 0) {
      Object.keys(filterparam).forEach(key => {
        params[key] = JSON.stringify(filterparam[key])
      });
      // params.filters = JSON.stringify(filterparam);
    }

    if (this.dischargeListing) {
      const dischargeOptionsObj = {
        patient: true,
        doctor: true,
        department: true,
        admissionXref: true,
        ward: true,
        roomType: true,
        currentBed: true,
        currentRoom: true
      };
      if (params.filters) {
        params.filters = JSON.stringify({...JSON.parse(params.filters), ...{includes: dischargeOptionsObj}});
      } else {
        params.filters = JSON.stringify({includes: dischargeOptionsObj});
      }
    }

    if (this.getPaymentPlans) {
      const paymentplanObj = {
        patient: {
          paymentPlans: true,
          medicalHistory: true
        }
      };
      if (params.filters) {
        params.filters = JSON.stringify({...JSON.parse(params.filters), ...{includes: paymentplanObj}});
      } else {
        params.filters = JSON.stringify({includes: paymentplanObj});
      }
      // params.includes = JSON.stringify(paymentplanObj);
    }

    if (this.getPatientHistory) {
      // medicalHistory
    }

    if (this.getTodayDataOnly) {
      const dayFilterObj = {
        from: moment().startOf('day').format(),
        to: moment().endOf('day').format()
      };
      if (params.filters) {
        const filtersObj = JSON.parse(params.filters);
        if (filtersObj.filters) {
          params.filters = JSON.stringify({filters: {...filtersObj.filters, ...dayFilterObj}})
        } else {
          params.filters = JSON.stringify({...filtersObj, filters: dayFilterObj})
        }
      } else {
        params.filters = JSON.stringify({filters: dayFilterObj});
      }
    }

    Object.keys(this.pageConfig)
      .forEach((key) => {
        params[key] = this.pageConfig[key] && this.pageConfig[key].toString();
      });

    this.response$ = this.service.getAll<ApiResponse<T>>(endpoint, '', new HttpParams({fromObject: params}))
      .pipe(
        tap(() => this.isLoading = false),
        share()
      );

    this.itemsPerPage$ = this.response$.pipe(pluck('itemsPerPage'));
    this.totalItems$ = this.response$.pipe(pluck('totalItems'));
    this.pageIndex$ = this.response$.pipe(pluck('pageIndex'));
    this.items$ = this.response$.pipe(
      pluck('items'),
      share(),
    );

  }

  setFilter(params) {
    const encode = btoa(JSON.stringify(params));
    this.router.navigate([], {queryParams: {filters: encode, page: 1}, queryParamsHandling: 'merge'});
  }

  setPage(pageInfo) {
    const {offset: page} = pageInfo;
    this.router.navigate([], {queryParams: {page: page + 1}, queryParamsHandling: 'merge'});
  }

  onSort(sortInfo) {
    // tslint:disable-next-line:variable-name
    const {prop: sort_field, dir: sort_dir} = sortInfo.sorts[0];
    this.router.navigate([], {queryParams: {sort_field, sort_dir, page: 1}, queryParamsHandling: 'merge'});
  }

}

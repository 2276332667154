export enum FacilityResourceTypeEnum {
  BED = 'Bed',
  OPERATION_TABLE = 'Operation Table',
  EQUIPMENT = 'Equipment',
  IPD_NS = 'IPD Nursing Station',
  OPD_NS = 'OPD Nursing Station',
  OPD = 'OPD',
  IPD = 'IPD',
  RADIOLOGY_RECEPTION = 'Radiology Reception',
  LAB_RECEPTION = 'Lab Reception',
  PHARMACY_RECEPTION = 'Pharmacy Reception',
  OR_RECEPTION = 'OR Reception',
  FRONT_DESK = 'Front Desk',
  OTHER = 'Other'
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatDialogModule, MatTooltipModule} from '@angular/material';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {PipesModule} from '../../pipes/pipes.module';
import {DocListModule} from '../doc-list/doc-list.module';
import {InfoItemModule} from '../info-item/info-item.module';
import {PaymentPlanSingleChipComponent} from './components/payment-plan-single-chip/payment-plan-single-chip.component';
import {PaymentPlanDetailModalComponent} from './payment-type-plan-chip/payment-plan-detail-modal/payment-plan-detail-modal.component';
import {PaymentTypePlanChipComponent} from './payment-type-plan-chip/payment-type-plan-chip.component';

@NgModule({
  declarations: [PaymentTypePlanChipComponent, PaymentPlanSingleChipComponent, PaymentPlanDetailModalComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    InfoItemModule,
    DocListModule,
    TranslocoModule,
    MatMenuModule,
    MatChipsModule,
    PipesModule,
    PipeModule,
    MatDialogModule,
    MatTooltipModule
  ],
  exports: [PaymentPlanSingleChipComponent, PaymentTypePlanChipComponent],
  entryComponents: [PaymentPlanDetailModalComponent],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class PaymentPriorityChipsModule {
}

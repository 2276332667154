import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {getCurrentDateTime, getEndOfDay, getFormattedDate, isSameDate} from '@v2/core/functions/functions';
import {UtilityService} from '@v2/core/services/utility.service';
import {BaseSubscriptionHandlerClass} from '@v2/core/subscription-handler/subscription-handler.class';
import * as _ from 'lodash';
import {finalize} from 'rxjs/operators';
import {FILTER_DROPDOWN_ALL_VALUE} from '../../../components/filter/filter-form.model';
import {MasterDataEnum} from '../../../resources/enums/master-data.enum';
import {IOperationalArea} from '../../../resources/models/http/IOperationalArea';
import {NoteAlertService} from '../note-alert.service';
import {NoteAlertFormDataModel, NoteAlertFormModel} from '../resources/model/form/note-alert-form.model';
import {INoteAlert} from '../resources/model/http/INoteAlert';
import {NoteAlertPayload} from '../resources/model/payload/note-alert.payload';

@Component({
  selector: 'app-add-edit-note-alert-modal',
  templateUrl: './add-edit-note-alert-modal.component.html',
  styleUrls: ['./add-edit-note-alert-modal.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class AddEditNoteAlertModalComponent extends BaseSubscriptionHandlerClass implements OnInit {

  noteAlertFormGroup: FormGroup;
  isSubmitting = false;
  currentDate = getCurrentDateTime();
  allOAId: string;
  masterDataEnum = MasterDataEnum;
  showLoader = false;
  deletedNotificationAreas: string[] = [];
  noteAlertDetails: NoteAlertFormDataModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      patientId: string,
      appointmentId: string,
      currentOAId: string,
      alertNoteId: string,
      allowVisitRestriction: boolean
    },
    private matDialogRef: MatDialogRef<AddEditNoteAlertModalComponent>,
    private formBuilder: FormBuilder,
    private noteAlertService: NoteAlertService,
    private utilityService: UtilityService,
    private translocoService: TranslocoService
  ) {
    super();
  }

  get endDateControl(): FormControl {
    return this.noteAlertFormGroup.get('validityEndDT') as FormControl;
  }

  get severityControl(): FormControl {
    return this.noteAlertFormGroup.get('severity') as FormControl;
  }

  get onlyForCurrentAppointmentControl(): FormControl {
    return this.noteAlertFormGroup.get('onlyForCurrentAppointment') as FormControl;
  }

  get notificationAreasControl(): FormControl {
    return this.noteAlertFormGroup.get('notificationArea') as FormControl;
  }

  ngOnInit() {
    this.initForm();
    if (this.data.alertNoteId) {
      this.getNoteAlert();
    }
  }

  getNoteAlert() {
    this.showLoader = true;
    this.noteAlertService.getNoteAlertById(this.data.alertNoteId)
      .pipe(this.pickUntil(), finalize(() => this.showLoader = false))
      .subscribe((response) => {
        this.noteAlertDetails = _.cloneDeep(response);
        this.currentDate = response.validityStartDT;
        this.noteAlertFormGroup.patchValue(response);
        this.updateEndDateControlValidator(response.onlyForCurrentAppointment);
      }, (error) => {
        this.utilityService.displayErrorSnackbar(error);
      })
  }

  initForm() {
    this.noteAlertFormGroup = this.formBuilder.group(new NoteAlertFormModel())
  }

  patchAlertToValue() {
    let notificationAreaValue;
    if (this.data.alertNoteId && this.noteAlertDetails.notificationArea && this.noteAlertDetails.notificationArea.length) {
      const index = this.noteAlertDetails.notificationArea.findIndex((notificationArea) => notificationArea === this.allOAId);
      if (index !== -1) {
        notificationAreaValue = [FILTER_DROPDOWN_ALL_VALUE];
      } else {
        notificationAreaValue = this.noteAlertDetails.notificationArea
      }
      this.notificationAreasControl.patchValue(notificationAreaValue);
    }
  }

  onValidForThisVisitOnlyClicked(isChecked = false) {
    this.updateEndDateControlValidator(isChecked);
  }

  updateEndDateControlValidator(isValidForThisVisitOnly = false) {
    this.endDateControl.setValidators(isValidForThisVisitOnly ? null : [Validators.required])
    this.endDateControl.updateValueAndValidity();
  }

  onNotificationAreasResponseReceived(notificationAreas: IOperationalArea[]) {
    if (notificationAreas && notificationAreas.length) {
      const allOA = notificationAreas.find((area: IOperationalArea) => area.title === 'All OA');
      if (allOA) {
        this.allOAId = allOA.id;
        this.patchAlertToValue();
      }
    }
  }

  onNoteAlertSave() {
    if (this.noteAlertFormGroup.invalid) {
      return;
    }
    this.isSubmitting = true;
    const payload = this.getNoteAlertPayload();
    let api = this.noteAlertService.addNoteAlert(payload);
    if (this.data.alertNoteId) {
      api = this.noteAlertService.updateNoteAlert(this.data.alertNoteId, payload);
    }
    api.pipe(this.pickUntil(), finalize(() => this.isSubmitting = false)).subscribe((response: INoteAlert) => {
      this.utilityService.showSnackBarCloseLabel(this.translocoService.translate((this.data.alertNoteId ? 'Updated' : 'Added') + ' successfully', {}, 'common'));
      this.closeModal(response);
    }, (error) => {
      this.utilityService.displayErrorSnackbar(error);
    })
  }

  getNoteAlertPayload(): NoteAlertPayload {
    const formValue = this.noteAlertFormGroup.value;
    if (formValue && formValue.notificationArea.findIndex(notificationArea => notificationArea === FILTER_DROPDOWN_ALL_VALUE) !== -1) {
      formValue.notificationArea = [this.allOAId];
      if (this.data.alertNoteId && this.noteAlertDetails.notificationArea && this.noteAlertDetails.notificationArea.length && !this.noteAlertDetails.notificationArea.includes(this.allOAId)) {
        this.deletedNotificationAreas = this.noteAlertDetails.notificationArea;
      }
    } else if (this.data.alertNoteId) {
      const savedNotificationsAreas = this.noteAlertDetails.notificationArea && this.noteAlertDetails.notificationArea.slice();
      if (savedNotificationsAreas) {
        this.deletedNotificationAreas = savedNotificationsAreas.filter((item) => !formValue.notificationArea.includes(item));
      }
    }
    if (formValue.validityEndDT) {
      if (!!this.data.alertNoteId) {
        if (!isSameDate(formValue.validityEndDT, this.noteAlertDetails.validityEndDT)) {
          formValue.validityEndDT = getEndOfDay(formValue.validityEndDT);
        } else {
          formValue.validityEndDT = getFormattedDate(formValue.validityEndDT);
        }
      } else {
        formValue.validityEndDT = getEndOfDay(formValue.validityEndDT);
      }
    }
    return NoteAlertPayload.mapFromFormModel(this.data.patientId, this.data.appointmentId, formValue, this.deletedNotificationAreas, !!this.data.alertNoteId);
  }

  closeModal(data?) {
    this.matDialogRef.close(data);
  }

}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';
import {PaginatedDropdownControlModule} from '../../paginated-dropdown-control.module';
import {MasterDataDropdownComponent} from './master-data-dropdown.component';

@NgModule({
  declarations: [MasterDataDropdownComponent],
  exports: [MasterDataDropdownComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    PaginatedDropdownControlModule
  ]
})
export class MasterDataDropdownModule { }

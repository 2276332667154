import {FormArray, FormControl, Validators} from '@angular/forms';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {
  getCurrentDateTime,
  getCurrentTime,
  getStartOfDay,
  getTimeFromDate,
  getUTCDateTime,
  mapDepartmentDataToDepartmentMinimal,
  mapFullNameInDoctor,
  objectValidator
} from '@v2/core/functions/functions';
import {ICatalogElement, ICatalogGroup, IDepartmentMinimal, IDoctor, IMasterData} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IMedicalPackage} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IFavoriteOrderElement, IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {FavoriteFormDataModel} from './favorite-form.model';

interface INutritionMealOrderForm {
  id: string | FormControl;
  mealCategory: IMasterData | FormControl;
  mealType: IMasterData | FormControl;
  mealName: ICatalogElement | FormControl;
  preferredDate: string | FormControl;
  preferredTime: string | FormControl;
  orderNote: string | FormControl;
  orderType: string | FormControl;
  orderStatus: string | FormControl;
  catalogGroupId?: string | FormControl;
  catalogGroupName?: string | FormControl;
  isMedicalPackageOrder: boolean | FormControl;
  isGroup?: string | FormControl;
  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
  requestedDateTime: string | FormControl;
  orderOrigin: string | FormControl;

  isSubmitting?: boolean | FormControl;
  isSubmitted?: boolean | FormControl;
  errorMessage?: string | FormControl;
  isAdded?: boolean | FormControl;
  index?: number | FormControl;
  isFormValid?: boolean | FormControl;
}

export class NutritionMealOrderForm implements INutritionMealOrderForm {
  id = new FormControl();
  mealCategory = new FormControl(null, Validators.required);
  mealType = new FormControl(null, Validators.required);
  mealName = new FormControl(null, [Validators.required, objectValidator]);
  preferredDate = new FormControl(getCurrentDateTime(), Validators.required);
  preferredTime = new FormControl(getCurrentTime(), Validators.required);
  orderNote = new FormControl();
  orderType = new FormControl(OrderTypeEnum.nutritionMeal);
  orderStatus = new FormControl();
  catalogGroupId = new FormControl();
  catalogGroupName = new FormControl();
  isMedicalPackageOrder = new FormControl();
  isGroup = new FormControl(false);
  updatedAt = new FormControl();
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  requestedDateTime = new FormControl();
  orderOrigin = new FormControl();
  isSubmitting = new FormControl();
  isSubmitted = new FormControl();
  errorMessage = new FormControl();
  isAdded = new FormControl(false);
  index = new FormControl();
  isFormValid = new FormControl(false);
}

export class NutritionMealFormDataModel extends ConcurrencyRequest implements INutritionMealOrderForm {
  id: string;
  mealCategory: IMasterData;
  mealType: IMasterData;
  mealName: ICatalogElement;
  preferredDate: string;
  preferredTime: string;
  requestedDateTime: string;
  orderNote: string;
  orderType: string;
  orderOrigin: string;
  orderStatus: string;
  isMedicalPackageOrder: boolean;
  department: IDepartmentMinimal;
  doctor: IDoctor;

  updatedAt: string;

  catalogGroupId: string;
  catalogGroupName: string;

  recordedTime: string;
  index?: number;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  errorMessage?: string;
  isAdded?: boolean;
  isFormValid: boolean;
  pkgSelectionItemUpdatedAt: string;
  medicalPackage: IMedicalPackage;
  packageSelectionItemId: string;

  static mapFromHttpModel(httpData: IPatientOrder): NutritionMealFormDataModel {
    const formData = new NutritionMealFormDataModel();
    formData.id = httpData.id;
    formData.mealCategory = httpData.mealCategory;
    formData.mealType = httpData.mealType;
    formData.pkgSelectionItemUpdatedAt = httpData.pkgSelectionItem ? getUTCDateTime(httpData.pkgSelectionItem.pkgSelectionItemUpdatedAt) : undefined;
    formData.mealName = httpData.catalogElement;
    formData.preferredDate = getStartOfDay(httpData.preferredDateTime);
    formData.preferredTime = getTimeFromDate(httpData.preferredDateTime);
    formData.requestedDateTime = httpData.requestedDateTime;
    formData.orderNote = httpData.note;
    formData.orderType = httpData.orderType ? httpData.orderType.label : '';
    formData.orderOrigin = httpData.orderOrigin ? httpData.orderOrigin.label : '';
    formData.orderStatus = httpData.mealOrderStatus && httpData.mealOrderStatus.label;
    formData.isMedicalPackageOrder = httpData.isPkgOrder;
    formData.medicalPackage = httpData.medicalPackage;
    formData.packageSelectionItemId = httpData.packageSelectionItemId;
    formData.doctor = mapFullNameInDoctor(httpData.orderingDoctor);
    formData.department = mapDepartmentDataToDepartmentMinimal(httpData.orderingDepartment);
    formData.isSubmitted = null;
    formData.errorMessage = null;
    formData.mapConcurrencyRequest(httpData);
    return formData;
  }

  static mapFromGroupOrderModel(groupOrderData: NutritionMealGroupFormDataModel): NutritionMealFormDataModel[] {
    const data: NutritionMealFormDataModel[] = [];
    if (groupOrderData && groupOrderData.group && Array.isArray(groupOrderData.group.catalogElements)) {
      groupOrderData.group.catalogElements.map(item => {
        const formData = new NutritionMealFormDataModel();
        formData.id = null;
        formData.mealCategory = null;
        formData.mealType = null;
        formData.mealName = item;
        formData.preferredDate = getStartOfDay();
        formData.preferredTime = getCurrentTime();
        formData.orderNote = item.notes;
        formData.orderType = OrderTypeEnum.nutritionMeal;
        formData.orderStatus = null;
        formData.catalogGroupId = groupOrderData.group.groupId;
        formData.catalogGroupName = groupOrderData.group.groupName;
        formData.doctor = groupOrderData.doctor;
        formData.department = groupOrderData.department;
        formData.isSubmitted = null;
        formData.isFormValid = false;
        data.push(formData);
      })
    }
    return data;
  }

  static mapFromFavoriteOrder(favoriteData: FavoriteFormDataModel, element: IFavoriteOrderElement): NutritionMealFormDataModel {
    const formData = new NutritionMealFormDataModel();
    formData.id = null;
    formData.mealCategory = null;
    formData.mealType = null;
    formData.mealName = {id: element.catalogElement.id, name: element.catalogElement.name} as ICatalogElement;
    formData.preferredDate = getStartOfDay();
    formData.preferredTime = getCurrentTime();
    formData.orderNote = element.instruction;
    formData.orderType = OrderTypeEnum.nutritionMeal;
    formData.doctor = favoriteData.doctor;
    formData.department = favoriteData.department;
    formData.orderStatus = null;
    formData.isSubmitted = null;
    return formData;
  }
}

interface INutritionMealGroupOrderForm {
  group: ICatalogGroup | FormControl;
  groupElement: string | FormArray;
  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
  isGroup: boolean | FormControl;
}

export class NutritionMealGroupOrderForm implements INutritionMealGroupOrderForm {
  group = new FormControl(null, [Validators.compose([objectValidator])]);
  groupElement = new FormArray([]);
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  isGroup = new FormControl(true);
  updatedAt = new FormControl();
}

export class NutritionMealGroupFormDataModel extends ConcurrencyRequest implements INutritionMealGroupOrderForm {
  department: IDepartmentMinimal;
  doctor: IDoctor;
  group: ICatalogGroup;
  groupElement: string;
  isGroup: boolean;
  updatedAt: string
}

import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NumberValidators extends Validators {

  static min(prm: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value !== undefined && (!isNaN(c.value)) && c.value <= prm) {
        return {
          isMin: true
        };
      }
      return null;
    };
  }

  static max(prm: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value !== undefined && (!isNaN(c.value)) && c.value >= prm) {
        return {
          isMax: true
        };
      }
      return null;
    };
  }

  numberInput(control: AbstractControl) {
    const numberRegx: RegExp = /^[0-9]*$/;
    return numberRegx.test(control.value) ? null : {numberInput: {valid: false}};
  }

}


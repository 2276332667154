import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslocoModule} from '@ngneat/transloco';
import {LottieModule} from 'ngx-lottie';
import {ClearBrowserCacheModalComponent} from './clear-browser-cache-modal.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    ClearBrowserCacheModalComponent
  ],
  entryComponents: [
    ClearBrowserCacheModalComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    TranslocoModule,
    LottieModule.forRoot({player: playerFactory})
  ]
})
export class ClearBrowserCacheModalModule {
}

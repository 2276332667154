import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'severityStatusClass'
})
export class SeverityStatusClassPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value === 'Urgent' ? 'text-burnt-sienna' : 'text-japanese-laurel'
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {OpdSummarySectionEnum} from '@v2/core/enums/opd-summary-section.enum';
import {getCurrentDateTime} from '@v2/core/functions/functions';
import {IOPDSummarySectionConfig} from '@v2/core/models/masterdata';
import {OpdSummaryViewModel} from '@v2/core/models/view/appointment.view.model';
import {BaseSubscriptionHandlerClass} from '@v2/core/subscription-handler/subscription-handler.class';
import {AuthService} from '../../../../../core';
import {AppUserViewModel} from '../../../../../core/models/app-user.view.model';
import {HOSPITAL_ADDRESS, HOSPITAL_CONTACT, HOSPITAL_LOGO, HOSPITAL_NAME} from '../../../../helpers/app-const';

@Component({
  selector: 'app-opd-summary-info',
  templateUrl: './opd-summary-info.component.html',
  styleUrls: ['./opd-summary-info.component.scss']
})
export class OpdSummaryInfoComponent extends BaseSubscriptionHandlerClass implements OnInit {

  hospitalLogo = HOSPITAL_LOGO;
  hospitalName = HOSPITAL_NAME;
  hospitalContact = HOSPITAL_CONTACT;
  hospitalAddress = HOSPITAL_ADDRESS;
  opdSummarySectionEnum = OpdSummarySectionEnum;

  currentDateTime = getCurrentDateTime();
  currentUserData: AppUserViewModel;

  @Input() sectionConfig: IOPDSummarySectionConfig;
  @Input() opdSummaryData: OpdSummaryViewModel;
  @Input() setDefaultBackgroundColor = false;

  constructor(
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit() {
    this.authService.currentUser.pipe(this.pickFirstOrUntil()).subscribe(data => this.currentUserData = data);
  }

}

import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export function FadeInLeft(timeout: number = 350) {
  return trigger('fadeInLeft', [
    transition(':enter', [
      animate(
        `${timeout}ms`,
        keyframes([
          style({transform: 'translate3d(-100%, 0, 0)', opacity: 0, offset: 0}),
          style({transform: 'translateZ(0)', opacity: 1, offset: 1})
        ])
      )
    ])
  ]);
}

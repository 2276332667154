import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {BaseSubscriptionHandlerClass} from '@v2/core/subscription-handler/subscription-handler.class';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-signature-control',
  templateUrl: './signature-control.component.html',
  styleUrls: ['./signature-control.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SignatureControlComponent),
    multi: true
  }, {
    provide: TRANSLOCO_SCOPE,
    useValue: 'common'
  }]
})
export class SignatureControlComponent extends BaseSubscriptionHandlerClass implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() controlTitle: string;
  @Input() isRequired: boolean;
  @Input() showTypes = true;
  @Input() showLabel = true;
  @Output() drawCompleteEvent = new EventEmitter<boolean>();
  @ViewChild(SignaturePad, {static: false}) public signaturePad: SignaturePad;
  @ViewChild('signatureTextRef', {static: false}) public signatureTextRef: ElementRef;
  @ViewChild('textCanvas', {static: false}) public textCanvas: ElementRef;


  signatureType = 'draw';
  signatureText = '';
  imgURL: any;
  file: File | null = null;
  signaturePadOptions = {
    minWidth: 0.5,
    canvasWidth: 711,
    canvasHeight: 300
  };
  signatureData: any;
  txtQueryChanged: Subject<string> = new Subject<string>();
  flag = true;
  canvasFontSize: number;

  constructor() {
    super();
  }

  get value(): any {
    return this.signatureData;
  }

  set value(value: any) {
    this.signatureData = value;
    this.onChange(value);
    this.onTouched();
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const fileObj = event && event.item(0);
    if (fileObj instanceof File) {
      this.file = fileObj;
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = ($event) => {
        this.imgURL = reader.result;
        this.writeValue(reader.result);
      }
    }
  }

  ngOnInit() {
    this.txtQueryChanged.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      this.pickUntil()
    ).subscribe(model => {
      this.generateImgFromText(model);
    })
  }

  ngAfterViewInit(): void {
    this.signaturePad.set('canvasWidth', this.signatureTextRef.nativeElement.clientWidth);
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    } else {
      this.value = null;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  switchType(newType: string) {
    this.writeValue(null);
    this.signatureType = newType;
    setTimeout(() => {
      if (this.signatureType === 'draw' && this.signaturePad) {
        this.signaturePad.set('canvasHeight', this.signatureTextRef.nativeElement.clientHeight);
        this.signaturePad.set('canvasWidth', this.signatureTextRef.nativeElement.clientWidth);
        this.signaturePad.set('minWidth', 0.5);
      }
    }, 100);
  }

  clearOldSignature() {
    this.writeValue(null);
    this.flag = false;
  }

  clearSignature() {
    switch (this.signatureType) {
      case 'draw':
        this.signaturePad.clear();
        this.clearOldSignature();
        break;
      case 'type':
        this.signatureText = '';
        break;
      case 'image':
        this.imgURL = null;
        this.file = null;
        this.writeValue(null);
    }
  }

  drawComplete() {
    this.writeValue(this.signaturePad.toDataURL());
    this.drawCompleteEvent.emit(true)
  }

  generateImgFromText(text) {
    const canvas = this.textCanvas.nativeElement.getContext('2d');
    this.clearTextCanvas(canvas);
    canvas.fillStyle = '#555555';
    canvas.font = `${this.canvasFontSize}px Roboto`;
    // We set the size of our canvas accordingly to the width of our text
    this.textCanvas.nativeElement.width = canvas.measureText(text).width;
    // Since our context has been reset, we have to reset its font as well
    canvas.font = `${this.canvasFontSize}px Roboto`;
    canvas.fillText(text, 0, 50);
    const dataURL = canvas.canvas.toDataURL('image/png');
    this.writeValue(dataURL);
  }

  clearTextCanvas(ctx: CanvasRenderingContext2D) {
    const {clientWidth, clientHeight} = this.textCanvas.nativeElement;
    ctx.clearRect(0, 0, clientWidth, clientHeight);
  }

  signatureTextInput(text: string) {
    this.txtQueryChanged.next(text);
  }

  setFontSizeForCanvas(fontSize: unknown) {
    this.canvasFontSize = fontSize as number;
  }

  handleFileUpload(file: HTMLInputElement) {
    file.value = null;
    file.click();
  }
}

import {ADMIN_ROLE, isArray} from '@v2/core/functions/functions';
import {generatePatientObjFromFullName, PatientNameTranslation} from '@v2/core/functions/patient-name-translation';
import {IAppUser, IPermissions} from '@v2/core/models/masterdata/IAppUser';

export class AppUserViewModel {

  id: string;
  medicalPartyId: string;
  medicalPartyType: string;
  fullName: string;
  userRole: string;
  operationAreas: Array<string>;
  companyId: string;
  companyName: string;
  profilePic: string;
  qualification: string[];
  designation: string[];
  licenseNum: string;
  userObj: PatientNameTranslation;
  companyTaxId: number;
  permissions: IPermissions;
  isAllowRealTimeSynchronisation: boolean;

  constructor() {
  }

  static mapFromApiModel(item: IAppUser): AppUserViewModel {
    const viewModel = new AppUserViewModel();

    viewModel.id = item.id;
    viewModel.medicalPartyId = item.medicalParty && item.medicalParty.id;
    viewModel.medicalPartyType = item.medicalPartyType && item.medicalPartyType.label;
    viewModel.fullName = item.fullName
    viewModel.userRole = (Array.isArray(item.hospitalTeams) && item.hospitalTeams.length > 0 && item.hospitalTeams[0].name) || '-'
    viewModel.operationAreas = this.getOperationAreas(item.permissions)
    viewModel.companyId = (item.hospital && item.hospital.company) && item.hospital.company.id
    viewModel.companyName = (item.hospital && item.hospital.company) && item.hospital.company.name
    viewModel.profilePic = item.medicalParty && item.medicalParty.profilePicPath
    viewModel.qualification = item.medicalParty && item.medicalParty.qualifications && item.medicalParty.qualifications.map(ele => ele.label)
    viewModel.designation = item.medicalParty && item.medicalParty.qualifications && item.medicalParty.qualifications.map(ele => ele.label)
    viewModel.licenseNum = item.medicalParty && item.medicalParty.licenseNo
    viewModel.userObj = generatePatientObjFromFullName(item.fullName)
    viewModel.companyTaxId = (item.hospital && item.hospital.company) && item.hospital.company.taxId
    viewModel.permissions = item.permissions;
    viewModel.isAllowRealTimeSynchronisation = isArray(item.hospitalTeams) ? item.hospitalTeams.some(team => {
      return isArray(team.roles) && team.roles.some(role => {
        return role.securityRole && role.securityRole.name && role.securityRole.name.toLowerCase() === ADMIN_ROLE && role.securityRole.isAllowRealTimeSynchronisation
      })
    }) : false;

    return viewModel;
  }

  static getOperationAreas(permissions: IPermissions): Array<string> {
    return Object.keys(permissions) || [];
  }
}

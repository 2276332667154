import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {IMedicalPackagePartOrder} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import _ from 'lodash';
import {MedicalPackageOrderActionEnum} from '../../enums/action-type-enum';
import {CatalogElementViewModel, CatalogGroupViewModel} from './catalog-detail-view.model';

export class MedicalPackagePartOrderViewModel {
  orderType: string;
  orders: MedicalPackagePartOrdersListViewModel[];
  isPartialSelectedForCancelOrDetach: boolean;
  isSelectedForCancelOrDetach: boolean;

  static mapFromApiModel(apiData: IMedicalPackagePartOrder[]): MedicalPackagePartOrderViewModel[] {
    return _.chain(apiData)
      .groupBy('orderTypeLabel')
      .map((value, key) => ({orderType: key, orders: value.map(item => MedicalPackagePartOrdersListViewModel.mapFromApiModel(item))}))
      .value();
  }
}

export class MedicalPackagePartOrdersListViewModel {
  id: string
  xref: number
  orderType: string
  orderOrigin: string
  orderStatus: string
  orderQty: number
  requestedDateTime: string
  statusNote: string
  isPkgOrder: boolean
  orderBillingStatus: string
  orderThrough: string
  packageSelectionItemId: string
  packageSelectionPartId: string
  packageOrderStatus: string
  updatedAt: string
  catalogGroup: CatalogGroupViewModel;
  catalogElement: CatalogElementViewModel;
  catalogGroupElement: CatalogElementViewModel;

  isPartialSelectedForCancelOrDetach: boolean;
  isSelectedForCancelOrDetach: boolean;
  isSubmitting: boolean;
  isSubmitted: boolean;
  hasError: boolean;
  errorMessage: string;
  isDailyCharge: boolean;
  parentId: string;
  returnQty: number;
  orderDirection: string;
  isCancelling: boolean;
  isDetaching: boolean;
  isCancelled: boolean;
  isDetached: boolean;

  static mapFromApiModel(apiData: IMedicalPackagePartOrder): MedicalPackagePartOrdersListViewModel {
    const viewModel = new MedicalPackagePartOrdersListViewModel();

    viewModel.id = apiData.id
    viewModel.orderStatus = apiData.orderStatus && apiData.orderStatus.label;
    viewModel.xref = apiData.xref;
    viewModel.isDailyCharge = apiData.isDailyCharge;
    viewModel.orderType = apiData.orderType && apiData.orderType.label;
    viewModel.orderOrigin = apiData.orderOrigin && apiData.orderOrigin.label
    viewModel.orderQty = apiData.orderQty
    viewModel.requestedDateTime = apiData.requestedDateTime
    viewModel.statusNote = apiData.statusNote
    viewModel.isPkgOrder = apiData.isPkgOrder;
    viewModel.returnQty = apiData.returnQty;
    viewModel.orderDirection = apiData.orderDirection && apiData.orderDirection.label;
    viewModel.parentId = apiData.parentId;
    viewModel.orderBillingStatus = apiData.orderBillingStatus
    viewModel.orderThrough = this.getOrderThrough(apiData);
    viewModel.packageSelectionItemId = apiData.packageSelectionItemId
    viewModel.packageSelectionPartId = apiData.packageSelectionPartId
    viewModel.packageOrderStatus = apiData.packageOrderStatus && apiData.packageOrderStatus.label
    viewModel.updatedAt = apiData.updatedAt;
    viewModel.catalogElement = apiData.catalogElement ? CatalogElementViewModel.mapFromApiModel(apiData.catalogElement, viewModel.orderType) : {} as CatalogElementViewModel;
    viewModel.catalogGroupElement = apiData.catalogGroupElement ? CatalogElementViewModel.mapFromApiModel(apiData.catalogGroupElement, viewModel.orderType) : {} as CatalogElementViewModel;
    viewModel.catalogGroup = apiData.catalogGroup ? CatalogGroupViewModel.mapFromApiModel(apiData.catalogGroup, viewModel.orderType) : {} as CatalogGroupViewModel;

    return viewModel;
  }

  static getOrderThrough(apiData: IMedicalPackagePartOrder): MedicalPackageOrderActionEnum {
    let orderThrough = MedicalPackageOrderActionEnum.CREATE_ORDER;
    if (apiData.orderType.label === OrderTypeEnum.Medication) {
      if (apiData.isServiceBilling) {
        orderThrough = MedicalPackageOrderActionEnum.SERVICE_BILLING
      } else if (apiData.medicationType && apiData.medicationType.label === OrderTypeEnum.Medication) {
        orderThrough = MedicalPackageOrderActionEnum.SEND_TO_TREATMENT
      } else if (apiData.medicationType && apiData.medicationType.label === OrderTypeEnum.prescriptionOnDischarge) {
        orderThrough = MedicalPackageOrderActionEnum.PRESCRIPTION_ON_DISCHARGE
      } else if (apiData.medicationType && apiData.medicationType.label === OrderTypeEnum.prescription) {
        orderThrough = MedicalPackageOrderActionEnum.PRESCRIPTION;
      }
    } else if (apiData.orderType.label === OrderTypeEnum.Supply || apiData.orderType.label === OrderTypeEnum.medicalService || apiData.orderType.label === OrderTypeEnum.TreatmentProcedure) {
      if (apiData.isServiceBilling) {
        orderThrough = MedicalPackageOrderActionEnum.SERVICE_BILLING
      }
    } else if (apiData.orderType.label === OrderTypeEnum.operationRoomRequest) {
      orderThrough = MedicalPackageOrderActionEnum.ADD_TO_OR_BOOKING
    }
    return orderThrough;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {getDOBBasedOnPref} from '@v2/core/functions/functions';
import {IPatient} from '@v2/core/models/masterdata';

@Pipe({
  name: 'getDOB'
})
export class GetDOBPipe implements PipeTransform {

  private readonly DATE_FMT = `DD-MM-YYYY`;

  transform(dobInfo: Partial<IPatient>, dateFormat = this.DATE_FMT): string {
    return getDOBBasedOnPref(dobInfo, dateFormat);
  }

}

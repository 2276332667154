import {animate, animateChild, keyframes, query, style, transition, trigger} from '@angular/animations';
import {AnimationTransaction} from './animation.interface';

export function SlideOutDown({speed = 350, easing = 'ease', delay = 0}: AnimationTransaction) {
  return trigger('slideOutDown', [
    transition(':leave', [
      animate(
        `${speed}ms ${delay}ms ${easing}`,
        keyframes([
          style({opacity: 1, offset: 0}),
          style({opacity: 0, transform: 'translate3d(0, -100%, 0)', offset: 1})
        ])
      ),
      query('@*', animateChild(), {optional: true})
    ])
  ]);
}

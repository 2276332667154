import {getElementPrices} from '@v2/core/functions/functions';
import {ICatalogElement, ICatalogGroup} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {OperationRoomBookingFormDataModel} from '../../../../../v2/shared-v2/components/patient-order/resources/models/form/operation-room-booking-form.model';
import {MedicalPackageLabOrderFormDataModel} from '../../../../../v2/shared-v2/resources/models/form/medical-package/medical-package-lab-order.form.model';
import {MedicalPackageMedicalOrderFormDataModel} from '../../../../../v2/shared-v2/resources/models/form/medical-package/medical-package-medical-order.form.model';
import {MedicalPackageNutritionMealOrderFormDataModel} from '../../../../../v2/shared-v2/resources/models/form/medical-package/medical-package-nutrition-meal-order.form.model';
import {MedicalPackageProcedureOrderFormDataModel} from '../../../../../v2/shared-v2/resources/models/form/medical-package/medical-package-procedure-order.form.model';
import {MedicalPackageRadiologyOrderFormDataModel} from '../../../../../v2/shared-v2/resources/models/form/medical-package/medical-package-radiology-order.form.model';
import {MedicalPackageSupplyOrderFormDataModel} from '../../../../../v2/shared-v2/resources/models/form/medical-package/medical-package-supply-order.form.model';
import {MedicalPackageServiceBillingOrderFormModel} from '../../../../../v2/shared-v2/resources/models/form/service-billing/medical-package-service-billing-order.form.model';

export class CatalogElementViewModel extends ConcurrencyRequest {

  id: string;
  xref: string;
  name: string;
  incomeType: string;
  baseSellingPrice: number;
  opdPrice: number;
  ipdPrice: number;
  masterCatalogPrice: number;
  isSelected: boolean;
  medicalCatalogType: string;
  medicalCatalogId: string;
  allowedOrderQty: number;
  plannedQty: number;
  remainingQty: number;
  usedQty: number;
  latestPackageUtilisationLogPlannedQty: number;
  latestPackageUtilisationLogRemainingQty: number;
  usedLogQty: number;
  orderQty: number;
  isOrdered: boolean;
  packageSelectionItemId: string;
  investigationTypeId: string;
  hasAllergy: boolean;
  dfType: string;
  hasError: boolean;
  isSubmitting: boolean;
  isSubmitted: boolean;
  submitError: string;
  selectedAction: string;
  orderData: MedicalPackageRadiologyOrderFormDataModel | MedicalPackageLabOrderFormDataModel | MedicalPackageNutritionMealOrderFormDataModel | MedicalPackageProcedureOrderFormDataModel | MedicalPackageServiceBillingOrderFormModel | MedicalPackageSupplyOrderFormDataModel | MedicalPackageMedicalOrderFormDataModel;

  instruction: string;
  usageMethod: string;
  template: string;
  usageTemplate: string;

  isSelectedForCancelOrDetach: boolean;
  isPartialSelectedForCancelOrDetach: boolean;

  static mapFromApiModel(apiData: ICatalogElement, catalogType: string, catalogElementArray?: CatalogElementViewModel[]): CatalogElementViewModel {
    const elementPrices = getElementPrices(apiData.elementPrices);
    const plannedQty = apiData.plannedQty ? apiData.plannedQty : 0;
    const remainingQty = apiData.remainingQty ? apiData.remainingQty : 0;

    const viewModel = new CatalogElementViewModel();
    viewModel.id = apiData.id;
    viewModel.xref = apiData.xref;
    viewModel.name = apiData.name || apiData.brandName || apiData.englishName || '-';
    viewModel.incomeType = apiData.incomeTypeComputed ? apiData.incomeTypeComputed.split(' ')[0] : '-';
    viewModel.baseSellingPrice = elementPrices.baseSellingPrice;
    viewModel.opdPrice = elementPrices.opdPrice;
    viewModel.ipdPrice = elementPrices.ipdPrice;
    viewModel.masterCatalogPrice = elementPrices.masterCatalogPrice;
    viewModel.isSelected = catalogElementArray && catalogElementArray.length && !!catalogElementArray.find(item => item.id === apiData.id);
    viewModel.medicalCatalogType = catalogType;
    viewModel.medicalCatalogId = apiData.medicalCatalog && apiData.medicalCatalog.id;
    viewModel.allowedOrderQty = apiData.allowedOrderQty ? apiData.allowedOrderQty : 0;
    viewModel.plannedQty = plannedQty;
    viewModel.remainingQty = remainingQty;
    viewModel.usedQty = plannedQty - remainingQty;
    viewModel.latestPackageUtilisationLogPlannedQty = apiData.latestPackageUtilisationLogPlannedQty || 0;
    viewModel.latestPackageUtilisationLogRemainingQty = apiData.latestPackageUtilisationLogRemainingQty || 0;
    viewModel.usedLogQty = viewModel.latestPackageUtilisationLogPlannedQty - viewModel.latestPackageUtilisationLogRemainingQty;
    viewModel.orderQty = null;
    viewModel.isOrdered = null;
    viewModel.hasError = false;
    viewModel.packageSelectionItemId = apiData.packageSelectionItemId;
    viewModel.investigationTypeId = apiData.investigationTypeId;
    viewModel.dfType = apiData.doctorFeeType && apiData.doctorFeeType.label;
    viewModel.isSubmitting = false;
    viewModel.isSubmitted = null;
    viewModel.submitError = null;

    viewModel.instruction = apiData.instruction;
    viewModel.usageMethod = apiData.thaiInstruction;
    viewModel.template = apiData.template;
    viewModel.usageTemplate = apiData.thaiTemplate;

    viewModel.mapConcurrencyRequest(apiData);
    return viewModel;
  }
}

export class CatalogGroupViewModel extends ConcurrencyRequest {
  id: string;
  xref: string;
  name: string;
  incomeType: string;
  baseSellingPrice: number;
  opdPrice: number;
  ipdPrice: number;
  masterCatalogPrice: number;
  catalogElements: CatalogElementViewModel[];
  isSelected: boolean;
  medicalCatalogType: string;
  medicalCatalogName: string;
  allowedOrderQty: number;
  plannedQty: number;
  remainingQty: number;
  latestPackageUtilisationLogPlannedQty: number;
  latestPackageUtilisationLogRemainingQty: number;
  usedLogQty: number;
  usedQty: number;
  orderQty: number;
  isOrdered: boolean;
  hasError: boolean;
  isSubmitting: boolean;
  isSubmitted: boolean;
  hasAllergy: boolean;
  submitError: string;
  packageSelectionItemId: string;
  selectedAction: string;
  orderData: MedicalPackageRadiologyOrderFormDataModel | MedicalPackageLabOrderFormDataModel | MedicalPackageNutritionMealOrderFormDataModel | MedicalPackageProcedureOrderFormDataModel | MedicalPackageServiceBillingOrderFormModel | OperationRoomBookingFormDataModel | MedicalPackageSupplyOrderFormDataModel | MedicalPackageMedicalOrderFormDataModel;
  pkgSelectionItemUpdatedAt: string;

  isSelectedForCancelOrDetach: boolean;
  isPartialSelectedForCancelOrDetach: boolean;

  static mapFromApiModel(apiData: ICatalogGroup, catalogType: string, catalogGroupArray?: CatalogGroupViewModel[]): CatalogGroupViewModel {
    const elementPrices = getElementPrices(apiData.elementPrices);
    const plannedQty = apiData.plannedQty ? apiData.plannedQty : 0;
    const remainingQty = apiData.remainingQty ? apiData.remainingQty : 0;

    const medicalCatalog = apiData.medicalCatalog
    const medicalCatalogType = medicalCatalog && medicalCatalog.type
    const medicalCatalogName = medicalCatalog && medicalCatalog.name

    const viewModel = new CatalogGroupViewModel();
    viewModel.id = apiData.id;
    viewModel.xref = apiData.xref;
    viewModel.name = apiData.name;
    viewModel.incomeType = apiData.incomeTypeComputed ? apiData.incomeTypeComputed.split(' ')[0] : '-';
    viewModel.baseSellingPrice = elementPrices.baseSellingPrice;
    viewModel.opdPrice = elementPrices.opdPrice;
    viewModel.ipdPrice = elementPrices.ipdPrice;
    viewModel.masterCatalogPrice = elementPrices.masterCatalogPrice;
    viewModel.catalogElements = (apiData.catalogElements && apiData.catalogElements.length) ? apiData.catalogElements.map(item => CatalogElementViewModel.mapFromApiModel(item, catalogType)) : null;
    viewModel.isSelected = catalogGroupArray && catalogGroupArray.length && !!catalogGroupArray.find(item => item.id === apiData.id);
    viewModel.medicalCatalogType = medicalCatalogType && medicalCatalogType.label
    viewModel.medicalCatalogName = medicalCatalogName
    viewModel.allowedOrderQty = apiData.allowedOrderQty ? apiData.allowedOrderQty : 0;
    viewModel.plannedQty = plannedQty;
    viewModel.remainingQty = remainingQty;
    viewModel.usedQty = plannedQty - remainingQty;
    viewModel.latestPackageUtilisationLogPlannedQty = apiData.latestPackageUtilisationLogPlannedQty || 0;
    viewModel.latestPackageUtilisationLogRemainingQty = apiData.latestPackageUtilisationLogRemainingQty || 0;
    viewModel.usedLogQty = viewModel.latestPackageUtilisationLogPlannedQty - viewModel.latestPackageUtilisationLogRemainingQty;
    viewModel.orderQty = null;
    viewModel.isOrdered = null;
    viewModel.hasError = false;
    viewModel.hasAllergy = false;
    viewModel.isSubmitting = false;
    viewModel.isSubmitted = null;
    viewModel.submitError = null;
    viewModel.packageSelectionItemId = apiData.packageSelectionItemId;
    return viewModel;
  }
}

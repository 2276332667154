import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';
import {PaginatedDropdownControlModule} from '../../paginated-dropdown-control.module';
import {NotificationAreasDropdownComponent} from './notification-areas-dropdown.component';

@NgModule({
  declarations: [NotificationAreasDropdownComponent],
  exports: [
    NotificationAreasDropdownComponent
  ],
  imports: [
    CommonModule,
    PaginatedDropdownControlModule,
    TranslocoModule
  ]
})
export class NotificationAreasDropdownModule {
}

import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'commonTime'
})
export class CommonTimePipe extends DatePipe implements PipeTransform {

  private readonly TIME_FMT = 'shortTime';

  transform(value: any, format?: string): any {
    return super.transform(value, format || this.TIME_FMT);
  }

}

export enum ErrorHandlerEnum {

  RAPID_REG_DSR_LIST = 'RAPID_REG_DSR_LIST',
  RAPID_REG_ALL_PATIENT_LIST = 'RAPID_REG_ALL_PATIENT_LIST',
  RAPID_REG_ALL_APPOINTMENT_LIST = 'RAPID_REG_ALL_APPOINTMENT_LIST',
  RAPID_REG_CHECKED_IN_PATIENT_LIST = 'RAPID_REG_CHECKED_IN_PATIENT_LIST',
  RAPID_REG_COMPLETED_VISIT_LIST = 'RAPID_REG_COMPLETED_VISIT_LIST',
  RAPID_REG_TRIAGE_PENDING_REQ_LIST = 'RAPID_REG_TRIAGE_PENDING_REQ_LIST',
  RAPID_REG_TRIAGE_COMPLETED_REQ_LIST = 'RAPID_REG_TRIAGE_COMPLETED_REQ_LIST',

  RAPID_REG_ADT_PENDING_ORDERS_LIST = 'RAPID_REG_ADT_PENDING_ORDERS_LIST',

  ADT_ADMIT_DSR_LIST = 'ADT_ADMIT_DSR_LIST',
  ADT_ADMIT_TRAN_REQ_PENDING_ADMIT_REQ_LIST = 'ADT_ADMIT_TRAN_REQ_PENDING_ADMIT_REQ_LIST',
  ADT_ADMIT_TRAN_REQ_PENDING_BED_TRANSFER_LIST = 'ADT_ADMIT_TRAN_REQ_PENDING_BED_TRANSFER_LIST',
  ADT_ADMIT_TRAN_REQ_COMPLETED_REQUEST_LIST = 'ADT_ADMIT_TRAN_REQ_COMPLETED_REQUEST_LIST',
  ADT_BED_OCCUPANCY_LIST = 'ADT_BED_OCCUPANCY_LIST',
  ADT_DISCHARGE_PAT_PENDING_REQ_LIST = 'ADT_DISCHARGE_PAT_PENDING_REQ_LIST',
  ADT_DISCHARGE_PAT_COMPLETED_REQ_LIST = 'ADT_DISCHARGE_PAT_PENDING_REQ_LIST',

  BED_MANAGEMENT_HOSPITAL_BED_LIST = 'BED_MANAGEMENT_HOSPITAL_BED_LIST',
  BED_MANAGEMENT_PATIENT_ROOM_LIST = 'BED_MANAGEMENT_PATIENT_ROOM_LIST',

  NURSE_OPD_ALL_PATIENT_DSR_LIST = 'NURSE_OPD_ALL_PATIENT_DSR_LIST',
  NURSE_OPD_NEW_PATIENT_DSR_LIST = 'NURSE_OPD_NEW_PATIENT_DSR_LIST',
  NURSE_OPD_OLD_PATIENT_DSR_LIST = 'NURSE_OPD_OLD_PATIENT_DSR_LIST',
  NURSE_OPD_PATIENT_PROFILE_LIST = 'NURSE_OPD_PATIENT_PROFILE_LIST',
  NURSE_OPD_PAT_DATA_PENDING_REQ_LIST = 'NURSE_OPD_PAT_DATA_PENDING_REQ_LIST',
  NURSE_OPD_PAT_DATA_IN_PROGRESS_REQ_LIST = 'NURSE_OPD_PAT_DATA_IN_PROGRESS_REQ_LIST',
  NURSE_OPD_PAT_DATA_COMPLETED_REQ_LIST = 'NURSE_OPD_PAT_DATA_COMPLETED_REQ_LIST',
  NURSE_OPD_PAT_REVIEW_TODAYS_APT_LIST = 'NURSE_OPD_PAT_REVIEW_TODAYS_APT_LIST',
  NURSE_OPD_CONSULTATION_TRANSFER_LIST = 'NURSE_OPD_CONSULTATION_TRANSFER_LIST',

  NURSE_IPD_DASHBOARD_DSR_LIST = 'NURSE_IPD_DASHBOARD_DSR_LIST',
  NURSE_IPD_PATIENT_PROFILE_LIST = 'NURSE_IPD_PATIENT_PROFILE_LIST',
  NURSE_IPD_PATIENT_DATA_ADMISSION_LIST = 'NURSE_IPD_PATIENT_DATA_ADMISSION_LIST',
  NURSE_IPD_PATIENT_DATA_DISCHARGE_LIST = 'NURSE_IPD_PATIENT_DATA_DISCHARGE_LIST',
  NURSE_IPD_PATIENT_REVIEW_CURRENT_ADMISSION_LIST = 'NURSE_IPD_PATIENT_REVIEW_CURRENT_ADMISSION_LIST',

  DOCTOR_IPD_DASHBOARD_DSR_LIST = 'DOCTOR_IPD_DASHBOARD_DSR_LIST',
  DOCTOR_IPD_PATIENT_PROFILE_LIST = 'DOCTOR_IPD_PATIENT_PROFILE_LIST',
  DOCTOR_IPD_PAT_DATA_ADMISSION_LIST = 'DOCTOR_IPD_PAT_DATA_ADMISSION_LIST',
  DOCTOR_IPD_PAT_DATA_DISCHARGE_LIST = 'DOCTOR_IPD_PAT_DATA_DISCHARGE_LIST',
  DOCTOR_IPD_PAT_DATA_MY_CONSULTATION_LIST = 'DOCTOR_IPD_PAT_DATA_MY_CONSULTATION_LIST',
  DOCTOR_IPD_PAT_REVIEW_TODAYS_APT_LIST = 'DOCTOR_IPD_PAT_REVIEW_TODAYS_APT_LIST',

  IPD_PATIENT_MGNT_PENDING_LIST = 'IPD_PATIENT_MGNT_PENDING_LIST',
  IPD_PATIENT_MGNT_IN_PROGRESS_LIST = 'IPD_PATIENT_MGNT_IN_PROGRESS_LIST',
  IPD_PATIENT_MGNT_COMPLETED_LIST = 'IPD_PATIENT_MGNT_COMPLETED_LIST',

  DOCTOR_OPD_DASHBOARD_DSR_LIST = 'DOCTOR_OPD_DASHBOARD_DSR_LIST',
  DOCTOR_OPD_PATIENT_PROFILE_LIST = 'DOCTOR_OPD_PATIENT_PROFILE_LIST',
  DOCTOR_OPD_PAT_DATA_PENDING_REQ_LIST = 'DOCTOR_OPD_PAT_DATA_PENDING_REQ_LIST',
  DOCTOR_OPD_PAT_DATA_IN_PROGRESS_REQ_LIST = 'DOCTOR_OPD_PAT_DATA_IN_PROGRESS_REQ_LIST',
  DOCTOR_OPD_PAT_DATA_COMPLETED_REQ_LIST = 'DOCTOR_OPD_PAT_DATA_COMPLETED_REQ_LIST',
  DOCTOR_OPD_PAT_REVIEW_TODAYS_APT_LIST = 'DOCTOR_OPD_PAT_REVIEW_TODAYS_APT_LIST',
  DOCTOR_OPD_CONSULTATION_TRANSFER_LIST = 'DOCTOR_OPD_CONSULTATION_TRANSFER_LIST',

  WARD_MANAGEMENT_HOSPITAL_WARD_LIST = 'WARD_MANAGEMENT_HOSPITAL_WARD_LIST',
  WARD_MANAGEMENT_NURSING_STATION_LIST = 'WARD_MANAGEMENT_NURSING_STATION_LIST',

  PAT_REVIEW_ALL_PATIENT_APT_LIST = 'PAT_REVIEW_ALL_PATIENT_APT_LIST',
  REPORTS_INVENTORY_REPORT_STOCK_ON_HAND = 'REPORTS_INVENTORY_REPORT_STOCK_ON_HAND',
  REPORTS_INVENTORY_REPORT_PR_SUMMARY = 'REPORTS_INVENTORY_REPORT_PR_SUMMARY',
  REPORTS_INVENTORY_REPORT_STOCK_AGING = 'REPORTS_INVENTORY_REPORT_STOCK_AGING',
  REPORTS_INVENTORY_REPORT_STOCK_EXPIRY = 'REPORTS_INVENTORY_REPORT_STOCK_EXPIRY',
  REPORTS_INVENTORY_REPORT_STOCK_NO_MOVEMENT = 'REPORTS_INVENTORY_REPORT_STOCK_NO_MOVEMENT',
  REPORTS_INVENTORY_REPORT_RECEIVED_ORDER = 'REPORTS_INVENTORY_REPORT_RECEIVED_ORDER',
  REPORTS_ADT_MANAGEMENT_REPORT = 'REPORTS_ADT_MANAGEMENT_REPORT',
  REPORTS_MANAGEMENT_REPORT_CONSUME_STOCK = 'REPORTS_MANAGEMENT_REPORT_CONSUME_STOCK',
  REPORTS_MANAGEMENT_REPORT_STOCK_INVENTORY_MASTER = 'REPORTS_MANAGEMENT_REPORT_STOCK_INVENTORY_MASTER',
  REPORTS_MANAGEMENT_REPORT_MEDICAL_BENEFITS_USAGE = 'REPORTS_MANAGEMENT_REPORT_MEDICAL_BENEFITS_USAGE',
  REPORTS_STATISTICS_REPORT_PATIENT_VISIT_STATISTIC = 'REPORTS_STATISTICS_REPORT_PATIENT_VISIT_STATISTIC',
  REPORTS_DOCTOR_REPORT_DOCTOR_FEE = 'REPORTS_DOCTOR_REPORT_DOCTOR_FEE',
  REPORTS_ICD_REPORT_ICD_10 = 'REPORTS_ICD_REPORT_ICD_10',
  REPORTS_ICD_REPORT_ICD_09 = 'REPORTS_ICD_REPORT_ICD_09',
  REPORTS_ORDER_ITEM_MEDICAL_PACKAGE_VALUE = 'REPORTS_ORDER_ITEM_MEDICAL_PACKAGE_VALUE',
  REPORTS_ORDER_ITEM_MEDICATION_AND_SERVICE_VALUE = 'REPORTS_ORDER_ITEM_MEDICATION_AND_SERVICE_VALUE',
  REPORTS_ORDER_ITEM_ORDERED_ITEMS_REPORT = 'REPORTS_ORDER_ITEM_ORDERED_ITEMS_REPORT',
  REPORTS_PROCUREMENT_VENDOR = 'REPORTS_PROCUREMENT_VENDOR',
  REPORTS_PROCUREMENT_PO_VALUE = 'REPORTS_PROCUREMENT_PO_VALUE',
  REPORTS_PROCUREMENT_PO_SUMMARY = 'REPORTS_PROCUREMENT_PO_SUMMARY',
  REPORTS_PROCUREMENT_PO_OUTSTANDING = 'REPORTS_PROCUREMENT_PO_OUTSTANDING',
  REPORTS_RAPID_REGISTRATION = 'REPORTS_RAPID_REGISTRATION',

  SUPPORT_CENTER_NEW_TICKET = 'SUPPORT_CENTER_NEW_TICKET',
  SUPPORT_CENTER_IN_PROGRESS = 'SUPPORT_CENTER_IN_PROGRESS',
  SUPPORT_CENTER_COMPLETED = 'SUPPORT_CENTER_COMPLETED',

  AIM_SETUP_MIGRATE_DATA = 'AIM_SETUP_MIGRATE_DATA',
  AIM_SETUP_INTEGRATION_SETUP = 'AIM_SETUP_INTEGRATION_SETUP',

  USER_MANAGEMENT_APP_ROLES = 'USER_MANAGEMENT_APP_ROLES',
  USER_MANAGEMENT_HOSPITAL_TEAM = 'USER_MANAGEMENT_HOSPITAL_TEAM',
  USER_MANAGEMENT_TEAM_ROLE_ASSOCIATION = 'USER_MANAGEMENT_TEAM_ROLE_ASSOCIATION',
  USER_MANAGEMENT_OA_ACCESS = 'USER_MANAGEMENT_OA_ACCESS',
  USER_MANAGEMENT_APP_USERS = 'USER_MANAGEMENT_APP_USERS',

  CORP_SETUP_COMPANY_LIST = 'CORP_SETUP_COMPANY_LIST',
  CORP_SETUP_DEPARTMENT_LIST = 'CORP_SETUP_DEPARTMENT_LIST',
  CORP_SETUP_HOSPITAL_LIST = 'CORP_SETUP_HOSPITAL_LIST',
  CORP_SETUP_HOSPITAL_FLOOR_LIST = 'CORP_SETUP_HOSPITAL_FLOOR_LIST',
  CORP_SETUP_HOSPITAL_BUILDING_LIST = 'CORP_SETUP_HOSPITAL_BUILDING_LIST',
  CORP_SETUP_SEED_CONFIG = 'CORP_SETUP_SEED_CONFIG',

  FACILITY_RESOURCE_NURSE_STATION_LIST = 'FACILITY_RESOURCE_NURSE_STATION_LIST',
  FACILITY_RESOURCE_HOSPITAL_ROOM = 'FACILITY_RESOURCE_HOSPITAL_ROOM',
  FACILITY_RESOURCE_EQUIPMENT_MANAGEMENT = 'FACILITY_RESOURCE_EQUIPMENT_MANAGEMENT',

  NON_MEDICAL_PARTY_EXECUTIVE_LIST = 'NON_MEDICAL_PARTY_EXECUTIVE_LIST',
  NON_MEDICAL_PARTY_MANAGER_LIST = 'NON_MEDICAL_PARTY_MANAGER_LIST',
  NON_MEDICAL_PARTY_OTHER_STAFF_LIST = 'NON_MEDICAL_PARTY_OTHER_STAFF_LIST',

  MEDICAL_PARTY_DOCTOR_LIST = 'MEDICAL_PARTY_DOCTOR_LIST',
  MEDICAL_PARTY_NURSE_LIST = 'MEDICAL_PARTY_NURSE_LIST',
  MEDICAL_PARTY_OTHER_STAFF_LIST = 'MEDICAL_PARTY_OTHER_STAFF_LIST',

  DAILY_STOCK_USAGE_REPORT = 'DAILY_STOCK_USAGE_REPORT',
  MONTHLY_STOCK_USAGE_REPORT = 'MONTHLY_STOCK_USAGE_REPORT',
  INVENTORY_EXPIRED_ITEM_REPORT = 'INVENTORY_EXPIRED_ITEM_REPORT',
  INVENTORY_NO_MOVEMENT_ITEM_REPORT = 'INVENTORY_NO_MOVEMENT_ITEM_REPORT',
  DELIVERED_ORDERS_ITEM_REPORT = 'DELIVERED_ORDERS_ITEM_REPORT',
  INVENTORY_RECEIVED_ORDER_REPORT = 'INVENTORY_RECEIVED_ORDER_REPORT',
  SUB_STOCK_RECEIVED_ORDERS_REPORT = 'SUB_STOCK_RECEIVED_ORDERS_REPORT',

  SUB_STOCK_MANAGEMENT_INVENTORY_LIST = 'SUB_STOCK_MANAGEMENT_INVENTORY_LIST',
  SUB_STOCK_MANAGEMENT_INVENTORY_MEDICINE_LIST = 'SUB_STOCK_MANAGEMENT_INVENTORY_MEDICINE_LIST',
  SUB_STOCK_MANAGEMENT_INVENTORY_SUPPLY_LIST = 'SUB_STOCK_MANAGEMENT_INVENTORY_SUPPLY_LIST',
  SUB_STOCK_MANAGEMENT_INVENTORY_HOUSEKEEPING_LIST = 'SUB_STOCK_MANAGEMENT_INVENTORY_HOUSEKEEPING_LIST',
  SUB_STOCK_MANAGEMENT_INVENTORY_STATIONERY_LIST = 'SUB_STOCK_MANAGEMENT_INVENTORY_STATIONERY_LIST',
  SUB_STOCK_MANAGEMENT_INVENTORY_EQUIPMENT_LIST = 'SUB_STOCK_MANAGEMENT_INVENTORY_EQUIPMENT_LIST',
  SUB_STOCK_MANAGEMENT_OUTGOING_TRANSFER_REQUEST_PENDING_LIST = 'SUB_STOCK_MANAGEMENT_OUTGOING_TRANSFER_REQUEST_PENDING_LIST',
  SUB_STOCK_MANAGEMENT_OUTGOING_TRANSFER_REQUEST_COMPLETED_LIST = 'SUB_STOCK_MANAGEMENT_OUTGOING_TRANSFER_REQUEST_COMPLETED_LIST',
  SUB_STOCK_MANAGEMENT_INCOMING_TRANSFER_REQUEST_PENDING_LIST = 'SUB_STOCK_MANAGEMENT_INCOMING_TRANSFER_REQUEST_PENDING_LIST',
  SUB_STOCK_MANAGEMENT_INCOMING_TRANSFER_REQUEST_COMPLETED_LIST = 'SUB_STOCK_MANAGEMENT_INCOMING_TRANSFER_REQUEST_COMPLETED_LIST',
  SUB_STOCK_MANAGEMENT_TRANSFER_REQUEST_MANAGEMENT_LIST = 'SUB_STOCK_MANAGEMENT_TRANSFER_REQUEST_MANAGEMENT_LIST',

  PROCUREMENT_MAIN_STOCK_DSR_PO_OUTSTANDING = 'PROCUREMENT_MAIN_STOCK_DSR_PO_OUTSTANDING',
  PROCUREMENT_MAIN_STOCK_MEDICINE_LIST = 'PROCUREMENT_MAIN_STOCK_MEDICINE_LIST',
  PROCUREMENT_MAIN_STOCK_SUPPLY_LIST = 'PROCUREMENT_MAIN_STOCK_SUPPLY_LIST',
  PROCUREMENT_MAIN_STOCK_HOUSEKEEPING_LIST = 'PROCUREMENT_MAIN_STOCK_HOUSEKEEPING_LIST',
  PROCUREMENT_MAIN_STOCK_STATIONERY_LIST = 'PROCUREMENT_MAIN_STOCK_STATIONERY_LIST',
  PROCUREMENT_MAIN_STOCK_EQUIPMENT_LIST = 'PROCUREMENT_MAIN_STOCK_EQUIPMENT_LIST',
  PROCUREMENT_PURCHASE_REQUEST_MAIN_STOCK_PR = 'PROCUREMENT_PURCHASE_REQUEST_MAIN_STOCK_PR',
  PROCUREMENT_PURCHASE_REQUEST_SUB_STOCK_PR = 'PROCUREMENT_PURCHASE_REQUEST_SUB_STOCK_PR',
  PROCUREMENT_PURCHASE_ORDER_BACKLOG_PENDING_LIST = 'PROCUREMENT_PURCHASE_ORDER_BACKLOG_PENDING_LIST',
  PROCUREMENT_PURCHASE_ORDER_BACKLOG_IN_PROGRESS_LIST = 'PROCUREMENT_PURCHASE_ORDER_BACKLOG_IN_PROGRESS_LIST',
  PROCUREMENT_PURCHASE_ORDER_BACKLOG_RECEIVED_LIST = 'PROCUREMENT_PURCHASE_ORDER_BACKLOG_RECEIVED_LIST',
  PROCUREMENT_VENDOR_MANAGEMENT_VENDOR_LIST = 'PROCUREMENT_VENDOR_MANAGEMENT_VENDOR_LIST',
  PROCUREMENT_VENDOR_MANAGEMENT_MANUFACTURER_LIST = 'PROCUREMENT_VENDOR_MANAGEMENT_MANUFACTURER_LIST',
  PROCUREMENT_PURCHASE_ORDER_APPROVAL = 'PROCUREMENT_PURCHASE_ORDER_APPROVAL',

  PHARMACY_PREPARE_MEDICINE_IPD = 'PHARMACY_PREPARE_MEDICINE_IPD',
  PHARMACY_PREPARE_MEDICINE_OPD = 'PHARMACY_PREPARE_MEDICINE_OPD',
  PHARMACY_VERIFY_MEDICINE_IPD = 'PHARMACY_VERIFY_MEDICINE_IPD',
  PHARMACY_VERIFY_MEDICINE_OPD = 'PHARMACY_VERIFY_MEDICINE_OPD',
  PHARMACY_DISPENSE_MEDICINE_OPD = 'PHARMACY_DISPENSE_MEDICINE_OPD',
  PHARMACY_DISPENSE_MEDICINE_IPD = 'PHARMACY_DISPENSE_MEDICINE_IPD',
  PHARMACY_PHARMA_CATALOG_ELEMENTS = 'PHARMACY_PHARMA_CATALOG_ELEMENTS',
  PHARMACY_PHARMA_CATALOG_GROUPS = 'PHARMACY_PHARMA_CATALOG_GROUPS',
  PHARMACY_PHARMACY_OUTLETS = 'PHARMACY_PHARMACY_OUTLETS',
  PHARMACY_RETURN_MEDICINE_VISIT = 'PHARMACY_RETURN_MEDICINE_VISIT',
  PHARMACY_RETURN_MEDICINE_ADMISSION = 'PHARMACY_RETURN_MEDICINE_ADMISSION',
  PHARMACY_DRUG_INTERACTION = 'PHARMACY_DRUG_INTERACTION',
  PHARMACY_DRUG_INTERACTION_ALLERGY = 'PHARMACY_DRUG_INTERACTION_ALLERGY',
  PHARMACY_DSR_LIST = 'PHARMACY_DSR_LIST',

  RADIOLOGY_PATIENT_BACKLOG = 'RADIOLOGY_PATIENT_BACKLOG',
  RADIOLOGY_PATIENT_INVESTIGATION_OPD_PENDING = 'RADIOLOGY_PATIENT_INVESTIGATION_OPD_PENDING',
  RADIOLOGY_PATIENT_INVESTIGATION_OPD_COMPLETED = 'RADIOLOGY_PATIENT_INVESTIGATION_OPD_COMPLETED',
  RADIOLOGY_PATIENT_INVESTIGATION_IPD_COMPLETED = 'RADIOLOGY_PATIENT_INVESTIGATION_IPD_COMPLETED',
  RADIOLOGY_PATIENT_INVESTIGATION_IPD_PENDING = 'RADIOLOGY_PATIENT_INVESTIGATION_IPD_PENDING',
  RADIOLOGY_CATALOG_ELEMENTS = 'RADIOLOGY_CATALOG_ELEMENTS',
  RADIOLOGY_CATALOG_GROUPS = 'RADIOLOGY_CATALOG_GROUPS',
  RADIOLOGY_RESULT_UPLOAD_OPD_PENDING = 'RADIOLOGY_RESULT_UPLOAD_OPD_PENDING',
  RADIOLOGY_RESULT_UPLOAD_OPD_COMPLETED = 'RADIOLOGY_RESULT_UPLOAD_OPD_COMPLETED',
  RADIOLOGY_RESULT_UPLOAD_IPD_COMPLETED = 'RADIOLOGY_RESULT_UPLOAD_IPD_COMPLETED',
  RADIOLOGY_RESULT_UPLOAD_IPD_PENDING = 'RADIOLOGY_RESULT_UPLOAD_IPD_PENDING',
  RADIOLOGY_REPORT_ENTRY_OPD_PENDING = 'RADIOLOGY_REPORT_ENTRY_OPD_PENDING',
  RADIOLOGY_REPORT_ENTRY_IPD_PENDING = 'RADIOLOGY_REPORT_ENTRY_IPD_PENDING',
  RADIOLOGY_REPORT_ENTRY_OPD_COMPLETED = 'RADIOLOGY_REPORT_ENTRY_OPD_COMPLETED',
  RADIOLOGY_REPORT_ENTRY_IPD_COMPLETED = 'RADIOLOGY_REPORT_ENTRY_IPD_COMPLETED',
  RADIOLOGY_DSR_IPD = 'RADIOLOGY_DSR_IPD',
  RADIOLOGY_DSR_OPD = 'RADIOLOGY_DSR_OPD',
  RADIOLOGY_DSR_PAYMENT_TYPE_WISE = 'RADIOLOGY_DSR_PAYMENT_TYPE_WISE',
  RADIOLOGY_DSR_STATUS_OF_TEST = 'RADIOLOGY_DSR_STATUS_OF_TEST',
  RADIOLOGY_DSR_NAME_WISE = 'RADIOLOGY_DSR_NAME_WISE',
  RADIOLOGY_DSR_TOTAL_PATIENT = 'RADIOLOGY_DSR_TOTAL_PATIENT',
  RADIOLOGY_DSR_TOTAL_REVENUE_GENERATED = 'RADIOLOGY_DSR_TOTAL_REVENUE_GENERATED',

  LAB_PATIENT_BACKLOG = 'LAB_PATIENT_BACKLOG',
  LAB_SAMPLE_COLLECTION_ALL = 'LAB_SAMPLE_COLLECTION_ALL',
  LAB_SAMPLE_COLLECTION_OPD_PENDING = 'LAB_SAMPLE_COLLECTION_OPD_PENDING',
  LAB_SAMPLE_COLLECTION_OPD_COMPLETED = 'LAB_SAMPLE_COLLECTION_OPD_COMPLETED',
  LAB_SAMPLE_COLLECTION_IPD_COMPLETED = 'LAB_SAMPLE_COLLECTION_IPD_COMPLETED',
  LAB_SAMPLE_COLLECTION_IPD_PENDING = 'LAB_PATIENT_INVESTIGATION_IPD_PENDING',
  LAB_CATALOG_ELEMENTS = 'LAB_CATALOG_ELEMENTS',
  LAB_CATALOG_GROUPS = 'LAB_CATALOG_GROUPS',
  LAB_RESULT_UPLOAD_OPD_PENDING = 'LAB_RESULT_UPLOAD_OPD_PENDING',
  LAB_RESULT_UPLOAD_OPD_COMPLETED = 'LAB_RESULT_UPLOAD_OPD_COMPLETED',
  LAB_RESULT_UPLOAD_IPD_COMPLETED = 'LAB_RESULT_UPLOAD_IPD_COMPLETED',
  LAB_RESULT_UPLOAD_IPD_PENDING = 'LAB_RESULT_UPLOAD_IPD_PENDING',
  LAB_REPORT_ENTRY_OPD_PENDING = 'LAB_REPORT_ENTRY_OPD_PENDING',
  LAB_REPORT_ENTRY_IPD_PENDING = 'LAB_REPORT_ENTRY_IPD_PENDING',
  LAB_REPORT_ENTRY_OPD_COMPLETED = 'LAB_REPORT_ENTRY_OPD_COMPLETED',
  LAB_REPORT_ENTRY_IPD_COMPLETED = 'LAB_REPORT_ENTRY_IPD_COMPLETED',
  LAB_DSR_IPD = 'LAB_DSR_IPD',
  LAB_DSR_OPD = 'LAB_DSR_OPD',
  LAB_DSR_TOTAL_PATIENT = 'LAB_DSR_TOTAL',
  LAB_DSR_TOTAL_REVENUE = 'LAB_DSR_TOTAL_REVENUE',
  LAB_DSR_PAYMENT_TYPE = 'LAB_DSR_PAYMENT_TYPE',
  LAB_DSR_TEST_STATUS = 'LAB_DSR_TEST_STATUS',

  INVENTORY_MAIN_STOCK_MEDICINE_LIST = 'INVENTORY_MAIN_STOCK_MEDICINE_LIST',
  INVENTORY_MAIN_STOCK_SUPPLY_LIST = 'INVENTORY_MAIN_STOCK_SUPPLY_LIST',
  INVENTORY_MAIN_STOCK_HOUSEKEEPING_LIST = 'INVENTORY_MAIN_STOCK_HOUSEKEEPING_LIST',
  INVENTORY_MAIN_STOCK_STATIONERY_LIST = 'INVENTORY_MAIN_STOCK_STATIONERY_LIST',
  INVENTORY_MAIN_STOCK_EQUIPMENT_LIST = 'INVENTORY_MAIN_STOCK_EQUIPMENT_LIST',
  INVENTORY_SUB_STOCK_OVERVIEW = 'INVENTORY_SUB_STOCK_OVERVIEW',
  INVENTORY_TRANSFER_REQUEST_PENDING_LIST = 'INVENTORY_TRANSFER_REQUEST_PENDING_LIST',
  INVENTORY_TRANSFER_REQUEST_COMPLETED_LIST = 'INVENTORY_TRANSFER_REQUEST_COMPLETED_LIST',
  INVENTORY_PR_BACKLOG_PENDING_PR_LIST = 'INVENTORY_PR_BACKLOG_PENDING_PR_LIST',
  INVENTORY_PR_BACKLOG_COMPLETED_PR_LIST = 'INVENTORY_PR_BACKLOG_COMPLETED_PR_LIST',
  INVENTORY_RECEIVED_INVENTORY_IN_PROGRESS_ORDERS = 'INVENTORY_RECEIVED_INVENTORY_IN_PROGRESS_ORDERS',
  INVENTORY_RECEIVED_INVENTORY_RECEIVED_ORDERS = 'INVENTORY_RECEIVED_INVENTORY_RECEIVED_ORDERS',
  INVENTORY_PURCHASE_REQUEST_MANAGEMENT = 'INVENTORY_PURCHASE_REQUEST_MANAGEMENT',

  CORP_MANAGEMENT_CATALOGS_LIST = 'CORP_MANAGEMENT_CATALOGS_LIST',
  CORP_MANAGEMENT_INCOME_TYPES_LIST = 'CORP_MANAGEMENT_INCOME_TYPES_LIST',
  CORP_MANAGEMENT_PATIENT_BILLING_CODE = 'CORP_MANAGEMENT_PATIENT_BILLING_CODE',
  CORP_MANAGEMENT_MEDICAL_CODE_LIST = 'CORP_MANAGEMENT_MEDICAL_CODE_LIST',
  CORP_MANAGEMENT_MEDICAL_CODE_LIST_VIEW = 'CORP_MANAGEMENT_MEDICAL_CODE_LIST_VIEW',
  CORP_MANAGEMENT_DF_SETUP = 'CORP_MANAGEMENT_DF_SETUP',
  CORP_MANAGEMENT_DF_ALLOCATION = 'CORP_MANAGEMENT_DF_ALLOCATION',
  CORP_MANAGEMENT_PAYMENT_TYPE_MANAGEMENT_LIST = 'CORP_MANAGEMENT_PAYMENT_TYPE_MANAGEMENT_LIST',
  CORP_MANAGEMENT_FORM_MANAGEMENT_LIST = 'CORP_MANAGEMENT_FORM_MANAGEMENT_LIST',
  CORP_MANAGEMENT_INTERNAL_OPERATION_CATALOG_LIST = 'CORP_MANAGEMENT_INTERNAL_OPERATION_CATALOG_LIST',
  CORP_MANAGEMENT_INTERNAL_OPERATION_CATALOG_ELEMENT = 'CORP_MANAGEMENT_INTERNAL_OPERATION_CATALOG_ELEMENT',
  CORP_MANAGEMENT_SERVICE_CATALOG_ELEMENTS = 'CORP_MANAGEMENT_SERVICE_CATALOG_ELEMENTS',
  CORP_MANAGEMENT_SERVICE_CATALOG_GROUPS = 'CORP_MANAGEMENT_SERVICE_CATALOG_GROUPS',
  CORP_MANAGEMENT_SUPPLIES_CATALOG_ELEMENTS = 'CORP_MANAGEMENT_SUPPLIES_CATALOG_ELEMENTS',
  CORP_MANAGEMENT_SUPPLIES_CATALOG_GROUPS = 'CORP_MANAGEMENT_SUPPLIES_CATALOG_GROUPS',
  CORP_MANAGEMENT_MEDICAL_PACKAGE_LIST = 'CORP_MANAGEMENT_MEDICAL_PACKAGE_LIST',
  CORP_MANAGEMENT_OPD_CHARGES_SETTINGS_LIST = 'CORP_MANAGEMENT_OPD_CHARGES_SETTINGS_LIST',
  CORP_MANAGEMENT_DIAGNOSIS_CODE = 'CORP_MANAGEMENT_DIAGNOSIS_CODE',

  MEDICAL_CODING_ADD_CODE_IPD_PENDING = 'MEDICAL_CODING_ADD_CODE_IPD_PENDING',
  MEDICAL_CODING_ADD_CODE_OPD_PENDING = 'MEDICAL_CODING_ADD_CODE_OPD_PENDING',
  MEDICAL_CODING_ADD_CODE_IPD_COMPLETED = 'MEDICAL_CODING_ADD_CODE_IPD_COMPLETED',
  MEDICAL_CODING_ADD_CODE_OPD_COMPLETED = 'MEDICAL_CODING_ADD_CODE_OPD_COMPLETED',
  MEDICAL_CODING_PATIENT_REVIEW_TODAY = 'MEDICAL_CODING_PATIENT_REVIEW_TODAY',
  MEDICAL_CODING_PATIENT_REVIEW_ALL = 'MEDICAL_CODING_PATIENT_REVIEW_ALL',

  BILLING_DSR_ALL_PATIENT = 'BILLING_DSR_ALL_PATIENT',
  BILLING_DSR_NEWLY_REGISTERED = 'BILLING_DSR_NEWLY_REGISTERED',
  BILLING_DSR_PREVIOUSLY_REG = 'BILLING_DSR_PREVIOUSLY_REG',
  BILLING_DSR_INSURANCE_CC_TYPE = 'BILLING_DSR_INSURANCE_CC_TYPE',
  BILLING_DSR_RECEIPT_NON_VAT_REPORT = 'BILLING_DSR_RECEIPT_NON_VAT_REPORT',
  BILLING_DSR_RECEIPT_VAT_REPORT = 'BILLING_DSR_RECEIPT_VAT_REPORT',
  BILLING_DSR_DEPOSIT_REFUND_FINAL_PAYMENT_REPORT = 'BILLING_DSR_DEPOSIT_REFUND_FINAL_PAYMENT_REPORT',
  BILLING_DSR_INVOICE_INSURANCE_CC = 'BILLING_DSR_INVOICE_INSURANCE_CC',

  BILLING_PIPELINE_ONGOING_APT = 'BILLING_PIPELINE_ONGOING_APT',
  BILLING_PIPELINE_BILLING_INITIATED = 'BILLING_PIPELINE_BILLING_INITIATED',
  BILLING_PIPELINE_BILLING_COMPLETED = 'BILLING_PIPELINE_BILLING_COMPLETED',
  BILLING_VISIT_BILLING_PENDING = 'BILLING_VISIT_BILLING_PENDING',
  BILLING_VISIT_BILLING_COMPLETED = 'BILLING_VISIT_BILLING_COMPLETED',
  BILLING_ADMISSION_BILLING_ONGOING = 'BILLING_ADMISSION_BILLING_ONGOING',
  BILLING_ADMISSION_BILLING_DISCHARGED = 'BILLING_ADMISSION_BILLING_DISCHARGED',
  BILLING_ADMISSION_BILLING_COMPLETED = 'BILLING_ADMISSION_BILLING_COMPLETED',
  BILLING_ACCEPT_PAYMENT_PENDING = 'BILLING_ACCEPT_PAYMENT_PENDING',
  BILLING_ACCEPT_PAYMENT_COMPLETED = 'BILLING_ACCEPT_PAYMENT_COMPLETED',
  BILLING_SUBMIT_CLAIM_PENDING = 'BILLING_SUBMIT_CLAIM_PENDING',
  BILLING_SUBMIT_CLAIM_COMPLETED = 'BILLING_SUBMIT_CLAIM_COMPLETED',
  BILLING_BILL_MANAGEMENT = 'BILLING_BILL_MANAGEMENT',
  BILLING_PATIENT_REVIEW_ONGOING = 'BILLING_PATIENT_REVIEW_ONGOING',
  BILLING_PATIENT_REVIEW_BILLING_INITIATED = 'BILLING_PATIENT_REVIEW_BILLING_INITIATED',

  INSURANCE_SS_CC_COMPANY_CONTRACT_COMPANY_LIST = 'INSURANCE_SS_CC_COMPANY_CONTRACT_COMPANY_LIST',
  INSURANCE_SS_CC_COMPANY_CONTRACT_CONTRACT_LIST = 'INSURANCE_SS_CC_COMPANY_CONTRACT_CONTRACT_LIST',
  INSURANCE_SS_CC_COMPANY_CONTRACT_PLANS_LIST = 'INSURANCE_SS_CC_COMPANY_CONTRACT_PLANS_LIST',
  INSURANCE_SS_CC_COMPANY_CONTRACT_EMPLOYEES_LIST = 'INSURANCE_SS_CC_COMPANY_CONTRACT_EMPLOYEES_LIST',
  INSURANCE_SS_CC_INSURANCE_PLAN_COMPANY_LIST = 'INSURANCE_SS_CC_INSURANCE_PLAN_COMPANY_LIST',
  INSURANCE_SS_CC_INSURANCE_PLAN_CONTRACT_LIST = 'INSURANCE_SS_CC_INSURANCE_PLAN_CONTRACT_LIST',
  INSURANCE_SS_CC_INSURANCE_PLAN_PLAN_LIST = 'INSURANCE_SS_CC_INSURANCE_PLAN_PLAN_LIST',
  INSURANCE_SS_CC_SELF_CODE = 'INSURANCE_SS_CC_SELF_CODE',

  OPERATION_ROOM_DSR = 'OPERATION_ROOM_DSR',
  OPERATION_ROOM_OR_REQUEST_LIST = 'OPERATION_ROOM_OR_REQUEST_LIST',
  OPERATION_ROOM_OR_REQUEST_STATUS_LIST = 'OPERATION_ROOM_OR_REQUEST_STATUS_LIST',
  OPERATION_ROOM_OR_SCHEDULE_LIST = 'OPERATION_ROOM_OR_SCHEDULE_LIST',
  OPERATION_ROOM_SURGICAL_INSTRUCTION_PENDING = 'OPERATION_ROOM_SURGICAL_INSTRUCTION_PENDING',
  OPERATION_ROOM_SURGICAL_INSTRUCTION_IN_PROGRESS = 'OPERATION_ROOM_SURGICAL_INSTRUCTION_IN_PROGRESS',
  OPERATION_ROOM_SURGICAL_INSTRUCTION_COMPLETED = 'OPERATION_ROOM_SURGICAL_INSTRUCTION_COMPLETED',
  OPERATION_ROOM_OR_LIST = 'OPERATION_ROOM_OR_LIST',

  NUTRITION_MEAL_PREPARE_LIST_VIEW = 'NUTRITION_MEAL_PREPARE_LIST_VIEW',
  NUTRITION_MEAL_PREPARE_PRINT_VIEW = 'NUTRITION_MEAL_PREPARE_PRINT_VIEW',
  NUTRITION_MEAL_CATALOG_ELEMENTS = 'NUTRITION_MEAL_CATALOG_ELEMENTS',
  NUTRITION_MEAL_CATALOG_GROUPS = 'NUTRITION_MEAL_CATALOG_GROUPS',

  INVENTORY_MANAGEMENT = 'INVENTORY_MANAGEMENT'
}

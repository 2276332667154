import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';

import {AuthService} from '../../core';
import {matchOtherValidator} from '../../shared';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'authentication'}]
})
export class ResetPasswordComponent implements OnInit {
  errorMessage = '';
  isSubmitting = false;
  passwordResetForm: FormGroup;
  token: string;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar) {
    /* this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
      } else {
        this.router.navigateByUrl('/login').then(nav => {
          return this.snackBar.open("Invalid password reset link.", "Close");
        });
      }
    }) */
  }

  ngOnInit() {
    this.passwordResetForm = this.fb.group({
      token: [this.token],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        matchOtherValidator('password', 'equal')
      ])]
    });
  }

  get password() {
    return this.passwordResetForm.get('password');
  }

  get confirmPassword() {
    return this.passwordResetForm.get('confirmPassword');
  }

  onSubmit() {

    /* this.isSubmitting = true;
    this.errorMessage = '';

    const postData = this.passwordResetForm.value;
    this.authService
      .resetPassword(postData)
      .subscribe(
        data => {
          this.router.navigateByUrl('/login').then(nav => {
						return this.snackBar.open("Password reset successfully. Sign in to continue.", "Close");
					});
        },
        err => {
          this.errorMessage = err.message;
          this.isSubmitting = false;
        }
      ); */
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from '@angular/material';
import {FadeInLeft, FadeInRight, FadeOutLeft, FadeOutRight} from '../../../animations';

const fadeInRightAnimation = FadeInRight(150);
const fadeOutRightAnimation = FadeOutRight(150);
const fadeInLeftAnimation = FadeInLeft(150);
const fadeOutLeftAnimation = FadeOutLeft(150);

@Component({
  selector: 'app-loading-icon-button',
  templateUrl: './loading-icon-button.component.html',
  animations: [fadeInRightAnimation, fadeOutRightAnimation, fadeInLeftAnimation, fadeOutLeftAnimation]
})
export class LoadingIconButtonComponent implements OnInit {
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() disabled = false;
  @Input() color: ThemePalette = 'primary';
  @Input() trigger = false;
  @Input() varient: 'flat' | 'stroked' = 'flat';
  @Input() cssClass = '';
  @Input() spinnerDiameter = 20;
  @Input() iconCss = '';
  @Input() loaderCss = '';

  constructor() {
  }

  ngOnInit() {
  }

  pressed(event: void) {
    if (!this.disabled) {
      this.clicked.emit(event);
    }
  }
}

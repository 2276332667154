import {OrderOriginEnum} from '@v2/core/enums/order-origin.enum';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {PatientOrderStatusEnum} from '@v2/core/enums/patient-order-status.enum';
import {getGenericOriginName, getOrderIdForOrderType, isOrderFromSameOrigin} from '@v2/core/functions/functions';
import {IBillingAppointment, IPageableData} from '@v2/core/models/masterdata';
import {DoctorFeeFormDataModel} from '../form/doctor-fee-form.model';
import {MedicationFormDataModel} from '../form/medication-form.model';
import {NutritionMealFormDataModel} from '../form/nutrition-meal-form.model';
import {PathologyFormDataModel} from '../form/pathology-form.model';
import {ProcedureFormDataModel} from '../form/procedure-form.model';
import {RadiologyFormDataModel} from '../form/radiology-form.model';
import {SupplyFormDataModel} from '../form/supply-form.model';

export class PatientOrderOperationsViewModel {
  orderId: string | number;
  orderStatus: string;
  displayUpdateDeleteOption?: boolean;

  static mapFromFormModel(formData: RadiologyFormDataModel | NutritionMealFormDataModel | PathologyFormDataModel | SupplyFormDataModel | ProcedureFormDataModel | MedicationFormDataModel | DoctorFeeFormDataModel, orderType: string, orderOrigin: OrderOriginEnum): PatientOrderOperationsViewModel {
    const viewModel = new PatientOrderOperationsViewModel();
    viewModel.orderId = getOrderIdForOrderType(orderType, formData);
    const genericOriginName = getGenericOriginName(orderOrigin);
    const isSameOrigin = isOrderFromSameOrigin(genericOriginName, formData.orderOrigin);

    switch (orderType) {
      // case orderTypeEnum.Medication:
      //   viewModel.orderId = (formData as MedicationFormModel).id;
      //   viewModel.orderStatus = (formData as MedicationFormModel).pharmacyStatus.label;
      //   viewModel.displayUpdateDeleteOption = !viewModel.orderId || (!!viewModel.orderId && viewModel.orderStatus === PatientOrderStatusEnum.OPEN);
      //   break;
      case OrderTypeEnum.Supply:
        const supplyData = formData as SupplyFormDataModel;
        viewModel.orderStatus = supplyData.orderStatus;
        viewModel.displayUpdateDeleteOption = !viewModel.orderId || (!!viewModel.orderId && viewModel.orderStatus === PatientOrderStatusEnum.OPEN && !supplyData.isOrderCancelled);
        break;
      case OrderTypeEnum.Procedure:
      case OrderTypeEnum.TreatmentPlanProcedure:
      case OrderTypeEnum.TreatmentProcedure:
        const procedureData = formData as ProcedureFormDataModel;
        viewModel.orderStatus = procedureData.procedureComplyStatus;
        viewModel.displayUpdateDeleteOption = !viewModel.orderId || (!!viewModel.orderId && !viewModel.orderStatus);
        break;
      case OrderTypeEnum.lab:
        const labData = formData as PathologyFormDataModel;
        viewModel.orderStatus = labData.orderStatus;
        viewModel.displayUpdateDeleteOption = !viewModel.orderId || (!!viewModel.orderId && (viewModel.orderStatus === PatientOrderStatusEnum.REQUESTED || viewModel.orderStatus === PatientOrderStatusEnum.CHECKED_IN));
        break;
      case OrderTypeEnum.radiology:
        const radiologyData = formData as RadiologyFormDataModel;
        viewModel.orderStatus = radiologyData.orderStatus;
        viewModel.displayUpdateDeleteOption = !viewModel.orderId || (!!viewModel.orderId && (viewModel.orderStatus === PatientOrderStatusEnum.REQUESTED || viewModel.orderStatus === PatientOrderStatusEnum.CHECKED_IN));
        break;
      case OrderTypeEnum.nutritionMeal:
        const nutritionMealData = formData as NutritionMealFormDataModel;
        viewModel.orderStatus = nutritionMealData.orderStatus;
        viewModel.displayUpdateDeleteOption = !viewModel.orderId || (!!viewModel.orderId && viewModel.orderStatus === PatientOrderStatusEnum.ORDERED);
        break;
      case OrderTypeEnum.DoctorFee:
        const doctorFeeData = formData as DoctorFeeFormDataModel;
        viewModel.orderStatus = doctorFeeData.orderStatus;
        viewModel.displayUpdateDeleteOption = !viewModel.orderId || (!!viewModel.orderId && !viewModel.orderStatus);
        break;
    }

    if (orderType !== OrderTypeEnum.DoctorFee) {
      viewModel.displayUpdateDeleteOption = !viewModel.orderId ? viewModel.displayUpdateDeleteOption : (viewModel.displayUpdateDeleteOption && isSameOrigin);
    }
    return viewModel;
  }
}

export class PatientReorderViewModel {
  appointmentId: string;
  appointmentType: string;
  visitAdmissionXref: string;
  visitDate: string;
  admissionStartDate: string;
  admissionEndDate: string;
  medicationOrders: IPageableData<MedicationFormDataModel>;
  radiologyOrders: IPageableData<RadiologyFormDataModel>;
  labOrders: IPageableData<PathologyFormDataModel>;
  medicationOrdersCount: number;
  radiologyOrdersCount: number;
  labOrdersCount: number;

  static mapFromAppointmentModel(httpData: IBillingAppointment): PatientReorderViewModel {
    const viewData = new PatientReorderViewModel();
    viewData.appointmentId = httpData.appointmentId;
    viewData.appointmentType = httpData.appointmentType ? httpData.appointmentType.label : '';
    if (httpData.visitXref) {
      viewData.visitAdmissionXref = httpData.visitXref.xref;
    } else if (httpData.admissionXref) {
      viewData.visitAdmissionXref = httpData.admissionXref.xref;
    }
    viewData.visitDate = httpData.aptDate;
    viewData.admissionStartDate = httpData.aptDate;
    viewData.admissionEndDate = httpData.aptEndDate;
    viewData.medicationOrders = {items: []} as IPageableData<MedicationFormDataModel>;
    viewData.radiologyOrders = {items: []} as IPageableData<RadiologyFormDataModel>;
    viewData.labOrders = {items: []} as IPageableData<PathologyFormDataModel>;
    viewData.medicationOrdersCount = httpData.medicationOrdersCount || 0;
    viewData.radiologyOrdersCount = httpData.radiologyOrdersCount || 0;
    viewData.labOrdersCount = httpData.pathologyOrdersCount || 0;
    return viewData;
  }
}

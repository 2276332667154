import {FormArray, FormControl, Validators} from '@angular/forms';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {getUTCDateTime, mapDepartmentDataToDepartmentMinimal, mapFullNameInDoctor} from '@v2/core/functions/functions';
import {
  ICatalogElement,
  ICatalogGroup,
  IDepartmentMinimal,
  IDoctor,
  IGroupCommercialOrder,
  IPrescribedBy
} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IMedicalPackage} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IFavoriteOrderElement, IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {PharmacyOutletViewModel} from '@v2/core/models/view/pharmacy-outlet.view.model';
import {objectValidator} from '../../../../../../../shared/validators/object-validator';
import {SupplyViewModel} from '../view/supply-view.model';
import {FavoriteFormDataModel} from './favorite-form.model';

interface ISupplyOrderForm {
  poSupplyId: number | FormControl;
  catalogElementId: SupplyViewModel | FormControl;
  catalogGroupId: string | FormControl;
  qty: number | FormControl;
  pharmacyOutlet: PharmacyOutletViewModel | FormControl;
  prescribedById: IPrescribedBy | string | FormControl;
  recordedTime: string | FormControl;
  requestedDateTime: string | FormControl;
  orderType: string | FormControl;
  orderStatus: string | FormControl;
  isGroup: boolean | FormControl;
  dispensingUnit: string | FormControl;
  size: string | FormControl;
  usageTemplate: string | FormControl;
  instruction: string | FormControl;
  supplyType: string | FormControl;
  isMedicalPackageOrder: boolean | FormControl;
  updatedAt: string | FormControl;
  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
  groupCommercialOrder: Partial<IGroupCommercialOrder> | FormControl;
  orderOrigin: string | FormControl;
  patientOrderId: string | FormControl;
  patientOrderUpdatedAt: string | FormControl;

  isSubmitting?: boolean | FormControl;
  isSubmitted?: boolean | FormControl;
  errorMessage?: string | FormControl;
  isAdded?: boolean | FormControl;
  index?: number | FormControl;
  isFormValid?: boolean | FormControl;
}

export class SupplyOrderForm implements ISupplyOrderForm {
  poSupplyId = new FormControl();
  catalogElementId = new FormControl(null, [Validators.compose([Validators.required, objectValidator])]);
  catalogGroupId = new FormControl();
  qty = new FormControl(null, [Validators.required, Validators.min(1)]);
  pharmacyOutlet = new FormControl(null, Validators.required);
  prescribedById = new FormControl();
  recordedTime = new FormControl();
  requestedDateTime = new FormControl();
  orderType = new FormControl(OrderTypeEnum.Supply);
  orderStatus = new FormControl();
  isGroup = new FormControl(false);
  dispensingUnit = new FormControl();
  size = new FormControl();
  usageTemplate = new FormControl();
  instruction = new FormControl();
  supplyType = new FormControl();
  isMedicalPackageOrder = new FormControl();
  updatedAt = new FormControl();
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  groupCommercialOrder = new FormControl();
  orderOrigin = new FormControl();
  patientOrderId = new FormControl();
  patientOrderUpdatedAt = new FormControl();

  isSubmitting = new FormControl();
  isSubmitted = new FormControl();
  errorMessage = new FormControl();
  isAdded = new FormControl(false);
  index = new FormControl();
  isFormValid = new FormControl();
}

export class SupplyFormDataModel extends ConcurrencyRequest implements ISupplyOrderForm {
  poSupplyId: number;
  poId: string;
  catalogElementId: SupplyViewModel;
  catalogGroupId: string;
  qty: number;
  returnedQty: number;
  prescribedById: IPrescribedBy | string;
  recordedTime: string;
  orderType: string;
  orderStatus: string;
  dispensingUnit: string;
  size: string;
  usageTemplate: string;
  instruction: string;
  supplyType: string;
  pharmacyOutlet: PharmacyOutletViewModel;
  department: IDepartmentMinimal;
  doctor: IDoctor;
  groupCommercialOrder: Partial<IGroupCommercialOrder>;
  groupCommercialOrderId: string;
  requestedDateTime: string;
  orderOrigin: string;
  orderXref: string;

  isGroup: boolean;
  updatedAt: string;

  isOrderCancelled: boolean;
  cancelOrderNote: string;

  index?: number;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  errorMessage?: string;
  isAdded?: boolean;
  isFormValid?: boolean;

  isMedicalPackageOrder: boolean;
  medicalPackage: IMedicalPackage;
  packageSelectionItemId: string;
  hasReturnOrder: boolean;
  patientOrderId: string;
  patientOrderUpdatedAt: string;

  static mapFromHttpModel(httpData: IPatientOrder): SupplyFormDataModel {
    const formData = new SupplyFormDataModel();
    if (httpData.supply) {
      formData.poSupplyId = httpData.supply.poSupplyId;
      formData.poId = httpData.supply.poId;
      formData.catalogElementId = httpData.supply.catalogElement && SupplyViewModel.mapFromHttpModel(httpData.supply.catalogElement);
      formData.catalogGroupId = httpData.supply.catalogGroupId && httpData.supply.catalogGroupId;
      formData.qty = httpData.supply.qty;
      formData.recordedTime = httpData.supply.recordedTime;
      if (httpData.supply.catalogElement) {
        formData.dispensingUnit = httpData.supply.catalogElement.dispenseUnit && httpData.supply.catalogElement.dispenseUnit.label;
        formData.size = httpData.supply.catalogElement.size;
        formData.usageTemplate = httpData.supply.catalogElement.englishTemplate;
        formData.instruction = httpData.supply.catalogElement.englishInstruction;
        formData.supplyType = httpData.supply.catalogElement.catalogCategory && httpData.supply.catalogElement.catalogCategory.name;
      }
      formData.pharmacyOutlet = httpData.supply.pharmacyOutlet && PharmacyOutletViewModel.mapFromApi(httpData.supply.pharmacyOutlet);
      formData.orderOrigin = httpData.supply.orderOrigin ? httpData.supply.orderOrigin.label : '';
      formData.department = mapDepartmentDataToDepartmentMinimal(httpData.supply.orderingDepartment);
      formData.doctor = mapFullNameInDoctor(httpData.supply.orderingDoctor);
    }
    formData.returnedQty = httpData.returnedQty;
    // formData.prescribedById = httpData.supply.prescribedBy;
    formData.requestedDateTime = httpData.requestedDateTime;
    formData.orderType = httpData.orderType ? httpData.orderType.label : '';
    formData.orderStatus = httpData.pharmacyStatus && httpData.pharmacyStatus.label;
    if (httpData.groupCommercialOrder) {
      formData.groupCommercialOrder = {
        id: httpData.groupCommercialOrder.id,
        updatedAt: httpData.groupCommercialOrder.updatedAt ? getUTCDateTime(httpData.groupCommercialOrder.updatedAt) : null
      };
    }
    formData.isMedicalPackageOrder = httpData.isPkgOrder;
    formData.medicalPackage = httpData.medicalPackage;
    formData.packageSelectionItemId = httpData.packageSelectionItemId;
    formData.hasReturnOrder = httpData.hasReturnOrder;
    formData.isOrderCancelled = httpData.orderStatus && httpData.orderStatus.label === 'Canceled';
    formData.cancelOrderNote = httpData.statusNote;
    formData.isSubmitted = null;
    formData.errorMessage = null;
    formData.patientOrderId = httpData.id;
    formData.patientOrderUpdatedAt = httpData.updatedAt;
    formData.orderXref = httpData.xref;
    formData.mapConcurrencyRequest(httpData.supply);
    return formData;
  }

  static mapFromGroupOrderModel(groupOrderData: SupplyGroupFormDataModel, currentUserId: string): SupplyFormDataModel[] {
    const data: SupplyFormDataModel[] = [];
    groupOrderData.group.catalogElements.map(item => {
      const formDataModel = new SupplyFormDataModel();
      formDataModel.poSupplyId = null;
      formDataModel.catalogElementId = SupplyViewModel.mapFromHttpModel(item);
      formDataModel.catalogGroupId = groupOrderData.group.id;
      formDataModel.qty = null;
      formDataModel.prescribedById = currentUserId;
      formDataModel.recordedTime = null;
      formDataModel.orderType = OrderTypeEnum.Supply;
      formDataModel.orderStatus = null;
      formDataModel.isGroup = false;
      formDataModel.dispensingUnit = item.dispenseUnit ? item.dispenseUnit.label : '';
      formDataModel.size = item.size;
      formDataModel.usageTemplate = item.englishTemplate;
      formDataModel.instruction = item.englishInstruction;
      formDataModel.supplyType = item.category as string;
      formDataModel.pharmacyOutlet = groupOrderData.pharmacyOutlet;
      formDataModel.department = groupOrderData.department;
      formDataModel.doctor = groupOrderData.doctor;
      formDataModel.isSubmitted = null;
      formDataModel.isFormValid = false;
      formDataModel.errorMessage = null;
      data.push(formDataModel);
    });
    return data;
  }

  static mapFromFavoriteOrder(favoriteData: FavoriteFormDataModel, element: IFavoriteOrderElement, currentUserId: string): SupplyFormDataModel {
    const formData = new SupplyFormDataModel();
    formData.poSupplyId = null;
    formData.catalogElementId = SupplyViewModel.mapFromHttpModel({
      id: element.catalogElement.id,
      name: element.catalogElement.name,
      dispenseUnit: element.catalogElement.dispensingUnit
    } as ICatalogElement);
    formData.catalogGroupId = null;
    formData.dispensingUnit = element.catalogElement.dispensingUnit ? element.catalogElement.dispensingUnit.label : null;
    formData.prescribedById = currentUserId;
    formData.supplyType = element.catalogElement.catalogCategory ? element.catalogElement.catalogCategory.name : '';
    formData.usageTemplate = element.catalogElement.englishTemplate;
    formData.size = element.size;
    formData.instruction = element.englishInstruction;
    formData.orderType = OrderTypeEnum.Supply;
    formData.qty = 1;
    formData.orderStatus = null;
    formData.department = favoriteData.department;
    formData.doctor = favoriteData.doctor;
    formData.isSubmitted = null;
    formData.errorMessage = null;
    return formData;
  }
}

interface ISupplyGroupOrderForm {
  group: ICatalogGroup | FormControl;
  groupElement: any[] | FormArray;
  pharmacyOutlet: PharmacyOutletViewModel | FormControl;
  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
  isGroup: boolean | FormControl;
}

export class SupplyGroupOrderForm implements ISupplyGroupOrderForm {
  group = new FormControl(null, [Validators.required, objectValidator]);
  groupElement = new FormArray([]);
  pharmacyOutlet = new FormControl();
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  isGroup = new FormControl(true);
}

export class SupplyGroupFormDataModel extends ConcurrencyRequest implements ISupplyGroupOrderForm {
  group: ICatalogGroup;
  groupElement: any[];
  pharmacyOutlet: PharmacyOutletViewModel;
  department: IDepartmentMinimal;
  doctor: IDoctor;
  isGroup: boolean;
  updatedAt: string;
}

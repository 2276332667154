import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'allergySeverity'
})
export class AllergySeverityPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'Severe':
        return 'text-burnt-sienna bg-burnt-sienna-15'
      case 'Moderate':
        return 'text-jaffa bg-jaffa-15'
      case 'Mild':
        return 'text-eucalyptus bg-eucalyptus-15'
      default:
        return 'bg-black-1 text-black-5';
    }
  }
}

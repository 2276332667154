import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material';
import {TranslocoModule} from '@ngneat/transloco';
import {SignatureDisplayComponent} from './signature-display.component';

@NgModule({
  declarations: [SignatureDisplayComponent],
  exports: [
    SignatureDisplayComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    FlexModule,
    MatButtonModule
  ]
})
export class SignatureDisplayModule {
}

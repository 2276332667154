import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {IBillingAppointment} from '@v2/core/models/masterdata';
import {INurseNote} from '@v2/core/models/masterdata/INurseNote';
import {Observable} from 'rxjs';
import {NurseNotePostModel} from '../../../../../visits/opd/shared/models/nurse-note.model';
import {PostModel} from '../../../../models';
import {BaseHttpService} from '../../../base-http.service';
import {BaseModel, DataModel} from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class NurseNoteApiService {

  endPoint = 'opd/ns';

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  getAppointmentData(nurseId: string, appointmentId: string): Observable<BaseModel<IBillingAppointment>> {
    return this.baseHttpService.get(`${this.endPoint}/${nurseId}/appointment/${appointmentId}`)
  }

  postForwardToDoctor(nurseId: string, appointmentId: string, forwardToDoctorData): Observable<BaseModel<IBillingAppointment>> {
    return this.baseHttpService.postWrapper(`${this.endPoint}/${nurseId}/appointment/${appointmentId}/forward-to-doctor`, forwardToDoctorData, ConcurrencyScopeEnum.FORWARD_TO_DOCTOR)
  }

  getNurseNotes(appointmentId: string): Observable<BaseModel<DataModel<INurseNote>>> {
    return this.baseHttpService.get(`appointment/${appointmentId}/patient-medical-data/nurse-note`)
  }

  putNurseNotes(appointmentId: string, nurseNoteData: PostModel<NurseNotePostModel, string>): Observable<BaseModel<DataModel<INurseNote>>> {
    return this.baseHttpService.postWrapper(`appointment/${appointmentId}/patient-medical-data/nurse-note`, nurseNoteData, ConcurrencyScopeEnum.NURSE_NOTE);
  }
}

export enum OrderTypeEnum  {
  lab = 'Lab',
  radiology = 'Radiology',
  operationRoomRequest = 'OR Request',
  bloodRequest = 'Blood Request',
  nutritionMeal = 'Nutrition & Meal',
  TreatmentPlanProcedure = 'Treatment Plan - Procedure',
  TreatmentProcedure = 'Treatment Procedure',
  Procedure = 'Procedure',
  Supply = 'Supply',
  Medication = 'Medication',
  TreatmentPlan = 'Treatment Plan',
  Favorite = 'Favorite',
  Pharmacy = 'Pharmacy',
  DoctorFee = 'Doctor Fee',
  MEDICAL_PACKAGE = 'Medical Package',
  prescriptionOnDischarge = 'Prescription On Discharge',
  prescription = 'Prescription',
  medicalService = 'Medical service',
  Reorder = 'Reorder' // not an actual order type, used for displaying reorder items without selecting any order type
}

import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppointmentTypeEnum} from '@v2/core/enums/appointment-type.enum';
import {FrequencyTypeEnum} from '@v2/core/enums/frequencyTypeEnum';
import {OrderOriginEnum} from '@v2/core/enums/order-origin.enum';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {
  getCurrentDateTime,
  getGenericOriginName,
  getName,
  isNullOrUndefined,
  isOrderFromSameOrigin,
  mapDepartmentDataToDepartmentMinimal,
  mapFullNameInDoctor,
  objectValidator
} from '@v2/core/functions/functions';
import {getMedicineDataForUsageTemplateLog} from '@v2/core/functions/usage-template-method';
import {
  ICatalogGroup,
  IDepartmentMinimal,
  IDoctor,
  IMasterData,
  IMedication,
  IMedicineFrequency,
  IMedicineUsageMethodTemplateLogData,
  IPrescribedBy
} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IMedicalPackage} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IFavoriteOrderElement} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {FrequencyDataViewModel} from '@v2/core/models/view/frequency-data.view.model';
import {PharmacyOutletViewModel} from '@v2/core/models/view/pharmacy-outlet.view.model';
import {MedicineViewModel} from '../view/medicine-view.model';
import {FavoriteFormDataModel} from './favorite-form.model';

interface IMedicationOrderForm {
  id: string | FormControl;
  isGroup: boolean | FormControl;
  catalogGroupId: string | FormControl;
  medicine: MedicineViewModel | FormControl;
  catalogGroup: ICatalogGroup | FormControl;
  dosage: number | string | FormControl;
  dosageUnit: IMasterData | FormControl;
  intake: IMasterData | FormControl;
  route: IMasterData | FormControl;
  days: number | FormControl;
  mentionDays: boolean | FormControl;
  frequency: IMasterData | FormControl;
  isCustomFrequency: boolean | FormControl;
  frequencyHourData: number | FormControl;
  frequencyData: IMedicineFrequency | FormGroup;
  instruction: string | FormControl;
  startDate: string | FormControl;
  qty: number | FormControl;
  dispensingUnit: IMasterData | FormControl;
  pharmacyOutlet: PharmacyOutletViewModel | FormControl;
  prescribedBy: IPrescribedBy | FormControl;
  prescribedById: string | FormControl;
  isExist: boolean | FormControl;
  isOutOfStock: boolean | FormControl;
  pharmacyStatus: IMasterData | FormControl;
  recordedTime: string | FormControl;
  isHomeMedicine: boolean | FormControl;
  usageTemplate: string | FormControl;
  usageMethod: string | FormControl;
  isMedicalPackageOrder: boolean | FormControl;
  updatedAt: string | FormControl;
  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
  isMedicineDecline: number | FormControl;
  declinedNote: string | FormControl;
  orderOrigin: string | FormControl;
  isSameOriginOrder: boolean | FormControl;
  patientOrderId: string | FormControl;
  orderType: string | FormControl;
  isReordered: boolean | FormControl;
  isDaysApplicable: boolean | FormControl;
  reorderVisitType: string | FormControl;

  isSubmitting?: boolean | FormControl;
  isSubmitted?: boolean | FormControl;
  errorMessage?: string | FormControl;
  isAdded?: boolean | FormControl;
  index?: number | FormControl;
  isFormValid?: boolean | FormControl;
  medicineUsageMethodTemplateLogData: IMedicineUsageMethodTemplateLogData | FormControl;
  patientOrderUpdatedAt: string | FormControl;
}

export class MedicationOrderForm implements IMedicationOrderForm {
  id = new FormControl();
  isGroup = new FormControl(false);
  catalogGroupId = new FormControl();
  medicine = new FormControl(null, [Validators.required, objectValidator]);
  catalogGroup = new FormControl();
  dosage = new FormControl(null, [Validators.required, Validators.min(0.0001)]);
  dosageUnit = new FormControl();
  intake = new FormControl();
  route = new FormControl();
  days = new FormControl(null, [Validators.min(1)]);
  mentionDays = new FormControl(false);
  frequency = new FormControl(null, [Validators.required]);
  isDaysApplicable = new FormControl();
  isCustomFrequency = new FormControl(false);
  frequencyHourData = new FormControl(null, [Validators.min(1)]);
  frequencyData = new FormGroup({
    inMorning: new FormControl(),
    inAfternoon: new FormControl(),
    inEvening: new FormControl(),
    inNight: new FormControl()
  });
  instruction = new FormControl();
  startDate = new FormControl();
  qty = new FormControl(null, [Validators.required, Validators.min(1)]);
  dispensingUnit = new FormControl();
  pharmacyOutlet = new FormControl(null, [Validators.required]);
  prescribedBy = new FormControl();
  prescribedById = new FormControl();
  isExist = new FormControl(true);
  isOutOfStock = new FormControl(false);
  pharmacyStatus = new FormControl();
  recordedTime = new FormControl();
  isHomeMedicine = new FormControl(false);
  usageTemplate = new FormControl();
  usageMethod = new FormControl();
  isMedicalPackageOrder = new FormControl();
  updatedAt = new FormControl();
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  isMedicineDecline = new FormControl(0);
  declinedNote = new FormControl();
  orderOrigin = new FormControl();
  isSameOriginOrder = new FormControl();
  patientOrderId = new FormControl();
  orderType = new FormControl(OrderTypeEnum.Medication);
  isReordered = new FormControl(false);
  reorderVisitType = new FormControl(null);

  isSubmitting = new FormControl();
  isSubmitted = new FormControl();
  errorMessage = new FormControl();
  isAdded = new FormControl(false);
  index = new FormControl();
  isFormValid = new FormControl(false);
  medicineUsageMethodTemplateLogData = new FormControl({usageMethod: '', usageTemplate: ''});
  patientOrderUpdatedAt = new FormControl();
}

export class MedicationFormDataModel extends ConcurrencyRequest implements IMedicationOrderForm {
  id: string;
  catalogGroupId: string;
  medicine: MedicineViewModel;
  dosage: number | string;
  dosageUnit: IMasterData;
  isCustomFrequency: boolean;
  frequencyData: IMedicineFrequency;
  frequencyHourData: number;
  intake: IMasterData;
  route: IMasterData;
  days: number;
  isDaysApplicable: boolean;
  mentionDays: boolean;
  frequency: IMasterData;
  instruction: string;
  prescribedBy: IPrescribedBy;
  prescribedById: string;
  startDate: string;
  isMedicineDecline: number;
  declinedNote: string;
  qty: number;
  returnedQty: number;
  pharmacyOutlet: PharmacyOutletViewModel;
  isExist: boolean;
  isOutOfStock: boolean;
  recordedTime: string;
  orderType: string;
  pharmacyStatus: IMasterData;
  isHomeMedicine: boolean;
  usageTemplate: string;
  usageMethod: string;
  hasAllergy: boolean;
  isMedicalPackageOrder: boolean;
  hasReturnOrder: boolean;
  department: IDepartmentMinimal;
  doctor: IDoctor;
  orderOrigin: string;
  orderXref: string;
  isSameOriginOrder: boolean;
  catalogCatogoryName?: string;
  catalogGroup: ICatalogGroup;
  dispensingUnit: IMasterData;
  isGroup: boolean;
  patientOrderId: string;
  updatedAt: string;
  isPkgOrder: boolean;
  prescribedByDisplayValue: string;
  groupCommercialOrderId: string;

  isOrderCancelled: boolean;
  cancelOrderNote: string;
  isReordered: boolean;
  reorderVisitType: string;
  isSelectedForReorder: boolean;
  checkingReorderValidation: boolean;
  reorderError: boolean;

  index?: number;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  errorMessage?: string;
  isAdded?: boolean;
  isFormValid?: boolean;
  medicalPackage: IMedicalPackage;
  packageSelectionItemId: string;
  medicineUsageMethodTemplateLogData: IMedicineUsageMethodTemplateLogData;
  patientOrderUpdatedAt: string;

  static mapFromHttpModel(order: IMedication, orderOrigin?: string, appointmentDoctor?: IDoctor, appointmentDepartment?: IDepartmentMinimal): MedicationFormDataModel {
    const formData = new MedicationFormDataModel();
    formData.id = order.pdMedicationId || order.id;
    formData.isGroup = false;
    formData.catalogGroupId = order.catalogGroupId;
    formData.medicine = MedicineViewModel.mapFromHttpModel(order.medicine);
    formData.dosage = order.dosage ? order.dosage.toString() : null;
    formData.dosageUnit = order.dosageUnit;
    formData.dispensingUnit = order.dispenseUnit;
    formData.isCustomFrequency = order.isCustomFrequency;
    if (order.frequencyData) {
      formData.frequencyData = order.frequencyData;
    } else {
      const frequencyData = new FrequencyDataViewModel();
      frequencyData.inMorning = 0;
      frequencyData.inAfternoon = 0;
      frequencyData.inEvening = 0;
      frequencyData.inNight = 0;
      formData.frequencyData = frequencyData;
    }
    formData.frequencyHourData = order.frequency || order.frequencyHours;
    formData.intake = order.intake;
    formData.route = order.route;
    formData.days = order.days;
    formData.isDaysApplicable = order.isDaysApplicable;
    formData.frequency = order.frequencyType;
    formData.instruction = order.instruction;
    formData.prescribedBy = order.prescribedBy;
    formData.prescribedById = order.prescribedBy ? order.prescribedBy.id : null;
    formData.prescribedByDisplayValue = order.orderingDoctor ? getName(order.orderingDoctor.firstName, order.orderingDoctor.lastName) : '';
    formData.startDate = order.medicine && order.medicine.startDate;
    formData.isMedicineDecline = order.isMedicineDecline;
    formData.declinedNote = order.medicationSpecAssociationDesc;
    formData.qty = order.qty;
    formData.returnedQty = order.returnedQty;
    formData.pharmacyOutlet = order.pharmacyOutlet ? PharmacyOutletViewModel.mapFromApi(order.pharmacyOutlet) : null;
    formData.isExist = null;
    formData.isOutOfStock = null;
    formData.recordedTime = order.recordedTime;
    formData.orderType = OrderTypeEnum.Medication;
    formData.isMedicalPackageOrder = order.isPkgOrder;
    formData.medicalPackage = order.medicalPackage;
    formData.packageSelectionItemId = order.packageSelectionItemId;
    formData.hasReturnOrder = order.hasReturnOrder;
    formData.pharmacyStatus = order.pharmacyStatus;
    formData.isHomeMedicine = order.isHomeMedicine;
    formData.isReordered = false;
    formData.usageTemplate = order.usageTemplate;
    formData.usageMethod = order.usageInstruction;
    formData.department = mapDepartmentDataToDepartmentMinimal(order.orderingDepartment) || appointmentDepartment;
    formData.doctor = mapFullNameInDoctor(order.orderingDoctor) || appointmentDoctor;
    formData.isSubmitted = null;
    formData.errorMessage = null;
    formData.orderXref = order.xref;
    if (orderOrigin) {
      const genericOriginName = getGenericOriginName(orderOrigin as OrderOriginEnum);
      const originToCompare = order.orderOrigin ? order.orderOrigin.label : '';
      formData.isSameOriginOrder = isOrderFromSameOrigin(genericOriginName, originToCompare);
    } else {
      formData.isSameOriginOrder = true;
    }
    formData.hasAllergy = false;
    formData.isPkgOrder = order.isPkgOrder;
    formData.hasAllergy = false;
    formData.medicineUsageMethodTemplateLogData = getMedicineDataForUsageTemplateLog(
      order.pdMedicationId,
      order.usageTemplate,
      order.usageInstruction,
      order.medicine && order.medicine.brandName,
      order.dispensingUnit || (order.medicine && order.medicine.dispensingUnit),
      order.dosage,
      order.dosageUnit,
      order.frequencyType,
      order.frequency
    );
    formData.patientOrderId = order.patientOrderId;
    formData.patientOrderUpdatedAt = order.patientOrderUpdatedAt;
    formData.mapConcurrencyRequest(order);
    return formData;
  }

  static mapFromGroupOrderModel(formData: MedicationFormDataModel): MedicationFormDataModel[] {
    const data: MedicationFormDataModel[] = [];
    formData.catalogGroup.catalogElements.map(element => {
      const formDataModel = new MedicationFormDataModel();
      formDataModel.id = null;
      formDataModel.isGroup = false;
      formDataModel.catalogGroupId = formData.catalogGroup.id;
      formDataModel.medicine = MedicineViewModel.mapFromHttpModel(element);
      formDataModel.dosage = element.dose;
      formDataModel.dosageUnit = element.dosageUnit;
      formDataModel.dispensingUnit = element.dispensingUnit;
      formDataModel.intake = element.intake;
      formDataModel.route = element.route;
      formDataModel.days = element.days;
      formDataModel.frequency = element.frequency;
      formDataModel.orderType = OrderTypeEnum.Medication;
      formDataModel.pharmacyOutlet = formData.pharmacyOutlet;
      formDataModel.isHomeMedicine = formData.isHomeMedicine;
      formDataModel.department = formData.department;
      formDataModel.doctor = formData.doctor;
      formDataModel.prescribedByDisplayValue = formData.doctor ? formData.doctor.fullName : '';
      formDataModel.usageTemplate = element.thaiTemplate;
      formDataModel.usageMethod = element.thaiInstruction;
      formDataModel.isSubmitted = null;
      formDataModel.errorMessage = null;
      /* no need to map medicine log data as we won't have group medicine orders in already placed orders list */
      formDataModel.medicineUsageMethodTemplateLogData = {
        usageMethod: element.thaiInstruction,
        usageTemplate: element.thaiTemplate
      } as IMedicineUsageMethodTemplateLogData;
      formDataModel.prescribedById = formData.prescribedById;
      formData.isFormValid = false;
      data.push(formDataModel);
    });
    return data;
  }

  static mapFromFavoriteOrder(favoriteData: FavoriteFormDataModel, element: IFavoriteOrderElement): MedicationFormDataModel {
    const formData = new MedicationFormDataModel();
    formData.id = null;
    formData.isGroup = false;
    formData.catalogGroupId = null;
    formData.qty = 1;
    formData.isHomeMedicine = false;
    if (element.catalogElement) {
      formData.medicine = MedicineViewModel.mapFromHttpModel(element.catalogElement);
      formData.dosage = element.catalogElement.dose;
      formData.dosageUnit = element.catalogElement.dosageUnit;
      formData.intake = element.catalogElement.intake;
      formData.route = element.catalogElement.route;
      formData.days = element.catalogElement.days;
      formData.frequency = element.catalogElement.frequency;
      formData.usageTemplate = element.catalogElement.thaiTemplate;
      formData.usageMethod = element.catalogElement.thaiInstruction;
      formData.dispensingUnit = element.catalogElement.dispensingUnit;
    }
    formData.orderType = OrderTypeEnum.Medication;
    formData.frequencyData = FrequencyDataViewModel.getDefaultData(null);
    formData.isExist = null;
    formData.isOutOfStock = null;
    formData.department = favoriteData.department;
    formData.doctor = favoriteData.doctor;
    formData.prescribedById = favoriteData.doctor ? favoriteData.doctor.id : null;
    formData.isSubmitted = null;
    formData.errorMessage = null;
    /* no need to map medicine log data as we don't display usage template method popup for in-memory orders, and favorite order will be in-memory only */
    formData.medicineUsageMethodTemplateLogData = {
      usageMethod: formData.usageMethod,
      usageTemplate: formData.usageTemplate
    } as IMedicineUsageMethodTemplateLogData;
    return formData;
  }

  static mapForMedicationReorder(
    medicationFormData: MedicationFormDataModel,
    currentAppointmentType: string,
    selectedOrders: Array<{ pdMedicationId: string, selectedOrderVisitType: string }>,
    appointmentDepartmentInfo: IDepartmentMinimal,
    appointmentDoctorInfo: IDoctor
  ): MedicationFormDataModel {
    const matchedOrderData = selectedOrders.find(order => order.pdMedicationId === medicationFormData.id);

    medicationFormData.id = null;
    medicationFormData.department = {...appointmentDepartmentInfo};
    medicationFormData.doctor = {...appointmentDoctorInfo};
    medicationFormData.isReordered = true;
    medicationFormData.isMedicineDecline = null;
    medicationFormData.isPkgOrder = false;
    medicationFormData.isHomeMedicine = isNullOrUndefined(medicationFormData.isHomeMedicine) ? true : medicationFormData.isHomeMedicine;
    medicationFormData.isMedicalPackageOrder = false;
    medicationFormData.hasReturnOrder = false;
    medicationFormData.hasAllergy = false;
    medicationFormData.reorderVisitType = currentAppointmentType;
    medicationFormData.recordedTime = getCurrentDateTime();
    medicationFormData.updatedAt = null;
    if (!matchedOrderData || (matchedOrderData && currentAppointmentType !== matchedOrderData.selectedOrderVisitType)) {
      medicationFormData.frequency = null;
      medicationFormData.frequencyData = FrequencyDataViewModel.getDefaultData(null);
      medicationFormData.frequencyHourData = null;
      medicationFormData.isCustomFrequency = false;
      medicationFormData.qty = null;
      medicationFormData.days = 1;
      medicationFormData.mentionDays = true;
    }
    if (currentAppointmentType === AppointmentTypeEnum.VISIT &&
      matchedOrderData.selectedOrderVisitType === AppointmentTypeEnum.VISIT &&
      medicationFormData.isHomeMedicine &&
      medicationFormData.frequency && medicationFormData.frequency.label === FrequencyTypeEnum.Stat
    ) {
      medicationFormData.frequency = null;
    }
    if (currentAppointmentType === AppointmentTypeEnum.ADMISSION && matchedOrderData.selectedOrderVisitType === AppointmentTypeEnum.ADMISSION && medicationFormData.isHomeMedicine) {
      medicationFormData.frequency = null;
      medicationFormData.frequencyHourData = null;
      if (medicationFormData.days && medicationFormData.isDaysApplicable) {
        medicationFormData.mentionDays = true;
      } else {
        medicationFormData.days = 1;
        medicationFormData.mentionDays = true;
      }
    }
    if (medicationFormData.isDaysApplicable) {
      if (!medicationFormData.days) {
        medicationFormData.days = 1;
      }
      medicationFormData.mentionDays = true;
    }
    let isFormValid = true;
    if (medicationFormData.isCustomFrequency) {
      if (
        !medicationFormData.frequencyData ||
        (medicationFormData.frequencyData && !medicationFormData.frequencyData.inMorning && !medicationFormData.frequencyData.inAfternoon && !medicationFormData.frequencyData.inEvening && !medicationFormData.frequencyData.inNight) ||
        !medicationFormData.qty || !medicationFormData.pharmacyOutlet
      ) {
        isFormValid = false;
      }
    } else {
      if (!medicationFormData.frequency || !medicationFormData.dosage || !medicationFormData.qty || !medicationFormData.pharmacyOutlet) {
        isFormValid = false;
      }
    }
    medicationFormData.isFormValid = isFormValid;
    return medicationFormData;
  }
}

export class MedicalPackagePrescriptionOrderFormDataModel {
  medicineId: string;
  dosage: string;
  dosageUnit: IMasterData;
  isCustomFrequency: boolean;
  frequency: IMasterData;
  frequencyData: IMedicineFrequency;
  frequencyHours: number;
  intake: IMasterData;
  route: number;
  department: IDepartmentMinimal;
  doctor: IDoctor;
  days: number;
  mentionDays: boolean;
  frequencyType: string;
  instruction: string;
  pharmacyOutletId: string;
  pkgSelectionItemId: string;
  requestedTime: string;
  prescribedById: string;
  groupCommercialOrderId?: string;

  usageTemplate: string;
  usageMethod: string;
}

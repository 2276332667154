import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {getModalWidth} from '@v2/core/functions/functions';
import {IPaymentPriority, IPaymentType} from '@v2/core/models/masterdata';
import {PaymentTypeEnum} from '../../../../v2/shared-v2/resources/enums/payment-type.enum';
import {PaymentPlanDetailModalComponent} from './payment-plan-detail-modal/payment-plan-detail-modal.component';

@Component({
  selector: 'app-payment-type-plan-chip',
  templateUrl: './payment-type-plan-chip.component.html',
  styleUrls: ['./payment-type-plan-chip.component.scss'],
})
export class PaymentTypePlanChipComponent implements OnInit {

  @Input() paymentPlan: Array<IPaymentType | IPaymentPriority> = [];
  @Input() showPriority: boolean
  @Input() onlyShowPlansHavingPriority = false;
  @Input() appointmentId: string;
  @Input() readonly = false;
  @Input() outerPlansCount = 2;
  @Input() showTooltip = false;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.onlyShowPlansHavingPriority) {
      this.paymentPlan = this.paymentPlan.filter(plan => plan.priority !== null);
    }
  }

  showPaymentPlanDetails(item: IPaymentPriority | IPaymentType) {
    if (this.readonly) {
      return;
    }
    if ((item as IPaymentPriority).paymentTypeShortName === 'SP' || (item as IPaymentPriority).typeObj && (item as IPaymentPriority).typeObj.category && (item as IPaymentPriority).typeObj.category.label === PaymentTypeEnum.selfPay) {
      return;
    }
    const [modalWidth] = getModalWidth(0);
    this.dialog.open(PaymentPlanDetailModalComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: modalWidth,
      data: {
        paymentPlanId: item.paymentPlanId || (item as IPaymentType).id,
        appointmentId: this.appointmentId
      }
    });
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-secondary-menu',
  templateUrl: './secondary-menu.component.html',
  styleUrls: ['./secondary-menu.component.scss']
})
export class SecondaryMenuComponent implements OnInit {

  @Input() menuLink: string;
  @Input() queryParams = {};
  @Input() menuItem: string;
  @Input() menuClass: string;
  @Input() disabled = false;
  @Input() showInfoIcon = false;
  @Input() infoMessage = '';

  constructor() {
  }

  ngOnInit() {
  }

}

import {IMasterData, IMedicineUsageMethodTemplateLogData} from '@v2/core/models/masterdata';

export function getMedicineDataForUsageTemplateLog(
  pdMedicationId: string,
  usageTemplate: string,
  usageMethod: string,
  brandName: string,
  dispensingUnit: string | IMasterData,
  dosage: string | number,
  dosageUnit: string | IMasterData,
  frequencyType: string | IMasterData,
  frequency: string | IMasterData
): IMedicineUsageMethodTemplateLogData {
  return {
    usageTemplate: usageTemplate || '',
    usageMethod: usageMethod || '',
    dosage: dosage || '',
    dosageUnit: (dosageUnit && typeof dosageUnit === 'object' ? dosageUnit.label : dosageUnit) || '',
    frequencyType: (frequencyType && typeof frequencyType === 'object' ? frequencyType.label : frequencyType) || '',
    frequency: (frequency && typeof frequency === 'object' ? frequency.label : frequency) || '',
    dispensingUnit: (dispensingUnit && typeof dispensingUnit === 'object' ? dispensingUnit.label : dispensingUnit) || '',
    brandName: brandName || '',
    pdMedicationId
  } as IMedicineUsageMethodTemplateLogData;
}

import {MAIN_MENU_ROUTES, MAIN_MENUS_KEYS} from './main-menu-routes';

interface MainSubMenu {
  label: string
  link: string
  icon: string
}

export interface MainMenu {
  label: string
  link: string
  key: string
  hasSubMenu: boolean
  subMenus: MainSubMenu[]
}

// Change names in client/src/assets/i18n/sidebar/en.json and th.json files when changing names in any menu below
export const MAIN_MENUS: MainMenu[] = [
  {
    label: 'Registration',
    link: `/${MAIN_MENU_ROUTES.registration}`,
    key: MAIN_MENUS_KEYS.registration,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/registration/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient List',
        link: '/registration/patient',
        icon: 'ic-patient'
      },
      {
        label: 'Patient  Verification',
        link: '/registration/patient-verification',
        icon: 'ic-patient-verification'
      }
    ]
  },
  {
    label: 'Rapid Registration',
    link: `/${MAIN_MENU_ROUTES.rapidRegistration}`,
    key: MAIN_MENUS_KEYS.rapidRegistration,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/rapid-registration/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient List',
        link: '/rapid-registration/patient',
        icon: 'ic-patient'
      },
      {
        label: 'Triage',
        link: '/rapid-registration/triage',
        icon: 'ic-triage'
      },
      {
        label: 'Pending Orders',
        link: '/rapid-registration/pending-orders',
        icon: 'ic-staff-scheduling'
      }
    ]
  },
  {
    label: 'Payment Type',
    link: `/${MAIN_MENU_ROUTES.paymentType}`,
    key: MAIN_MENUS_KEYS.paymentType,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/payment-type/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Payment  Plan',
        link: '/payment-type/payment-plan',
        icon: 'ic-payment-plan'
      },
      {
        label: 'Validate  Payment',
        link: '/payment-type/validate-payment',
        icon: 'ic-validate-payment'
      },
      {
        label: 'Validate  Coverage',
        link: '/payment-type/validate-coverage',
        icon: 'ic-validate-coverage'
      },
      {
        label: 'Payment  Priority',
        link: '/payment-type/payment-priority',
        icon: 'ic-payment-priority'
      }
    ]
  },
  {
    label: 'Patient Visit',
    link: `/${MAIN_MENU_ROUTES.patientVisit}`,
    key: MAIN_MENUS_KEYS.patientVisit,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/patient-visit/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient  Appointment',
        link: '/patient-visit/patient-appointment',
        icon: 'ic-patient-appointment'
      },
      {
        label: 'Triage',
        link: '/patient-visit/triage',
        icon: 'ic-triage'
      }
    ]
  },
  {
    label: 'ADT Management',
    link: `/${MAIN_MENU_ROUTES.adtManagement}`,
    key: MAIN_MENUS_KEYS.adtManagement,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/adt-management/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Admit Transfer Request',
        link: '/adt-management/admit-transfer-request',
        icon: 'ic-admit-transfer-request'
      },
      {
        label: 'Bed Occupancy',
        link: '/adt-management/bed-occupancy',
        icon: 'ic-bed-occupacy'
      },
      {
        label: 'Discharge Patient',
        link: '/adt-management/discharge-patient',
        icon: 'ic-discharge-patient'
      },
      {
        label: 'Pending Orders',
        link: '/adt-management/pending-order',
        icon: 'ic-staff-scheduling'
      }
    ]
  },
  {
    label: 'Bed Management',
    link: `/${MAIN_MENU_ROUTES.bedManagement}`,
    key: MAIN_MENUS_KEYS.bedManagement,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Hospital Bed',
        link: '/bed-management/hospital-bed',
        icon: 'ic-hospital-bed'
      },
      {
        label: 'Patient Room',
        link: '/bed-management/patient-room',
        icon: 'ic-room-type'
      },
      {
        label: 'Bed Pipeline',
        link: '/bed-management/bed-pipeline',
        icon: 'ic-bed-pipeline1'
      }
    ]
  },
  {
    label: 'Nurse Station (OPD)',
    link: `/${MAIN_MENU_ROUTES.nurseOpd}`,
    key: MAIN_MENUS_KEYS.nurseOpd,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/opd/nurse/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient Profile',
        link: '/opd/nurse/patient-profile/',
        icon: 'ic-patient-profile'
      },
      {
        label: 'Patient Data',
        link: '/opd/nurse/patient-data',
        icon: 'ic-patient-data'
      },
      {
        label: 'Patient Review',
        link: '/opd/nurse/patient-review',
        icon: 'ic-patient-review'
      },
      {
        label: 'Consultation/Transfer',
        link: '/opd/nurse/consultation-transfer',
        icon: 'ic-consultation-transfer'
      }
    ]
  },
  {
    label: 'Nurse Station (IPD)',
    link: `/${MAIN_MENU_ROUTES.nurseIpd}`,
    key: MAIN_MENUS_KEYS.nurseIpd,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/ipd/nurse/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient Profile',
        link: '/ipd/nurse/patient-profile/',
        icon: 'ic-patient-profile'
      },
      {
        label: 'Patient Data',
        link: '/ipd/nurse/patient-data',
        icon: 'ic-patient-data'
      },
      {
        label: 'Patient Review',
        link: '/ipd/nurse/patient-review',
        icon: 'ic-patient-review'
      },
      {
        label: 'Patient Management',
        link: '/ipd/nurse/patient-management',
        icon: 'ic-admission-management'
      }
    ]
  },
  {
    label: 'Doctor (OPD)',
    link: `/${MAIN_MENU_ROUTES.doctorOpd}`,
    key: MAIN_MENUS_KEYS.doctorOpd,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/opd/doctor/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient Profile',
        link: '/opd/doctor/patient-profile/',
        icon: 'ic-patient-profile'
      },
      {
        label: 'Patient Data',
        link: '/opd/doctor/patient-data',
        icon: 'ic-patient-data'
      },
      {
        label: 'Patient Review',
        link: '/opd/doctor/patient-review',
        icon: 'ic-patient-review'
      },
      {
        label: 'Consultation/Transfer',
        link: '/opd/doctor/consultation-transfer',
        icon: 'ic-consultation-transfer'
      }
    ]
  },
  {
    label: 'Doctor (IPD)',
    link: `/${MAIN_MENU_ROUTES.doctorIpd}`,
    key: MAIN_MENUS_KEYS.doctorIpd,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/ipd/doctor/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient Profile',
        link: '/ipd/doctor/patient-profile/',
        icon: 'ic-patient-profile'
      },
      {
        label: 'Patient Data',
        link: '/ipd/doctor/patient-data',
        icon: 'ic-patient-data'
      },
      {
        label: 'Patient Review',
        link: '/ipd/doctor/patient-review',
        icon: 'ic-patient-review'
      },
      {
        label: 'Patient Management',
        link: '/ipd/doctor/patient-management',
        icon: 'ic-admission-management'
      }
    ]
  },
  {
    label: 'Ward Management',
    link: `/${MAIN_MENU_ROUTES.wardManagement}`,
    key: MAIN_MENUS_KEYS.wardManagement,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/ward-management/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Hospital Ward',
        link: '/ward-management/hospital-ward',
        icon: 'ic-hospital-ward'
      },
      {
        label: 'Nursing Station',
        link: '/ward-management/nursing-station',
        icon: 'ic-nursing-stations'
      }
    ]
  },
  {
    label: 'Radiology',
    link: `/${MAIN_MENU_ROUTES.radiology}`,
    key: MAIN_MENUS_KEYS.radiology,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/radiology/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient Backlog',
        link: '/radiology/patient-backlog',
        icon: 'ic-patient-backlog'
      },
      {
        label: 'Patient Investigation',
        link: '/radiology/patient-investigation',
        icon: 'ic-patient-investigation'
      },
      {
        label: 'Result Upload',
        link: '/radiology/result-upload',
        icon: 'ic-patient-reports'
      },
      {
        label: 'Report Entry',
        link: '/radiology/report-entry',
        icon: 'ic-report-request'
      }
    ]
  },
  {
    label: 'Lab',
    link: `/${MAIN_MENU_ROUTES.lab}`,
    key: MAIN_MENUS_KEYS.lab,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/pathology/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Patient Backlog',
        link: '/pathology/patient-backlog',
        icon: 'ic-patient-backlog'
      },
      {
        label: 'Sample Collection',
        link: '/pathology/investigation',
        icon: 'ic-patient-investigation'
      },
      {
        label: 'Result Upload',
        link: '/pathology/patient-report',
        icon: 'ic-patient-reports'
      },
      {
        label: 'Report Entry',
        link: '/pathology/report-request',
        icon: 'ic-report-request'
      }
    ]
  },
  {
    label: 'Pharmacy',
    link: `/${MAIN_MENU_ROUTES.pharmacy}`,
    key: MAIN_MENUS_KEYS.pharmacy,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/pharmacy/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Verify Medicine',
        link: '/pharmacy/verify-medicine',
        icon: 'ic-prepare-medicine'
      },
      {
        label: 'Prepare Medicine',
        link: '/pharmacy/prepare-medicine',
        icon: 'ic-prepare-bill'
      },
      {
        label: 'Dispense Medicine',
        link: '/pharmacy/dispense-medicine',
        icon: 'ic-dispense'
      },
      {
        label: 'Return Medicine',
        link: '/pharmacy/return-medicine',
        icon: 'ic-return-medicine'
      },
      {
        label: 'Drug Interaction / Allergy',
        link: '/pharmacy/drug-interaction-allergy',
        icon: 'ic-allergy'
      }
    ]
  },
  {
    label: 'Nutrition & Meal',
    link: `/${MAIN_MENU_ROUTES.nutritionMeal}`,
    key: MAIN_MENUS_KEYS.nutritionMeal,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Prepare & Serve Meal',
        link: '/nutrition-meal/prepare-serve-meal',
        icon: 'ic-prepare-serve-meal'
      }
    ]
  },
  {
    label: 'Operation Room (OR)',
    link: `/${MAIN_MENU_ROUTES.operationRoom}`,
    key: MAIN_MENUS_KEYS.operationRoom,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/operation-room/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'OR Request',
        link: '/operation-room/or-requests',
        icon: 'ic-or-request'
      },
      {
        label: 'OR Schedule',
        link: '/operation-room/or-schedule',
        icon: 'ic-or-schedule1'
      },
      {
        label: 'Surgical Instruction',
        link: '/operation-room/surgical-instruction',
        icon: 'ic-surgical-instruction1'
      },
      {
        label: 'OR List',
        link: '/operation-room/list',
        icon: 'ic-or-list'
      }
    ]
  },
  {
    label: 'Insurance/SS/CC',
    link: `/${MAIN_MENU_ROUTES.insuranceSSCC}`,
    key: MAIN_MENUS_KEYS.insuranceSSCC,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/insurance-ss-cc/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Company Contract Management',
        link: '/insurance-ss-cc/company-contract',
        icon: 'ic-insurance-company-contract-management'
      },
      {
        label: 'Insurance Plan Management',
        link: '/insurance-ss-cc/insurance-plan',
        icon: ' ic-insurance-plan-management'
      },
      {
        label: 'Self Pay Code Management',
        link: '/insurance-ss-cc/selfpay-code',
        icon: 'ic-insurance-self-pay-management'
      }
    ]
  },
  {
    label: 'Billing',
    link: `/${MAIN_MENU_ROUTES.billing}`,
    key: MAIN_MENUS_KEYS.billing,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/billing/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Billing Pipeline',
        link: '/billing/billing-pipeline',
        icon: 'ic-billing-pipeline'
      },
      {
        label: 'Visit Billing',
        link: '/billing/visit-billing',
        icon: 'ic-visit-billing'
      },
      {
        label: 'Admission Billing',
        link: '/billing/admission-billing',
        icon: 'ic-admission-billing'
      },
      {
        label: 'Submit Claim',
        link: '/billing/submit-claim',
        icon: 'ic-submit-claim'
      },
      {
        label: 'Accept Payment',
        link: '/billing/accept-payment',
        icon: 'ic-accept-payment'
      },
      {
        label: 'Bill Management',
        link: '/billing/bill-management',
        icon: 'ic-bill-management'
      },
      {
        label: 'Patient Review',
        link: '/billing/patient-review',
        icon: 'ic-patient-review'
      }
    ]
  },
  {
    label: 'Medical Coding',
    link: `/${MAIN_MENU_ROUTES.medicalCoding}`,
    key: MAIN_MENUS_KEYS.medicalCoding,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Add Codes',
        link: '/medical-coding/add-codes',
        icon: 'ic-add-examination'
      },
      {
        label: 'Patient Review',
        link: '/medical-coding/patient-review',
        icon: 'ic-patient-review'
      }
    ]
  },
  {
    label: 'Corp. Management',
    link: `/${MAIN_MENU_ROUTES.corpManagement}`,
    key: MAIN_MENUS_KEYS.corpManagement,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Catalogs',
        link: '/corp-management/catalogs',
        icon: 'ic-corp-catalogs',
      },
      {
        label: 'Income Types',
        link: '/corp-management/income-types',
        icon: 'ic-corp-income-types',
      },
      {
        label: 'Patient Billing Code',
        link: '/corp-management/patient-billing-code',
        icon: 'ic-corp-patient-billing-code',
      },
      {
        label: 'Medical Code List',
        link: '/corp-management/medical-code',
        icon: 'ic-medical-code-list',
      },
      {
        label: 'Doctor Fee Set-up',
        link: '/corp-management/doctor-fee-setup',
        icon: 'ic-doctor-fee',
      },
      {
        label: 'Payment Type Management',
        link: '/corp-management/payment-type',
        icon: 'ic-corp-payment-type-management',
      },
      {
        label: 'Form Management',
        link: '/corp-management/form-management',
        icon: 'ic-corp-form-management',
      },
      /*{
        label: 'Diagnosis Code',
        link: '/corp-management/diagnosis-code',
        icon: 'ic-medical-code-list'
      },*/
      {
        label: 'Radiology Catalog',
        link: '/corp-management/radiology-catalog',
        icon: 'ic-radiology-catalog'
      },
      {
        label: 'Lab Catalog',
        link: '/corp-management/lab-catalog',
        icon: 'ic-pathology-catalog'
      },
      {
        label: 'Meal Catalog',
        link: '/corp-management/meal-catalog',
        icon: 'ic-meal-catalog'
      },
      {
        label: 'Internal Operation Catalog',
        link: '/corp-management/internal-operation-catalog',
        icon: 'ic-internal-operations-catalogs'
      },
      {
        label: 'Service Catalog',
        link: '/corp-management/service-catalog',
        icon: 'ic-service-catalog'
      },
      {
        label: 'Supplies Catalog',
        link: '/corp-management/supplies-catalog',
        icon: 'ic-supplies-catalog'
      },
      {
        label: 'Pharma Catalog',
        link: '/corp-management/pharma-catalog',
        icon: 'ic-pharma-catalog'
      },
      {
        label: 'Pharmacy Outlets',
        link: '/corp-management/pharmacy-outlets',
        icon: 'ic-pharmacy-outlet'
      },
      {
        label: 'Medical Package',
        link: '/corp-management/medical-package',
        icon: 'ic-medical-package'
      },
      {
        label: 'OPD Charges Setting',
        link: '/corp-management/opd-charges-setting',
        icon: 'ic-opd-charges-setting'
      }
    ]
  },
  {
    label: 'Inventory',
    link: `/${MAIN_MENU_ROUTES.inventory}`,
    key: MAIN_MENUS_KEYS.inventory,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/stock-management/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Main Stock (Inventory)',
        link: '/stock-management/main-stock-inventory',
        icon: 'ic-main-stock-inventory'
      },
      {
        label: 'Sub Stock Overview',
        link: '/stock-management/sub-stock-inventory',
        icon: 'ic-sub-stock-overview'
      },
      {
        label: 'Transfer Request',
        link: '/stock-management/transfer-request',
        icon: 'ic-sub-stock-order-backlog'
      },
      {
        label: 'Purchase Request',
        link: '/stock-management/purchase-request',
        icon: 'ic-add-purchase-request'
      },
      {
        label: 'Received Inventory',
        link: '/stock-management/received-inventory',
        icon: 'ic-receive-inventory'
      },
      {
        label: 'Inventory Management',
        link: '/stock-management/inventory-management',
        icon: 'ic-inventory-management'
      },
      {
        label: 'Purchase Request Management',
        link: '/stock-management/purchase-request-management',
        icon: 'ic-purchase-order-approval'
      }
    ]
  },
  {
    label: 'Procurement',
    link: `/${MAIN_MENU_ROUTES.procurement}`,
    key: MAIN_MENUS_KEYS.procurement,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Main Stock (Inventory)',
        link: '/procurement/main-stock-inventory',
        icon: 'ic-main-stock-inventory'
      },
      {
        label: 'Purchase Request Backlog',
        link: '/procurement/purchase-request-backlog',
        icon: 'ic-purchase-request'
      },
      {
        label: `Purchase Order Backlog`,
        link: '/procurement/purchase-order-backlog',
        icon: 'ic-purchase-order-backlog'
      },
      {
        label: 'Vendor Management',
        link: '/procurement/vendor-management',
        icon: 'ic-vendor-management'
      },
      {
        label: `Purchase Order Management`,
        link: '/procurement/purchase-order-management',
        icon: 'ic-purchase-order-approval'
      }
    ]
  },
  {
    label: 'Sub Stock Management',
    link: `/${MAIN_MENU_ROUTES.subStockManagement}`,
    key: MAIN_MENUS_KEYS.subStockManagement,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Dashboard',
        link: '/sub-stock-management/dashboard',
        icon: 'ic-dashboard'
      },
      {
        label: 'Sub Stock Inventory',
        link: '/sub-stock-management/inventory',
        icon: 'ic-sub-stock-inventory'
      },
      {
        label: 'Outgoing Transfer Request',
        link: '/sub-stock-management/transfer-request',
        icon: 'ic-outgoing-transfer-request'
      },
      {
        label: 'Incoming Transfer Request',
        link: '/sub-stock-management/requested-transfer',
        icon: 'ic-incoming-transfer-request'
      },
      {
        label: 'Inventory Management',
        link: '/sub-stock-management/inventory-management',
        icon: 'ic-inventory-management'
      },
      {
        label: 'Transfer Request Management',
        link: '/sub-stock-management/transfer-request-management',
        icon: 'ic-purchase-order-approval'
      },
    ]
  },
  {
    label: 'Medical Party',
    link: `/${MAIN_MENU_ROUTES.medicalParty}`,
    key: MAIN_MENUS_KEYS.medicalParty,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Doctor',
        link: '/medical-party/staff-doctor',
        icon: 'ic-staff-doctor'
      },
      {
        label: 'Nurse',
        link: '/medical-party/staff-nurse',
        icon: 'ic-staff-nurse'
      },
      {
        label: 'Other Staff',
        link: '/medical-party/other-staff',
        icon: 'ic-other-staff'
      },
      /*{
        label: 'Hospital Room',
        link: '/medical-party/hospital-room',
        icon: 'ic-hospital-room'
      },
      {
        label: 'Nurse Station',
        link: '/medical-party/nurse-station',
        icon: 'ic-nursing-station'
      },
      {
        label: 'Department',
        link: '/medical-party/department',
        icon: 'ic-department'
      },
      {
        label: 'Equipment Management',
        link: '/medical-party/equipment-management',
        icon: 'ic-equipment-management'
      }*/
    ]
  },
  {
    label: 'Non-Medical Party',
    link: `/${MAIN_MENU_ROUTES.nonMedicalParty}`,
    key: MAIN_MENUS_KEYS.nonMedicalParty,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Executive',
        link: '/non-medical-party/executive',
        icon: 'ic-executive'
      },
      {
        label: 'Manager',
        link: '/non-medical-party/manager',
        icon: 'ic-manager'
      },
      {
        label: 'Other Staff',
        link: '/non-medical-party/other-staff',
        icon: 'ic-other-staff'
      },
    ]
  },
  {
    label: 'Facility Resource',
    link: `/${MAIN_MENU_ROUTES.facilityResource}`,
    key: MAIN_MENUS_KEYS.facilityResource,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Hospital Room',
        link: '/medical-resource/hospital-room',
        icon: 'ic-hospital-room'
      },
      {
        label: 'Nurse Station',
        link: '/medical-resource/nurse-station',
        icon: 'ic-nursing-station'
      },
      {
        label: 'Equipment Management',
        link: '/medical-resource/equipment-management',
        icon: 'ic-equipment-management'
      }
    ]
  },
  {
    label: 'Corp Setup',
    link: `/${MAIN_MENU_ROUTES.corpSetup}`,
    key: MAIN_MENUS_KEYS.corpSetup,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Company',
        link: '/corp-setup/company',
        icon: 'ic-company'
      },
      {
        label: 'Hospital',
        link: '/corp-setup/hospital',
        icon: 'ic-hospital'
      },
      {
        label: 'Department',
        link: '/corp-setup/department',
        icon: 'ic-department'
      },
      {
        label: 'Seed Configuration',
        link: '/corp-setup/seed-configuration',
        icon: 'ic-seed-configuration'
      },
    ]
  },
  {
    label: 'User Management',
    link: `/${MAIN_MENU_ROUTES.userManagement}`,
    key: MAIN_MENUS_KEYS.userManagement,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'App Roles',
        link: '/user-management/app-roles',
        icon: 'ic-app-roles'
      },
      {
        label: 'Hospital Teams',
        link: '/user-management/hospital-team',
        icon: 'ic-hospital-team'
      },
      {
        label: 'Team Role Association',
        link: '/user-management/team-role-association',
        icon: 'ic-team-role-association'
      },
      {
        label: 'Operational Area Access',
        link: '/user-management/operational-area-access',
        icon: 'ic-operational-area'
      },
      {
        label: 'App Users',
        link: '/user-management/app-users',
        icon: 'ic-app-users'
      }
    ]
  },
  {
    label: 'AIM Setup',
    link: `/${MAIN_MENU_ROUTES.aimSetup}`,
    key: MAIN_MENUS_KEYS.aimSetup,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Migrate Data',
        link: '/aim-setup/migrate-data',
        icon: 'ic-migrate-data'
      },
      {
        label: 'Integration Setup',
        link: '/aim-setup/integration-setup',
        icon: 'ic-integration-setup'
      },
      /*{
        label: 'Integration Data',
        link: '/aim-setup/integration-data',
        icon: 'ic-integration-data'
      }*/
    ]
  },
  {
    label: 'Support Center',
    link: `/${MAIN_MENU_ROUTES.supportCenter}`,
    key: MAIN_MENUS_KEYS.supportCenter,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Support Ticket List',
        link: '/support-center/support-ticket',
        icon: 'ic-app-roles'
      }
    ]
  },
  {
    label: 'Reports',
    link: `/${MAIN_MENU_ROUTES.reports}`,
    key: MAIN_MENUS_KEYS.reports,
    hasSubMenu: true,
    subMenus: [
      {
        label: 'Inventory',
        link: '/reports/inventory-report',
        icon: 'ic-main-stock-inventory'
      },
      {
        label: 'Ordered Items Report',
        link: '/reports/order-item-report',
        icon: 'ic-app-roles'
      },
      {
        label: 'ADT Management',
        link: '/reports/adt-management-report',
        icon: 'ic-admit-transfer-request'
      },
      {
        label: 'Rapid Registration',
        link: '/reports/rapid-registration-report',
        icon: 'ic-patient'
      },
      {
        label: 'Doctor',
        link: '/reports/doctor-report',
        icon: 'ic-doctor-fee'
      },
      {
        label: 'Procurement',
        link: '/reports/procurement-report',
        icon: 'ic-vendor-management'
      },
      {
        label: 'Statistics',
        link: '/reports/statistics-report',
        icon: 'ic-seed-configuration'
      },
      {
        label: 'Management',
        link: '/reports/management-report',
        icon: 'ic-corp-catalogs'
      },
      {
        label: 'International Classification of Diseases (ICD)',
        link: '/reports/icd-report',
        icon: 'ic-add-examination'
      }
    ]
  }
];

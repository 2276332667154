import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatOptionSelectionChange} from '@angular/material/core';
import {MatFormFieldAppearance} from '@angular/material/form-field/typings/form-field';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {isNullOrUndefined} from '@v2/core/functions/functions';
import {IPageableData} from '@v2/core/models/masterdata';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {Observable, Subject, Subscription} from 'rxjs';
import {finalize, takeUntil, takeWhile} from 'rxjs/operators';
import {InfiniteScrollDirective} from '../../../shared/directive/infinite-scroll.directive';
import {FILTER_DROPDOWN_ALL_VALUE} from '../components/filter/filter-form.model';

@Component({
  selector: 'app-paginated-dropdown-control',
  templateUrl: './paginated-dropdown-control.component.html',
  styleUrls: ['./paginated-dropdown-control.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class PaginatedDropdownControlComponent implements OnInit, OnChanges, OnDestroy {

  @Input() apiCall: (filters: ICommonFilters) => Observable<IPageableData<unknown>>;
  @Input() getDropdownValue: (item: any) => string | number;
  @Input() getDropdownDisplayValue: (item: any) => string;

  @Input() control: FormControl;
  @Input() controlLabel: string;

  @Input() isMasterData = false;
  // (NOTE): 'defaultOptionLang' set default dropdown option language (used for masterData dropdown options)
  @Input() defaultOptionLang: 'eng' | 'thai';

  @Input() isFilter = false;
  @Input() isRequired = false;
  @Input() requiredMessage: string;
  @Input() disabledOptionBy: string | number;
  @Input() disabled = false;

  @Input() allowMultiSelect = false;
  @Input() showValueInChip = false;
  @Input() setFirstValueAsDefault = false;

  @Input() hintText: string
  @Input() placeHolderText: string;

  @Input() giveObject = false;
  @Input() formFieldClass = '';
  @Input() reloadData = false;
  @Input() resetDataOnReload = false;

  @Input() showLabel = true;
  @Input() appearance: MatFormFieldAppearance;

  @Input() isEditMode = false;
  @Input() panelClass = '';
  @Input() allLabel = 'All';

  @Output() optionSelected = new EventEmitter<unknown>();
  @Output() responseReceived = new EventEmitter<Array<unknown>>();

  @ViewChild(InfiniteScrollDirective, {static: false}) infiniteScrollDirective: InfiniteScrollDirective;

  totalPages = 1;
  isLoading = false;
  onScrollLoading: boolean;
  items: Array<any> = [];
  filterDropdownAllValue = FILTER_DROPDOWN_ALL_VALUE;

  filters: ICommonFilters = {
    filters: {
      page_size: 15
    },
    page: 1
  };

  private unsubscribe$ = new Subject<void>();
  private subscription: Subscription;
  private controlValueReceived = false;

  constructor() {
  }

  get isAllSelected(): boolean {
    return (this.control.value as Array<unknown> || []).includes(this.filterDropdownAllValue);
  }

  ngOnInit() {
    if (this.isEditMode) {
      const controlValue = this.control.value;
      if (controlValue) {
        this.handleInitValue(controlValue);
      } else {
        this.loadData(true);

        this.control.valueChanges.pipe(takeWhile((value, index) => !this.controlValueReceived))
          .subscribe(value => {
            this.controlValueReceived = true;
            this.handleInitValue(value);
          })
      }
    } else {
      this.loadData(true);
    }
  }

  handleInitValue(value: unknown) {
    if (value) {
      const result = this.giveObject ? Array.isArray(value) ? value.map(model => this.getDropdownValue(model)) : this.getDropdownValue(value) : value;
      if (result) {
        this.filters.filters.selectedId = result as (number | string | number[] | string[]);
        this.loadData(true);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reloadData && !changes.reloadData.firstChange) {
      if (this.resetDataOnReload) {
        this.control.setValue(null);
      }
      this.filters.page = 1;
      this.items = [];
      this.loadData(true);
    }
  }

  ngOnDestroy() {
    this.controlValueReceived = true;

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onScroll() {
    if (!this.isLoading && !this.onScrollLoading) {
      this.infiniteScrollDirective.checkForLastIndex();
      this.filters.page += 1;
      if (this.filters.page <= this.totalPages) {
        this.loadData()
      }
    }
  }

  onSelectionChanged(event: MatOptionSelectionChange, selectedData: unknown) {
    if (event.isUserInput) {
      if (isNullOrUndefined(selectedData) && this.allowMultiSelect) {
        if (!this.isAllSelected) {
          this.control.setValue(null);
        }
      }
      this.optionSelected.emit(selectedData);
    }
  }

  onChipRemove(removeItem: unknown) {
    const newValues = this.control.value.filter(value => this.getDropdownValue(value) !== this.getDropdownValue(removeItem));
    this.control.setValue(newValues);
  }

  compareWithObject = (data1: any, data2: any): boolean => {
    return !!data1 && !!data2 && this.getDropdownValue(data1) === this.getDropdownValue(data2);
  }

  compareWithId = (data1: any, data2: any): boolean => {
    return !!data1 && !!data2 && data1 === data2;
  }

  onOpened() {
    this.infiniteScrollDirective.open()
  }

  onClosed() {
    this.infiniteScrollDirective.close()
  }

  private loadData(resetData: boolean = false) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.isLoading = resetData;
    this.onScrollLoading = !resetData;

    this.subscription = this.apiCall(this.filters)
      .pipe(takeUntil(this.unsubscribe$), finalize(() => {
        this.isLoading = false;
        this.onScrollLoading = false;
      }))
      .subscribe((response: IPageableData<any>) => {
        if (response) {
          this.totalPages = response.totalPages;
          if (resetData) {
            this.items = []
          }
          this.items = [...this.items, ...response.items];
          this.infiniteScrollDirective.setActiveItem(undefined)
          if (this.setFirstValueAsDefault && this.items.length) {
            this.control.setValue(this.items[0]);
          }
          this.responseReceived.emit(this.items);
        }
      });
  }
}

import {Injectable} from '@angular/core';
import {IBillingAppointment, IDoctor} from '@v2/core/models/masterdata';
import {Observable, of, Subject} from 'rxjs';
import {catchError, map, pluck} from 'rxjs/operators';
import {NurseNoteApiService} from '../../../../core/http/services/opd/nurse';
import {PostModel} from '../../../../core/models';
import {NurseNotePostModel, NurseNoteViewModel} from '../models/nurse-note.model';

@Injectable({
  providedIn: 'root'
})
export class NurseNoteService {

  forwardedDoctor$ = new Subject<IDoctor>();

  constructor(private nurseNoteApiService: NurseNoteApiService) {
  }

  getAppointmentData(nurseId: string, appointmentId: string): Observable<IBillingAppointment> {
    return this.nurseNoteApiService.getAppointmentData(nurseId, appointmentId).pipe(
      pluck('data')
    )
  }

  postForwardToDoctor(nurseId: string, appointmentId: string, forwardToDoctorData): Observable<IBillingAppointment> {
    return this.nurseNoteApiService.postForwardToDoctor(nurseId, appointmentId, forwardToDoctorData).pipe(
      pluck('data')
    )
  }

  getNurseNotes(appointmentId: string): Observable<NurseNoteViewModel[]> {
    return this.nurseNoteApiService.getNurseNotes(appointmentId).pipe(
      pluck('data', 'items'),
      map(elements => elements.map(element => NurseNoteViewModel.mapFromApiModel(element))),
      catchError(_ => of([]))
    );
  }

  postNurseNotes(appointmentId: string, nurseNoteData: PostModel<NurseNotePostModel, string>): Observable<NurseNoteViewModel[]> {
    return this.nurseNoteApiService.putNurseNotes(appointmentId, nurseNoteData).pipe(
      pluck('data', 'items'),
      map(elements => elements.map(note => NurseNoteViewModel.mapFromApiModel(note)))
    );
  }

}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material';
import {TranslocoModule} from '@ngneat/transloco';
import {SignaturePadModule} from 'angular2-signaturepad';
import {Ng2FittextModule} from 'ng2-fittext';
import {SignatureControlComponent} from './signature-control.component';

@NgModule({
  declarations: [SignatureControlComponent],
  exports: [SignatureControlComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FlexModule,
    MatButtonModule,
    Ng2FittextModule,
    SignaturePadModule,
    FormsModule
  ]
})
export class SignatureControlModule {
}

import {IMedication} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';

export class CurrentMedicationViewModel extends ConcurrencyRequest {

  id: string;
  medicineName: string;
  medicineId: string;
  days: number;
  prescribedBy: string;

  static mapFromApiModel(apiData: IMedication): CurrentMedicationViewModel {
    const viewModel = new CurrentMedicationViewModel();
    viewModel.id = apiData.id;
    viewModel.medicineName = apiData.medicine && apiData.medicine.brandName;
    viewModel.medicineId = apiData.medicineId;
    viewModel.days = apiData.days;
    viewModel.prescribedBy = apiData.prescribedBy && apiData.prescribedBy.fullName;
    return viewModel;
  }
}

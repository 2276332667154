import {formatDateTime, getName, isArray} from '@v2/core/functions/functions';
import {PatientNameTranslation, translatePatientName} from '@v2/core/functions/patient-name-translation';
import {
  IBillingAppointment,
  IBookedNextAppointment,
  IMasterData,
  INoteAlert,
  IOPDSummary,
  IPatient,
  IPatientDOBInfo,
  IVisit,
  IWardMinimal,
} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {DiagnosisViewModel} from '@v2/core/models/view/diagnosis.view.model';
import {DoctorNoteViewModel} from '@v2/core/models/view/doctor-note.view.model';
import {OpdVitalsViewModel} from '@v2/core/models/view/opd-vitals.view.model';
import {PhysicalExaminationViewModel} from '@v2/core/models/view/physical-examination.view.model';
import {SystemicReviewViewModel} from '@v2/core/models/view/systemic-review.view.model';
import {TodaysAppointmentListViewModel} from '../../../../rapid-registration/patient/models/view/todays-appointment-list.view.model';
import {MedicationFormDataModel} from '../../../shared-v2/components/patient-order/resources/models/form/medication-form.model';
import {PathologyFormDataModel} from '../../../shared-v2/components/patient-order/resources/models/form/pathology-form.model';
import {RadiologyFormDataModel} from '../../../shared-v2/components/patient-order/resources/models/form/radiology-form.model';
import {SupplyFormDataModel} from '../../../shared-v2/components/patient-order/resources/models/form/supply-form.model';

export class AppointmentViewModel extends ConcurrencyRequest {
  patientId: string;
  profilePicPath: string;
  patientObj: PatientNameTranslation | IPatientDOBInfo;
  patientXref: string;
  patientGender: string;
  patientDOB: string;
  appointmentId: string;
  appointmentXref: string;
  appointmentDate: string;
  isTriage: boolean;
  departmentName: string;
  doctorName: string;
  note: string;
  isBillFinalized: boolean;
  isNSCheckedIn: boolean;
  claimId: string;
  claimUpdatedAt: string;

  static mapFromHttpModel(httpModel: IBillingAppointment): AppointmentViewModel {
    const viewData = new AppointmentViewModel();
    if (httpModel.patient) {
      viewData.patientObj = httpModel.patient;
      viewData.patientId = httpModel.patient.patientId;
      viewData.profilePicPath = httpModel.patient.profilePicPath;
      viewData.patientXref = httpModel.patient.xref;
      viewData.patientGender = httpModel.patient.gender ? httpModel.patient.gender.label : '';
      viewData.patientDOB = httpModel.patient.dob ? formatDateTime(httpModel.patient.dob) : '';
    }
    viewData.appointmentId = httpModel.appointmentId;
    viewData.appointmentXref = httpModel.appointmentId_xref;
    viewData.appointmentDate = httpModel.aptScheduledDate;
    viewData.isTriage = httpModel.isTriage;
    viewData.departmentName = httpModel.departmentName;
    viewData.doctorName = httpModel.doctorName;
    viewData.note = httpModel.note;
    viewData.isNSCheckedIn = httpModel.isNSCheckedIn;
    if (httpModel.claim) {
      viewData.isBillFinalized = httpModel.claim.billFinalized;
      viewData.claimId = httpModel.claim.claimId;
      viewData.claimUpdatedAt = httpModel.claim.updatedAt;
    }
    viewData.mapConcurrencyRequest(httpModel);
    return viewData;
  }

  static mapFromEditAppointment(httpData: TodaysAppointmentListViewModel): AppointmentViewModel {
    const data = new AppointmentViewModel();
    if (httpData.patientObj) {
      data.patientObj = httpData.patientObj;
      data.patientId = httpData.patientObj.patientId;
      data.profilePicPath = httpData.profilePicPath;
      data.patientXref = httpData.patientXref;
      data.patientGender = httpData.gender;
      data.patientDOB = httpData.dob ? formatDateTime(httpData.dob) : '';
    }
    data.appointmentId = httpData.appointmentId;
    data.appointmentXref = httpData.appointmentXref;
    data.appointmentDate = httpData.appointmentDate;
    data.isTriage = httpData.isTriage;
    data.departmentName = httpData.departmentName;
    data.doctorName = httpData.doctorName;
    data.note = httpData.note;
    data.isNSCheckedIn = httpData.isNSCheckedIn;
    data.isBillFinalized = httpData.isBillFinalized;
    data.claimId = httpData.claimId;
    data.claimUpdatedAt = httpData.claimUpdatedAt;
    data.mapConcurrencyRequest(httpData);
    return data
  }
}

export class AppointmentNoteViewModel {
  note: string;
  updatedAt: string;
  updatedBy: string;

  static mapFromHttpResponse(response: INoteAlert): AppointmentNoteViewModel {
    const model = new AppointmentNoteViewModel();

    model.note = response.note;
    model.updatedAt = response.changedAt;
    if (response.changedBy) {
      model.updatedBy = getName(response.changedBy.firstName, response.changedBy.lastName);
    }
    return model;
  }
}

export class BookedAppointmentViewModel {
  appointmentId: string;
  appointmentIdXref: string;
  appointmentDate: string;
  appointmentTime: string;
  referredBy: string;
  severityStatus: string;
  visitType: string;
  departmentName: string;
  doctorName: string;
  preferredRoom: string;
  preferredRooms: Array<IMasterData>;
  preferredWard: string;
  preferredWards: Array<IWardMinimal>;
  appointmentNote: string;
  requestedBy: string;
  requestedDateTime: string;
  linkedOPDAppts: Array<IVisit>;
  isLinkedWithCurrentAppointment: boolean;

  static mapFromHttpModel(httpData: IBookedNextAppointment, currentAppointmentId?: string): BookedAppointmentViewModel {
    const viewData = new BookedAppointmentViewModel();
    viewData.appointmentId = httpData.appointmentId;
    viewData.appointmentIdXref = httpData.appointmentId_xref;
    viewData.appointmentDate = httpData.aptScheduledDate;
    viewData.appointmentTime = httpData.aptScheduledDate;
    viewData.referredBy = httpData.suggestedBy;
    viewData.severityStatus = httpData.severityStatus ? httpData.severityStatus.label : '';
    viewData.visitType = httpData.visitType;
    viewData.departmentName = httpData.departmentName;
    viewData.doctorName = httpData.doctorName;
    viewData.preferredRooms = isArray(httpData.preferredRoomTypes) ? httpData.preferredRoomTypes : [];
    viewData.preferredRoom = viewData.preferredRooms.map(type => type.label).join(', ');
    viewData.preferredWards = isArray(httpData.preferredWards) ? httpData.preferredWards.filter(ward => !!ward).map(ward => ({
      ...ward,
      wardId: ward.id,
      wardTitle: ward.title
    })) : [];
    viewData.preferredWard = viewData.preferredWards.map(ward => ward.wardTitle).join(', ');
    viewData.appointmentNote = httpData.appointmentNote ? httpData.appointmentNote.note : '';
    viewData.requestedBy = httpData.apptRequestedBy;
    viewData.requestedDateTime = httpData.apptRequestedTime;
    viewData.linkedOPDAppts = isArray(httpData.linkedOPDAppts) ? httpData.linkedOPDAppts.map((appt) => ({...appt, isSelected: true})) : [];
    viewData.isLinkedWithCurrentAppointment = viewData.linkedOPDAppts.some(appt => appt.id === currentAppointmentId);
    return viewData;
  }
}

export class BookedAppointmentPatientInfoViewModel {
  appointmentIdXref: string;
  appointmentDate: string;
  requestedBy: string;
  requestedDateTime: string;
  visitType: string;
  departmentName: string;
  doctorName: string;
  appointmentNote: string;
  patientProfilePic: string;
  patientName: string;
  patientXref: string;
  patientDOB: string;
  patientAge: number;
  patientBloodGroup: string;
  patientContactNo: string;
  patientEmail: string;
  paymentType: string;
  nationalId: string;
  patientObj: PatientNameTranslation;
  patient: IPatient;
  departmentId: string;
  doctorId: string;

  static mapFromBookedAppointmentViewModel(data: BookedAppointmentViewModel): BookedAppointmentPatientInfoViewModel {
    const model = new BookedAppointmentPatientInfoViewModel();
    model.appointmentIdXref = data.appointmentIdXref;
    model.requestedBy = data.requestedBy;
    model.requestedDateTime = data.requestedDateTime;
    model.visitType = data.visitType;
    model.departmentName = data.departmentName;
    model.doctorName = data.doctorName;
    model.appointmentNote = data.appointmentNote;
    model.appointmentDate = data.appointmentDate;
    return model;
  }

  static mapFromAppointmentHttpModel(httpData: IBillingAppointment): BookedAppointmentPatientInfoViewModel {
    const model = new BookedAppointmentPatientInfoViewModel();
    if (httpData.patient) {
      model.patientObj = httpData.patient;
      model.patientProfilePic = httpData.patient.profilePicPath;
      model.patientName = translatePatientName(httpData.patient);
      model.patientXref = httpData.patient.xref;
      model.patientAge = httpData.patient.age;
      model.patientDOB = httpData.patient.dob;
      model.patientBloodGroup = httpData.patient.bloodGroup;
      model.patientContactNo = httpData.patient.phoneNumber;
      model.patientEmail = httpData.patient.email;
      model.nationalId = httpData.patient.nationalityId;
      model.patient = httpData.patient;

      if (isArray(httpData.patient.paymentPlans)) {
        model.paymentType = httpData.patient.paymentPlans
          .map((plan) => (plan.paymentType ? plan.paymentType.name : ''))
          .filter((type) => !!type)
          .join(' + ');
      }
    }
    model.departmentId = httpData.departmentId;
    model.doctorId = httpData.doctorId;
    return model;
  }
}

export class OpdSummaryViewModel {
  vitals: Array<OpdVitalsViewModel>;
  chiefComplaints: Array<string>;
  presentIllness: Array<string>;
  physicalExamination: PhysicalExaminationViewModel;
  systemReview: SystemicReviewViewModel;
  nurseNotes: Array<string>;
  diagnosis: Array<DiagnosisViewModel>;
  doctorNotes: Array<DoctorNoteViewModel>;
  radiologyOrders: Array<RadiologyFormDataModel>;
  labOrders: Array<PathologyFormDataModel>;
  treatmentOrders: Array<MedicationFormDataModel>;
  prescriptionOrders: Array<MedicationFormDataModel>;
  supplyOrders: Array<SupplyFormDataModel>;
  nextAppointments: Array<BookedAppointmentViewModel>;
  followUpAppointments: Array<BookedAppointmentViewModel>;
  admitRequests: Array<BookedAppointmentViewModel>;
  patientObj: IPatient;
  drugAllergy: string;
  visitXref: string;
  visitDateTime: string;
  department: string;
  doctor: string;

  static mapFromHttpModel(httpData: IOPDSummary): OpdSummaryViewModel {
    const viewData = new OpdSummaryViewModel();
    viewData.vitals = isArray(httpData.vitals) ? httpData.vitals.map((vital) => OpdVitalsViewModel.mapFromHttpModel(vital)) : [];
    viewData.chiefComplaints = isArray(httpData.chiefComplaints) ? httpData.chiefComplaints.map((complaint) => complaint.note) : [];
    viewData.presentIllness = isArray(httpData.presentIllness) ? httpData.presentIllness.map((complaint) => complaint.note) : [];
    viewData.physicalExamination = httpData.physicalExamination
      ? PhysicalExaminationViewModel.mapFromHttpModel(httpData.physicalExamination)
      : null;
    viewData.systemReview = httpData.systemicReview ? SystemicReviewViewModel.mapFromHttpModel(httpData.systemicReview) : null;
    viewData.nurseNotes = isArray(httpData.nurseNote) ? httpData.nurseNote.map((note) => note.note) : [];
    viewData.diagnosis = isArray(httpData.diagnosis)
      ? httpData.diagnosis.map((diagnosis) => DiagnosisViewModel.mapFromHttpModel(diagnosis))
      : [];
    viewData.doctorNotes = isArray(httpData.doctorNote)
      ? httpData.doctorNote.map((note) => DoctorNoteViewModel.mapFromHttpModel(note))
      : [];
    return viewData;
  }

  static getInitialModel(
    visitXref: string,
    appointmentDate: string,
    patient: IPatient,
    department: string,
    doctor: string
  ): OpdSummaryViewModel {
    const viewData = new OpdSummaryViewModel();
    viewData.vitals = [];
    viewData.chiefComplaints = [];
    viewData.presentIllness = [];
    viewData.physicalExamination = null;
    viewData.systemReview = null;
    viewData.nurseNotes = [];
    viewData.diagnosis = [];
    viewData.doctorNotes = [];
    viewData.radiologyOrders = [];
    viewData.labOrders = [];
    viewData.treatmentOrders = [];
    viewData.prescriptionOrders = [];
    viewData.supplyOrders = [];
    viewData.nextAppointments = [];
    viewData.followUpAppointments = [];
    viewData.admitRequests = [];
    viewData.patientObj = patient;
    viewData.drugAllergy = null;
    viewData.visitXref = visitXref;
    viewData.visitDateTime = appointmentDate;
    viewData.department = department;
    viewData.doctor = doctor;
    return viewData;
  }
}

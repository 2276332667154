import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';
import {TableBadgeComponent} from './table-badge.component';

@NgModule({
  declarations: [TableBadgeComponent],
  imports: [
    CommonModule,
    TranslocoModule
  ],
  exports: [TableBadgeComponent]
})
export class TableBadgeModule {
}

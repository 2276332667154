import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material';
import {RouterModule} from '@angular/router';
import {SecondaryMenuComponent} from './secondary-menu.component';

@NgModule({
  declarations: [
    SecondaryMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule
  ],
  exports: [
    SecondaryMenuComponent
  ]
})
export class SecondaryMenuModule {
}

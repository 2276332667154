import {ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import {MaybeArray, TRANSLOCO_LANG, TRANSLOCO_SCOPE, TranslocoScope, TranslocoService} from '@ngneat/transloco';
import {TranslationLanguageEnum} from '@v2/core/enums/translation-language.enum';
import {IFSOFacilityResource} from '@v2/core/models/masterdata/IFSOFinancialServiceOrg';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/internal/operators/take';

@Pipe({
  name: 'fsoFacilityResourceTranslation'
})
export class FsoFacilityResourceTranslationPipe implements PipeTransform {

  private subscription: Subscription | null = null;

  private lastValue = '';
  private lastKey: string | undefined;
  private listenToLangChange: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
    @Optional() @Inject(TRANSLOCO_LANG) private providerLang: string | null,
    @Optional() @Inject(TRANSLOCO_SCOPE) private providerScope: MaybeArray<TranslocoScope>
  ) {
    this.listenToLangChange = this.shouldListenToLangChanges(this.translocoService, this.providerLang);
  }

  transform(facilityResourceName: IFSOFacilityResource): string {
    const key = facilityResourceName.facilityResourceDesc;
    if (key === this.lastKey) {
      return this.lastValue;
    }
    this.lastKey = key;
    this.unsubscribe();
    this.subscription = this.translocoService.langChanges$
      .pipe(this.listenOrNotOperator(this.listenToLangChange))
      .subscribe((activeLang: string) => this.updateValue(facilityResourceName, activeLang));

    return this.lastValue;
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateValue(item: IFSOFacilityResource, activeLang: string) {
    this.lastValue = this.resolveValue(item, activeLang);
    this.cdr.markForCheck();
  }

  private resolveValue(item: IFSOFacilityResource, activeLang: string): string {
    if (!activeLang || !item) {
      return null;
    }

    switch (activeLang) {
      case TranslationLanguageEnum.ENGLISH:
        return !!item.facilityResourceDesc ? item.facilityResourceDesc : item.thaiFacilityResourceDesc;
      case TranslationLanguageEnum.THAI:
        return !!item.thaiFacilityResourceDesc ? item.thaiFacilityResourceDesc : item.facilityResourceDesc;
      default:
        return item.facilityResourceDesc;
    }
  }

  private listenOrNotOperator(listenToLangChange: boolean) {
    return listenToLangChange ? source => source : take(1);
  }

  private getPipeValue(str: string, value: string, char = '|'): [boolean, string] {
    if (typeof str === 'string') {
      const splitted = str.split(char);
      const lastItem = splitted.pop();
      return lastItem === value ? [true, splitted.toString()] : [false, lastItem];
    }

    return [false, ''];
  }

  private shouldListenToLangChanges(service: TranslocoService, lang: string) {
    const [hasStatic] = this.getPipeValue(lang, 'static');
    if (hasStatic === false) {
      // If we didn't get 'lang|static' check if it's set in the global level
      return service.config.reRenderOnLangChange;
    }

    // We have 'lang|static' so don't listen to lang changes
    return false;
  }

}

import {getStartOfDay} from '@v2/core/functions/functions';
import {ConcurrencyPayload} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IDeletableEntity} from '@v2/core/models/masterdata/IPost.master-data';
import {NoteAlertFormDataModel} from '../form/note-alert-form.model';

export class NoteAlertPayload extends ConcurrencyPayload {
  note: string;
  notificationArea: IDeletableEntity<string, string>;
  severity: number;
  patientId: string;
  appointmentId: string;
  onlyForCurrentAppointment: boolean;
  validityStartDT: string;
  validityEndDT: string;

  static mapFromFormModel(patientId: string, appointmentId: string, formData: NoteAlertFormDataModel, deletedNotificationAreas = [], updateNoteAlert = false) {
    const payload = new NoteAlertPayload();

    payload.note = formData.note;
    payload.notificationArea = {items: formData.notificationArea, deletedItems: deletedNotificationAreas};
    payload.severity = formData.severity;
    payload.patientId = patientId;
    payload.appointmentId = appointmentId;
    payload.onlyForCurrentAppointment = formData.onlyForCurrentAppointment;
    payload.validityStartDT = getStartOfDay(formData.validityStartDT);
    payload.validityEndDT = formData.validityEndDT;
    payload.mapConcurrencyPayload(formData);

    return payload;
  }
}
